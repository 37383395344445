import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ResponsiveContainer } from 'recharts';
import { flex } from '../../../../../components/AvThemeProvider';
import AvTooltip from '../../../../../components/AvTooltip';
import AvComposedWidget from '../../../../../components/Widgets/AvComposedWidget';
import { ChartClickInteraction } from '../../../../../components/Widgets/types';
import { getPercent, uniqBy } from '../../../../../utils/Utils';
import { getDateFormatFromWidget } from '../../../EditCustomDashboard/utils';
import { useGetDisplayName } from '../../../hooks';
import { ColoringRules, tileTitleSX, TrendTypeWidget } from '../../../types';
import { getTimeRangeDescription, Orientation } from '../../../Utils';
import ClickableTitle from './ClickableTitle';
import { ReactComponent as Down } from '../../../../../assets/Down.svg';
import { ReactComponent as Up } from '../../../../../assets/Up.svg';

export const getArrowIndication = (coloringRule: ColoringRules | undefined, comparisonDifference, theme) => {
  const commonSx = { ...flex.justifyStartCenter, fontSize: 13, fontWeight: 600, mr: 0.5, ml: -0.5 };
  const displayNumber = `${getPercent(Math.abs(comparisonDifference))} `;
  if (parseInt(comparisonDifference) === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          ...flex.justifyStartCenter,
          fontSize: '14px',
          fontWeight: 600,
          color: theme => theme.palette.colors.neutrals[500],
        }}>
        No change
      </Box>
    );
  }
  if (coloringRule === ColoringRules.UP_IS_GOOD) {
    return comparisonDifference > 0 ? (
      <Box sx={{ ...commonSx, color: theme.palette.colors.positive[500] }}>
        <Up style={{ color: theme.palette.colors.positive[500] }} />
        {displayNumber}
      </Box>
    ) : (
      // +x is good
      <Box sx={{ ...commonSx, color: theme.palette.colors.negative[500] }}>
        <Down style={{ color: theme.palette.colors.negative[500] }} />
        {displayNumber}
      </Box>
    ); // -x is bad
  }
  if (coloringRule === ColoringRules.DOWN_IS_GOOD) {
    return comparisonDifference > 0 ? (
      <Box sx={{ ...commonSx, color: theme.palette.colors.negative[500] }}>
        <Up style={{ color: theme.palette.colors.negative[500] }} />
        {displayNumber}
      </Box>
    ) : (
      // +x is bad
      <Box sx={{ ...commonSx, color: theme.palette.colors.positive[500] }}>
        <Down style={{ color: theme.palette.colors.positive[500] }} />
        {displayNumber}
      </Box>
    ); // -x is good
  }
  return undefined;
};
export const getComparisonDifference = (data, metric) => {
  if (data[data.length - 1][metric] === 0 && data[0][metric] === 0) {
    return '0';
  }
  return ((data[data.length - 1][metric] / data[0][metric] - 1) * 100).toFixed(1);
};

const verticalSx = {
  px: 1,
  flexShrink: 0,
  ...flex.colJustifyAround,
  height: '100%',
};

const horizontalSx = {
  height: '100%',
  width: '100%',
  gap: 1,
  ...flex.justifyStartCenter,
};

export interface TrendWidgetProps {
  data: any[];
  metric: string;
  orientation?: Orientation;
  trendWidth?: number | string;
  showPercentage?: boolean;
  widget: TrendTypeWidget;
  clickInteractions?: ChartClickInteraction[];
}

const TrendTileWidget = ({
  data,
  metric,
  widget,
  orientation = Orientation.Vertical,
  trendWidth = '100%',
  showPercentage = true,
  clickInteractions,
}: TrendWidgetProps) => {
  const theme = useTheme();
  const {
    select: { dims, metrics },
    timeRange,
    projectionId: { name: projName },
  } = widget.requests[0];
  const { coloringRule } = widget.definition.custom;
  const dateFormat = getDateFormatFromWidget(widget);
  const timeRangeDescription = getTimeRangeDescription(timeRange!);
  const getDisplayName = useGetDisplayName(projName);
  const dataRow = data?.map(
    row =>
      data
        ?.filter(v => v[dims[0]?.name] === row[dims[0]?.name])
        .reduce(
          (acc, v) => ({
            date: v[dims[0]?.name],
            ...acc,
            ...metrics.reduce((acc, metric) => ({ ...acc, [metric.alias || 0]: v[metric.alias || 0] }), {}),
          }),
          {}
        )
  );
  const lineData = uniqBy(dataRow, v => v.date);
  const series = [{ name: getDisplayName(metric), color: theme.palette.colors.primary[500], type: 'line', dataKey: metric }];
  const currentValue = data[data.length - 1][metric];
  const comparisonDifference = getComparisonDifference(data, metric);
  return (
    <Box sx={orientation === Orientation.Vertical ? verticalSx : horizontalSx}>
      <Box sx={orientation === Orientation.Vertical ? flex.justifyStartEnd : { ...flex.justifyStartCenter, width: 200 }}>
        {showPercentage && (
          <ClickableTitle
            value={currentValue}
            metricName={metric}
            projName={projName}
            titleSx={tileTitleSX}
            clickInteractions={clickInteractions}
          />
        )}
        <Box
          sx={{
            mb: orientation === Orientation.Vertical ? 0.8 : undefined,
            ml: orientation === Orientation.Vertical ? 1 : 0,
            color: theme.palette.colors.neutrals[600],
            fontSize: 13,
            fontWeight: 400,
            ...flex.justifyStartCenter,
            flexShrink: 0,
            gap: orientation === Orientation.Vertical ? 1 : undefined,
          }}>
          {getArrowIndication(coloringRule, comparisonDifference, theme)}
          <Typography>
            <AvTooltip hasWidthLimit sx={orientation === Orientation.Horizontal ? { width: 100 } : {}}>
              {((orientation === Orientation.Horizontal && parseInt(comparisonDifference) !== 0) || orientation === Orientation.Vertical) &&
                timeRangeDescription}
            </AvTooltip>
          </Typography>
        </Box>
      </Box>
      <Box />
      <ResponsiveContainer
        minHeight={0}
        style={orientation === Orientation.Vertical ? { marginBottom: 50, marginTop: 10 } : undefined}
        width={trendWidth}>
        <AvComposedWidget
          xAxisKey="date"
          isDate
          data={lineData}
          dateFormat={dateFormat}
          series={series}
          showLegend={false}
          defaultAxisProps={{ x: { hide: true }, y: { hide: true } }}
          showCartesianGridProps={{ vertical: false, horizontal: false }}
          tooltipComponentProps={{ allowEscapeViewBox: { x: false, y: true } }}
          responsiveContainerProps={{ sx: { overflow: 'visible' } }}
        />
      </ResponsiveContainer>
    </Box>
  );
};
export default TrendTileWidget;
