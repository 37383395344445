import React, { useContext, useEffect, useState } from 'react';
import { Button, capitalize, Collapse, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/system';
import { flex } from '../../../components/AvThemeProvider';
import { formatDate } from '../../../components/DatePicker/utils';
import { ConditionType, operatorLabels } from '../../../components/filters/Utils';
import { useAvContext } from '../../../context/AvContextProvider';
import { FeatureFlags } from '../../../types';
import { DateCondition, Expression, Filter } from '../../../types/filter.types';
import { iconSize, isNullOrUndefined } from '../../../utils/Utils';
import { typeLabelMap } from '../../Sources/Mapping/mapping.types';
import { CustomDashboardContext } from '../CustomDashboardContext';
import { useGetDisplayName } from '../hooks';
import { SortBy, TopResults } from '../types';
import { TabsType } from '../types/types';
import { ArrowDownIcon } from './SelectedFieldsReorder';
import { formatTimeRange } from './TimeFilter/utils';
import { ReactComponent as Edit } from '../../../assets/Edit.svg';
import { ReactComponent as History } from '../../../assets/History.svg';
import { ReactComponent as Plus } from '../../../assets/Plus.svg';
import { ReactComponent as SortDown } from '../../../assets/Sort Down.svg';

const plusIcon = <Plus style={iconSize(16)} />;
const editIcon = <Edit style={iconSize(16)} />;
const ArrowIcon = isUp => <SortDown style={{ ...iconSize(16), transform: `rotate(${isUp ? -180 : 0}deg)` }} />;

const textStyle = size => ({ fontSize: size === 'small' ? 12 : undefined });
const HEIGHT = 42;
type FiltersDescriptionProps = {
  isFocus: boolean;
};
const FiltersDescription: React.FC<FiltersDescriptionProps> = ({ isFocus }) => {
  const theme = useTheme();

  const { newWidget, filters, setFilters } = useContext(CustomDashboardContext);
  const {
    requests: [request],
  } = newWidget;
  const { filter, top, sorting, timeRange } = request;
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (!isCollapse) {
      setIsCollapse(isFocus);
    }
  }, [isFocus]);

  const openFiltersTab = () => setFilters({ ...filters, tab: [TabsType.Filters] });

  return (
    <Box
      sx={{
        ...flex.col,
        backgroundColor: isFocus ? theme.palette.colors.primary[100] : theme.palette.colors.neutrals[100],
        borderRadius: '12px',
      }}>
      <Box
        onClick={() => setIsCollapse(!isCollapse)}
        sx={{
          ...flex.itemsCenter,
          px: '10px',
          height: HEIGHT,
          gap: 1,
        }}>
        {ArrowDownIcon(isCollapse, isFocus, theme)}
        <Typography
          sx={{ fontSize: 11, color: isFocus ? theme.palette.colors.neutrals[600] : theme.palette.colors.neutrals[500] }}
          variant="h7">
          Filters
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* @ts-ignore */}
        <Button onClick={openFiltersTab} sx={{ p: '3px' }} active={isFocus} size="xSmall" variant="outlined" type="icon">
          {filter ? editIcon : plusIcon}
        </Button>
      </Box>
      <Collapse in={isCollapse} timeout="auto" unmountOnExit>
        <Box sx={{ px: 2, pb: filter ? 1 : 0 }}>
          <FilterToText filter={filter} top={top} sorting={sorting[0]} projectionId={request.projectionId.name} timeRange={timeRange} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default FiltersDescription;

interface OneLevelTextConditionProps {
  expression: Expression;
  index: number;
  arr: any[];
  operator: string;
  isMainCondition?: boolean;
  projectionId: string;
  size?: 'small' | 'medium';
}
export const OneLevelTextCondition: React.FC<OneLevelTextConditionProps> = ({
  projectionId,
  expression,
  index,
  arr,
  operator,
  isMainCondition = false,
  size = 'small',
}) => {
  const {
    accountEntities: { fieldTypeMap },
  } = useAvContext();
  const innerExpression = expression?.arrayCondition?.underlying || expression;
  const fieldName = innerExpression?.fieldName;
  const typeCondition = ConditionType[typeLabelMap[fieldTypeMap[fieldName || '']]] || ConditionType.string;
  const getDisplayName = useGetDisplayName(projectionId);
  const value = innerExpression?.[typeCondition]?.[Object.keys(innerExpression?.[typeCondition] || {})[0]];

  const displayValue = isNullOrUndefined(value)
    ? ''
    : value.values
      ? value.values?.map(v => formatDate(new Date(v), 'MMM dd, yyyy'))?.join(' - ') || ''
      : value?.unit
        ? `${capitalize(value.unit)} ${value.value} ${capitalize(value.periodBehaviour)}`
        : typeof value === 'string'
          ? `"${value}"`
          : typeof value === 'boolean'
            ? capitalize(value.toString())
            : typeof value === 'object'
              ? Object.keys(value).length
                ? JSON.stringify(value)
                : ''
              : value.toString();

  return (
    <Box style={textStyle(size)}>
      {index === 0 && !isMainCondition && '('}
      <span style={textStyle(size)}>{getDisplayName(fieldName)}</span>{' '}
      <span style={{ fontWeight: 600, ...textStyle(size) }}>
        {
          operatorLabels[
            `${Object.keys(innerExpression?.[typeCondition] || {})[0]}${
              expression?.arrayCondition?.resolution ? `|${expression.arrayCondition.resolution}` : ''
            }`
          ]
        }{' '}
      </span>{' '}
      <span style={textStyle(size)}>{displayValue}</span>{' '}
      {index === arr.length - 1 ? (
        ''
      ) : (
        <Typography
          component="span"
          sx={{
            fontWeight: 600,
            color: ({ palette }) => (operator === 'and' ? palette.colors.primary[400] : palette.colors.complementary[600]),
            ...textStyle(size),
          }}>
          {operator.toUpperCase()}{' '}
        </Typography>
      )}
      {index === arr.length - 1 && !isMainCondition && ')'}
    </Box>
  );
};
interface FilterToTextProps {
  filter?: Filter;
  top?: TopResults;
  sorting?: SortBy;
  projectionId: string;
  size?: 'small' | 'medium';
  oneLine?: boolean;
  timeRange?: DateCondition;
}
export const FilterToText: React.FC<FilterToTextProps> = ({ filter, sorting, top, projectionId, size = 'small', oneLine, timeRange }) => {
  const { featureFlags } = useAvContext();
  const getDisplayName = useGetDisplayName(projectionId);

  return (
    <Box sx={{ ...flex.col, gap: 1, ...(oneLine ? { overflow: 'hidden' } : undefined) }}>
      {featureFlags[FeatureFlags.HistoricalAnalytics] && timeRange && (
        <Box sx={{ ...flex.itemsCenter, gap: '6px' }}>
          <History />
          <Typography sx={{ fontWeight: 600 }}>Historical</Typography>
          <Divider orientation="vertical" sx={{ height: '15px' }} />
          <Typography sx={{ fontSize: '12px' }}>{formatTimeRange(timeRange)}</Typography>
        </Box>
      )}
      {top?.size && (
        <Box sx={textStyle(size)}>
          Top <b>{top.size}</b>
        </Box>
      )}
      {(sorting || top) && (
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {sorting && (
            <>
              <Box sx={textStyle(size)}>Sorted By {getDisplayName(sorting.name)}</Box>
              {ArrowIcon(sorting.dir === 'ASC')}
            </>
          )}
        </Box>
      )}
      <Box sx={oneLine ? { whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis', '> div': { display: 'inline' } } : undefined}>
        {filter
          ? filter[Object.keys(filter)[0]]?.operands?.map((operandObject, index, arr) => {
              if (operandObject.expression) {
                return (
                  <OneLevelTextCondition
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    projectionId={projectionId}
                    expression={operandObject.expression}
                    index={index}
                    arr={arr}
                    operator={Object.keys(filter)[0]}
                    isMainCondition
                    size={size}
                  />
                );
              }
              return operandObject[Object.keys(operandObject)[0]]?.operands?.map((operandObjectLevel2, index, arr) => (
                <OneLevelTextCondition
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  projectionId={projectionId}
                  expression={operandObjectLevel2.expression}
                  index={index}
                  arr={arr}
                  operator={Object.keys(operandObject)[0]}
                  size={size}
                />
              ));
            })
          : undefined}
      </Box>
    </Box>
  );
};
