import { useTheme } from '@mui/material';
import { SCATTER_COLORS } from '../components/AvThemeProvider.tsx';
import { rebranding } from '../rebranding.ts';
import { NO_VALUE, OTHERS } from '../views/CustomDashboards/constants.ts';
import { queryOrderBy } from '../views/Tickets/hooks';
import { entityViewConfig } from './entityViewConfig.ts';
import { generateStringList, isNullOrUndefined } from './Utils';

const getRiskStatusPercentage = (data, metricName, days) => {
  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
  const isComingSoon = sortedData[0][metricName] === 0 && sortedData[1][metricName] === 0;
  const timeComparison = isComingSoon ? '0' : ((sortedData[1][metricName] / sortedData[0][metricName] - 1) * 100).toFixed(1);
  const transData = {
    id: sortedData[1].id,
    value: sortedData[1][metricName],
    isComingSoon,
    timeComparison: timeComparison || 0,
    timeComparisonDays: days,
  };
  return [transData];
};

const widgetData = {
  sources: {
    title: 'Sources',
    sql: () =>
      `SELECT
           EXPLODE(finding.source_names) as name,
           active_findings_granular AS findingCount,
           total_assets_granular AS assetCount
       FROM uber_findings
       WHERE finding.state = 'ACTIVE'
       GROUP BY name
       ORDER BY findingCount DESC`,
  },
  findingDuplication: {
    title: 'Finding Duplication',
    sql: () =>
      `SELECT
           active_findings_granular AS finding_count,
           open_tickets_granular AS ticket_count,
           active_tickets_granular AS active_tickets
       FROM uber_findings`,
  },
  activeTicketsProgress: {
    title: 'Open Tickets by Current Status',
    sql: where =>
      `SELECT ticket.status.name as status, ticket.status.status_bucket.name as bucket, 
        ticket.status.status_bucket.order_by as bucketorder, open_tickets_granular as count
        FROM tickets ${where} ${where ? 'AND' : 'WHERE'} ticket.state IN ('ACTIVE', 'INACTIVE')
        AND ticket.status.status_bucket.closed != true GROUP BY status, bucket, bucketorder ORDER BY bucketorder
        `,
    transformData: data => {
      const { id, ...dataWithoutId } = data[0];
      if (Object.values(dataWithoutId).every(isNullOrUndefined)) {
        return null;
      }
      return data;
    },
  },
  activeTicketsProgressOverTime: {
    title: 'Active Tickets Progress Over Time',
    sql: (where, timeArgs) =>
      `SELECT TIME_BUCKET(${timeArgs}) AS date,
       critical_open_tickets_granular AS CRITICAL,
       high_open_tickets_granular AS HIGH,
       medium_open_tickets_granular AS MEDIUM,
       low_open_tickets_granular AS LOW,
       info_open_tickets_granular AS INFO
       FROM tickets ${where} GROUP BY date ORDER BY date ASC`,
    transformData: data =>
      data.reduce(
        (arr, { date, id, ...severities }) =>
          Object.keys(severities).reduce(
            (acc, severity) =>
              severities[severity] !== null && severities[severity] > 0
                ? acc.at(-1)?.date === date
                  ? [...acc.slice(0, -1), { ...acc.at(-1), date, [severity]: severities[severity] }]
                  : [...acc, { date, [severity]: severities[severity] }]
                : acc,
            arr
          ),
        []
      ),
  },
  totalVulnerabilities: {
    title: 'Total Vuls',
    sql: where => `SELECT active_findings_open_tickets_granular as total_vuls,
                                               open_tickets_granular as open_tickets
      FROM tickets ${where}`,
    transformData: data => data?.[0],
  },
  totalIncidents: {
    title: 'Total Incidents',
    sql: where => `SELECT active_alerts_granular as total_vuls,
      open_incidents_granular as open_tickets
      FROM incidents ${where} `,
    transformData: data => data?.[0],
  },
  overlappingAssetsBySource: {
    title: 'Overlapping',
    sql: (where, dataSources) => `SELECT ${dataSources
      .map((dataSource, i) => `COUNT_DISTINCT_IF(asset._key, asset.source_names IN ('${dataSource.name}')) AS source${i + 1}all, `)
      .join('')} 
${dataSources
  .map(
    (dataSource, i) =>
      `COUNT_DISTINCT_IF(asset._key, asset.source_names IN ('${dataSource.name}') ${
        dataSources.length > 1
          ? `AND asset.source_names NOT IN (${generateStringList(dataSources.filter((_, index) => i !== index).map(ds => ds.name))})`
          : ''
      }) AS source${i + 1}only, `
  )
  .join('')} 
total_assets_granular AS totalAssets 
FROM uber_assets ${where}`,
  },
  uniqueAssetsByType: {
    title: 'Unique Assets By Type',
    sql: where =>
      `AVALOR PARAM group_others = true;
SELECT asset.type AS type, total_assets_granular AS count FROM uber_assets
${where}
GROUP BY type 
ORDER BY count DESC
LIMIT 4`,
  },
  uniqueAssetsByOwner: {
    title: 'Unique Assets By Owner',
    sql: where =>
      `AVALOR PARAM group_others = true;
SELECT asset.owner_id AS owner, total_assets_granular AS count FROM uber_assets ${where}
GROUP BY owner 
ORDER BY count DESC
LIMIT 4`,
    transformData: data => data.map(item => ({ ...item, owner: item.owner || 'No Owner' })),
  },
  assetList: {
    title: 'Asset List',
    sql: (page, headCells, orderBy, where) => {
      const fields = headCells
        ? headCells.join(', ')
        : 'asset.owner_id, asset.source_names, asset.last_update, asset.type, asset.source_key, asset._key, asset.name';
      return `AVALOR PARAM total_row_count = true;
SELECT ${fields}, COLLECT_DISTINCT(ticket._key) as relatedTickets FROM uber_assets ${where} GROUP BY ${fields} ${queryOrderBy(
        orderBy
      )} OFFSET ${page * 10} LIMIT 10`;
    },
    totals: where => `SELECT COUNT_DISTINCT(asset._key) as count FROM uber_assets ${where}`,
  },
  severityStatusBars: {
    sql: where =>
      `SELECT ticket.severity,
              open_tickets_granular as ticket_count,
              active_findings_granular as finding_count 
       FROM tickets ${where} ${where ? 'AND' : 'WHERE'} ticket.status.status_bucket.closed != true 
       GROUP BY ticket.severity`,
  },
  keyMetricsOverTime: {
    title: 'Key Metrics Over Time',
    sql: (metrics, where, timeArgs) => `SELECT
    TIME_BUCKET(${timeArgs}) AS date,
    ${metrics.join(', ')}
    FROM tickets ${where} GROUP BY date ORDER BY date ASC`,
  },
  analyzeRemediationWork: {
    title: 'Analyze Remediation Work',
    sql: ({ where, timeArgs, metrics, rowDim, explodeFields, page, buckets, isExport = false }) => {
      const limit = buckets * 10;
      const paginationSql = isExport ? '' : `OFFSET ${page * limit} LIMIT ${limit}`;
      return `SELECT TIME_BUCKET(${timeArgs}) AS date,
       ${[...metrics, explodeFields[rowDim]?.select || rowDim].join(', ')} FROM tickets 
       ${where}
       GROUP BY date, ${explodeFields[rowDim]?.group || rowDim}
       ORDER BY ${explodeFields[rowDim]?.group || rowDim} ASC, date ASC
       ${paginationSql}`;
    },
    totals: (where, timeArgs, metrics) =>
      `SELECT TIME_BUCKET(${timeArgs}) AS date, ${[...metrics].join(', ')} FROM tickets ${where} GROUP BY date ORDER BY date ASC`,
    buckets: timeArgs =>
      `SELECT TIME_BUCKET(${timeArgs}) AS date
      FROM tickets
      WHERE ticket.state != 'ARCHIVED'
      GROUP BY date`,
  },
  overlappingDataBySource: {
    title: 'Overlapping Data by Sources',
    sql: 'SELECT COUNT_DISTINCT(ticket._key) as count FROM tickets',
  },
  riskStatus: {
    title: 'Overall Risk',
    sql: (date, days, where, risk) => `SELECT TIME_BUCKET('${date}', 'day', 2, ${days}) as date,
        ${risk}
        FROM uber_findings
        ${where} ${where ? 'AND' : 'WHERE'} (finding.state = 'ACTIVE' OR finding.last_state_transition > date)
        GROUP BY date 
        ORDER BY date ASC`,
    sqlNewTimeBuckets: (start, end, where, risk) => `SELECT
        TIME_BUCKET('${start}', '${end}', '1 MONTHS', 2) AS date,
        ${risk}
        FROM uber_findings
        ${where}  
        GROUP BY date
        ORDER BY date ASC`,
    transformData: data => getRiskStatusPercentage(data, 'risk'),
  },
  riskTrendOverTime: {
    title: 'Key Metrics Over Time',
    sql: (metrics, start, end, where) => `SELECT TIME_BUCKET('${start}', '${end}', 'MONTH') AS date,
         ${metrics.join(', ')} 
         FROM uber_findings ${where} GROUP BY date ORDER BY date ASC`,
    transformData: data => data.sort((a, b) => new Date(a.date) - new Date(b.date)),
  },
  activeFindings: {
    sql: (date, days, where) => `SELECT TIME_BUCKET('${date}', 'day', 2, ${days}) as date,
        active_findings_in_range as count
        FROM uber_findings
        ${where}
        GROUP BY date
        ORDER BY date ASC`,
    sqlNewTimeBuckets: (start, end, where) => `SELECT
        TIME_BUCKET('${start}', '${end}', '1 MONTHS', 2) AS date,
        active_findings_granular as count
        FROM uber_findings
        ${where}
        GROUP BY date
        ORDER BY date ASC
        `,
    transformData: data => getRiskStatusPercentage(data, 'count'),
  },
  infectedAssets: {
    title: 'Vulnerable Assets',
    sql: (date, days, where) => `SELECT TIME_BUCKET('${date}', 'day', 2, ${days}) as date,
    infected_assets_in_range AS infectedAssets
    FROM uber_findings 
    ${where} ${where ? 'AND' : 'WHERE'} finding.state = 'ACTIVE'
    GROUP BY date
    ORDER BY date ASC`,
    sqlNewTimeBuckets: (start, end, where) => `SELECT
        TIME_BUCKET('${start}', '${end}', '1 MONTHS', 2) AS date,
        assets_with_active_findings_granular AS infectedAssets
        FROM uber_findings
        ${where}
        GROUP BY date
        ORDER BY date ASC
        `,
    transformData: data => getRiskStatusPercentage(data, 'infectedAssets'),
  },
  findingsLastXdays: {
    sql: (date, where) => `SELECT
                               discovered_findings_granular as discovered,
                               turned_to_undetected_findings_granular as undetected
                           FROM uber_findings
    ${where ? `${where} AND TIME >= '${date}'` : `WHERE TIME >= '${date}'`}`,
    transformData: data => [
      {
        name: 'Discovered',
        value: data[0].discovered,
      },
      {
        name: 'Undetected',
        value: data[0].undetected,
      },
    ],
  },
  uniqueCveCount: {
    sql: (date, days, where) => `SELECT TIME_BUCKET('${date}', 'day', 2, ${days}) as date,
      unique_cves_in_range as count
      FROM uber_findings
      ${where} ${where ? 'AND' : 'WHERE'} (finding.state = 'ACTIVE' OR finding.last_state_transition > date)
      GROUP BY date
      ORDER BY date ASC`,
    sqlNewTimeBuckets: (start, end, where) => `SELECT
      TIME_BUCKET('${start}', '${end}', '1 MONTHS', 2) AS date,
      unique_cves_granular as count
      FROM uber_findings
      ${where} ${where ? 'AND' : 'WHERE'} (finding.state = 'ACTIVE' OR finding.last_state_transition > date)
      GROUP BY date
      ORDER BY date ASC`,
    transformData: data => getRiskStatusPercentage(data, 'count'),
  },
  findingsByX: {
    sql: (type, where, risk) => `AVALOR PARAM group_others = true;
        SELECT ${type} as type, active_findings_granular as count,
        ${risk}
        FROM uber_findings
        ${where} ${where ? 'AND' : 'WHERE'} finding.state = 'ACTIVE'
        GROUP BY type
        ORDER BY count DESC LIMIT 4`,
    transformData: data =>
      data?.map(({ id, risk, type, count }) => ({
        id,
        risk,
        count,
        type: type ?? NO_VALUE,
        disable: type === OTHERS,
      })),
  },
  assetsByRiskScore: {
    title: 'Number of Assets by Risk Score',
    transformData: data => {
      const transformedData = Object.keys(data[0])
        .reduce((acc, key) => {
          if (key !== 'id') {
            const name = +key.replace('bucket', '');
            acc.push({
              id: key,
              assets: data[0][key],
              pureRisk: name * 0.5,
              risk: `${name * 0.5 - 0.5}-${name * 0.5}`,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a.pureRisk - b.pureRisk);
      if (transformedData.every(item => item.assets === 0)) {
        return [];
      }
      return transformedData;
    },
  },
  newHistogram: {
    title: 'Number of Assets by Risk Score',
    sql: (method, where) =>
      `SELECT COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) >= 0 AND ${method}(finding.severity_score, asset._key) <= 0.5) AS bucket1,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 0.5 AND ${method}(finding.severity_score, asset._key) <= 1) AS bucket2,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 1 AND ${method}(finding.severity_score, asset._key) <= 1.5) AS bucket3,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 1.5 AND ${method}(finding.severity_score, asset._key) <= 2) AS bucket4,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 2 AND ${method}(finding.severity_score, asset._key) <= 2.5) AS bucket5,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 2.5 AND ${method}(finding.severity_score, asset._key) <= 3) AS bucket6,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 3 AND ${method}(finding.severity_score, asset._key) <= 3.5) AS bucket7,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 3.5 AND ${method}(finding.severity_score, asset._key) <= 4) AS bucket8,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 4 AND ${method}(finding.severity_score, asset._key) <= 4.5) AS bucket9,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 4.5 AND ${method}(finding.severity_score, asset._key) <= 5) AS bucket10,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 5 AND ${method}(finding.severity_score, asset._key) <= 5.5) AS bucket11,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 5.5 AND ${method}(finding.severity_score, asset._key) <= 6) AS bucket12,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 6 AND ${method}(finding.severity_score, asset._key) <= 6.5) AS bucket13,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 6.5 AND ${method}(finding.severity_score, asset._key) <= 7) AS bucket14,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 7 AND ${method}(finding.severity_score, asset._key) <= 7.5) AS bucket15,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 7.5 AND ${method}(finding.severity_score, asset._key) <= 8) AS bucket16,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 8 AND ${method}(finding.severity_score, asset._key) <= 8.5) AS bucket17,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 8.5 AND ${method}(finding.severity_score, asset._key) <= 9) AS bucket18,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 9 AND ${method}(finding.severity_score, asset._key) <= 9.5) AS bucket19,
        COUNT_DISTINCT_IF(asset._key, ${method}(finding.severity_score, asset._key) > 9.5) AS bucket20
        FROM uber_assets
        ${where}`,
    transformData: data => {
      const transformedData = Object.keys(data[0]).reduce((acc, key) => {
        if (key !== 'id') {
          const name = +key.replace('bucket', '');
          acc.push({
            id: key,
            assets: data[0][key],
            pureRisk: name * 0.5,
            risk: `${name * 0.5 - 0.5}-${name * 0.5}`,
          });
        }
        return acc;
      }, []);
      if (transformedData.every(item => item.assets === 0)) {
        return [];
      }
      return transformedData.sort((a, b) => a.pureRisk - b.pureRisk);
    },
  },
  scatterAssetList: {
    sql: (dim, where, risk) => `SELECT ${dim} as dim, assets_with_active_findings_granular AS activeAssets,
    ${risk}
    FROM uber_findings
    ${where} 
    GROUP BY dim
    ORDER BY risk DESC
    LIMIT 20`,
    transformData: data =>
      data.map(({ id, risk, dim, activeAssets: assets }, responseIndex) => ({
        id,
        title: isNullOrUndefined(dim) ? NO_VALUE : `${dim}`,
        x: assets,
        y: risk || 0,
        color: SCATTER_COLORS[responseIndex],
      })),
  },
  riskScatter: {
    title: 'Risk Severity by',
    sql: (start, end, where, risk) => `SELECT TIME_BUCKET('${start}', '${end}', 'MONTH') AS date,
    assets_with_active_findings_granular AS activeAssets,
    ${risk}
    FROM uber_findings
    ${where}
    GROUP BY date
    ORDER BY date ASC`,
    transformData: (data, responseIndex) => {
      if (responseIndex === 0) {
        return data.map(({ id, activeAssets, date, risk }) => ({ id, ASSETS: activeAssets, date, risk }));
      }
      if (responseIndex === 1) {
        const severities = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
        return data.reduce((acc, item) => {
          const itemIndex = acc.findIndex(({ date }) => date === item.date);
          const severity = item['finding.severity'] === 'NONE' ? 'LOW' : item['finding.severity'];
          if (severity && item.active_findings > 0 && severities.includes(severity)) {
            if (itemIndex !== -1) {
              const findings = acc[itemIndex][severity] ? acc[itemIndex][severity] + item.active_findings : item.active_findings;
              acc[itemIndex] = {
                ...acc[itemIndex],
                [severity]: findings,
                totalFindings: +acc[itemIndex].totalFindings >= 0 ? acc[itemIndex].totalFindings + findings : 0,
              };
            } else if (severities.includes(severity)) {
              acc.push({ date: item.date, [severity]: item.active_findings, totalFindings: item.active_findings });
            }
          } else {
            acc.push({ date: item.date });
          }
          return acc;
        }, []);
      }
      return data;
    },
  },
  riskScatterDrilldown: {
    sql: (start, end, where) => `SELECT TIME_BUCKET('${start}', '${end}', 'MONTH') AS date,
    finding.severity,
    active_findings_granular AS active_findings
    FROM uber_findings
    ${where}
    GROUP BY date, finding.severity
    ORDER BY date ASC, finding.severity`,
  },
  assetAssignment: {
    title: 'Asset Ownership',
    sql: () => `SELECT
                    100 * COUNT_DISTINCT_IF(asset._key, asset.owner_id != null) / total_assets_granular AS count
                FROM uber_assets
                WHERE asset.state = 'ACTIVE'`,
    transformData: d => d[0],
  },
  findingsAssignment: {
    title: 'Active Findings Assignment',
    sql: () => `SELECT
    100 * COUNT_DISTINCT_IF(finding._key, ticket.assignee_id != null) / active_findings_granular AS count
FROM uber_findings
WHERE finding.state = 'ACTIVE'`,
    transformData: d => d[0],
  },
  ticketsAssignment: {
    title: 'Open Tickets Assignment',
    sql: "SELECT 100 * COUNT_DISTINCT_IF(ticket._key, ticket.assignee_id != null) / total_tickets_granular AS count FROM tickets WHERE ticket.status.status_bucket.closed != true AND ticket.state IN ('ACTIVE', 'INACTIVE')",
    transformData: d => d[0],
  },
};

export function useWidgetData() {
  const { palette } = useTheme();

  widgetData.findingDuplication.transformData = ([{ active_tickets: count3, finding_count: count1, ticket_count: count2 }]) =>
    count1
      ? [
          { name: 'Active Findings', value: count1, color: rebranding ? palette.colors.primary[300] : palette.colors.primary[400] },
          {
            name: 'Open Tickets',
            value: count2,
            color: rebranding ? palette.colors.primary[350] : palette.colors.primary[400],
            ratio: Math.round(count1 / count2),
          },
          {
            name: 'Active Open Tickets',
            value: count3,
            color: rebranding ? palette.colors.primary[600] : palette.colors.negative[400],
          },
        ].map(item => ({ ...item, displayValues: [item.value, item.ratio] }))
      : [];
  return widgetData;
}

export const formatPercent = (a, b) => `${parseFloat(((a / b) * 100 || 0).toFixed(1))}%`;

export const ticketDimensionsOptions = labels => Object.keys(labels).map(label => ({ title: labels[label], value: `${label}` }));

export const isPercentageMetric = key => key?.includes?.('percent');

export const useExcludedStates = projectionName => {
  const isTickets = projectionName === entityViewConfig.Ticket.projectionName;
  return isTickets ? "('ARCHIVED')" : "('INACTIVE', 'ARCHIVED')";
};
