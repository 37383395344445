import React from 'react';
import { Box, Link, Skeleton, Typography, useTheme } from '@mui/material';
import { format, isPast } from 'date-fns';
import PropTypes from 'prop-types';
import AvItemWithLogoGroup, { IconSize } from '../../components/AvItemWithLogoGroup.tsx';
import AvJsonViewer from '../../components/AvJsonViewer.tsx';
import AvProgress from '../../components/AvProgress.tsx';
import AvTag from '../../components/AvTag.tsx';
import AvTagsList from '../../components/AvTagsList.tsx';
import { flex } from '../../components/AvThemeProvider.tsx';
import AvTooltip from '../../components/AvTooltip.tsx';
import { IconVariant, SeverityItem } from '../../components/ItemWithLogo.tsx';
import { entityViewConfig } from '../../utils/entityViewConfig.ts';
import { mapAliasesAndDims, mapAliasesAndMetrics } from '../../utils/mapping.ts';
import { isDebugMode } from '../../utils/rum.utils.ts';
import { SEVERITY_LABELS, SeverityFromWeight, VulCountBySeverity } from '../../utils/severity.utils.tsx';
import {
  abbreviateNumber,
  cellContentStyle,
  ellipsis,
  getAssetTypeIcon,
  iconSize,
  isJSON,
  isValidHttpUrl,
  truncateDecimal,
} from '../../utils/Utils';
import { FieldType, FieldTypeEnum } from '../Sources/Mapping/mapping.types.ts';
import FactorLineChart from './Factors/FactorLineChart.tsx';
import { factorCategoryToIcon, FactorEntitiesList } from './Factors/utils.tsx';
import ScoreExplanation from './ScoreExplanation.tsx';
import {
  ALERT_SEVERITY,
  ALERT_SEVERITY_SCORE,
  FINDING_ORIGINAL_SEVERITY,
  FINDING_ORIGINAL_SEVERITYSCORE,
  FINDING_SEVERITY,
  FINDING_SEVERITY_SCORE,
  formatAsUTC,
  SCORE_EXPLANATION_FIELD,
} from './ticket.types';
import { ReactComponent as Drill } from '../../assets/Drill down.svg';

export const severityCell = ({ value, width = 24, showText = true, severity }) => (
  <SeverityItem width={width} value={severity} numericValue={value} showText={showText} />
);

export const getStateValue = state =>
  typeof state === 'boolean' ? (state ? 'Undetected' : 'Active') : state === 'ACTIVE' ? 'Active' : 'Undetected';

export function StatusCell({ value, lastSeen }) {
  const theme = useTheme();
  const isActive = value === 'Active';
  return (
    <AvTooltip
      title={!isActive ? (lastSeen ? `Last Seen on ${new Date(lastSeen)?.toLocaleString()}` : 'Undetected in the latest scan') : ''}>
      <span>
        <AvTag
          isActive={isActive}
          text={value}
          bg={isActive ? undefined : theme.palette.colors.positive[100]}
          color={isActive ? undefined : theme.palette.colors.positive[600]}
        />
      </span>
    </AvTooltip>
  );
}
StatusCell.propTypes = {
  value: PropTypes.bool.isRequired,
  lastSeen: PropTypes.bool,
};

StatusCell.defaultProps = {
  lastSeen: undefined,
};

export const drilldownIcon = <Drill style={{ transform: 'rotate(-90deg)', marginTop: '-4px' }} />;

export const riskMassHeadcell = {
  id: 'active_findings_risk_mass_granular',
  label: 'Risk Mass',
  type: 'number',
  formatter: v => (v ? v?.toLocaleString() : 0),
};

const getSlaFormatter = ({ value, row, keepSlaWithoutFormat = false, pathAlias }) => {
  const dateValue = new Date(value);
  const isInPast = value && isPast(dateValue);
  const isOverSla = isInPast && row[`${pathAlias}.state`] === 'ACTIVE';
  return (
    <Box sx={{ ...(isOverSla && { color: theme => theme.palette.error.main }) }}>
      {value ? formatAsUTC(value, false, keepSlaWithoutFormat) : 'No SLA'}
    </Box>
  );
};

// TODO: move tableHeadCellsMap to entityViewConfig
export const tableHeadCellsMap = keepSlaWithoutFormat => ({
  [entityViewConfig.Ticket.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'ticket._key' },
    { id: 'ticket.type', label: 'Type', hidden: !isDebugMode() },
    {
      id: 'ticket.severity_score',
      initWidth: 140,
      formatter: (v, row) =>
        severityCell({
          value: v,
          width: 24,
          severity: row['ticket.severity'],
        }),
      label: 'Severity Score',
      getValue: row => `${row['ticket.severity_score']?.toFixed(1)} ${SEVERITY_LABELS[row['ticket.severity']]}`,
    },
    riskMassHeadcell,
    {
      id: 'ticket.title',
      label: 'Title',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      type: 'string',
    },
    {
      id: 'ticket.first_seen',
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      label: 'Discovered',
      getValue: row => (row[`ticket.first_seen`] ? format(new Date(row[`ticket.first_seen`]), 'MMM dd, yyyy') : row[`ticket.first_seen`]),
    },
    {
      id: mapAliasesAndDims[entityViewConfig.Ticket.entityTypeId].sla.alias,
      label: 'SLA',
      initWidth: 120,
      formatter: (v, row) => getSlaFormatter({ value: v, row, keepSlaWithoutFormat, pathAlias: 'ticket' }),
      getValue: row => (row.sla ? formatAsUTC(row.sla, false, keepSlaWithoutFormat) : 'No SLA'),
    },
    {
      id: `ticket.assignee_id`,
      label: 'Assignee',
      getValue: ({ [`ticket.assignee_id`]: owner }) => owner || 'No Assignee',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
    {
      id: `ticket.source_names`,
      label: 'Sources',
      formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    {
      id: `ticket.current_status.name`,
      label: 'Status',
      formatter: v => <AvTag text={v} oneLine />,
    },
    {
      id: `ticket.integration_info.key`,
      label: 'External ticket ID',
      hidden: true,
    },
    {
      id: 'remediation',
      label: 'Remediation',
      formatter: v => <AvProgress width={50} value={truncateDecimal(v, 1)} />,
      getValue: row => `${Math.round(row.remediation)}%`,
    },
    {
      id: 'ticket.finding_count',
      label: 'Findings',
      type: 'number',
      style: { width: 50 },
      formatter: v => (v ? v?.toLocaleString() : ''),
    },
    {
      id: 'ticket.asset_count',
      label: 'Assets',
      type: 'number',
      style: { width: 50 },
      formatter: v => (v ? v?.toLocaleString() : ''),
    },
  ],
  [entityViewConfig.Alert.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'alert._key' },
    {
      id: `alert.severity_score`,
      initWidth: 140,
      formatter: (v, row) =>
        severityCell({
          value: null,
          width: 24,
          severity: row[`alert.severity`],
        }),
      label: 'Severity Score',
      getValue: row => SEVERITY_LABELS[row[`alert.severity`]],
    },
    {
      id: `alert.created`,
      label: 'Date',
      type: 'date',
    },
    {
      id: 'alert.rule_name',
      label: 'Rule Name',
    },
    {
      id: `alert.last_seen`,
      label: 'Last Seen',
      type: 'date',
    },
    {
      id: 'alert.title',
      label: 'Alert Title',
      type: 'string',
    },
    {
      id: `alert.source_names`,
      label: 'Sources',
      formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    {
      id: 'alert.tactic_name',
      label: 'Tactic',
    },
    {
      id: 'alert.technique_name',
      label: 'Technique',
    },
    {
      id: 'alert.state',
      label: 'State',
    },
    {
      id: 'alert.tags',
      label: 'Tags',
      formatter: values => (Array.isArray(values) ? <AvTagsList tags={values} /> : ''),
    },
  ],
  [entityViewConfig.Incident.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'incident._key' },
    { id: `incident.type`, label: 'Type', hidden: !isDebugMode() },
    {
      id: `incident.severity_score`,
      initWidth: 140,
      formatter: (v, row) =>
        severityCell({
          value: null,
          width: 24,
          severity: row[`incident.severity`],
        }),
      label: 'Severity Score',
      getValue: row => `${row[`incident.severity_score`]?.toFixed(1)} ${SEVERITY_LABELS[row[`incident.severity`]]}`,
    },
    {
      id: `incident.title`,
      label: 'Title',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      type: 'string',
    },
    {
      id: `incident.first_seen`,
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      label: 'First Seen',
      getValue: row =>
        row[`incident.first_seen`] ? format(new Date(row[`incident.first_seen`]), 'MMM dd, yyyy') : row[`incident.first_seen`],
    },
    {
      id: mapAliasesAndDims[entityViewConfig.Incident.entityTypeId].sla.alias,
      label: 'SLA',
      initWidth: 120,
      formatter: v => {
        const dateValue = new Date(v);
        const isInPast = v && isPast(dateValue);
        return <Box sx={{ ...(isInPast && { color: theme => theme.palette.error.main }) }}>{v ? formatAsUTC(v, true) : 'No SLA'}</Box>;
      },
      getValue: row => (row.sla ? formatAsUTC(row.sla, true) : 'No SLA'),
    },
    {
      id: `incident.assignee_id`,
      label: 'Assignee',
      getValue: ({ [`incident.assignee_id`]: owner }) => owner || 'No Assignee',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
    {
      id: `incident.source_names`,
      label: 'Sources',
      formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    {
      id: `incident.current_status.name`,
      label: 'Status',
      formatter: v => <AvTag text={v} oneLine />,
    },
    {
      id: `incident.integration_info.key`,
      label: 'External ticket ID',
      hidden: true,
    },
  ],
  [entityViewConfig.Asset.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'asset._key' },
    {
      id: 'asset.type',
      label: 'Type',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      formatter: v => {
        const icon = getAssetTypeIcon(v);
        return (
          <Box sx={{ ...flex.itemsStart, gap: 1, ...ellipsis }}>
            {icon}
            <AvTooltip>{v}</AvTooltip>
          </Box>
        );
      },
    },
    {
      id: 'asset.name',
      label: 'Name',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
    },
    {
      id: mapAliasesAndMetrics.severityScore.alias,
      label: 'Risk Score',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      formatter: (v, row) =>
        severityCell({
          value: v,
          width: 24,
          severity: row.severity,
        }),
    },
    riskMassHeadcell,
    {
      id: 'asset.owner_id',
      label: 'Owner',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
    {
      id: `asset.source_names`,
      label: 'Sources',
      formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    {
      id: 'asset.crown_jewel',
      label: 'Crown Jewel',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
    {
      id: 'asset.site',
      label: 'Site',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
    {
      id: 'asset.last_seen',
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      label: 'Last Seen',
      getValue: row => (row[`asset.last_seen`] ? format(new Date(row[`asset.last_seen`]), 'MMM dd, yyyy') : row[`asset.last_seen`]),
    },
    {
      id: mapAliasesAndMetrics.totalFindings.alias,
      label: 'Total Findings',
      hasTooltip: true,
      initWidth: 400,
      formatter: (v, row) => (
        <Box sx={{ ...flex.itemsEnd, gap: 1.5 }}>
          <Box sx={{ fontWeight: 600, whiteSpace: 'nowrap', minWidth: 40, width: 40, textAlign: 'end' }}>{abbreviateNumber(v)}</Box>
          <VulCountBySeverity data={row} />
        </Box>
      ),
    },
    {
      id: 'asset.tags',
      label: 'Tags',
      hasTooltip: true,
      style: { maxWidth: 0 },
    },
  ],
  [entityViewConfig.Finding.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'finding._key' },
    { id: 'finding.type', label: 'Type', hidden: !isDebugMode() },
    {
      id: 'finding.severity_score',
      initWidth: 140,
      formatter: (v, row) =>
        severityCell({
          value: v,
          width: 24,
          severity: row['finding.severity'],
        }),
      label: 'Severity Score',
      getValue: row => `${row['finding.severity_score']?.toFixed(1)} ${SEVERITY_LABELS[row['finding.severity']]}`,
    },
    {
      id: `finding.title`,
      label: 'Title',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      type: 'string',
    },
    {
      id: `finding.source_names`,
      label: 'Source',
      formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    {
      id: `asset._key`,
      label: 'Asset Key',
      hasTooltip: true,
      type: 'string',
    },
    {
      id: `asset.type`,
      label: 'Asset Type',
      hasTooltip: true,
      type: 'string',
    },
    {
      id: `finding.cve_id`,
      label: 'CVE',
      hasTooltip: true,
    },
    {
      id: `finding.first_seen`,
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      label: 'First Seen',
      getValue: row =>
        row[`finding.first_seen`] ? format(new Date(row[`finding.first_seen`]), 'MMM dd, yyyy') : row[`finding.first_seen`],
    },
    {
      id: `finding.last_seen`,
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      label: 'Last Seen',
      getValue: row => (row[`finding.last_seen`] ? format(new Date(row[`finding.last_seen`]), 'MMM dd, yyyy') : row[`finding.last_seen`]),
    },
    {
      id: `component.name`,
      label: 'Component',
      hasTooltip: true,
      type: 'string',
    },
    {
      id: `finding.state`,
      label: 'State',
      formatter: (v, row) => <StatusCell value={getStateValue(v)} lastSeen={row[`finding.last_seen`]} />,
      getValue: row => getStateValue(row['finding.state']),
    },
  ],
  [entityViewConfig.Factor.entityTypeId]: [
    { isKey: true, id: 'factor._key', label: 'ID', hidden: !isDebugMode() },
    {
      id: 'factor.name',
      label: 'Name',
    },
    {
      id: 'factor.category_name',
      label: 'Category',
      formatter: v => (
        <Box sx={{ ...flex.itemsCenter, gap: 0.5, svg: { color: theme => theme.palette.colors.neutrals[600], ...iconSize(18) } }}>
          {factorCategoryToIcon[v]}
          <AvTooltip title={v}>
            <Box sx={{ ...cellContentStyle, WebkitLineClamp: '1' }}>{v}</Box>
          </AvTooltip>
        </Box>
      ),
    },
    {
      id: 'scoreLeft',
      label: 'Score',
      formatter: (v, row) => <SeverityFromWeight scoreRight={row.scoreRight} scoreLeft={row.scoreLeft} />,
    },
    {
      id: 'Last30Days',
      label: 'Last 30 Days',
      disableSortBy: true,
      formatter: (v, row) => (
        <FactorLineChart yKey="score" factorKey={row['factor._key']} scoreRight={row.scoreRight} scoreLeft={row.scoreLeft} />
      ),
    },
    {
      id: 'factor.tags',
      label: 'Entities',
      formatter: v => <FactorEntitiesList values={v} />,
    },
    { id: 'factor.recommendation', label: 'Recommendation' },
    {
      id: 'factor.include_in_effective_score',
      label: 'Included In Effective Score',
      type: 'bool',
      formatter: includeInEffectiveScore => (
        <AvTag text={includeInEffectiveScore ? 'Included' : 'Excluded'} isActive={includeInEffectiveScore} />
      ),
      getFilterOptionFunc: ({ include_in_effective_score: includeInEffectiveScore }) => ({
        value: includeInEffectiveScore,
        title: includeInEffectiveScore ? 'Included' : 'Excluded',
      }),
    },
  ],
  [entityViewConfig.PolicyPopulation.entityTypeId]: [
    { isKey: true, id: 'policy_population._key', label: 'ID' },
    {
      id: 'policy_population.severity_score',
      initWidth: 140,
      formatter: (v, row) =>
        severityCell({
          value: v,
          width: 24,
          severity: row['policy_population.severity'],
        }),
      label: 'Severity Score',
      getValue: row => `${row['policy_population.severity_score']?.toFixed(1)} ${SEVERITY_LABELS[row['policy_population.severity']]}`,
    },
    { id: 'policy_population.title', label: 'Title' },
    { id: 'policy_population.asset.name', label: 'Asset Name' },
    {
      id: 'policy_population.asset.type',
      label: 'Asset Type',
      hasTooltip: true,
      style: { maxWidth: 0 },
      initWidth: 200,
      formatter: v => {
        const icon = getAssetTypeIcon(v);
        return (
          <Box sx={{ ...flex.itemsStart, gap: 1, ...ellipsis }}>
            {icon}
            <AvTooltip>{v}</AvTooltip>
          </Box>
        );
      },
    },
    {
      id: 'policy_population.first_seen',
      label: 'First Seen',
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      getValue: row =>
        row[`policy_population.first_seen`]
          ? format(new Date(row[`policy_population.first_seen`]), 'MMM dd, yyyy')
          : row[`policy_population.first_seen`],
    },
    {
      id: 'policy_population.last_seen',
      label: 'Last Seen',
      formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v),
      getValue: row =>
        row[`policy_population.first_seen`]
          ? format(new Date(row[`policy_population.first_seen`]), 'MMM dd, yyyy')
          : row[`policy_population.first_seen`],
    },
    {
      id: 'policy_population.state',
      label: 'State',
      formatter: (v, row) => <StatusCell value={getStateValue(v)} lastSeen={row['policy_population.last_seen']} />,
    },
  ],
  [entityViewConfig.Exception.entityTypeId]: [
    { isKey: true, label: 'ID', id: 'exception._key' },
    { id: 'exception.current_status', label: 'Status', formatter: v => <AvTag text={v} oneLine /> },
    { id: 'exception.reason', label: 'Reason' },
    {
      id: 'exception.requested_sla',
      label: 'Requested SLA',
      type: 'date',
      formatter: (v, row) => getSlaFormatter({ value: v, row, keepSlaWithoutFormat, pathAlias: 'exception' }),
    },
    {
      id: 'ticket.sla',
      label: 'Current SLA',
      type: 'date',
      formatter: (v, row) => getSlaFormatter({ value: v, row, keepSlaWithoutFormat, pathAlias: 'exception' }),
    },
    { id: 'requester.name', label: 'Requester Name' },
    {
      id: 'ticket.severity_score',
      initWidth: 140,
      label: 'Ticket Severity Score',
      formatter: (v, row) =>
        severityCell({
          value: v,
          width: 24,
          severity: row['ticket.severity'],
        }),
      getValue: row => `${row['ticket.severity_score']?.toFixed(1)} ${SEVERITY_LABELS[row['ticket.severity']]}`,
    },
    { id: 'ticket.title', label: 'Ticket Title' },
    { id: 'ticket._key', label: 'Ticket ID' },
    { id: 'reviewer.name', label: 'Reviewer Name' },
  ],
});

const dynamicCellEllipsis = {
  '&.MuiTableCell-root': {
    whiteSpace: 'normal',
    '> div': {
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
  },
};

export const fieldColumnProps = {
  'finding.description': { hasTooltip: true, style: { maxWidth: 480 } },
  'finding.severity_score': {
    formatter: (value, row) => severityCell({ value, severity: row[FINDING_SEVERITY] }),
    getValue: row => `${row[FINDING_SEVERITY_SCORE]?.toFixed(1)} ${row[FINDING_SEVERITY]}`,
    initWidth: 140,
    type: 'string',
  },
  'finding.severity': {
    formatter: severity => severityCell({ severity }),
    getValue: row => `${row[FINDING_SEVERITY_SCORE]?.toFixed(1)} ${row[FINDING_SEVERITY]}`,
  },
  'alert.severity': {
    formatter: severity => severityCell({ severity }),
    getValue: row => `${row[ALERT_SEVERITY_SCORE]?.toFixed(1)} ${row[ALERT_SEVERITY]}`,
  },
  'finding.original_severity_score': {
    formatter: (value, row) => severityCell({ value, severity: row[FINDING_ORIGINAL_SEVERITY] }),
    getValue: row => `${row[FINDING_ORIGINAL_SEVERITYSCORE]?.toFixed(1)} ${row[FINDING_ORIGINAL_SEVERITY]}`,
    initWidth: 140,
    type: 'string',
  },
  'finding.original_severity': {
    formatter: severity => severityCell({ severity }),
    getValue: row => `${row[FINDING_ORIGINAL_SEVERITYSCORE]?.toFixed(1)} ${row[FINDING_ORIGINAL_SEVERITY]}`,
  },
  'finding.state': {
    formatter: (v, row) => <StatusCell value={getStateValue(v)} lastSeen={row['finding.last_seen']} />,
  },
  'finding.details_str': { formatter: v => <div>{v}</div>, style: dynamicCellEllipsis },
  'finding.asset_key': { hasTooltip: true, style: { maxWidth: 190 } },
  'finding.explore_url': {
    formatter: value => linkCell({ value, title: 'Pivot Observation' }),
    hasTooltip: true,
    style: { maxWidth: 190 },
  },
  'finding.alert_url': { formatter: value => linkCell({ value, title: 'View Events' }), hasTooltip: true, style: { maxWidth: 190 } },
  'alert.alert_link': { formatter: value => linkCell({ value, title: 'View Events' }), hasTooltip: true, style: { maxWidth: 190 } },
  'finding.last_seen': { type: 'date' },
  'finding.first_seen': { type: 'date' },
  'alert.last_seen': { type: 'date' },
  'alert.first_seen': { type: 'date' },
  'asset.type': {
    formatter: v => {
      const icon = getAssetTypeIcon(v);
      return (
        v && (
          <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
            {icon}
            <AvTooltip>{v}</AvTooltip>
          </Box>
        )
      );
    },
  },
  'finding.source_names': {
    formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
  },
  'asset.source_names': {
    formatter: v => <AvItemWithLogoGroup items={v || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
  },
  'asset.last_seen': { type: 'date', formatter: v => (v ? format(new Date(v), 'MMM dd, yyyy') : v) },
};

export const linkCell = ({ value, title }) =>
  isValidHttpUrl(value) ? (
    <Link href={value} target="_blank" underline="none">
      {title || value}
    </Link>
  ) : (
    value
  );

const sqlSelectors = {
  remediation: () => ['100 * (1 - ticket.active_finding_count / ticket.finding_count) as remediation'],
  'ticket.severity_score': () => ['ticket.severity_score', 'ticket.severity'],
  'incident.severity_score': () => ['incident.severity_score', 'incident.severity'],
  'alert.severity_score': () => ['alert.severity_score', 'alert.severity'],
  'finding.severity_score': () => ['finding.severity_score', 'finding.severity'],
  sla: projectionName => [projectionName === entityViewConfig.Incident.projectionName ? 'incident.sla as sla' : 'ticket.sla as sla'],
};

export const getHeadCellsIDs = (headCells, projectionName) => [
  ...new Set(
    headCells
      .filter(({ hidden }) => !hidden)
      .flatMap(({ id }) => sqlSelectors[id]?.(projectionName) || [id])
      .filter(id => id.includes('.') || id.includes('remediation'))
  ),
];

export const getTableCellsByAlias = (headCells, alias) => headCells.filter(e => e.id.startsWith(`${alias}.`) || e.id === `${alias}._key`);

export const getHeadCellType = (fieldTypeMap, activeProjName, entityFieldKey) =>
  FieldTypeEnum[fieldTypeMap[`${activeProjName}.${entityFieldKey}`]] === FieldType.Number
    ? 'number'
    : FieldTypeEnum[fieldTypeMap[`${activeProjName}.${entityFieldKey}`]] === FieldType.Date
      ? 'date'
      : FieldTypeEnum[fieldTypeMap[`${activeProjName}.${entityFieldKey}`]] === FieldType.Message
        ? 'json'
        : Array.isArray(fieldTypeMap[`${activeProjName}.${entityFieldKey}`])
          ? 'array'
          : undefined;

export function TextBlock({ loading, data, headCells }) {
  return (
    <Box sx={{ ...flex.col, gap: 3, py: 2 }}>
      {loading ? (
        <>
          <Skeleton variant="text" height={21} width="50%" />
          <Skeleton variant="text" height={21} width="35%" />
        </>
      ) : (
        headCells.map(({ id, label, hidden, isJson, forceFormatter, formatter }) =>
          hidden || !data[id] ? null : (
            <Box key={id} sx={{ ...flex.col, gap: '4px' }}>
              {id !== SCORE_EXPLANATION_FIELD && <Typography variant="h7">{label}</Typography>}
              <div style={{ whiteSpace: 'pre-line' }}>
                {id === SCORE_EXPLANATION_FIELD ? (
                  <ScoreExplanation
                    data={data[id]}
                    severityData={{ severity: data[FINDING_SEVERITY], score: data[FINDING_SEVERITY_SCORE] }}
                  />
                ) : forceFormatter ? (
                  formatter(data[id])
                ) : isJson ? (
                  <AvJsonViewer data={isJSON(data[id]) ? data[id] : JSON.parse(data[id])} />
                ) : typeof data[id] === 'boolean' ? (
                  <AvTag text={data[id] ? 'True' : 'False'} />
                ) : (
                  data[id]
                )}
              </div>
            </Box>
          )
        )
      )}
    </Box>
  );
}
TextBlock.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape(),
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      hidden: PropTypes.bool,
    })
  ),
};
TextBlock.defaultProps = {
  loading: false,
  data: undefined,
  headCells: [],
};

/** * SORTING LOGIC: took it from: https://www.npmjs.com/package/tiny-version-compare ** */
const splitDev = v => String(v).split('-');

const splitSub = v =>
  String(v)
    .replace(/^[vr]/, '') // Drop initial 'v' or 'r'
    .replace(/([a-z]+)/gi, '.$1.') // Sort each word separately
    .replace(/\.+/g, '.') // Trim repeating separators
    .split('.');

// Development versions are considered "negative",
// but localeCompare doesn't handle negative numbers.
// This offset is applied to reset the lowest development version to 0
const offset = part => {
  // Not numeric, return as is
  if (Number.isNaN(part)) {
    return part;
  }

  return 5 + Number(part);
};

const parseSub = part => {
  // Missing, consider it zero
  if (typeof part === 'undefined') {
    return 0;
  }

  // Sort development versions
  switch (part.toLowerCase()) {
    case 'dev':
      return -5;
    case 'alpha':
    case 'a':
      return -4;
    case 'beta':
    case 'b':
      return -3;
    case 'rc':
    case 'c':
      return -2;
    case 'pre':
      return -1;
    default:
  }

  // Return as is, it’s either a plain number or text that will be sorted alphabetically
  return part;
};

function compareSubs(a, b) {
  for (let i = 0; i < a.length || i < b.length; i++) {
    const ai = offset(parseSub(a[i]));
    const bi = offset(parseSub(b[i]));
    const sort = String(ai).localeCompare(bi, 'en', {
      numeric: true,
    });

    // Once the difference is found,
    // stop comparing the rest of the parts
    if (sort !== 0) {
      return sort;
    }
  }

  return 0;
}

export function compareVersions(a, b) {
  if (a === b) {
    return 0;
  }

  const [aMain, aDev] = splitDev(a).map(splitSub);
  const [bMain, bDev] = splitDev(b).map(splitSub);

  const mainSort = compareSubs(aMain, bMain);
  if (mainSort !== 0) {
    return mainSort;
  }

  if (aDev && !bDev) {
    return -1;
  }

  if (!aDev && bDev) {
    return 1;
  }

  if (aDev && bDev) {
    return compareSubs(aDev, bDev);
  }

  // No difference found
  return 0;
}
