import React, { FC } from 'react';
import { FormComponent, INPUT_TYPES } from './formInputTypes';
import TextInput from './TextInput';

const AvFormComponent: FC<{ formComponents: FormComponent[]; data; onFormChange }> = ({ formComponents, data, onFormChange }) => {
  const onChange = field => value => {
    const updatedFields = validateChangeAnotherField(field, value);
    onFormChange(updatedFields);
  };

  const validateChangeAnotherField = (changedField, changedValue) => {
    const toUpdate = { [changedField]: changedValue };
    const component = formComponents.find(({ shouldUpdateFieldWith, valueName }) => valueName === changedField && shouldUpdateFieldWith);
    if (component && component.shouldUpdateFieldWith) {
      const { field, value } = component.shouldUpdateFieldWith(data);
      toUpdate[field] = value;
    }
    return toUpdate;
  };

  return formComponents
    .filter(({ shouldShow = () => true }) => shouldShow(data))
    .map(({ label, valueName, params, required, type: Component = TextInput, text }) =>
      Component === INPUT_TYPES.TEXT ? (
        text
      ) : (
        <Component
          size="small"
          key={valueName}
          label={label}
          value={data[valueName] || (params?.isMultiple ? [] : data[valueName])}
          onChange={onChange(valueName)}
          required={required} // Changed 'isRequired' to 'required'
          {...params}
        />
      )
    );
};

export default AvFormComponent;
