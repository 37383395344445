import React from 'react';
import { Box, useTheme } from '@mui/material';
import AvAddOperandButton from '../../../components/AvAddOperandButton';
import { flex } from '../../../components/AvThemeProvider';
import DataSourceSelect from '../../../components/DataSourceSelect';
import FieldFilterBox from '../../../components/filters/FieldFilterBox';
import { boxWidth, getEmptyExpression } from '../../../components/filters/Utils';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { CommonPolicyFilterConfig, FilterType, PolicyType } from '../../../types/executionRules.types';
import { OperatorType } from '../../../types/filter.types';
import { setNestedValue } from '../../../utils/Utils';
import { getToolCoverageFilter } from '../utils';

const ToolCoverageSimpleFilter = ({ setNewData, newData }: { setNewData: (newData: PolicyType) => void; newData: PolicyType }) => {
  const { palette } = useTheme();
  const {
    accountEntities: { aggProjs },
    typeNameMap: {
      sourcesObj: { sourceOptions },
    },
  } = useAvContext();
  const { filterType, filter } = newData.clientConfig.policyScenario as CommonPolicyFilterConfig;
  const assetFields = aggProjs.uber_assets.fields;
  const rootOperator = filter.and ? OperatorType.AND : OperatorType.OR;
  const firstFilter = filter[rootOperator]?.operands[0];
  const sourcesNames = (firstFilter?.and?.operands[0].expression?.arrayCondition?.underlying.stringCondition?.contains || '')
    .split(',')
    .filter(v => v);
  const numOfDays = firstFilter?.and?.operands[1].not?.expression?.dateCondition?.relative?.value;
  const secondFilter = filter[rootOperator]?.operands[1] || getEmptyExpression();
  const borderColor = filter.and ? palette.colors.primary[500] : palette.colors.yellow[500];
  return (
    <Box>
      {filterType === FilterType.TOOL_COVERAGE && (
        <Box sx={{ ...flex.colItemsStart }}>
          <Box
            sx={{
              ...flex.colItemsStart,
              gap: 1,
              mt: 1,
              padding: 1.5,
              border: `1px solid ${palette.colors.neutrals[300]}`,
              borderLeft: `2px solid ${borderColor}`,
              borderRadius: 1,
              width: 850,
            }}>
            Asset was not discovered in the following sources:
            <Box sx={{ ...flex.justifyStartCenter, width: '100%', gap: 1 }}>
              <DataSourceSelect
                onChange={newSources => {
                  setNewData(
                    setNestedValue(
                      `clientConfig.policyScenario.filter`,
                      newData,
                      getToolCoverageFilter({
                        sourcesNames: newSources,
                        numOfDays,
                        rootOperator,
                        secondFilter,
                      })
                    )
                  );
                }}
                options={sourceOptions}
                filterValue={sourcesNames}
                variant="input"
                label=""
                selectProps={{ placeholder: 'Select...' }}
              />
              <Box>in the last</Box>
              <TextInput
                inputMode="numeric"
                sx={{ width: 90 }}
                size="small"
                value={numOfDays}
                onChange={newVal => {
                  setNewData(
                    setNestedValue(
                      `clientConfig.policyScenario.filter`,
                      newData,
                      getToolCoverageFilter({
                        sourcesNames,
                        numOfDays: newVal,
                        rootOperator,
                        secondFilter,
                      })
                    )
                  );
                }}
              />
              <Box>day(s)</Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...flex.itemsStart,
              my: '-4px',
              ml: `${boxWidth / 4}px`,
            }}>
            <AvAddOperandButton
              style={{ translate: '-50%' }}
              size="small"
              operator={rootOperator}
              onClick={() =>
                setNewData(
                  setNestedValue(
                    `clientConfig.policyScenario.filter`,
                    newData,
                    getToolCoverageFilter({
                      sourcesNames,
                      numOfDays,
                      rootOperator: rootOperator === OperatorType.AND ? OperatorType.OR : OperatorType.AND,
                      secondFilter,
                    })
                  )
                )
              }
            />
          </Box>
          <FieldFilterBox
            setFilter={exp =>
              setNewData(
                setNestedValue(
                  `clientConfig.policyScenario.filter`,
                  newData,
                  getToolCoverageFilter({
                    sourcesNames,
                    numOfDays,
                    rootOperator,
                    secondFilter: exp,
                  })
                )
              )
            }
            fields={assetFields}
            isVertical={false}
            addLogicalExpression={() => {}}
            filter={secondFilter}
            defaultField="asset._key"
            index={0}
            level={0}
            onDelete={() => {}}
            rootOperator={rootOperator}
            size="small"
            customSx={{ borderLeft: `2px solid ${borderColor}` }}
            fieldDescription="Asset missing coverage indication"
            showDeleteAddFilter={false}
            showDisableToggle
            initialEnable={filter[rootOperator]?.operands[1] !== undefined}
          />
        </Box>
      )}
    </Box>
  );
};
export default ToolCoverageSimpleFilter;
