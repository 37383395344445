import React from 'react';
import { Box, SxProps } from '@mui/material';
import AvTooltip from '../../../../../components/AvTooltip';
import CustomTooltip from '../../../../../components/Widgets/CustomTooltip';
import { ChartClickInteraction } from '../../../../../components/Widgets/types';
import { useAvContext } from '../../../../../context/AvContextProvider';
import { FeatureFlags } from '../../../../../types';
import { isPercentageMetric } from '../../../../../utils/dashboardDataUtils';
import { abbreviateNumber, getPercent } from '../../../../../utils/Utils';
import { MeasurementCategory } from '../../../../Reports/types';
import { useGetDisplayName, useGetFieldType } from '../../../hooks';
import { tileTitleSX } from '../../../types';

interface Props {
  value: string | number;
  metricName: string;
  clickInteractions?: ChartClickInteraction[];
  titleSx?: SxProps;
  projName: string;
}

const ClickableTitle = ({ value, metricName, titleSx = tileTitleSX, clickInteractions, projName }: Props) => {
  const {
    featureFlags,
    measurements: { asObject },
  } = useAvContext();
  const isEnabledClickthrough = clickInteractions?.some(({ isEnabledForCell }) => isEnabledForCell({ dataKey: metricName }));
  const getMetricType = useGetFieldType(projName);
  const metricType = getMetricType({ entityFieldKey: metricName });
  const numericValue =
    isPercentageMetric(metricName) || metricType === 'percentage' || asObject[metricName].category === MeasurementCategory.PERCENTAGE
      ? getPercent(value)
      : abbreviateNumber(value);
  const getDisplayName = useGetDisplayName(projName);
  return (
    <Box
      sx={{
        pl: 1,
        width: 'fit-content',
        '&:hover': clickInteractions
          ? {
              background: theme => theme.palette.colors.neutrals[200],
              borderRadius: '10px',
            }
          : {},
      }}
      className="draggableCancel">
      <AvTooltip
        variant="message"
        muiProps={{
          placement: 'right',
          componentsProps: {
            popper: { sx: { '& .MuiTooltip-tooltip': { p: 0 } } },
          },
        }}
        title={
          <CustomTooltip
            payload={[{ payload: { [metricName]: value }, dataKey: metricName }]}
            showTitle={false}
            showContentLegend={false}
            clickInteractions={clickInteractions}
            metricFormatter={() => (getDisplayName ? getDisplayName(metricName) : metricName)}
            metricKey={metricName}
            sx={{ ml: 0 }}
          />
        }>
        <Box
          sx={{
            fontSize: 32,
            color: theme => theme.palette.colors.neutrals[800],
            fontWeight: 500,
            cursor: isEnabledClickthrough ? 'pointer' : 'default',
            ...titleSx,
          }}
          onClick={isEnabledClickthrough ? clickInteractions![0].onClick : undefined}>
          {featureFlags[FeatureFlags.TileWidgetSupportsText] && typeof value !== 'number' ? value : numericValue}
        </Box>
      </AvTooltip>
    </Box>
  );
};

export default ClickableTitle;
