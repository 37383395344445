import React from 'react';
import {
  AllTypeWidgets,
  BarCategoryWidgetTypes,
  LineCategoryWidgetTypes,
  PieCategoryWidgetTypes,
  TableCategoryWidgetTypes,
  TextCategoryWidgetTypes,
  TileCategoryWidgetTypes,
  WidgetCategory,
} from '../../types';
import BarWidget from './BarWidget/BarWidget';
import LineWidget from './LineWidget';
import PieWidget from './PieWidget';
import TableWidget from './TableWidget';
import TextWidget from './TextWidget';
import TileWidget from './TileWidget';

const RenderWidget = ({
  widget,
  setWidget,
  customPalette,
}: {
  widget: AllTypeWidgets;
  setWidget?: (v: AllTypeWidgets) => void;
  customPalette?: string[];
  showBarGraphLabel?: boolean;
}) =>
  ({
    [WidgetCategory.Table]: <TableWidget widget={widget as TableCategoryWidgetTypes} setWidget={setWidget} />,
    [WidgetCategory.Pie]: <PieWidget widget={widget as PieCategoryWidgetTypes} customPalette={customPalette} />,
    [WidgetCategory.Bar]: <BarWidget widget={widget as BarCategoryWidgetTypes} />,
    [WidgetCategory.Line]: <LineWidget widget={widget as LineCategoryWidgetTypes} />,
    [WidgetCategory.Text]: <TextWidget widget={widget as TextCategoryWidgetTypes} />,
    [WidgetCategory.Tile]: <TileWidget widget={widget as TileCategoryWidgetTypes} />,
  })[widget.category];

export default RenderWidget;
