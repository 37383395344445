import { useCallback, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { QueryObserverOptions, QueryObserverResult, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags } from '../types';
import { Filter } from '../types/filter.types';
import { ErrorTypes, QueryOrigin, QueryOriginType } from '../types/query.types';
import { generateUUID, isNullOrUndefined } from '../utils/Utils';
import { QueryResponse } from '../views/CustomDashboards/types';
import { useDebounceV1 } from './useDebounce';

const QUERY = gql`
  query querySql($req: Json!) {
    querySql(value: $req) {
      totalRowCount
      rows {
        data
        id
      }
      totalRowCount
      queryId
    }
  }
`;

export const CANCEL_QUERY = gql`
  mutation cancelQuery($queryId: String!) {
    cancelQuery(queryId: $queryId)
  }
`;

export const CANCEL_ASYNC_QUERY = gql`
  mutation cancelAsyncQuery($queryId: String!) {
    cancelAsyncQuery(queryId: $queryId)
  }
`;

type QuerySqlResponse = {
  data: any;
  totals?: number;
  isLoading: boolean;
  errors?: any[];
  refetch: () => Promise<any>;
  isRefetching: boolean;
  isPreviousData: boolean;
};

export default function useQuerySql({
  key,
  sql,
  origin = QueryOrigin.INTERACTIVE_QUERY,
  usage,
  searchQuery = '',
  onSuccess = d => d,
  onError = undefined,
  options = {},
  muteErrors = undefined,
  additionalFilter = undefined,
  debounceQueries = true,
  showTotals = false,
}: {
  key: string;
  sql: string | string[];
  origin?: QueryOriginType;
  searchQuery?: string;
  usage?: string;
  onSuccess?: (d: any[], index: number) => any;
  onError?: (error) => void;
  options?: Partial<QueryObserverOptions>;
  muteErrors?: boolean;
  additionalFilter?: Filter;
  debounceQueries?: boolean;
  showTotals?: boolean;
}): QuerySqlResponse {
  const dummyUUID: string = useMemo(generateUUID, []);
  const { api, featureFlags } = useAvContext();
  const sqls = Array.isArray(sql) ? sql : [sql];
  const [, setPrevData] = useState(false);
  const keys = useMemo(() => (Array.isArray(sql) ? sql.map(sql => [key, sql]) : [[key, sql]]), [sql, key]);
  const debounceDelay = featureFlags[FeatureFlags.QuerySQLDebounce];
  const debouncedKeys = useDebounceV1(keys, debounceQueries && debounceDelay ? +debounceDelay : 0);
  const uuids = useMemo(() => debouncedKeys.map(generateUUID), [debouncedKeys]);
  const { mutate: cancel } = useMutation({ mutationFn: queryId => api(CANCEL_QUERY, { options: { queryId } }) });
  const queries =
    !sql || options.enabled === false || !sql[0]
      ? [{ queryKey: [dummyUUID] } as QueryObserverOptions]
      : sqls.map((SQL, index) => ({
          queryKey: debouncedKeys[index],
          queryFn: ({ signal }: { signal?: AbortSignal }): QueryResponse =>
            api(QUERY, {
              options: {
                req: {
                  sql: SQL,
                  ...(origin && { origin }),
                  ...(searchQuery && { searchQuery }),
                  totals: { totalRowCount: showTotals },
                  ...(usage && { pathAliasNamespace: usage }),
                  granularAggregations: true,
                  additionalFilter,
                  queryId: uuids[index],
                },
              },
              onError,
              signal,
              muteErrors,
            })
              .then(data => {
                const flattenedData = data?.data?.querySql?.rows?.map(row => ({ ...row.data, id: row.id }));
                if (data === undefined || data.errors) {
                  throw data?.errors || new Error(`${ErrorTypes.Network}: ${keys[index]}`);
                }
                setPrevData(true);
                return {
                  data: flattenedData?.length ? onSuccess(flattenedData, index) : flattenedData,
                  totals: data?.data?.querySql?.totalRowCount,
                };
              })
              .catch(e => {
                if (signal?.aborted && uuids[index]) {
                  cancel(uuids[index]);
                }
                if (muteErrors && !signal?.aborted) {
                  onError?.(e);
                } else {
                  throw e;
                }
              }),
          enabled: !!SQL && !!sql,
          gcTime: featureFlags[FeatureFlags.disableCacheQueryClient] ? 0 : undefined,
          ...options,
        }));

  const queryEnabled = queries.every(r => isNullOrUndefined(r.enabled) || r.enabled);

  const resultsMulti: QueryObserverResult<{ data: any; totals: number }>[] = useQueries({
    queries: queries.map(m => ({ ...m, enabled: queryEnabled && queries.length > 1 })),
  });

  const resultSingle: QueryResponse & QueryObserverResult = useQuery({
    ...queries[0],
    enabled: queryEnabled && queries.filter(f => !!f.queryFn).length === 1,
  });

  const refetchMulti = useCallback(() => Promise.all(resultsMulti.map(r => r.refetch())), [resultsMulti]);
  const result = useMemo(
    () =>
      queries.length > 1
        ? {
            totals: !resultsMulti.length ? undefined : resultsMulti[0].data?.totals,
            data:
              !resultsMulti.length || (queries.length > 1 && resultsMulti.some(({ data }) => !data?.data?.length))
                ? undefined
                : resultsMulti.length === 1
                  ? resultsMulti[0].data?.data
                  : resultsMulti.map(({ data }) => data?.data),
            isLoading: resultsMulti.some(result => result.isLoading),
            errors: resultsMulti.map(result => result.error).filter(e => !!e) || [],
            refetch: refetchMulti,
            isRefetching: resultsMulti.some(result => result.isFetching) || (debounceQueries && debouncedKeys !== keys),
            isPreviousData: resultsMulti.some(result => result.isPlaceholderData),
          }
        : {
            data: resultSingle.data?.data,
            totals: resultSingle.data?.totals,
            isLoading: resultSingle.isLoading,
            errors: resultSingle.error ? [resultSingle.error] : [],
            refetch: resultSingle.refetch,
            isRefetching: resultSingle.isRefetching || (debounceQueries && debouncedKeys !== keys),
            isPreviousData: resultSingle.isPlaceholderData,
          },
    [resultsMulti, resultSingle, debouncedKeys]
  );

  return result;
}
