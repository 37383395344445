import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, InputLabel, Skeleton, useTheme } from '@mui/material';
import { useAvContext } from '../context/AvContextProvider';
import { dotStyle, downloadBlob } from '../utils/Utils';
import { useGetFile } from '../views/Tickets/hooks';
import { apiUrls } from '../views/Tickets/ticket.types';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import Loading from './Loading';
import { ReactComponent as Attach } from '../assets/Attach.svg';
import { ReactComponent as Delete } from '../assets/Delete.svg';
import { ReactComponent as Export } from '../assets/Export.svg';
import { ReactComponent as Code } from '../assets/fileTypes/Code.svg';
import { ReactComponent as CSV } from '../assets/fileTypes/CSV.svg';
import { ReactComponent as Doc } from '../assets/fileTypes/doc.svg';
import { ReactComponent as PDF } from '../assets/fileTypes/PDF.svg';
import { ReactComponent as Ppt } from '../assets/fileTypes/ppt.svg';
import { ReactComponent as Text } from '../assets/fileTypes/Text.svg';
import { ReactComponent as Video } from '../assets/fileTypes/Video.svg';
import { ReactComponent as Zip } from '../assets/fileTypes/Zip.svg';

export const getFile = (api, fileId, isPreview, projection) =>
  api(`${apiUrls.ADD_ATTACHMENT}?projection=${projection}&builtin=true&preview=${isPreview}&storedName=${fileId}`, {
    isWebserver: false,
    isJson: false,
  }).then(async data => {
    const blob = await data.blob();
    return { dataURL: URL.createObjectURL(blob), blob };
  });
const imageButtonStyle = {
  backgroundColor: theme => `${theme.palette.colors.neutrals[100]}B3`,
  borderRadius: '6px',
  opacity: 1,
  padding: '2px',
};

interface FilePreviewIconProps {
  fileName: string;
}
function FilePreviewIcon({ fileName }: FilePreviewIconProps) {
  const fileExtension = fileName.split('.').at(-1)!;
  const { palette } = useTheme();
  const styleIcon = { color: palette.colors.neutrals[100], borderRadius: '10px', height: '40px', width: '40px', padding: '5px' };
  const iconByType: Record<string, any> = {
    csv: { text: 'CSV', icon: <CSV style={{ backgroundColor: palette.colors.positive[400], ...styleIcon }} /> },
    pdf: { text: 'PDF', icon: <PDF style={{ backgroundColor: palette.colors.negative[400], ...styleIcon }} /> },
    zip: { text: 'Zip', icon: <Zip style={{ backgroundColor: palette.colors.complementary[600], ...styleIcon }} /> },
    pptx: { text: 'PPT', icon: <Ppt style={{ backgroundColor: palette.colors.orange[500], ...styleIcon }} /> },
    mp4: { text: 'Video', icon: <Video style={{ backgroundColor: palette.colors.blue[400], ...styleIcon }} /> },
    mov: { text: 'Video', icon: <Video style={{ backgroundColor: palette.colors.blue[400], ...styleIcon }} /> },
    text: { text: 'Text', icon: <Text style={{ backgroundColor: palette.colors.primary[350], ...styleIcon }} /> },
    docx: { text: 'Doc', icon: <Doc style={{ backgroundColor: palette.colors.blue[600], ...styleIcon }} /> },
    json: { text: 'JSON', icon: <Code style={{ backgroundColor: palette.colors.blue[600], ...styleIcon }} /> },
  };
  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        borderRadius: '12px',
        border: `0.5px solid ${palette.colors.neutrals[400]}`,
        ...flex.colCenter,
        gap: 1,
      }}>
      {iconByType[fileExtension] ? (
        iconByType[fileExtension].icon
      ) : (
        <Attach style={{ backgroundColor: palette.colors.blue[500], ...styleIcon }} />
      )}
      <Box sx={{ fontSize: '13px', color: palette.colors.neutrals[600] }}>
        {iconByType[fileExtension] ? iconByType[fileExtension].text : fileExtension}
      </Box>
    </Box>
  );
}

interface ImageDialogProps {
  open: boolean;
  base64?: string;
  fileId?: string;
  url: string;
  onClose: () => void;
}
function ImageDialog({ open, base64 = '', fileId = '', url, onClose }: ImageDialogProps) {
  const { isLoading: isLoadingFullScreen, data: dataFullScreen } = useGetFile({ url, fileId, isPreview: false, enabled: open && !base64 });
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent sx={{ p: 0 }}>
        {base64 || (!isLoadingFullScreen && dataFullScreen) ? (
          <img src={base64 || dataFullScreen?.dataURL} alt="" style={{ width: '100%', height: 'auto' }} />
        ) : (
          <Skeleton variant="rectangular" width="50vw" height="50vh" sx={{ borderRadius: '12px' }} />
        )}
      </DialogContent>
    </Dialog>
  );
}

interface AvPreviewImageProps {
  fileName: string;
  fileId: string;
  base64?: string;
  fileType: string;
  onDeleteFile?: () => void;
  index?: number;
  isLoading?: boolean;
  projection: Record<string, any>;
}
function AvPreviewImage({
  fileName = '',
  fileId,
  fileType,
  onDeleteFile,
  index = 0,
  base64,
  isLoading = false,
  projection,
}: AvPreviewImageProps) {
  const theme = useTheme();
  const { api } = useAvContext();
  const [open, setOpen] = useState(false);
  const isImage = fileType?.includes('image/');
  const url = `${apiUrls.ADD_ATTACHMENT}?projection=${projection}&builtin=true`;
  const { isLoading: isLoadingPreview, data } = useGetFile({ url, fileId, isPreview: isImage, enabled: !base64 });

  return isLoading || isLoadingPreview ? (
    <AvLoadingFile />
  ) : (
    <Box key={index} sx={{ ...flex.col, ':hover .hoverImage': { opacity: 1 } }}>
      <ImageDialog open={open} onClose={() => setOpen(false)} base64={base64} url={url} fileId={fileId} />
      <Box sx={{ width: 120 }}>
        <AvTooltip title={fileName} muiProps={{ placement: 'top' }}>
          <InputLabel>{fileName}</InputLabel>
        </AvTooltip>
      </Box>
      <Box onClick={() => (isImage ? setOpen(true) : null)} sx={{ position: 'relative' }}>
        {isImage ? (
          <img
            src={base64 || data?.dataURL}
            alt=""
            style={{ width: 120, height: 120, borderRadius: '12px', border: `0.5px solid ${theme.palette.colors.neutrals[400]}` }}
          />
        ) : (
          <FilePreviewIcon fileName={fileName} />
        )}
        <Box className="hoverImage" sx={{ position: 'absolute', top: 6, right: 6, opacity: 0, transitionDelay: '200ms' }}>
          <Box sx={{ ...flex.row, gap: 1 }}>
            {!!fileId && (
              <Box sx={imageButtonStyle}>
                {/* @ts-ignore */}
                <Button
                  onClick={event => {
                    event.stopPropagation();
                    getFile(api, fileId, false, projection).then(res => downloadBlob(res?.blob, fileType, fileName));
                  }}
                  sx={{ color: theme.palette.colors.neutrals[600] }}
                  variant="link"
                  type="icon">
                  <Export />
                </Button>
              </Box>
            )}
            {onDeleteFile && (
              <Box sx={imageButtonStyle}>
                {/* @ts-ignore */}
                <Button
                  onClick={event => {
                    event.stopPropagation();
                    onDeleteFile();
                  }}
                  sx={{ color: theme.palette.colors.neutrals[600] }}
                  variant="link"
                  type="icon">
                  <Delete />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function AvLoadingFile() {
  const theme = useTheme();
  return (
    <Box sx={{ ...flex.colCenter, position: 'relative', mt: 2 }}>
      <Skeleton variant="rounded" width={121} height={121} sx={{ borderRadius: '12px' }} />
      <Box sx={{ ...dotStyle(theme.palette.colors.neutrals[200], 25), right: -10, top: -10, position: 'absolute', ...flex.center }}>
        <Loading height={18} color={theme.palette.colors.primary[500]} />
      </Box>
    </Box>
  );
}

interface AttachmentListProps {
  attachments: Array<Record<string, any>>;
  onDeleteFile?: (param: string | number) => void;
  style?: Record<string, any>;
  deleteByIndex?: boolean;
  isLoading?: boolean;
  projection: Record<string, any>;
}

export function AttachmentList({
  attachments,
  onDeleteFile,
  style = {},
  deleteByIndex = false,
  isLoading = false,
  projection,
}: AttachmentListProps) {
  return (
    <Box sx={{ ...flex.wrap, gap: 2, p: 2, pt: 1, mt: 2, borderRadius: '12px', ...style }}>
      {attachments.map((attachment, index) => (
        <AvPreviewImage
          /* eslint-disable-next-line no-underscore-dangle */
          key={attachment._key || attachment.original_name}
          isLoading={isLoading}
          fileName={attachment.original_name}
          fileId={attachment.stored_name}
          base64={attachment.base64}
          /* eslint-disable-next-line no-underscore-dangle */
          onDeleteFile={onDeleteFile ? () => onDeleteFile(deleteByIndex ? index : attachment._key) : undefined}
          fileType={attachment.content_type}
          index={index}
          projection={projection}
        />
      ))}
    </Box>
  );
}
