import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../context/AvContextProvider';

export const getDefaultValues = formComponents =>
  formComponents.reduce((obj, { valueName, defaultValue }) => (defaultValue ? { ...obj, [valueName]: defaultValue } : obj), {});

export const transformedNewData = (newData, formComponents) =>
  Object.keys(newData).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: formComponents.some(({ sendAsArray, valueName }) => valueName === cur && sendAsArray) ? [newData[cur]] : newData[cur],
    }),
    {}
  );

export const useFormMutate = (query, propagateTo, formComponents, onSuccess, queryOptions) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (newData: Record<string, any>) =>
      api(query, {
        options: propagateTo ? { [propagateTo]: transformedNewData(newData, formComponents) } : transformedNewData(newData, formComponents),
        muteErrors: true,
        ...queryOptions,
      })
        .then(onSuccess)
        .catch(error => {
          enqueueSnackbar(error?.graphQLErrors?.[0]?.extensions?.message || error.message, {
            variant: 'error',
          });
        }),
  });
};
