export enum TabsType {
  Data = 'data',
  Filters = 'filters',
  Style = 'style',
  DrillDown = 'drilldown',
}

export enum FieldType {
  Measurements = 'metrics',
  Dimensions = 'dims',
  All = 'all',
}

export type SizeLimit = {
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
};

export enum DashboardsTab {
  Custom = 'Custom',
  Templates = 'templates',
}
