import React from 'react';
import { Divider, IconButton, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { flex } from '../../../../components/AvThemeProvider';
import AvTooltip from '../../../../components/AvTooltip';
import { rebranding } from '../../../../rebranding';
import { Filter } from '../../../../types/filter.types';
import { iconSize } from '../../../../utils/Utils';
import { useGetWidgetSelectionProps } from '../../EditCustomDashboard/hooks';
import { useGetWidgetData } from '../../hooks';
import { AllTypeWidgets, TileWidgetVisualization, WidgetCategory } from '../../types';
import { FilterToText } from '../FiltersDescription';
import RenderWidget from '../Widgets/RenderWidget';
import ClickthroughTable from './ClickthroughTable';
import { ReactComponent as FilterActive } from '../../../../assets/colorful/FilterActive.svg';
import { ReactComponent as FilterActiveNew } from '../../../../assets/colorful2/FilterActive.svg';

const filterActiveIcon = rebranding ? <FilterActiveNew style={iconSize(18)} /> : <FilterActive style={iconSize(18)} />;

interface ClickthroughContentProps {
  widget: AllTypeWidgets;
  globalFilters: Filter;
  setWidget: (v: AllTypeWidgets) => void;
}

const ClickthroughContent: React.FC<ClickthroughContentProps> = ({ widget, globalFilters, setWidget }) => {
  const {
    data = [],
    totals: responseTotals,
    isLoading,
    isRefetching,
    totalRowCount,
  } = useGetWidgetData({ widget, contextFilter: globalFilters });
  const widgetSelectionProps = useGetWidgetSelectionProps();

  const { filter: widgetFilter, top, sorting, projectionId } = widget.requests[0];

  const width = 'calc(max(25%, 550px))';
  return (
    <Box sx={{ ...flex.row, height: '100%' }}>
      {!(widget.category === WidgetCategory.Tile && widget.definition.custom.visualization === TileWidgetVisualization.None) && (
        <>
          <Box sx={{ width, minWidth: width, ...flex.col, p: 3, pt: 2 }}>
            <Box sx={{ ...flex.justifyBetweenCenter }}>
              <Typography variant="h5" sx={{ py: '5px', m: 0 }}>
                {widget.definition.title.title}
              </Typography>
              {widgetFilter && (
                <AvTooltip title={<FilterToText filter={widgetFilter} top={top} sorting={sorting[0]} projectionId={projectionId.name} />}>
                  <IconButton>{filterActiveIcon}</IconButton>
                </AvTooltip>
              )}
            </Box>
            <Box sx={{ flex: 1, overflow: 'hidden', ...(widget.category === WidgetCategory.Table ? flex.row : {}) }}>
              {isLoading ? (
                <Skeleton width="100%" height="50%" variant="rectangular" />
              ) : (
                React.cloneElement(RenderWidget({ widget }), {
                  responseTotals,
                  totalRowCount,
                  data,
                  isLoading,
                  isRefetching,
                  setWidget,
                  ...widgetSelectionProps({ widget }),
                })
              )}
            </Box>
          </Box>
          <Divider flexItem orientation="vertical" sx={{ borderColor: theme => theme.palette.colors.neutrals[200] }} />
        </>
      )}
      <ClickthroughTable widget={widget} globalFilters={globalFilters} widgetData={data} />
    </Box>
  );
};

export default ClickthroughContent;
