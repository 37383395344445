import { gql } from '@apollo/client';

export const GET_ENTITIES = gql`
  query allEntityTypes {
    ingressProjections {
      meta {
        id {
          name
          builtIn
        }
        typeId {
          name
          builtIn
        }
      }
    }
    externalProjections {
      meta {
        id {
          name
          builtIn
        }
        typeId {
          name
          builtIn
        }
      }
    }
    allEntityTypes {
      id {
        name
        builtIn
      }
      fields {
        name
        builtIn
        displayName
        type
        repeated
        tags
        additionalInfo {
          hideFromMapping
        }
      }
    }
    metadataFields {
      name
      builtIn
      displayName
      type
      repeated
      tags: tagsList
    }
    projectionAliasPaths(includeIngressAliases: true, includeRecommendations: true) {
      projId {
        name
        builtIn
      }
      entityTypeId {
        name
        builtIn
      }
      projDisplayName
      aggregates {
        name
        builtIn
      }
      aliases {
        projId {
          name
          builtIn
        }
        entityTypeId {
          name
          builtIn
        }
        alias
        displayName
        usages
        fieldsRecommendation
        fieldsPopulation
      }
    }
  }
`;

export const FIELD_VISIBILITY = gql`
  query findFieldVisibilitiesByAccountId {
    findFieldVisibilitiesByAccountId {
      id
      projectionName
      builtInProjection
      fieldName
      config
    }
  }
`;

export const GET_ACCOUNT = gql`
  query findStartupAccountByContext {
    findStartupAccountByContext {
      name
      logo
      accountType
      createdAt
      apps {
        id
        name
      }
    }
  }
`;

export const GET_USER_ADDITIONAL_DATA = gql`
  query getUserAdditionalData {
    getUserAdditionalData {
      accounts {
        accountId
        accountName
        userRoleId
      }
      favoriteApps
    }
  }
`;

export const GET_MEASUREMENT = gql`
  query {
    findMeasurementsByAccountId {
      measurement
      createdByUserId
      updatedByUserId
      id
    }
  }
`;

export const GET_UI_CONFIG = gql`
  query ($projectionName: String) {
    getUIConfigurationByProjection(projectionName: $projectionName)
  }
`;
