import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags } from '../types';
import { iconSize } from '../utils/Utils';
import { FieldType } from '../views/CustomDashboards/types/types';
import NoDataFound from './NoDataFound';

interface Props {
  onClick: VoidFunction;
  activeFilterType: FieldType;
  isTopFieldsFilterActive: boolean;
  isInUseFilterActive: boolean;
  isUnpopulatedFieldsActive: boolean;
}

export default function FiltersEmptyState({
  onClick,
  activeFilterType,
  isTopFieldsFilterActive,
  isInUseFilterActive,
  isUnpopulatedFieldsActive,
}: Props) {
  const { featureFlags } = useAvContext();
  const areFiltersCleared =
    activeFilterType === FieldType.All &&
    !isTopFieldsFilterActive &&
    !isInUseFilterActive &&
    (featureFlags[FeatureFlags.PopulatedFields] ? isUnpopulatedFieldsActive : true);

  return areFiltersCleared ? (
    <NoDataFound
      headline="No Results Found"
      callToAction={<Box sx={{ textWrap: 'wrap', fontSize: '12px', width: '150px' }}>Try refining your input and search again</Box>}
      style={{ titleStyle: { fontSize: '16px', margin: 0 } }}
      imgStyle={{ ...iconSize(160) }}
    />
  ) : (
    <NoDataFound
      headline="Can't find what you're looking for?"
      style={{ titleStyle: { fontSize: '16px', margin: 0 } }}
      imgStyle={{ ...iconSize(160) }}
      callToAction={
        <Box sx={{ textWrap: 'wrap', fontSize: '12px', fontWeight: 'normal', width: '237px' }}>
          <Button sx={{ fontSize: '12px' }} onClick={onClick}>
            Clear filters
          </Button>
          <span> and search again to search across all available fields.</span>
        </Box>
      }
    />
  );
}
