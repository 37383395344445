import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvDateRangePicker from '../../../components/DatePicker/AvDateRangePicker';
import { DatePickerStartAdornment } from '../../../components/DatePicker/utils';
import { getStringConditionFromDatePicker } from '../../../components/filters/Utils';
import { DateGap } from '../../../utils/date.utils';
import { isNullOrUndefined } from '../../../utils/Utils';
import { TimeBucket } from '../../Reports/types';
import { ReactComponent as HistoryData } from '../../../assets/History.svg';

const TimeRangeComp = ({ onFilterChange, dateGap, datePickerValue }) => (
  <Box sx={{ ...flex.justifyEndCenter, gap: 1 }}>
    <ToggleButtonGroup
      size="small"
      color="white"
      onChange={e => onFilterChange({ dateGap: (e.target as HTMLInputElement).value as DateGap })}
      value={dateGap}>
      <ToggleButton value={TimeBucket.BUCKET_DAY}>Day</ToggleButton>
      <ToggleButton value={TimeBucket.BUCKET_WEEK}>Week</ToggleButton>
      <ToggleButton value={TimeBucket.BUCKET_MONTH}>Month</ToggleButton>
    </ToggleButtonGroup>
    <AvDateRangePicker
      showDropdownIcon
      inputSx={{ width: '500px' }}
      icon={<DatePickerStartAdornment tooltipText="Historical Data" icon={<HistoryData />} />}
      value={datePickerValue}
      onChange={timeRange => {
        onFilterChange({
          dateObject: isNullOrUndefined(timeRange.value)
            ? []
            : [encodeURIComponent(JSON.stringify(getStringConditionFromDatePicker(timeRange).dateCondition!))],
        });
      }}
      isEditableInput={false}
      showRelativeOptions
      isRange
      allowFutureDates={false}
      showOptions={false}
    />
  </Box>
);

export default TimeRangeComp;
