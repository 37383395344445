import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '../components/amplifyProvider';
import { getTimeoutFromAuthConfig } from './localStorageAuthUtils.utils';

const defaultIdleTimeout = -1; // no idle timeout was set, do not logout the user

export const useLogoutWhenUserIsIdle = () => {
  const { shouldLogout, timeout } = logoutOnIdle();
  const { logout } = useAuth();

  const onIdle = async () => {
    if (shouldLogout) {
      await logout();
    }
  };
  useIdleTimer({
    timeout,
    onIdle,
    startOnMount: true,
    crossTab: true,
    syncTimers: 100,
    name: 'logoutInActivity',
    disabled: !shouldLogout,
  });
};

const logoutOnIdle = () => {
  const timeout = getTimeoutFromAuthConfig({ key: 'logoutInactivityTimeout', defaultValue: 1 }); // for when there is no auth config
  const shouldLogout = timeout !== defaultIdleTimeout;
  return { shouldLogout, timeout: shouldLogout ? timeout : 10000 };
};

export default useLogoutWhenUserIsIdle;
