import { datadogRum } from '@datadog/browser-rum';
import { sendRebrandingPendoEvent } from '../rebranding';
import envVariables from '../shared/projectEnvVariables';
import { getExporterWorkflowMetadata, isExportMode } from './exportDashboards.utils';

// eslint-disable-next-line no-underscore-dangle
const isSynthetics = window._DATADOG_SYNTHETICS_BROWSER || navigator.userAgent.includes('Datadog');

export const isDebugMode = () => {
  const resetDebugModeCount = Number(localStorage.getItem('resetDebugModeCount'));
  if (!['prod-se01', 'prod'].includes(envVariables.VITE_ENV) || resetDebugModeCount > 0) {
    return localStorage.getItem('debugMode') === 'true';
  }
  localStorage.setItem('debugMode', 'false');
  localStorage.setItem('resetDebugModeCount', `${resetDebugModeCount + 1}`);
  return false;
};
export const shouldRecordSession = !isDebugMode() && !isSynthetics && !envVariables.STORYBOOK && envVariables.VITE_DD_CLIENT_TOKEN;

export const initializePendo = ({ userId, name, email, role, roleId, roleName, accountId, accountName, accountType, accountCreated }) => {
  (window as any).pendo?.initialize({
    visitor: {
      id: `${userId}_${envVariables.VITE_ENV}`, // Required if user is logged in
      email, // Recommended if using Pendo Feedback, or NPS Email
      name,
      role,
      roleId,
      roleName,
      // full_name:    // Recommended if using Pendo Feedback

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: `${accountId}_${envVariables.VITE_ENV}`, // Highly recommended, required if using Pendo Feedback
      name: accountName, // Optional
      type: accountType, // is_paying:    // Recommended if using Pendo Feedback
      creationDate: accountCreated,
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
  sendRebrandingPendoEvent(email);
};
if (shouldRecordSession) {
  datadogRum.init({
    applicationId: '79576ecf-b1bc-4bf0-ab5f-a99515bd4b2c',
    clientToken: envVariables.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'app-avalor',
    env: envVariables.VITE_ENV,
    version: envVariables.VITE_VERSION,
    sessionReplaySampleRate: 100,
    compressIntakeRequests: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    beforeSend: (event, context: any) => {
      if (event.type === 'resource' && event.resource.type === 'fetch') {
        // eslint-disable-next-line no-param-reassign
        event.context = {
          ...event.context,
          request: {
            graphqlname: context.requestInit?.headers?.graphqlname,
            body: context.requestInit?.body && typeof context.requestInit.body === 'string' && JSON.parse(context.requestInit.body),
          },
          response: { headers: Object.fromEntries(context.response?.headers || []) },
        };
      }
      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
  if (isExportMode()) {
    datadogRum.setUser({
      ...datadogRum.getUser(),
      ...getExporterWorkflowMetadata(),
    });
  }
}

export const initializePendoScript = () => {
  if (envVariables.VITE_PENDO_API_KEY) {
    /* eslint-disable */
    (function (p, e, n, d) {
      let v;
      let w;
      let x;
      let y;
      let z;
      let o = (p[d] = p[d] || {});
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        o[v[w]] = o[v[w]] || (() => o._q[v[w] === v[0] ? 'unshift' : 'push']([v[w]].concat([].slice.call(arguments, 0))));
      }
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${envVariables.VITE_PENDO_API_KEY}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    /* eslint-enable */
  }
};
