import React, { ReactElement, useContext } from 'react';
import { LinearProgress, SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { flex } from '../../../components/AvThemeProvider';
import { ChartClickInteraction } from '../../../components/Widgets/types';
import useElementOnScreen from '../../../hooks/useElementOnScreen';
import { Filter } from '../../../types/filter.types';
import { emptyObject, noop } from '../../../utils/Utils';
import { CustomDashboardContext } from '../CustomDashboardContext';
import { useGetWidgetData } from '../hooks';
import { TableTypeWidget, WidgetCategory } from '../types';
import { AllTypeWidgets } from '../types/combined.types';
import { defaultWidget } from '../Utils';
import Widget from './Widget/Widget';
import TableWidget from './Widgets/TableWidget';
import WidgetToolbar from './WidgetToolbar';

interface WidgetProps {
  widget: AllTypeWidgets;
  setWidget?: (newWidget: any) => void;
  globalFilters?: Filter;
  children: any;
  showToolbar?: boolean;
  grabbable?: boolean;
  customToolbar?: ReactElement;
  isTable?: boolean;
  setIsTable?: any;
  selected?: any;
  onSelect?: (v) => void;
  clickInteractions?: ChartClickInteraction[];
  sx?: SxProps;
}

const DraggableWidget: React.FC<WidgetProps> = ({
  widget,
  setWidget = noop,
  globalFilters,
  children,
  showToolbar = true,
  grabbable = false,
  isTable,
  setIsTable,
  customToolbar = <WidgetToolbar widget={widget} setWidget={setWidget} isTable={isTable} setIsTable={setIsTable} />,
  selected,
  onSelect,
  clickInteractions,
  sx = emptyObject,
}) => {
  const { filters } = useContext(CustomDashboardContext);
  const isEditMode = filters.isEditMode[0] === 'true';
  const { ref, isVisible } = useElementOnScreen({});
  const { isRefetching } = useGetWidgetData({
    widget,
    contextFilter: globalFilters,
    visible: isVisible,
    debounceQueries: false,
  });
  const tableViewWidget = {
    ...defaultWidget,
    requests: widget.requests,
  };
  return (
    <>
      {isRefetching && <LinearProgress sx={{ position: 'absolute', width: '100%', height: '2px' }} />}
      <Box
        ref={ref}
        sx={{
          pl: 3,
          pr: 2,
          pt: 2,
          height: '100%',
          border: theme => `1px solid ${theme.palette.colors.neutrals[200]}`,
          ...flex.col,
          cursor: isEditMode && grabbable ? 'grab' : 'default',
          ...([WidgetCategory.Tile, WidgetCategory.Text].includes(widget.category) ? { overflow: 'hidden' } : {}),
          ...sx,
        }}>
        {showToolbar && customToolbar}
        {isTable ? (
          <Widget widget={tableViewWidget} isEdit={false} visible={isVisible} globalFilters={globalFilters}>
            <TableWidget widget={tableViewWidget as TableTypeWidget} />
          </Widget>
        ) : (
          <Widget
            widget={widget}
            setWidget={setWidget}
            isEdit={false}
            visible={isVisible}
            globalFilters={globalFilters}
            selected={selected}
            onSelect={onSelect}
            clickInteractions={clickInteractions}>
            {children}
          </Widget>
        )}
      </Box>
    </>
  );
};

export default DraggableWidget;
