import { clickthroughPrefix } from './consts';

// eslint-disable-next-line import/prefer-default-export
export const getFiltersWithoutKeyPrefix = (filters, keyPrefix = clickthroughPrefix) =>
  Object.keys(filters).reduce(
    (acc, cur) => ({
      ...acc,
      ...(cur.startsWith(keyPrefix) ? {} : { [cur]: filters[cur] }),
    }),
    {}
  );
