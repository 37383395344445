import { useContext } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { WorkflowType } from '../../context/SnackBar.types';
import {
  BaseExecutionRule,
  BuiltInFactorLogicExecutionRule,
  ExecutionRuleTypes,
  FactorKind,
  FactorLogicExecutionRule,
  FactorRule,
} from '../../types/executionRules.types';
import { getCleanExecutableUnits } from './components/utils';

const useFactorsExecutionRulesList = () => {
  const { api } = useAvContext();
  return useQuery<FactorLogicExecutionRule[]>({
    queryKey: ['findRiskFactorExecutionRulesByAccountId'],
    queryFn: () => api(GET_ACCOUNT_FACTOR_LIST).then(({ data }) => data?.findRiskFactorExecutionRulesByAccountId),
  });
};

const useFindBuiltInExecutionRuleByType = ({ type }) => {
  const { api } = useAvContext();
  return useQuery<BuiltInFactorLogicExecutionRule[]>({
    queryKey: ['findBuiltInExecutionRulesByType', type],
    queryFn: () => api(GET_BUILT_IN_FACTOR_LIST, { options: { type } }).then(({ data }) => data?.findBuiltInExecutionRulesByType),
    enabled: !!type,
    gcTime: 0,
  });
};

export const useGetListByFacorKind = {
  [FactorKind.ACCOUNT]: useFactorsExecutionRulesList,
  // eslint-disable-next-line react-hooks/rules-of-hooks
  [FactorKind.BUILT_IN]: () => useFindBuiltInExecutionRuleByType({ type: ExecutionRuleTypes.RISK_FACTOR }),
};

const useGetFactorLogic = ({ id, onSuccess, onError }: { id?: string; onSuccess?: (v: any) => void; onError?: (v: any) => void }) => {
  const { api } = useAvContext();
  return useQuery<BaseExecutionRule>({
    queryKey: ['findRiskFactorExecutionRuleById', id],
    queryFn: () =>
      api(GET_FACTOR_RULES, {
        options: { id },
        muteErrors: true,
        onSuccess: ({ data, errors }) => {
          if (errors && onError) {
            onError(errors);
            return errors;
          }
          if (onSuccess) {
            onSuccess(data.findRiskFactorExecutionRuleById);
          }
          return data.findRiskFactorExecutionRuleById;
        },
      }).catch(onError),
    enabled: !!id,
    gcTime: 0,
  });
};

const useGetBuiltInFactorLogic = ({
  id,
  onSuccess,
  onError,
}: {
  id?: string;
  onSuccess?: (v: any) => void;
  onError?: (v: any) => void;
}) => {
  const { api } = useAvContext();
  return useQuery<BaseExecutionRule>({
    queryKey: ['findBuiltInExecutionRuleById', id],
    queryFn: () =>
      api(GET_BUILT_IN_FACTOR_RULES, {
        options: { id },
        muteErrors: true,
        onSuccess: ({ data, errors }) => {
          if (errors && onError) {
            onError(errors);
            return errors;
          }
          if (onSuccess) {
            onSuccess(data.findBuiltInExecutionRuleById);
          }
          return data.findBuiltInExecutionRuleById;
        },
      }).catch(onError),
    enabled: !!id,
    gcTime: 0,
  });
};

export const useGetSourcesTypeForAccount = () => {
  const { api } = useAvContext();
  return useQuery<{ name: string; value: string }[]>({
    queryKey: ['sourceTypes'],
    queryFn: () =>
      api(GET_DATA_SOURCE_TYPE_FOR_ACCOUNT).then(({ data }) => {
        const res = data.getAllDsiTypesForAccount;
        return res
          ? ([
              ...new Set(
                res?.map(({ name, type }) => ({
                  name,
                  value: type,
                }))
              ),
            ] as { name: string; value: string }[])
          : [];
      }),
    gcTime: 0,
  });
};

export const useGetFactorLogicByKind = ({
  kind,
  id,
  onSuccess,
  onError,
}: {
  kind: FactorKind;
  id?: string;
  onError?: (v: any) => void;
  onSuccess?: (v: any) => void;
}) => {
  const factorLogicFuncByKind = {
    [FactorKind.ACCOUNT]: useGetFactorLogic,
    [FactorKind.BUILT_IN]: useGetBuiltInFactorLogic,
  };
  return factorLogicFuncByKind[kind]({ id, onSuccess, onError });
};

const getGqlByKind = (kind, isEditMode) => {
  const gqlByKind = {
    [FactorKind.ACCOUNT]: isEditMode ? UPDATE_ACCOUNT_FACTOR_RULES : CREATE_ACCOUNT_FACTOR_RULES,
    [FactorKind.BUILT_IN]: isEditMode ? UPDATE_BUILT_IN_FACTOR_RULES : CREATE_BUILT_IN_FACTOR_RULES,
  };
  return gqlByKind[kind];
};

export const useEditFactorLogic = ({ kind, isEditMode }: { kind: FactorKind; isEditMode: boolean }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: ({
      factor: { [kind]: dto },
      onSuccess,
      onError,
    }: {
      factor: { [key: string]: FactorRule };
      onSuccess: () => void;
      onError: (e) => void;
    }) => {
      const finalDto: FactorRule = {
        ...dto,
        preCheckUnits: getCleanExecutableUnits(dto.preCheckUnits),
        executableUnits: getCleanExecutableUnits(dto.executableUnits),
      };
      return api(getGqlByKind(kind, isEditMode), {
        options: { [kind]: finalDto },
        muteErrors: true,
        onSuccess,
      }).catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        onError(e);
      });
    },
  });
};

export const useDeleteFactorExecutionRule = (kind, onSuccess) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (ids: string[]) =>
      api(deleteGqlByKind[kind], {
        options: { ids },
        onSuccess: () => {
          enqueueSnackbar(`Successfully Deleted Factor Rule`, { variant: 'success' });
          onSuccess();
        },
      }),
  });
};

const onErrorSnackBar = (enqueueSnackbar, err) => enqueueSnackbar(`${err.message}`, { variant: 'error' });

export const useRunFactorRules = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { setRunInfo } = useContext(NotificationContext);

  return useMutation({
    mutationFn: () =>
      api(RUN_FACTOR_RULES, {
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
        onSuccess: ({
          data: {
            triggerRiskFactorExecutionGroup: { runId, wfId },
          },
        }) =>
          setRunInfo({
            runId,
            wfId,
            returnStatusOnly: true,
            workflowType: WorkflowType.RunFactorRules,
          }),
      }),
  });
};

export const useGetExecutionGroupId = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['ExecutionGroupId'],
    queryFn: () => api(GET_EXEC_GROUP_ID, { onSuccess: ({ data }) => data.findRiskFactorExecutionGroupIdByAccountId }),
  });
};

const RUN_FACTOR_RULES = gql`
  mutation TriggerRiskFactorExecutionGroup {
    triggerRiskFactorExecutionGroup {
      runId
      wfId
    }
  }
`;

const DELETE_FACTOR_EXECUTION_RULES = gql`
  mutation deleteRiskFactorExecutionRules($ids: [String!]!) {
    deleteRiskFactorExecutionRules(ids: $ids)
  }
`;

const DELETE_BUILT_IN_FACTOR_EXECUTION_RULES = gql`
  mutation deleteBuiltInExecutionRules($ids: [String!]!) {
    deleteBuiltInExecutionRules(ids: $ids)
  }
`;

const CREATE_ACCOUNT_FACTOR_RULES = gql`
  mutation CreateRiskFactorExecutionRule($executionRuleDto: ExecutionRuleDtoInput!) {
    createRiskFactorExecutionRule(executionRuleDto: $executionRuleDto) {
      id
    }
  }
`;

const CREATE_BUILT_IN_FACTOR_RULES = gql`
  mutation createBuiltInExecutionRule($builtInExecutionRuleDto: BuiltInExecutionRuleDtoInput!) {
    createBuiltInExecutionRule(builtInExecutionRuleDto: $builtInExecutionRuleDto) {
      id
    }
  }
`;

const UPDATE_ACCOUNT_FACTOR_RULES = gql`
  mutation UpdateRiskFactorExecutionRule($executionRuleDto: ExecutionRuleDtoInput!) {
    updateRiskFactorExecutionRule(executionRuleDto: $executionRuleDto) {
      id
    }
  }
`;

const UPDATE_BUILT_IN_FACTOR_RULES = gql`
  mutation updateBuiltInExecutionRule($builtInExecutionRuleDto: BuiltInExecutionRuleDtoInput!) {
    updateBuiltInExecutionRule(builtInExecutionRuleDto: $builtInExecutionRuleDto) {
      id
    }
  }
`;

const GET_FACTOR_RULES = gql`
  query findRiskFactorExecutionRuleById($id: String!) {
    findRiskFactorExecutionRuleById(id: $id) {
      id
      executableUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      preCheckUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      metadata
      type
    }
  }
`;

const GET_BUILT_IN_FACTOR_RULES = gql`
  query findBuiltInExecutionRuleById($id: String!) {
    findBuiltInExecutionRuleById(id: $id) {
      id
      executableUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      preCheckUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      metadata
      type
    }
  }
`;

const GET_ACCOUNT_FACTOR_LIST = gql`
  query findRiskFactorExecutionRulesByAccountId {
    findRiskFactorExecutionRulesByAccountId {
      id
      executableUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      preCheckUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      metadata
      type
      createdByUserId
      updatedByUserId
      updatedAt
    }
  }
`;

const GET_BUILT_IN_FACTOR_LIST = gql`
  query findBuiltInExecutionRulesByType($type: ExecutionType!) {
    findBuiltInExecutionRulesByType(type: $type) {
      id
      executableUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      preCheckUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      metadata
      type
      createdByUserId
      updatedByUserId
      updatedAt
    }
  }
`;

const GET_DATA_SOURCE_TYPE_FOR_ACCOUNT = gql`
  query getAllDsiTypesForAccount {
    getAllDsiTypesForAccount {
      type
      name
    }
  }
`;

const GET_EXEC_GROUP_ID = gql`
  query findRiskFactorExecutionGroupIdByAccountId {
    findRiskFactorExecutionGroupIdByAccountId
  }
`;

const deleteGqlByKind = {
  [FactorKind.ACCOUNT]: DELETE_FACTOR_EXECUTION_RULES,
  [FactorKind.BUILT_IN]: DELETE_BUILT_IN_FACTOR_EXECUTION_RULES,
};
