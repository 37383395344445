import { formatDateValue } from '../../../../components/FieldFilter';
import { getDateFromFilter } from '../../../../components/filters/Utils';
import { DateCondition } from '../../../../types/filter.types';
import { dateFormat as defaultDateFormat } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const formatTimeRange = (timeRange: DateCondition, dateFormat = defaultDateFormat) => {
  const dateObject = getDateFromFilter(timeRange);
  return formatDateValue({ value: dateObject.value, dateFormat });
};
