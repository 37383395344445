import React, { useMemo } from 'react';
import { Box, Skeleton, SxProps, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import RGL, { WidthProvider } from 'react-grid-layout';
import AvFilters from '../../../components/AvFilters';
import { flex } from '../../../components/AvThemeProvider';
import NoDataFound from '../../../components/NoDataFound';
import { useAvContext } from '../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../hooks/UseCustomSearchParams';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { cleanEmptyFilters, filterToExpression } from '../../../utils/filterUtils';
import { noop } from '../../../utils/Utils';
import ClickthroughContent from '../../CustomDashboards/components/Clickthrough/ClickthroughContent';
import { clickthroughPrefix, clickthroughWidgetId } from '../../CustomDashboards/components/Clickthrough/consts';
import { useGetWidgetSelectionProps } from '../../CustomDashboards/EditCustomDashboard/hooks';
import { SortDir } from '../../CustomDashboards/types';
import { filtersFieldToIgnore } from '../../Tickets/ticket.types';
import TimeRangeComp from '../components/TimeRangeComp';
import { getTimeRangeAndDatePickerValue } from '../utils';
import PolicyComplianceWidget from './PolicyComplianceWidget';
import { getProgressQuery, getTrendQuery } from './Queries';
import { breakdownSuffix, defaultFilter, expandedSuffix, sortSuffix } from './utils';
import { getPolicyProgressWidget, getPolicyTrendWidget } from './Widgets';

const commonStyle = {
  ...flex.col,
  background: 'white',
  position: 'relative',
  overflowX: 'hidden',
  '& .react-resizable-handle': {
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover .react-resizable-handle': {
    opacity: 1,
  },
  '&:not(:hover) .showOnHover': {
    opacity: 0,
    display: 'none',
  },
} as SxProps;

const dateFilters = ['dateGap', 'dateObject'];

const activeProjName = 'uber_policy_populations';
const ReactGridLayout = WidthProvider(RGL);

const PolicyCompliance = () => {
  const {
    accountEntities: { aggProjs, fieldTypeMap },
  } = useAvContext();
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter,
    shouldBeArray: id =>
      !dateFilters.includes(id) && !id.endsWith(expandedSuffix) && !id.endsWith(breakdownSuffix) && !id.endsWith(sortSuffix),
  });
  const onFilterChange = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };
  const filteredFilters = Object.keys(filters)
    .filter(
      key =>
        !key.endsWith(expandedSuffix) && !key.endsWith(breakdownSuffix) && !key.endsWith(sortSuffix) && !key.startsWith(clickthroughPrefix)
    )
    .reduce(
      (acc, field) =>
        ![...filtersFieldToIgnore, ...dateFilters].includes(field) && !!filters[field].length
          ? {
              ...acc,
              [field]: filters[field],
            }
          : acc,
      {}
    );
  const { dateObject, dateGap } = filters;
  const { timeRange, datePickerValue } = getTimeRangeAndDatePickerValue(dateObject);
  const regularFilters = filterToExpression(fieldTypeMap, filteredFilters);
  const cleanFilters = cleanEmptyFilters(regularFilters);
  const trendQuery = getTrendQuery(dateGap, timeRange, cleanFilters);
  const progressQuery = getProgressQuery(cleanFilters);

  const { isLoading: isLoadingTrend, data: trendWidgetsData = [] } = useQueryObject({
    queryObject: trendQuery,
  });

  const { isLoading: isLoadingProgress, data: progressWidgetsData = [] } = useQueryObject({
    queryObject: progressQuery,
  });

  const getWidgetSelectionProps = useGetWidgetSelectionProps();

  const trendWidgetsObj = useMemo(
    () =>
      trendWidgetsData.reduce((acc, cur) => {
        const { policyName, ...rest } = cur;
        return {
          ...acc,
          [policyName]: acc[policyName] ? [...acc[policyName], rest] : [rest],
        };
      }, {}),
    [trendWidgetsData]
  );

  const progressWidgetsObj =
    useMemo(
      () =>
        progressWidgetsData.reduce((acc, cur) => {
          const { policyName, ...rest } = cur;
          return {
            ...acc,
            [policyName]: rest,
          };
        }, {}),
      [progressWidgetsData]
    ) || {};

  const setPolicyState = policyName => field => val => onFilterChange({ [`${policyName}${field}`]: val });
  const clickthroughWidget = filters[clickthroughWidgetId] ? getPolicyProgressWidget(filters[clickthroughWidgetId][0]) : undefined;
  return clickthroughWidget ? (
    <Box
      key={clickthroughWidget.id}
      sx={{
        ...commonStyle,
        height: 'calc(100% - 24px)',
        boxShadow: `0px 15px 50px 0px rgba(18, 18, 27, 0.10)`,
        mx: 3,
        mb: 3,
      }}>
      <ClickthroughContent widget={clickthroughWidget} globalFilters={filters} setWidget={noop} />
    </Box>
  ) : (
    <Box sx={{ gap: 2, ...flex.col, overflowY: 'scroll' }}>
      <Typography variant="h3">Policy Compliance</Typography>
      <Box sx={flex.justifyBetweenCenter}>
        <AvFilters
          filters={filters}
          updateFilters={(field, val) => onFilterChange({ [field]: val })}
          setFilters={setFilters}
          activeProjName={activeProjName}
          dims={aggProjs[activeProjName].fieldList.INTERACTIVE}
        />
        <TimeRangeComp datePickerValue={datePickerValue} dateGap={dateGap} onFilterChange={onFilterChange} />
      </Box>
      {isLoadingProgress ? (
        <Box sx={{ width: '100%' }}>
          {['a', 'b', 'c', 'd'].map(k => (
            <Skeleton key={k} height={120} width="100%" />
          ))}
        </Box>
      ) : Object.keys(progressWidgetsObj).length > 0 ? (
        <ReactGridLayout useCSSTransforms={false} className="layout" style={{ ...flex.col, gap: 12, paddingBottom: 10 }}>
          {Object.keys(progressWidgetsObj).map(policyName => {
            const [sortField, sortDir] = filters[`${policyName}${sortSuffix}`]?.split('--') || [undefined, undefined];
            const breakdown = filters[`${policyName}${breakdownSuffix}`];
            return (
              <PolicyComplianceWidget
                policyName={policyName}
                trendConfig={
                  trendWidgetsObj[policyName]
                    ? {
                        data: trendWidgetsObj[policyName],
                        metric: 'policy_compliance_rate_granular',
                        widget: getPolicyTrendWidget(dateGap, timeRange),
                      }
                    : undefined
                }
                progressConfig={{
                  metricsValues: [
                    progressWidgetsObj[policyName].policy_compliance_rate_granular,
                    progressWidgetsObj[policyName].policy_pass_count_granular,
                    progressWidgetsObj[policyName].policy_asset_population_granular,
                  ],
                  widget: getPolicyProgressWidget(policyName),
                  ...getWidgetSelectionProps({ widget: getPolicyProgressWidget(policyName) }),
                }}
                isLoadingTrend={isLoadingTrend}
                isLoadingProgress={isLoadingProgress}
                expanded={filters[`${policyName}${expandedSuffix}`] === 'true' || false}
                breakdown={breakdown}
                sortDir={sortDir as SortDir}
                sortField={sortField}
                setPolicyState={setPolicyState}
                key={policyName}
                filter={cleanFilters}
              />
            );
          })}
        </ReactGridLayout>
      ) : (
        <NoDataFound
          callToAction={
            <Box sx={flex.justifyBetweenCenter}>
              Ensure a policy is created or <Button onClick={() => setFilters(defaultFilter)}>Clear all filters</Button>
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default PolicyCompliance;
