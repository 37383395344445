import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Theme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { flex } from './AvThemeProvider';

type AvSnackBarMessageProps = {
  title: string;
  subTitle?: string;
  closeSnackbar: () => void;
  onClickSkipValidation?: (value: boolean) => void;
  isTest?: boolean;
  message?: string;
};

export default function AvSnackBarMessage({
  title,
  subTitle = '',
  closeSnackbar,
  isTest = true,
  onClickSkipValidation,
  message = '',
}: AvSnackBarMessageProps) {
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);

  const handleToggleShowMore = () => setShowMore(prev => !prev);

  useEffect(() => {
    const element = messageRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > element.clientHeight);
    }
  }, [message]);

  return (
    <Box sx={{ ...flex.col, gap: '4px', pl: '4px', pb: '4px', wordBreak: 'break-word', flexGrow: 1 }}>
      <Box sx={{ color: (theme: Theme) => theme.palette.colors.neutrals[850] }}>{title}</Box>
      {subTitle && <Box sx={{ fontWeight: 400 }}>{subTitle}</Box>}
      <Box
        ref={messageRef}
        sx={{
          fontWeight: 400,
          color: (theme: Theme) => theme.palette.colors.neutrals[600],
          mt: 1,
          fontSize: 13,
          whiteSpace: 'pre-line',
          maxHeight: showMore ? '200px' : '60px',
          overflowY: showMore ? 'auto' : 'hidden',
        }}>
        {message}
      </Box>
      {isOverflowing && (
        <Box
          sx={{
            cursor: 'pointer',
            color: (theme: Theme) => theme.palette.primary.main,
            fontSize: 13,
            mt: '4px',
          }}
          onClick={handleToggleShowMore}>
          {showMore ? 'Show Less' : 'Show More'}
        </Box>
      )}
      <Box sx={{ ...flex.justifyEnd, gap: 1, mt: '10px' }}>
        {!isTest && (
          <Button
            variant="outlined"
            size="xSmall"
            onClick={() => {
              onClickSkipValidation?.(true);
              closeSnackbar();
            }}>
            Skip Validation
          </Button>
        )}
        <Button variant="contained" size="xSmall" onClick={closeSnackbar}>
          OK
        </Button>
      </Box>
    </Box>
  );
}

export const useStaticErrorSnackBar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return (params: Omit<AvSnackBarMessageProps, 'closeSnackbar'>) => {
    const detailedError = () => <AvSnackBarMessage {...params} closeSnackbar={() => closeSnackbar(snackBarKey)} />;
    const snackBarKey = enqueueSnackbar(detailedError(), { variant: 'static', persist: true, action: <span /> } as any);
  };
};
