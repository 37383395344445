import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { useExportQuerySqlWorkflow } from '../../hooks/useExportQueryWorklflow';
import { rebranding } from '../../rebranding';
import { PAGE_PATHS } from '../../types/index';
import { isDebugMode } from '../../utils/rum.utils';
import { deserializeSortItem, refetchingStyle, serializeSortItem, uniqBy } from '../../utils/Utils';
import { getHeadCellsWithSort } from '../../views/Tickets/hooks';
import AvItemWithLogoGroup, { IconSize, itemWithLogoTableCellStyle } from '../AvItemWithLogoGroup';
import { flex } from '../AvThemeProvider';
import ColumnReorder from '../ColumnReorder';
import ExportButton from '../ExportButton';
import { IconVariant } from '../ItemWithLogo';
import AvTable from '../Table/AvTable';
import { ActionButton } from '../Table/Utils';
import { ReactComponent as AssetCloudNew } from '../../assets/Asset_Cloud_new.svg';
import { ReactComponent as AssetCloud } from '../../assets/Asset_Cloud.svg';
import { ReactComponent as AssetDefaultNew } from '../../assets/Asset_Default_new.svg';
import { ReactComponent as AssetDefault } from '../../assets/Asset_Default.svg';
import { ReactComponent as AssetHostNew } from '../../assets/Asset_Host_new.svg';
import { ReactComponent as AssetHost } from '../../assets/Asset_Host.svg';
import { ReactComponent as AssetMobile } from '../../assets/Asset_mobile_device.svg';
import { ReactComponent as Ticket } from '../../assets/Ticket.svg';

const ticketIcon = <Ticket />;

const assetStyle = { height: 18, aspectRatio: 1, alignSelf: 'center' };
const assetList = {
  'Windows Server': rebranding ? <AssetCloudNew style={assetStyle} /> : <AssetCloud style={assetStyle} />,
  'Linux Server': rebranding ? <AssetCloudNew style={assetStyle} /> : <AssetCloud style={assetStyle} />,
  Workstation: rebranding ? <AssetHostNew style={assetStyle} /> : <AssetHost style={assetStyle} />,
  'Mobile Device': <AssetMobile style={assetStyle} />,
  Unknown: rebranding ? <AssetDefaultNew style={assetStyle} /> : <AssetDefault style={assetStyle} />,
};
const assetCellStyle = {
  paddingY: 1,
  paddingX: '22px',
  span: { ...flex.itemsCenter, gap: 2 },
  svg: { width: 28, height: 28, alignSelf: 'flex-start' },
};
const assetCell = v => (
  <span>
    {assetList[v] || assetList.Unknown}
    {v}
  </span>
);

function useAssetListHeadCells() {
  const { aggProjs } = useAvContext().accountEntities;
  const assetNameID = 'asset.name';
  const ownerID = 'asset.owner_id';

  const headers = [
    { id: 'asset._key', label: 'ID', isKey: true, hidden: true },
    { id: 'asset.type', label: 'Asset Type', formatter: assetCell, style: { ...assetCellStyle, padding: 0 } },
    { id: assetNameID, label: 'Asset Name', style: { wordBreak: 'break-all' } },
    { id: 'asset.source_key', label: 'Asset Source Key', style: { wordBreak: 'break-all' } },
    { id: ownerID, label: 'Owner', getValue: ({ [ownerID]: owner }) => owner || 'No Owner' },
    {
      id: 'asset.source_names',
      label: 'Data Source',
      type: 'array',
      style: itemWithLogoTableCellStyle,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: values => <AvItemWithLogoGroup items={values || []} variant={IconVariant.sourcesMapByName} size={IconSize.collapsed} />,
    },
    { id: 'asset.last_update', label: 'Last Seen', type: 'date', style: { whiteSpace: 'nowrap' } },
  ];
  return useMemo(
    () =>
      uniqBy(
        [
          ...headers.map(header => ({
            ...header,
            hidden: !!header.hidden,
          })),
          ...aggProjs.uber_assets.fieldList.INTERACTIVE.filter(({ group }) => group === 'Owner').map(({ systemName, value, title }) => ({
            id: value,
            label: title || systemName,
            hidden: true,
          })),
          ...aggProjs.uber_assets.fields
            .map(({ name, displayName }) => ({
              id: `${aggProjs.uber_assets.pathAlias}.${name}`,
              label: displayName || name,
              hidden: true,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ],
        f => f.id
      ),
    []
  );
}

interface AssetListProps {
  data?: any[];
  sql: string;
  isLoading?: boolean;
  filtersOriginal: any;
  setFilters: (v: any) => void;
  totals?: number;
}

export default function AssetList({ data, sql, isLoading, filtersOriginal, setFilters, totals }: AssetListProps) {
  const { getPathName } = useAvContext();

  const onHeadCellsChange = items => {
    setFilters({
      ...filtersOriginal,
      headCells: items.filter(({ hidden }) => !hidden).map(({ id }) => id),
      orderBy: filtersOriginal.orderBy.filter(({ property }) =>
        items.find(({ id, hidden, isKey }) => id === property && (isKey || !hidden))
      ),
    });
  };
  const page = +filtersOriginal.tablePage[0];

  const orderBy = filtersOriginal.orderBy
    .map(deserializeSortItem)
    .filter(({ property }) => !filtersOriginal.headCells || filtersOriginal.headCells.includes(property));

  const onPageChange = newPage => setFilters({ ...filtersOriginal, tablePage: [newPage] });

  const onSort = (property, direction) => {
    const isAsc = direction === 'asc';

    const nextOrderBy = [
      ...filtersOriginal.orderBy.filter(item => item.property !== property).slice(0, 0),
      ...(direction ? [{ property, isAsc }] : []),
    ];
    setFilters({ ...filtersOriginal, orderBy: nextOrderBy.map(serializeSortItem) });
  };

  const exploreTicketsUrl = keys =>
    getPathName(PAGE_PATHS.TICKETS, `?${keys.map(key => `ticket._key=${encodeURIComponent(key)}`).join('&')}`);
  const ticketAction = ({ relatedTickets }) =>
    relatedTickets?.length
      ? ActionButton('Go to related tickets list', undefined, ticketIcon, exploreTicketsUrl(relatedTickets), true)
      : null;

  const initialHeadCells = useAssetListHeadCells();
  const headCells = getHeadCellsWithSort(
    filtersOriginal.headCells.length
      ? [
          ...filtersOriginal.headCells.map(id => ({ ...initialHeadCells.find(h => h.id === id), hidden: false })),
          ...initialHeadCells.filter(h => !filtersOriginal.headCells.includes(h.id)).map(h => ({ ...h, hidden: true })),
        ]
      : initialHeadCells,
    orderBy
  );

  const exportSQL = `${sql.split('OFFSET')[0]}`;

  const { mutate: exportSqlWorkflow, isPending: isExportingWorkflow } = useExportQuerySqlWorkflow();

  return (
    <Box>
      <Box sx={{ ...flex.justifyEnd, gap: 1 }}>
        <ColumnReorder items={headCells} setItems={onHeadCellsChange} initialColumns={initialHeadCells} />
        <ExportButton
          onClick={() => exportSqlWorkflow({ querySql: exportSQL, projectionID: { name: 'uber_assets', builtIn: true } })}
          loading={isExportingWorkflow}
          width={18}
          totalRowsCount={totals}
        />
      </Box>
      <AvTable
        storageKey="AssetList"
        resizable
        rows={data}
        headCells={headCells}
        loading={isLoading}
        enableSelectionColor={false}
        hasHeaderFilters={false}
        rowLimit={10}
        onSort={onSort}
        pagination
        paginationProps={{ onPageChange, totalRows: totals, pageNum: page }}
        allowEdit={false}
        inlineActions={isDebugMode() ? [ticketAction] : undefined}
        sx={{ ...(isLoading && refetchingStyle) }}
      />
    </Box>
  );
}
