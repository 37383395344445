import React, { ReactNode, useMemo, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { alpha, Box, SxProps, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { BarChart, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';
import { isPercentageMetric } from '../../utils/dashboardDataUtils';
import { abbreviateNumber, emptyArray, emptyObject, normalize100PercentData } from '../../utils/Utils';
import { BarCategoryDefinition } from '../../views/CustomDashboards/types/BarWidget.types';
import AvLegend from '../AvLegend';
import { flex } from '../AvThemeProvider';
import { getXAngle, getYAngle, TickRotation, X_TICK_MARGIN, Y_TICK_MARGIN } from './AvComposedWidget.utils';
import { componentType } from './constants';
import CustomTick from './CustomTick';
import CustomTooltip from './CustomTooltip';
import { getHorizontalWidth } from './layout.components';
import { ChartClickInteraction } from './types';

interface Props {
  data: any[];
  series?: any[];
  isDate?: boolean;
  xAxisKey: string;
  showLegend?: boolean;
  labels?: { x?: string; y?: string };
  additionalAxis?: ReactJSXElement | ReactNode | ReactNode[];
  chartProps?: Partial<Pick<Readonly<CategoricalChartProps>, keyof CategoricalChartProps>> & {
    shouldRotateTicks?: boolean;
    xAxisTickType?: BarCategoryDefinition['xAxisTickType'];
    yAxisTickType?: BarCategoryDefinition['yAxisTickType'];
  };
  defaultAxisProps?: {
    x?: XAxisProps;
    xLabel?: object;
    y?: YAxisProps;
  };
  tooltipProps?: {
    showContentLegend?: boolean;
    metricsToHide?: string[];
    showTitle?: boolean;
    showTitleLegend?: boolean;
    metricFormatter?: (value: string) => string | (() => string);
    widgetData?: React.ComponentProps<typeof CustomTooltip>['widgetData'];
  };
  dateFormat?: string;
  getTooltipContent?: ContentType<ValueType, NameType>;
  sx?: SxProps;
  className?: string;
  selected?: any[];
  onSelect?: (v: any) => void;
  tooltipComponentProps?: { shared?: boolean; allowEscapeViewBox?: { x: boolean; y: boolean }; wrapperStyle?: any };
  clickInteractions?: ChartClickInteraction[];
  responsiveContainerProps?: { sx?: SxProps; width?: number | string; height?: number | string };
  showCartesianGridProps?: { vertical?: boolean; horizontal?: boolean };
}

function AvComposedWidget({
  data: d = emptyArray,
  series = emptyArray,
  isDate,
  xAxisKey,
  showLegend = true,
  labels = emptyObject,
  additionalAxis,
  chartProps,
  defaultAxisProps = { x: emptyObject, y: emptyObject, xLabel: emptyObject },
  tooltipProps = { showContentLegend: true, metricsToHide: emptyArray, showTitleLegend: false },
  dateFormat,
  getTooltipContent,
  sx = emptyObject,
  className,
  selected = emptyArray,
  onSelect,
  tooltipComponentProps = emptyObject,
  clickInteractions,
  responsiveContainerProps = emptyObject,
  showCartesianGridProps = emptyObject,
}: Props) {
  const [temporaryClickedCell, setTemporaryClickedCell] = useState<{ d: any; dataKey: any }>();
  const series100Percent = series.filter(({ is100Percent }) => is100Percent);
  const is100Percent = series100Percent.length;
  const data = useMemo(() => (is100Percent ? normalize100PercentData(d, series) : d), [d, series]);
  const theme = useTheme();
  const [hoveredCell, setHoveredCell] = useState();
  const rightAxisMetrics = useMemo(() => series.filter(s => isPercentageMetric(s.dataKey)), [series]);
  const isBar = series.every(({ type }) => type === 'bar');
  const isHorizontal = chartProps?.layout !== 'vertical';
  const shouldRotateTicks = data.length > 6 || (!is100Percent && chartProps?.shouldRotateTicks);

  const xTickRotation = getXAngle(chartProps?.xAxisTickType, shouldRotateTicks);
  const yTickRotation = getYAngle(chartProps?.yAxisTickType);

  const onCellSelection = cell => {
    if (clickInteractions && !selected.length) {
      const availableCellClickInteractions = clickInteractions.filter(v => v.isEnabledForCell(cell));
      if (availableCellClickInteractions.length === 1) {
        availableCellClickInteractions[0].onClick(cell);
      } else if (availableCellClickInteractions.length) {
        setTemporaryClickedCell(cell);
      }
      return undefined;
    }
    onSelect?.(cell);
    return undefined;
  };
  const tickFormatterX = isDate ? date => (date ? format(new Date(date), dateFormat || 'MMM') : '') : v => v;
  const tickFormatterY = tick => `${abbreviateNumber(tick)}${is100Percent ? '%' : ''}`;
  const horizontalWidth = useMemo(() => getHorizontalWidth(data), [data]);
  const content = (
    <>
      <CartesianGrid
        stroke={theme.palette.colors.neutrals[300]}
        vertical={!isHorizontal}
        horizontal={isHorizontal}
        {...showCartesianGridProps}
      />
      <XAxis
        label={{
          ...(labels.x && {
            value: labels.x,
            style: { textAnchor: 'bottom', fill: theme.palette.colors.neutrals[600] },
            dy: 10,
            position: 'center',
            offset: 0,
            ...defaultAxisProps.xLabel,
          }),
        }}
        dy={10}
        dataKey={isHorizontal ? xAxisKey : undefined}
        type={isHorizontal ? 'category' : 'number'}
        axisLine={false}
        tickLine={false}
        tickCount={5}
        tickMargin={X_TICK_MARGIN}
        height={shouldRotateTicks || xTickRotation !== TickRotation.horizontal ? 80 : undefined}
        tick={<CustomTick axis="X" rotation={xTickRotation} />}
        tickFormatter={isHorizontal ? tickFormatterX : tickFormatterY}
        {...defaultAxisProps.x}
      />
      <YAxis
        label={{
          ...(labels.y && {
            value: labels.y,
            style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
            angle: -90,
            position: 'left',
            offset: isHorizontal ? 0 : horizontalWidth,
          }),
        }}
        tickMargin={Y_TICK_MARGIN}
        yAxisId="left"
        tickCount={5}
        dataKey={isHorizontal ? undefined : xAxisKey}
        type={isHorizontal ? 'number' : 'category'}
        orientation="left"
        axisLine={false}
        tickLine={false}
        allowDecimals={false}
        tickFormatter={isHorizontal ? tickFormatterY : tickFormatterX}
        tick={<CustomTick axis="Y" rotation={yTickRotation} />}
        {...defaultAxisProps.y}
      />
      {additionalAxis}
      {!!rightAxisMetrics?.length && (
        <YAxis
          yAxisId="right"
          tickCount={5}
          tickMargin={15}
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickFormatter={tick => `${tick}%`}
          domain={[0, 1]}
          allowDecimals={false}
          {...defaultAxisProps.y}
        />
      )}
      <Tooltip
        cursor={{ fill: alpha(theme.palette.colors.neutrals[300], 0.5) }}
        {...(clickInteractions?.some(v => v.isEnabledForCell(temporaryClickedCell))
          ? { active: !!hoveredCell || !!temporaryClickedCell, trigger: temporaryClickedCell ? 'click' : 'hover' }
          : {})}
        content={
          getTooltipContent ||
          /* eslint-disable-next-line react/no-unstable-nested-components */
          (({ payload } = {}) => (
            <CustomTooltip
              payload={payload}
              isDate={isDate}
              {...tooltipProps}
              dateFormat={dateFormat}
              series100Percent={series100Percent}
              clickInteractions={clickInteractions}
              areClickOptionsVisible={!!temporaryClickedCell}
              setAreClickOptionsVisible={setTemporaryClickedCell}
            />
          ))
        }
        wrapperStyle={{ zIndex: theme.zIndex.modal, pointerEvents: 'auto', overflow: 'auto', maxHeight: '300px' }}
        {...tooltipComponentProps}
      />
      {series.map(s =>
        componentType[s.type]({
          props: s,
          theme,
          selected,
          onSelect: onCellSelection,
          data,
          hoveredCell: temporaryClickedCell ? { ...temporaryClickedCell.d, dataKey: temporaryClickedCell.dataKey } : hoveredCell,
          setHoveredCell: v => setHoveredCell(v),
        })
      )}
    </>
  );

  return (
    <Box
      sx={{ ...flex.col, height: '100%', gap: 2, ...(Array.isArray(sx) ? sx : [sx]) }}
      onMouseLeave={() => setTemporaryClickedCell(undefined)}>
      {showLegend && <AvLegend series={series} isHorizontal />}
      <Box sx={{ flex: 1, overflow: 'hidden', ...responsiveContainerProps.sx }}>
        <ResponsiveContainer
          className={className}
          width={responsiveContainerProps.width ?? '100%'}
          height={responsiveContainerProps.height ?? '100%'}>
          {isBar ? (
            <BarChart {...chartProps} data={data}>
              {content}
            </BarChart>
          ) : (
            <ComposedChart {...chartProps} data={data}>
              {content}
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}
export default AvComposedWidget;
