import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import Select from '../../../components/Select';
import { useAvContext } from '../../../context/AvContextProvider';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { Filter } from '../../../types/filter.types';
import TableWidget from '../../CustomDashboards/components/Widgets/TableWidget';
import { SortDir } from '../../CustomDashboards/types';
import { getTableQuery } from './Queries';
import { breakdownSuffix, sortSuffix } from './utils';
import { getTableWidget } from './Widgets';

const projName = 'uber_policy_populations';

interface Props {
  filter: Filter;
  policyName: string;
  breakdown?: string;
  setPolicyState: (name) => (field) => (val) => void;
  sortField?: string;
  sortDir?: SortDir;
}

const PolicyTableBreakdown = ({ filter, policyName, breakdown = 'asset.type', setPolicyState, sortField, sortDir }: Props) => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const aggProjFieldList = useMemo(
    () => aggProjs.uber_policy_populations.fieldList.INTERACTIVE.filter(field => field.mainProjId.name !== projName),
    [aggProjs, projName]
  );

  const tableQuery = getTableQuery(breakdown, filter, sortField, sortDir);
  const { isLoading: isLoadingTable, data: tableWidgetData = [] } = useQueryObject({
    queryObject: tableQuery,
  });

  const tableWidget = getTableWidget(tableQuery);
  const setWidget = ({
    requests: [
      {
        sorting: [{ name, dir }],
      },
    ],
  }) => setPolicyState(policyName)(sortSuffix)(`${name}--${dir}`);
  return (
    <Box
      sx={{
        height: 460,
        bgcolor: theme => theme.palette.colors.neutrals[100],
        borderTop: 1,
        borderColor: theme => theme.palette.colors.neutrals[350],
        p: 4,
        ...flex.justifyBetween,
        gap: 4,
      }}>
      <Box sx={{ width: 280, ...flex.col, gap: 2 }}>
        <Box sx={{ color: theme => theme.palette.colors.neutrals[800], fontWeight: 600 }}>Breakdown</Box>
        <Box>Find out more about this policy status by breaking it down to other dimensions</Box>
        <Select
          label="Report policy status by"
          size="small"
          options={aggProjFieldList}
          value={breakdown}
          onChange={value => setPolicyState(policyName)(breakdownSuffix)(value)}
          isRequired
        />
      </Box>
      <TableWidget widget={tableWidget} setWidget={setWidget} data={tableWidgetData} isLoading={isLoadingTable} />
    </Box>
  );
};

export default PolicyTableBreakdown;
