import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, FormControl, IconButton, LinearProgress, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { AttachmentList } from '../../components/AvPreviewImage.tsx';
import { flex } from '../../components/AvThemeProvider.tsx';
import AvTooltip from '../../components/AvTooltip.tsx';
import { generateID, iconSize } from '../../utils/Utils';
import InputPopper from './InputPopper';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as Attach } from '../../assets/Attach.svg';
import { ReactComponent as FormatQuote } from '../../assets/Blockquote.svg';
import { ReactComponent as FormatBold } from '../../assets/Bold.svg';
import { ReactComponent as FormatListBulleted } from '../../assets/Bulletlist.svg';
import { ReactComponent as Code } from '../../assets/Code.svg';
import { ReactComponent as CodeOff } from '../../assets/Codeblock.svg';
import { ReactComponent as Image } from '../../assets/Image.svg';
import { ReactComponent as FormatItalic } from '../../assets/Italic.svg';
import { ReactComponent as LinkSVG } from '../../assets/Link.svg';
import { ReactComponent as FormatListNumbered } from '../../assets/Numberslist.svg';
import { ReactComponent as StrikethroughS } from '../../assets/Strikethrogh.svg';
import { ReactComponent as FormatUnderlined } from '../../assets/Underline.svg';

const formats = ['bold', 'italic', 'size', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'code', 'code-block'];
const icons = ReactQuill.Quill.import('ui/icons');
formats.forEach(format => (icons[format] = undefined));

const Block = ReactQuill.Quill.import('blots/block');
Block.tagName = 'div';
ReactQuill.Quill.register(Block);

const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];
const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
ReactQuill.Quill.register(Size, true);

function ItemButton({ qlClass, qlValue, onClick, title, icon }) {
  return (
    <AvTooltip muiProps={{ placement: 'top' }} title={title}>
      <IconButton className={qlClass} value={qlValue} onClick={onClick}>
        {icon}
      </IconButton>
    </AvTooltip>
  );
}
ItemButton.propTypes = {
  qlClass: PropTypes.string,
  qlValue: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};
ItemButton.defaultProps = {
  qlClass: undefined,
  qlValue: undefined,
  onClick: undefined,
};

export default function AvRichText({ showBar, value, onChange, fileUploadProps, ...otherProps }) {
  const { palette } = useTheme();
  const [popper, setPopper] = useState();
  const toolbarID = useRef(`toolbar_${generateID()}`).current;
  const quillRef = useRef();
  const getEditor = () => quillRef.current.getEditor();

  const popperProps = {
    image: {
      text: 'Image URL',
      onApply: ({ input: url }) => {
        const editor = getEditor();
        editor.insertEmbed(editor.getSelection(), 'image', url);
      },
    },
  };
  const openPopper =
    type =>
    ({ currentTarget }) =>
      setPopper({ anchor: currentTarget, ...popperProps[type] });

  const divider = <Divider flexItem orientation="vertical" sx={{ borderColor: palette.colors.neutrals[400] }} />;
  const customToolbar = (
    <Box id={toolbarID}>
      <Box sx={{ position: 'relative', whiteSpace: 'nowrap' }}>
        <select className="ql-size" defaultValue="14px">
          {fontSizeArr.map(v => (
            <option value={v}>{v.split('px')[0]}</option>
          ))}
        </select>
        <ArrowDown style={{ ...iconSize(14), position: 'absolute', right: '8px', top: '6px', color: palette.colors.neutrals[600] }} />
      </Box>
      {divider}
      <ItemButton title="Bold" qlClass="ql-bold" icon={<FormatBold />} />
      <ItemButton title="Italic" qlClass="ql-italic" icon={<FormatItalic />} />
      <ItemButton title="Underline" qlClass="ql-underline" icon={<FormatUnderlined />} />
      <ItemButton title="Strikethrough" qlClass="ql-strike" icon={<StrikethroughS />} />
      {divider}
      <ItemButton title="Numbered List" qlClass="ql-list" qlValue="ordered" icon={<FormatListNumbered />} />
      <ItemButton title="Bulleted List" qlClass="ql-list" qlValue="bullet" icon={<FormatListBulleted />} />
      {divider}
      <ItemButton title="Blockquote" qlClass="ql-blockquote" icon={<FormatQuote />} />
      <ItemButton title="Inline Code" qlClass="ql-code" icon={<Code />} />
      <ItemButton title="Code Block" qlClass="ql-code-block" icon={<CodeOff />} />
      {divider}
      <ItemButton title="Link" qlClass="ql-link" icon={<LinkSVG />} />
      <ItemButton title="Inset Image" onClick={openPopper('image')} icon={<Image />} />
      {fileUploadProps.enable && (
        <>
          {divider}
          <ItemButton title="Add Attachment" onClick={fileUploadProps.handleAttachmentClick} icon={<Attach />} />
        </>
      )}
      {popper && (
        <InputPopper
          anchorEl={popper.anchor}
          onApply={popper.onApply}
          texts={{ input: popper.text, button: 'Apply' }}
          onClose={() => setPopper(false)}
        />
      )}
    </Box>
  );

  const forceTriggerChange = () => quillRef.current?.getEditor().getSelection();

  useEffect(() => {
    const handlePaste = async event => {
      try {
        const { clipboardData } = event;
        const files = Array.from(clipboardData.files);
        if (files.length > 0) {
          event.preventDefault();
          fileUploadProps.handleFileChange(clipboardData.files);
        }
      } catch (error) {
        console.error('Error reading clipboard data:', error);
      }
    };
    document.addEventListener('paste', handlePaste);
    return () => document.removeEventListener('paste', handlePaste);
  }, [fileUploadProps.handleFileChange]);

  return (
    <FormControl sx={wrapperStyle}>
      {showBar && customToolbar}
      <ReactQuill
        key={showBar}
        ref={quillRef}
        value={value}
        onKeyUp={forceTriggerChange}
        onChange={onChange}
        modules={{ toolbar: showBar && { container: `#${toolbarID}` } }}
        formats={formats}
        preserveWhitespace
        {...otherProps}
        onKeyDown={e => {
          e.stopPropagation();
          otherProps.onKeyDown?.(e);
        }}
      />
      {fileUploadProps.enable && !!fileUploadProps.attachmentsList.length && (
        <AttachmentList
          attachments={fileUploadProps.attachmentsList}
          onDeleteFile={fileUploadProps.onDeleteFile}
          deleteByIndex
          style={{ pl: 3, pt: 2, pb: 3 }}
          isLoading={fileUploadProps.isLoading}
          projection={fileUploadProps.projectionId}
        />
      )}
      {fileUploadProps.isLoading && <LinearProgress sx={{ height: '1px' }} />}
    </FormControl>
  );
}
AvRichText.propTypes = {
  showBar: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  fileUploadProps: PropTypes.shape({
    enable: PropTypes.bool,
    attachmentsList: PropTypes.arrayOf(PropTypes.shape()),
    handleAttachmentClick: PropTypes.func,
    handleFileChange: PropTypes.func,
    onDeleteFile: PropTypes.func,
    downloadFile: PropTypes.func,
    isLoading: PropTypes.bool,
    projectionId: PropTypes.shape(),
  }),
};
AvRichText.defaultProps = {
  showBar: true,
  value: '',
  onChange: undefined,
  fileUploadProps: {
    enable: true,
    attachmentsList: undefined,
    handleAttachmentClick: undefined,
    handleFileChange: undefined,
    onDeleteFile: undefined,
    downloadFile: undefined,
    isLoading: false,
    projectionId: undefined,
  },
};

const wrapperStyle = ({ palette }) => ({
  width: '100%',
  wordBreak: 'break-all',
  position: 'relative',
  ':before': {
    content: '""',
    height: '100%',
    width: '100%',
    pointerevents: 'none',
    position: 'absolute',
    borderRadius: '12px',
    transition: theme =>
      theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.shorter,
      }),
    border: `1px solid ${palette.colors.neutrals[400]}`,
  },
  '&:hover:before': {
    border: `1px solid ${palette.colors.neutrals[800]}`,
  },
  '&:has(.ql-editor:focus):before': {
    border: `1px solid ${palette.primary.main}`,
  },
  '.ql-snow': {
    '&.ql-container': {
      border: 'none',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      '.ql-editor.ql-blank:before': {
        fontStyle: 'inherit',
      },
    },
    '&.ql-toolbar, .ql-toolbar': {
      ...flex.row,
      gap: 2,
      px: 2,
      py: '10px',
      backgroundColor: palette.colors.neutrals[200],
      border: 'none',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      button: {
        display: 'inherit',
        float: 'none',
        height: 'inherit',
        padding: 0,
        width: 'inherit',
        '&, &:focus': {
          color: palette.colors.neutrals[600],
        },
        '&.ql-active, &:hover': {
          color: 'inherit',
        },
        '&.ql-active:before': {
          backgroundColor: palette.colors.neutrals[350],
        },
      },
      ':after': {
        content: 'none',
      },
    },
    '.ql-tooltip': {
      left: '16px !important',
      px: 2,
      py: 1,
      color: palette.colors.neutrals[800],
      borderRadius: '2px',
      border: 0,
      boxShadow: theme => theme.components.MuiPaper.styleOverrides.root.boxShadow,
      input: {
        borderRadius: '10px',
        px: 1,
        outline: 0,
        transition: theme =>
          theme.transitions.create(['border-color'], {
            duration: theme.transitions.duration.shorter,
          }),
        ':hover': {
          borderColor: palette.colors.neutrals[800],
        },
        ':focus': {
          borderColor: palette.primary.main,
        },
      },
      '.ql-action, .ql-remove': {
        color: 'inherit',
        fontWeight: 600,
        padding: '3px 10px',
        verticalAlign: 'middle',
        fontSize: 13,
        lineHeight: 1.5,
        borderRadius: '8px',
        border: `1px solid ${palette.colors.neutrals[400]}`,
        ml: 1,
        ':before, :after': {
          p: 0,
          m: 0,
          border: 0,
        },
      },
      '&.ql-editing .ql-action': {
        backgroundColor: palette.primary.main,
        color: palette.white.main,
        border: 0,
      },
    },
    'code, .ql-editor pre.ql-syntax': {
      fontFamily: 'inherit',
      backgroundColor: palette.colors.neutrals[200],
      color: 'inherit',
    },
    code: {
      fontSize: 13,
      padding: '2px 4px',
    },
    '.ql-editor a, a.ql-preview': {
      color: palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '&.ql-toolbar.ql-snow .ql-picker-label': {
      border: `1px solid ${palette.colors.neutrals[400]}`,
      borderRadius: '8px',
      padding: '0px 24px 0px 8px',
      color: palette.colors.neutrals[600],
      ':hover': {
        color: palette.colors.neutrals[700],
        border: `1px solid ${palette.colors.neutrals[500]}`,
      },
    },
    '&.ql-snow.ql-toolbar .ql-picker-item:hover': {
      color: palette.colors.neutrals[800],
      backgroundColor: palette.colors.neutrals[300],
    },
    '.ql-picker': {
      svg: {
        display: 'none',
      },
      '&.ql-size': {
        width: 'unset',
      },
    },
  },
});
