import { getIfValidStringifiedObject } from '../../utils/Utils';
import { FormatterTypes } from '../../views/CustomDashboards/constants';
import { GroupHeaderRowType } from './types';
import { getComparator, getGroupComparator } from './Utils';

const stringOperators = {
  contains: (str, value) => str.toLowerCase().includes(value),
  notContains: (str, value) => !str.toLowerCase().includes(value),
  starts: (str, value) => str.toLowerCase().startsWith(value),
  ends: (str, value) => str.toLowerCase().endsWith(value),
  equals: (str, value) => str.toLowerCase() === value,
  notEqual: (str, value) => str.toLowerCase() !== value,
};

const numberOperators = {
  equals: (rowValue, value) => rowValue === value,
  notEqual: (rowValue, value) => rowValue !== value,
  gt: (rowValue, value) => rowValue > value,
  gte: (rowValue, value) => rowValue >= value,
  lt: (rowValue, value) => rowValue < value,
  lte: (rowValue, value) => rowValue <= value,
};
const getRows = (rows, order, orderBy, headers, page, rowsPerPage, isServerRows, filters, hasHeaderFilters, rowGroupByField?) => {
  const headTypeMap =
    hasHeaderFilters &&
    headers.reduce((acc, header) => {
      acc[header.id] = header.type;
      return acc;
    }, {});
  const filterKeys = Object.keys(headTypeMap);
  const filteredRows = hasHeaderFilters
    ? rows.filter(r =>
        filterKeys.every(k => {
          const noFilter = !filters[k]?.length;
          const rowValue = r[k];
          const isArray = Array.isArray(rowValue);
          const colGetValue = findHeaderGetValue(headers, k);
          const isInList = isArray && rowValue.some(v => filters[k]?.includes(v));
          const isBoolField = headTypeMap[k] === 'bool';
          const isTrue = isBoolField && filters[k]?.[0] === 'true' && rowValue;
          const isFalse = isBoolField && filters[k]?.[0] === 'false' && !rowValue;
          const conditionFilter = getIfValidStringifiedObject(filters[k]);

          if (conditionFilter) {
            const rootOperator = Object.keys(conditionFilter)[0];
            const { value, operator } = conditionFilter[rootOperator][0];
            if (typeof value === FormatterTypes.string && operator in stringOperators) {
              const lowerCasedValue = value?.toLowerCase();
              const strRowValue = `${rowValue}`;
              return stringOperators[operator](strRowValue, lowerCasedValue);
            }
            if (typeof value === FormatterTypes.number && operator in numberOperators) {
              return numberOperators[operator](rowValue, value);
            }
            if (operator === 'empty') {
              return !rowValue;
            }
            if (operator === 'notEmpty') {
              return Boolean(rowValue);
            }
            return false;
          }
          return (
            noFilter ||
            isTrue ||
            isFalse ||
            isInList ||
            filters[k].includes(colGetValue ? colGetValue(r) : r[k]) ||
            r.groupHeaderType === GroupHeaderRowType.GROUP
          );
        })
      )
    : rows;
  const comparator = getComparator(order, findHeaderGetValue(headers, orderBy) || (row => row[orderBy]));
  const groupComparator = getGroupComparator(rowGroupByField, order);
  const orderedRows = orderBy
    ? filteredRows.slice().sort(rowGroupByField ? (a, b) => groupComparator(a, b) || comparator(a, b) : comparator)
    : filteredRows;
  return rowsPerPage === null || isServerRows ? orderedRows : orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

const findHeaderGetValue = (headers, colName) => headers.find(({ id }) => id === colName)?.getValue;

export default getRows;
