export const QueryOrigin = {
  INTERACTIVE_QUERY: 'INTERACTIVE_QUERY',
  QUERY_RUNNER: 'QUERY_RUNNER',
  DATA_EXPLORATION: 'DATA_EXPLORATION'
};

export type QueryOriginType = (typeof QueryOrigin)[keyof typeof QueryOrigin];

export const ErrorTypes = {
  AvContext: '[Context Error]',
  Setup: '[Setup Error]',
  Offline: '[Offline]',
  Network: '[Network Error]',
  Authentication: '[authentication Error]',
  Validation: '[validation Error]',
};
