import { DateFormats } from '../../../utils/date.utils';
import { TimeBucketToDateGap } from '../../Reports/types';
import {
  BarCategoryWidgetTypes,
  LineCategoryWidgetTypes,
  PieCategoryWidgetTypes,
  TileCategoryWidgetTypes,
  TileWidgetVisualization,
} from '../types';
import { hasTimeBucket } from './TimeBucketBreakdown/utils';

// eslint-disable-next-line import/prefer-default-export
export const getDateFormatFromWidget = (
  widget: LineCategoryWidgetTypes | BarCategoryWidgetTypes | PieCategoryWidgetTypes | TileCategoryWidgetTypes
) => {
  if (widget.requests[0].timeRange) {
    const selectedTimeBucket = widget.requests[0].select.dims.find(({ name }) => hasTimeBucket(name))?.name;
    return DateFormats[TimeBucketToDateGap[selectedTimeBucket || '']];
  }

  return "MM/dd/yyyy', ' HH':'mm':'ss";
};

export const visualizationOptions = [
  { label: TileWidgetVisualization.None, value: TileWidgetVisualization.None },
  { label: TileWidgetVisualization.Progress, value: TileWidgetVisualization.Progress },
];
