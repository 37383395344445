import { useApolloClient } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { print } from 'graphql/language/printer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/amplifyProvider';
import useHandleError from '../hooks/useHandleError';
import envVariables from '../shared/projectEnvVariables';
import { APP_PATHS, FeatureFlags } from '../types';
import { ErrorTypes } from '../types/query.types';
import { getAccountTokenFromLS } from './auth.utils';
import { AuthStorageKeys } from './localStorageAuthUtils.utils';
import { getAppIdFromPathName, getPathNameByAccount } from './router.utils';

const omitField = field => (key, value) => (key === field ? undefined : value);
const omitTypeNames = obj => JSON.parse(JSON.stringify(obj || {}), omitField('__typename'));
type RequestOptions = {
  options?: any;
  body?: any;
  isJson?: boolean;
  onError?: (errors: any) => void;
  isWebserver?: boolean;
  muteErrors?: boolean;
  excludeAccountIdHeader?: boolean;
  onSuccess?: (d: any) => any;
  signal?: AbortSignal;
};

type ApiFunction = (
  uri: string,
  { options, body, isJson, onError, isWebserver, muteErrors, excludeAccountIdHeader }?: RequestOptions
) => Promise<any>;

export default function useApi(demoMode, accountId, refetchAccessToken, featureFlags): ApiFunction {
  const navigate = useNavigate();
  const location = useLocation();
  const client: any = useApolloClient();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const checkUnauthorized = status => status === 401;
  const checkPermission = status => status === 418;
  const { logout } = useAuth();

  const navigateNotFound = () => {
    const accountIdLocalStorage = localStorage.getItem(AuthStorageKeys.accountId);
    navigate(
      accountIdLocalStorage
        ? getPathNameByAccount(accountIdLocalStorage, getAppIdFromPathName(location.pathname) || APP_PATHS.PLATFORM)('not-found')
        : 'not-found'
    );
  };

  return async (
    uri: any,
    {
      options = {},
      body,
      isJson = true,
      onError,
      onSuccess = d => d,
      isWebserver = true,
      muteErrors,
      excludeAccountIdHeader,
      signal,
    }: RequestOptions = {}
  ) => {
    const graphqlErrorHandling = async (error, isRetry, additionalInfo) => {
      if (checkUnauthorized(error.networkError?.statusCode)) {
        const accessToken = getAccountTokenFromLS(accountId);
        if (isRetry) {
          console.error(
            `${ErrorTypes.Authentication}: Error fetching new access token after retry, logging out: ${!accessToken}, graphqlName: ${
              additionalInfo.graphqlName
            }, account: ${accountId}`
          );

          await logout(!!accessToken);
          return undefined;
        }
        return refetchAccessToken(accountId)
          .then(res => fetchGraphql(res, true))
          .catch(async () => {
            console.error(
              `${ErrorTypes.Authentication}: Error fetching new access token, logging out: ${!accessToken}, graphqlName: ${
                additionalInfo.graphqlName
              }, account: ${accountId}`
            );
            await logout(!!accessToken);
          });
      }
      if (checkPermission(error.networkError?.statusCode)) {
        navigateNotFound();
        return undefined;
      }
      if (muteErrors) {
        throw error;
      } else if (onError && !signal?.aborted) {
        onError(error);
      } else {
        handleError(error.networkError?.bodyText || error, additionalInfo);
      }
      throw error;
    };

    const restErrorHandling = async (res, isRetry) => {
      const isTextError = res.headers?.get('content-type')?.startsWith('text/');
      const responseContent = await (isTextError ? res.text() : res.json());
      datadogRum.addAction(`Error:REST:${uri}`, { request: { uri, options }, response: responseContent });
      const error = res.headers ? responseContent : res.message;
      if (checkUnauthorized(res.status)) {
        const accessToken = getAccountTokenFromLS(accountId);
        if (isRetry) {
          await logout(!!accessToken);
          return undefined;
        }
        return refetchAccessToken(accountId)
          .then(res => fetchRest(res, true))
          .catch(async () => {
            await logout(!!accessToken);
          });
      }
      if (checkPermission(error.networkError?.statusCode)) {
        navigateNotFound();
        return undefined;
      }
      if (muteErrors) {
        throw error;
      } else if (onError) {
        onError(error);
      } else {
        handleError(error);
      }
      throw error;
    };
    const fetchGraphql = (accessToken, isRetry?) => {
      if (demoMode) {
        return fetch(`${envVariables.PUBLIC_URL}/mocks/${uri.definitions[0].selectionSet.selections[0].name.value}.json`).then(res =>
          res.json()
        );
      }
      const graphqlName = uri.definitions[0].selectionSet.selections[0].name.value;
      const action = uri.definitions[0].operation === 'mutation' ? 'mutate' : 'query';
      const variables = omitTypeNames(options);
      return client[action]({
        [uri.definitions[0].operation]: uri,
        variables,
        context: {
          fetchOptions: {
            signal,
          },
          headers: {
            ...options.headers,
            ...(shouldSendAccessToken() ? { Authorization: `Bearer ${accessToken}` } : {}),
            ...(excludeAccountIdHeader ? {} : { accountId }),
            graphqlName,
            'x-full-url': window.location.pathname,
          },
        },
      })
        .then(res => {
          datadogRum.addAction(`Graphql:${graphqlName}`, { graphqlName, request: { uri: print(uri), variables }, response: res });
          if (!muteErrors && res.errors) {
            if (onError) {
              onError(res.errors);
            } else {
              handleError(res.errors, { accountId, graphqlName });
            }
          }
          return onSuccess(res);
        })
        .catch(error => {
          datadogRum.addAction(`Error:Graphql:${graphqlName}`, { graphqlName, request: { uri: print(uri), variables }, response: error });
          return graphqlErrorHandling(error, isRetry, { accountId, graphqlName });
        });
    };

    const shouldSendAccessToken = () => envVariables.VITE_SEND_REQUESTS_WITH_TOKEN === 'true';
    const fetchRest = async (accessToken, isRetry?) => {
      const res = await fetch(isWebserver ? `${envVariables.VITE_WEBSERVER_API_URL}/${uri}` : `${envVariables.VITE_API_URL}/${uri}`, {
        body: isJson ? JSON.stringify(omitTypeNames(body)) : body, // TODO fix ugliness
        ...options,
        headers: {
          ...(isJson ? { 'Content-Type': 'application/json' } : undefined),
          ...options.headers,
          ...(shouldSendAccessToken() ? { Authorization: `Bearer ${accessToken}` } : {}),
          ...(excludeAccountIdHeader ? {} : { accountId }),
        },
      }).catch(res => res);
      if (res.ok) {
        const responseContent = await Promise.resolve(
          res.headers.get('Content-Type') === 'application/octet-stream' ? res : res.headers.get('content-length') === '0' ? '' : res.json()
        );
        datadogRum.addAction(`REST:${uri}`, { request: { uri, options }, response: responseContent });
        return onSuccess(responseContent) || responseContent;
      }
      return restErrorHandling(res, isRetry);
    };

    if (uri.definitions) {
      return fetchGraphql(getAccountTokenFromLS(accountId));
    }
    if (demoMode) {
      return fetch(`${envVariables.PUBLIC_URL}/mocks/${uri}.json`).then(res => res.json());
    }
    return fetchRest(getAccountTokenFromLS(accountId));
  };
}
