import React from 'react';
import { useTheme } from '@mui/material';
import { emptyArray } from '../../utils/Utils';
import AvComposedWidget from './AvComposedWidget';

interface LabelProps {
  x?: number;
  y?: number;
  payload?: any;
  active?: number;
}

export function Label({ x = 0, y = 0, payload, active }: LabelProps) {
  const theme = useTheme();
  const isActive = active === payload.index;
  const textStyle = isActive ? { fontWeight: '600', fill: theme.palette.colors.neutrals[800] } : {};
  return (
    <text className={isActive ? 'custom-chart-text' : ''} x={x} y={y + 10} textAnchor="middle" {...textStyle}>
      {payload?.value}
    </text>
  );
}

function ActiveTicketsProgress({ data = emptyArray }: { data?: any[] }) {
  const themeHook = useTheme();
  const colors = themeHook.palette.chartColors;

  const buckets = Array.from(new Set(data.map(({ bucket }) => bucket)));
  const statuses = Array.from(new Set(data.map(({ status }) => status))).filter(Boolean);
  const series = statuses.reduce(
    (acc, status, index) => [...acc, { name: status, dataKey: status, color: colors[index], type: 'bar', stackId: 't' }],
    []
  );

  const chartData = buckets.reduce((acc, bucket) => {
    const statusesForBucket = data
      .filter(row => row.bucket === bucket)
      .reduce((acc, row) => {
        acc[row.status] = row.count;
        return acc;
      }, {});

    return [...acc, { name: bucket, ...statusesForBucket }];
  }, []);

  return <AvComposedWidget data={chartData} series={series} isDate={false} xAxisKey="name" />;
}
export default ActiveTicketsProgress;
