import React, { useContext, useState } from 'react';
import { capitalize, Divider, IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import AvBreadcrumbs, { EVariant } from '../../../components/AvBreadcrumbs';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { RelativeDatePeriod, RelativeDateType } from '../../../components/DatePicker/AvDateRangePicker.constants';
import { dateFormat, formatDate, getDatesFromDefinition, getInputValue, validateValue } from '../../../components/DatePicker/utils';
import { getDateFromFilter } from '../../../components/filters/Utils';
import { HistoricDataIndicator } from '../../../components/Widgets/layout.components';
import { useAvContext } from '../../../context/AvContextProvider';
import { rebranding } from '../../../rebranding';
import { FeatureFlags } from '../../../types';
import { NewRelativeDate } from '../../../types/filter.types';
import { cellContentStyle, generateInnerDefaultFormatter, iconSize } from '../../../utils/Utils';
import { CustomDashboardContext } from '../CustomDashboardContext';
import { useGetDisplayName, useGetFieldType } from '../hooks';
import { AllTypeWidgets, TableSubType, TableTypeWidget, TextSubType, TileSubType, WidgetCategory } from '../types';
import { TabsType } from '../types/types';
import { activeIconStyle, defaultDrillDownHierarchy, shouldShowDrillDown } from '../Utils';
import DrillUpButton from './DrillUpButton';
import { FilterToText } from './FiltersDescription';
import WidgetToolbarActions from './WidgetToolbarActions';
import { ReactComponent as FilterActive } from '../../../assets/colorful/FilterActive.svg';
import { ReactComponent as FilterActiveNew } from '../../../assets/colorful2/FilterActive.svg';
import { ReactComponent as Edit } from '../../../assets/Edit.svg';
import { ReactComponent as Maximize } from '../../../assets/MaximizeFullScreen.svg';
import { ReactComponent as Minimize } from '../../../assets/MinimizeFullScreen.svg';
import { ReactComponent as More } from '../../../assets/More.svg';
import { ReactComponent as Table } from '../../../assets/Table.svg';

const editIcon = <Edit style={iconSize(18)} />;
const maximizeIcon = <Maximize style={iconSize(18)} />;
const minimizeIcon = <Minimize style={iconSize(18)} />;
const moreIcon = <More style={iconSize(18)} />;
const filterActiveIcon = rebranding ? <FilterActiveNew style={iconSize(18)} /> : <FilterActive style={iconSize(18)} />;
const tableIcon = <Table style={{ ...iconSize(18) }} />;

interface WidgetToolbarProps {
  widget: AllTypeWidgets;
  setWidget?: (newWidget: any) => void;
  isTable?: boolean;
  setIsTable?: any;
}

const WidgetToolbar: React.FC<WidgetToolbarProps> = ({ widget, setWidget, isTable = false, setIsTable }) => {
  const { featureFlags } = useAvContext();
  const theme = useTheme();
  const isHistoricalAnalytics = featureFlags[FeatureFlags.HistoricalAnalytics];
  const { setNewWidget, globalFilters, filters, setFilters, expandedWidgetId, setExpandedWidgetId } = useContext(CustomDashboardContext);
  const isEditMode = filters.isEditMode[0] === 'true';
  const isMaximized = expandedWidgetId === widget.id;

  const [anchorEl, setAnchorEl] = useState<Element>();
  const {
    drillDownHierarchy = defaultDrillDownHierarchy,
    requests: [
      {
        select: {
          dims: [dim],
        },
        filter,
        top,
        sorting,
        projectionId,
        timeRange,
      },
    ],
  } = widget;

  const getDisplayName = useGetDisplayName(projectionId.name);
  const getFieldType = useGetFieldType(projectionId.name);

  const onChangeFilter = (newValues: Record<string, any>) =>
    setFilters({
      ...filters,
      ...Object.fromEntries(Object.entries(newValues).map(([key, value]) => [key, Array.isArray(value) ? value : [value]])),
    });

  const handleFilter = () => {
    if (isEditMode) {
      setNewWidget(widget);
      onChangeFilter({ tab: [TabsType.Filters], isEditWidgetOpen: ['true'] });
    }
  };
  const handleEdit = () => {
    const newWidget: AllTypeWidgets = {
      ...widget,
      ...(widget.drillDownHierarchy ? { drillDownHierarchy: { ...widget.drillDownHierarchy, activeIndex: 0 } } : {}),
      requests: widget.requests.map(r => ({ ...r, ...(r.top ? { top: { ...r.top, offset: 0 } } : r.top) })),
    };
    setNewWidget(newWidget);
    if (setWidget) {
      setWidget(newWidget);
    }
    onChangeFilter({ tab: [TabsType.Data], isEditWidgetOpen: ['true'] });
  };

  const handleExpand = () => setExpandedWidgetId(prev => (prev ? undefined : widget.id));
  const toggleShowAsTable = () => setIsTable?.(!isTable);

  const toggleUserMenu = ({ currentTarget }) => setAnchorEl(anchorEl ? undefined : currentTarget);
  const [closeTimeout, setCloseTimeout] = useState<any>();

  const clearCloseTimeout = () => clearTimeout(closeTimeout);

  const closeMenu = () => {
    clearCloseTimeout();
    setCloseTimeout(setTimeout(() => setAnchorEl(undefined), 350));
  };

  const drillUpLevelByIndex = index => {
    if (setWidget) {
      setWidget({
        ...widget,
        requests: [{ ...widget.requests[0], top: { ...widget.requests[0].top, offset: 0 } }],
        drillDownHierarchy: {
          ...drillDownHierarchy,
          activeIndex: index,
          drillDownValues: drillDownHierarchy.drillDownValues.slice(0, index),
        },
      });
    }
  };
  const showBreadcrumbs = !!drillDownHierarchy.activeIndex && !(widget as TableTypeWidget).definition?.custom?.crossTableView;
  const drilldownlist = [{ name: widget.definition.title.title || getDisplayName(dim?.name) }, ...drillDownHierarchy.fields];

  const getFormattedDate = () => {
    if (timeRange?.relative) {
      return capitalize(
        `${RelativeDateType[(timeRange.relative as NewRelativeDate).periodBehaviour.toLowerCase()]} ${timeRange.relative.value} ${
          RelativeDatePeriod[timeRange.relative.unit.toLowerCase()]
        }`
      );
    }

    const dateObject = getDateFromFilter(timeRange ?? {});
    const isRange = Boolean(timeRange?.relative || timeRange?.between);
    const date = timeRange?.relative ? getDatesFromDefinition(dateObject.preset) : validateValue(dateObject.value, isRange);

    if (timeRange?.between) {
      return getInputValue({ date, isRange, preset: dateObject.preset });
    }

    return formatDate(date?.to, dateFormat);
  };

  return (
    <Box
      sx={{
        ...flex.justifyBetween,
        pt: isMaximized ? 1.5 : 0,
        pr: isMaximized ? 0.5 : 0,
        pb: 0.25,
      }}>
      <Box sx={{ ...flex.itemsCenter, gap: '10px' }}>
        {shouldShowDrillDown(widget) &&
          (widget.category !== WidgetCategory.Table || !widget.definition.custom.crossTableView) &&
          !!drillDownHierarchy.fields.length && <DrillUpButton widget={widget} />}
        {widget.category === WidgetCategory.Text || showBreadcrumbs ? undefined : (
          <AvTooltip sx={{ fontSize: '18px', fontWeight: 600, ...cellContentStyle, WebkitLineClamp: '2', wordBreak: 'break-word' }}>
            {widget.definition.title.title}
          </AvTooltip>
        )}
        {showBreadcrumbs && (
          <Box className="draggableCancel" sx={{ ...flex.row, height: 'fit-content', flexWrap: 'wrap' }}>
            <AvBreadcrumbs
              breadcrumbs={drilldownlist.slice(0, drillDownHierarchy.activeIndex + 1).map((value, index) => ({
                title: index === 0 ? '' : index === 1 ? getDisplayName(dim.name) : getDisplayName(drilldownlist[index - 1].name),
                value:
                  index === 0
                    ? value.name
                    : generateInnerDefaultFormatter({
                        value: drillDownHierarchy.drillDownValues[index - 1],
                        type: getFieldType({ entityFieldKey: drillDownHierarchy.fields[index - 1] }),
                        shouldDisplayNull: true,
                      }),
                onClick:
                  index === drilldownlist.length - 1
                    ? undefined
                    : () => {
                        drillUpLevelByIndex(index);
                      },
              }))}
              variant={EVariant.drillDown}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ ...flex.itemsCenter, gap: 1.5 }} className="draggableCancel">
        {isEditMode && (
          <AvTooltip title="Edit Widget">
            <IconButton className="showOnHover" onClick={handleEdit}>
              {editIcon}
            </IconButton>
          </AvTooltip>
        )}
        {![TableSubType.Table, TextSubType.Text, TileSubType.Tile].includes(widget.type as any) && !drillDownHierarchy.activeIndex && (
          <AvTooltip title="Table view">
            <IconButton
              className={!isTable ? 'showOnHover' : undefined}
              sx={isTable ? activeIconStyle(theme) : undefined}
              onClick={toggleShowAsTable}>
              {tableIcon}
            </IconButton>
          </AvTooltip>
        )}
        {isHistoricalAnalytics && timeRange && (
          <HistoricDataIndicator
            hideText
            title={`Historical Data: ${getFormattedDate()}`}
            sx={{ background: theme.palette.colors.primary[150] }}
          />
        )}
        {filter && (
          <AvTooltip title={<FilterToText filter={filter} top={top} sorting={sorting[0]} projectionId={projectionId.name} />}>
            <IconButton sx={isEditMode ? undefined : { cursor: 'default' }} onClick={handleFilter}>
              {filterActiveIcon}
            </IconButton>
          </AvTooltip>
        )}
        {(isEditMode || (widget.category !== WidgetCategory.Text && !isEditMode)) && (
          <Box onMouseOver={clearCloseTimeout} onMouseLeave={closeMenu}>
            <AvTooltip title="More Options">
              <IconButton onClick={toggleUserMenu} sx={anchorEl ? activeIconStyle(theme) : undefined}>
                {moreIcon}
              </IconButton>
            </AvTooltip>
            {anchorEl && (
              <WidgetToolbarActions
                anchorEl={anchorEl}
                widget={widget}
                isEditMode={isEditMode}
                closeMenu={closeMenu}
                handleExpand={handleExpand}
                globalFilters={globalFilters}
              />
            )}
          </Box>
        )}
        {isMaximized && widget.category !== WidgetCategory.Text && (
          <>
            <Divider orientation="vertical" sx={{ height: 18, alignSelf: 'center' }} />
            <AvTooltip title={isMaximized ? 'Minimize' : 'Full Screen'}>
              <IconButton onClick={handleExpand}>{isMaximized ? minimizeIcon : maximizeIcon}</IconButton>
            </AvTooltip>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WidgetToolbar;
