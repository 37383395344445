import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, ButtonProps } from '@mui/material';
import Loading from './Loading';

const AvButton = ({ children, loading, ...props }: ButtonProps & { loading?: boolean }) =>
  loading !== undefined ? (
    <LoadingButton {...props} loading={loading} loadingIndicator={<Loading height={20} />}>
      {children}
    </LoadingButton>
  ) : (
    <Button {...props}>{children}</Button>
  );
export default AvButton;
