import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { useGetDisplayName } from '../hooks';
import { AllTypeWidgets } from '../types/combined.types';
import { defaultDrillDownHierarchy } from '../Utils';
import { ReactComponent as DrillDown } from '../../../assets/Drill down.svg';

const drillDownIcon = <DrillDown />;

interface DrillUpButtonProps {
  widget: AllTypeWidgets;
}

const DrillUpButton: React.FC<DrillUpButtonProps> = ({ widget }) => {
  const getDisplayName = useGetDisplayName(widget.requests[0].projectionId.name);

  const {
    requests: [
      {
        select: { dims },
      },
    ],
    drillDownHierarchy = defaultDrillDownHierarchy,
  } = widget;

  return (
    <AvTooltip
      title={
        drillDownHierarchy.activeIndex === 0
          ? 'Click on chart to drill down'
          : `Drill up to ${getDisplayName([dims[0], ...drillDownHierarchy.fields][drillDownHierarchy.activeIndex - 1]?.name)}`
      }>
      <Box sx={flex.center} className="draggableCancel">
        {drillDownHierarchy.activeIndex === 0 ? drillDownIcon : undefined}
      </Box>
    </AvTooltip>
  );
};

export default DrillUpButton;
