import { Filter } from '../../../types/filter.types';
import {
  BarLegendPosition,
  BarSubType,
  BarTypeWidget,
  DisplayModeOptions,
  DonutTypeWidget,
  DwQueryRequest,
  LineLegendPosition,
  LineSubType,
  PieBasicLegendPosition,
  PieLegendStyles,
  PieSubType,
  SortDir,
  SplineTypeWidget,
  WidgetCategory,
} from '../../CustomDashboards/types';

export const getAssetOverTimeWidget = (req: DwQueryRequest): SplineTypeWidget => ({
  id: '',
  type: LineSubType.Spline,
  category: WidgetCategory.Line,
  requests: [req],
  definition: {
    title: {
      title: '',
    },
    coords: {
      w: 6,
      h: 4,
      x: 4,
      y: 0,
    },
    custom: {
      legend: {
        position: LineLegendPosition.Top,
      },
      fieldsPalette: {},
    },
  },
});

export const getTotalAssetsBrokenDownWidget = (fieldName: string, filter?: Filter): DonutTypeWidget => ({
  id: '',
  definition: {
    title: {
      title: '',
    },
    coords: {
      x: 0,
      y: 0,
      h: 0,
      w: 0,
    },
    custom: {
      showTotals: true,
      legend: {
        position: PieBasicLegendPosition.Top,
        style: PieLegendStyles.Table,
        showValues: true,
        showPercentage: true,
      },
      fieldsPalette: {},
    },
  },
  category: WidgetCategory.Pie,
  type: PieSubType.Donut,
  requests: [
    {
      filter,
      select: {
        dims: [
          {
            name: fieldName,
          },
        ],
        metrics: [
          {
            name: 'total_assets_granular',
            alias: 'total_assets_granular',
          },
        ],
      },
      groupBy: [fieldName],
      sorting: [
        {
          name: 'total_assets_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: 'uber_assets',
        builtIn: true,
      },
      distinct: false,
    },
  ],
});

export const getTotalAssetBreakDownBarWidget = (fieldName: string, filter?: Filter): BarTypeWidget => ({
  id: '',
  definition: {
    title: {
      title: '',
    },
    coords: {
      x: 0,
      y: 0,
      h: 4,
      w: 6,
    },
    custom: {
      legend: {
        position: BarLegendPosition.None,
      },
      hideLabel: true,
      rowsColumnSwitch: false,
      fieldsPalette: {},
      displayMode: DisplayModeOptions.FIT,
    },
  },
  category: WidgetCategory.Bar,
  type: BarSubType.BarVertical,
  requests: [
    {
      filter,
      select: {
        dims: [
          {
            name: fieldName,
          },
        ],
        metrics: [
          {
            name: 'total_assets_granular',
            alias: 'total_assets_granular',
          },
        ],
      },
      groupBy: [fieldName],
      sorting: [
        {
          name: 'total_assets_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: 'uber_assets',
        builtIn: true,
      },
      distinct: false,
      metricsFilterThreshold: 0,
    },
  ],
});
