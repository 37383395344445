import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import AvActionCards from '../../components/AvActionCards';
import { INPUT_TYPES, ResponseHandler } from '../../components/formInputTypes';
import { useAvContext } from '../../context/AvContextProvider';
import { GET_FILTERED_LIST_RISK360 } from '../AccountManagement/hooks';

const Risk360BackofficeActions: React.FC = () => {
  const theme = useTheme();
  const { api } = useAvContext();
  const [accountList, setAccountList] = useState([]);

  useQuery({
    queryKey: ['findAllRisk360Accounts'],
    queryFn: () => {
      api(GET_FILTERED_LIST_RISK360).then(({ data }) => {
        setAccountList(
          data.findAllRisk360Accounts.map(({ name, id }) => ({
            title: `${id} | ${name}`,
            value: id,
          }))
        );
      });
    },
  });

  const forms = [
    {
      title: 'Add Risk360 Permissions to Account',
      saveButtonText: 'Save',
      formComponents: [
        {
          label: 'Account Name',
          valueName: 'accountId',
          type: INPUT_TYPES.SELECT,
          params: { options: accountList },
          isRequired: true,
        },
      ],
      query: ADD_PERMISSIONS_BY_RISK360_ACCOUNT,
      handleResponse: ResponseHandler.ADD_ACCOUNT,
    },
    {
      title: 'Trigger All Factor Execution Workflows',
      saveButtonText: 'Trigger',
      formComponents: [
        {
          valueName: 'info',
          type: INPUT_TYPES.TEXT,
          text: 'Please note it will trigger the factor execution workflow in all accounts with Risk360 app.',
        },
        {
          valueName: 'warning',
          type: INPUT_TYPES.TEXT,
          text: <Box sx={{ color: theme.palette.colors.negative[500] }}>It is recommended to sync all builtin factors first.</Box>,
        },
      ],
      query: TRIGGER_ALL_ACCOUNTS_FACTORS,
      handleResponse: ResponseHandler.NONE,
    },
  ];

  return <AvActionCards forms={forms} title="Risk 360 Backoffice Actions" />;
};

export default Risk360BackofficeActions;

const TRIGGER_ALL_ACCOUNTS_FACTORS = gql`
  mutation {
    triggerRiskFactorExecutionGroupsAllAccounts
  }
`;

const ADD_PERMISSIONS_BY_RISK360_ACCOUNT = gql`
  mutation AddPermissionsByRisk360Account($accountId: String!) {
    addPermissionByRisk360Account(accountId: $accountId)
  }
`;
