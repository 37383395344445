import {
  AppPathsValue,
  PAGE_PATHS,
  Permission,
  PermissionCategoriesNames,
  PermissionEntitiesNames,
  PermissionModelEntityName,
} from '../types';

// eslint-disable-next-line import/prefer-default-export
export type AvPermissionsType = {
  hasAllowedPermission: (options: { app?: AppPathsValue; path: PagePathsValue; permission?: Permission }) => boolean;

  hasAllowedPermissionToResource: (options: {
    resource: PermissionEntitiesNames;
    category?: PermissionCategoriesNames;
    permission?: Permission;
  }) => boolean;

  hasAllowedEditResourcePermission: (options: { app?: AppPathsValue; path: PagePathsValue; isNew?: boolean }) => boolean;

  allowedPermissionsToResource: (options: {
    resource: PermissionEntitiesNames;
    category?: PermissionCategoriesNames;
  }) => Partial<Record<Permission, boolean>>;

  allowedPermissionsToPath: (options: { app?: AppPathsValue; path: PagePathsValue }) => Partial<Record<Permission, boolean>>;

  allowedPermissions: Record<string, Record<Permission, boolean>>;

  isInternalRole: boolean;

  isAvalorAdmin: boolean;

  hasOnlyPermission: (options: { app?: AppPathsValue; path: PagePathsValue; permission?: Permission }) => boolean;
};

export type PagePathsValue = (typeof PAGE_PATHS)[keyof typeof PAGE_PATHS];

export enum AccountType {
  POC = 'POC',
  PAYING = 'PAYING',
  RISK360 = 'RISK360',
  DEMO = 'DEMO',
  EXPIRED = 'EXPIRED',
  SANDBOX = 'SANDBOX',
}

export const projToPermissionCategory = {
  tickets: PermissionModelEntityName.TICKET,
  uber_findings: PermissionModelEntityName.FINDING,
  uber_assets: PermissionModelEntityName.ASSET,
  incidents: PermissionModelEntityName.INCIDENT,
  uber_alerts: PermissionModelEntityName.ALERT,
  uber_policy_populations: PermissionModelEntityName.POLICYPOPULATION,
  uber_exceptions: PermissionModelEntityName.EXCEPTION,
};
