import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material';
import { generateOption, getCleanNumberInputValue, noop, uniqBy } from '../utils/Utils';
import Select from './Select';

const uniqueOptions = (options, value, isMultiple) =>
  uniqBy([...options, ...(isMultiple ? value : value ? [value] : []).map(generateOption)], ({ value }) => value);

const filter = createFilterOptions();

type SelectProps = any; // TODO remove when doing Select.tsx refactor
interface IOpenSelect {
  label?: string;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  isMultiple?: boolean;
  optionsPromise?: () => Promise<string[]>;
  options?: string[] | { title: string; value: string }[];
  optionsUseQueryProps?: { options: any[]; isLoading: boolean };
  muiProps?: any;
}

const emptyOptions = [];
const emptyValues = [];
export const generateNumericOption = value => ({ title: `${value}`, value });

function OpenSelect({
  label,
  isMultiple = false,
  value = isMultiple ? emptyValues : '',
  onChange = noop,
  options = emptyOptions,
  optionsPromise,
  optionsUseQueryProps,
  muiProps,
  ...props
}: IOpenSelect & SelectProps) {
  const isNumeric = muiProps?.InputProps.type === 'number';
  const [innerOptions, setOptions] = useState(uniqueOptions(options, value, isMultiple));
  useEffect(() => {
    optionsPromise?.().then(opt => setOptions(prev => uniqueOptions(prev, opt, isMultiple)));
  }, []);

  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  useEffect(() => {
    setOptions(uniqueOptions(innerOptions, value, isMultiple));
  }, [stringValue]);

  useEffect(() => {
    setOptions(uniqueOptions(options, value, isMultiple));
  }, [options]);

  useEffect(() => {
    if (optionsUseQueryProps && !optionsUseQueryProps.isLoading && optionsUseQueryProps.options) {
      setOptions(() => uniqueOptions(optionsUseQueryProps.options, value, isMultiple));
    }
  }, [optionsUseQueryProps?.isLoading, optionsUseQueryProps?.options]);

  const checkValueDoesntExist = val => !innerOptions.find(({ title }) => title === val);
  const onAdd = (values, ...args) => {
    const formattedValues = isNumeric ? values.map(value => getCleanNumberInputValue(value)) : values;
    const newVal = isMultiple ? formattedValues.find(checkValueDoesntExist) : checkValueDoesntExist(formattedValues) && formattedValues;
    if (newVal) {
      setOptions(prev => [...prev, generateOption(newVal)]);
    }
    onChange(formattedValues, ...args);
  };

  const onAddBlur = val => {
    const formattedNewVal = isNumeric ? getCleanNumberInputValue(val) : val;
    const newVal = checkValueDoesntExist(formattedNewVal) && formattedNewVal;
    if (newVal) {
      setOptions(prev => [...prev, generateOption(newVal)]);
      onChange([...(value as string[]), newVal]);
    }
  };

  return (
    <Select
      label={label}
      value={value}
      onChange={onAdd}
      options={innerOptions}
      isMultiple={isMultiple}
      freeSolo
      getValueFunc={val => (val?.value === undefined ? val : val.value)}
      getLabelFunc={val => (val?.title === undefined ? val : val.title)}
      muiProps={{
        onBlur: isMultiple ? e => onAddBlur(e.target.value) : undefined,
        clearOnBlur: true,
        filterOptions: isMultiple
          ? undefined
          : (options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '' && checkValueDoesntExist(params.inputValue)) {
                return [{ value: params.inputValue, title: `Add "${params.inputValue}"` }, ...filtered];
              }
              return filtered;
            },
        ...muiProps,
      }}
      {...props}
    />
  );
}

export default React.memo(
  OpenSelect,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps) === JSON.stringify(nextProps) &&
    Object.is(prevProps?.optionsPromise, nextProps?.optionsPromise) &&
    Object.is(prevProps?.onChange, nextProps?.onChange)
);
