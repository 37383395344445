import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import { FactorKind } from '../types/executionRules.types';
import avLazy from '../utils/AvLazy';
import AuditLogsFactorRules from "../views/FactorRules/AuditLogsFactorRules";

const FactorRules = avLazy(() => import('../views/FactorRules/FactorRules'));
const EditFactorRules = avLazy(() => import('../views/FactorRules/EditFactorRules'));
const FactorRuns = avLazy(() => import('../views/FactorRules/FactorRuns'));

const FactorLogicRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();
  const path = PAGE_PATHS.FACTOR_RULES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || isInternalRole}>
            <FactorRules kind={FactorKind.ACCOUNT} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute
            isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.CREATE }) || isInternalRole}>
            <EditFactorRules kind={FactorKind.ACCOUNT} />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute
            isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE }) || isInternalRole}>
            <EditFactorRules kind={FactorKind.ACCOUNT} isEditMode />
          </ProtectedRoute>
        }
      />
        <Route
            path="audit/:id"
            element={
                <ProtectedRoute
                    isAllowed={hasAllowedPermission({ path }) || isInternalRole}>
                    <AuditLogsFactorRules kind={FactorKind.ACCOUNT}/>
                </ProtectedRoute>
            }
        />
      <Route
        path="runs"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || isInternalRole}>
            <FactorRuns />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default FactorLogicRoute;
