import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { useAvContext } from '../../../context/AvContextProvider';
import { FeatureFlags } from '../../../types';
import { getNewRequestParams } from '../../../utils/RequestUtils';
import { iconSize, rearrange } from '../../../utils/Utils';
import { CustomDashboardContext } from '../CustomDashboardContext';
import { Field, WidgetCategory } from '../types';
import { FieldType } from '../types/types';
import { isTableWidget } from '../Utils';
import { ReactComponent as DragSmall } from '../../../assets/DragSmall.svg';
import { ReactComponent as X } from '../../../assets/X.svg';

const dragIcon = <DragSmall />;
const clearIcon = <X style={iconSize(18)} />;

type ReorderFieldsListProps = {
  fieldType: FieldType;
  isFocus: boolean;
  customFilter?: (...args: any) => any;
};
const ReorderFieldsList: React.FC<ReorderFieldsListProps> = ({ fieldType, isFocus, customFilter }) => {
  const theme = useTheme();
  const { featureFlags } = useAvContext();

  const { newWidget, setNewWidget, getDisplayName } = useContext(CustomDashboardContext);

  const fields = customFilter ? customFilter(newWidget.requests[0].select[fieldType]) : newWidget.requests[0].select[fieldType];

  const setFields = (newFields: Field[]) => {
    setNewWidget({
      ...newWidget,
      requests: [
        {
          ...newWidget.requests[0],
          select: {
            ...newWidget.requests[0].select,
            [fieldType]: newFields,
          },
        },
      ],
    });
  };

  const onDragEnd = ({ source, destination }) => {
    if (destination && source.index !== destination.index) {
      setFields(rearrange(fields, fields[source.index], destination.index, 'name', source.droppableId !== destination.droppableId));
    }
  };

  const deleteField = field => {
    const { newSelect, newGroupBy, newSortBy, newDistinct, newDrillDownHierarchy, newTop } = getNewRequestParams({
      fields: [field],
      fieldType,
      widget: newWidget,
      isHistoricalAnalyticsFeatureFlag: featureFlags[FeatureFlags.HistoricalAnalytics],
    });

    const newMetric = newSelect.metrics?.[0] || '';

    setNewWidget({
      ...newWidget,
      drillDownHierarchy: newDrillDownHierarchy,
      definition: {
        ...newWidget.definition,
        title:
          newWidget.category === WidgetCategory.Tile
            ? { title: getDisplayName(newMetric.alias || newMetric.name) }
            : newWidget.definition.title,
        ...(isTableWidget(newWidget) && { custom: { ...newWidget.definition.custom, crossTableView: false } }),
      } as any,
      requests: [
        {
          ...newWidget.requests[0],
          select: newSelect,
          groupBy: newGroupBy,
          sorting: newSortBy,
          distinct: newDistinct,
          top: newTop,
        },
      ],
    });
  };

  const itemFunc = (items, icon?) =>
    function dragItem(providedDrag, snapshotDrag, rubric) {
      return (
        <Box
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          sx={getItemStyle(snapshotDrag.isDragging, providedDrag.draggableProps.style)}>
          <Box sx={{ ...flex.row, gap: 1, overflow: 'hidden' }}>
            {dragIcon}
            <AvTooltip>{getDisplayName(items[rubric.source.index].alias) || getDisplayName(items[rubric.source.index].name)}</AvTooltip>
          </Box>
          {icon}
        </Box>
      );
    };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {!!fields.length && (
        <Box
          sx={{
            ...flex.col,
            gap: '10px',
            mx: 1,
            mb: 1,
            p: 1,
            pb: 0,
            backgroundColor: isFocus ? theme.palette.colors.primary[150] : theme.palette.colors.neutrals[150],
            borderRadius: '12px',
          }}>
          <Droppable droppableId="used" renderClone={itemFunc(fields)}>
            {(provided, snapshot) => (
              <Box component="section" className="used" data-dragorigin={snapshot.draggingFromThisWith && !snapshot.isDraggingOver}>
                <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ ...flex.col, height: '100%', overflow: 'auto' }}>
                  {fields.map(({ name }) => (
                    <Box key={name} sx={itemWrapperStyle(false)}>
                      <Draggable
                        draggableId={name}
                        index={fields.findIndex(item => item.name === name)}
                        isDragDisabled={fields.length === 1}>
                        {itemFunc(
                          fields,
                          <Box sx={{ ...flex.itemsCenter }}>
                            <IconButton onClick={() => deleteField(name)}>{clearIcon}</IconButton>
                          </Box>
                        )}
                      </Draggable>
                    </Box>
                  ))}
                  {provided.placeholder}
                </Box>
              </Box>
            )}
          </Droppable>
        </Box>
      )}
    </DragDropContext>
  );
};

export default ReorderFieldsList;

// const itemWidth = 250;
// const emptyOutlineWidth = 2;
const itemWrapperStyle = hasNumbers => ({
  ...flex.col,
  // borderRadius: '10px',
  // backgroundColor: theme => theme.palette.colors.primary[200],

  // '&:empty': {
  //   outline: ({ palette }) => `2px solid ${palette.colors.neutrals[400]}`,
  //   top: -4,
  //   left: (hasNumbers ? 24 : 0) + emptyOutlineWidth,
  //   width: itemWidth - emptyOutlineWidth * 2,
  // },
  ...(hasNumbers && {
    '+ [data-rbd-placeholder-context-id]': {
      position: 'relative',
    },
    '&:not(:empty), + [data-rbd-placeholder-context-id]': {
      counterIncrement: 'column',
      pl: 3,
      '&:before': {
        content: 'counter(column)',
        position: 'absolute',
        top: 4,
        left: 0,
        fontWeight: 600,
        fontSize: 12,
        color: theme => theme.palette.colors.primary[300],
      },
    },
  }),
});

const getItemStyle =
  (isDragging, draggableStyle) =>
  ({ palette }) => ({
    ...flex.justifyBetween,
    flexGrow: 1,
    height: 24,
    mb: 1,
    userSelect: 'none',
    padding: '2px 8px',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: isDragging ? palette.colors.primary[500] : palette.colors.neutrals[350],
    backgroundColor: palette.colors.neutrals[100],
    fontSize: 12,
    ...draggableStyle,
    svg: {
      color: isDragging ? palette.colors.primary[500] : palette.colors.neutrals[600],
    },
  });
