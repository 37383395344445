import React, { useRef } from 'react';
import { Box, LinearProgress, SxProps } from '@mui/material';
import { flex } from '../../../../../components/AvThemeProvider';
import AvTooltip from '../../../../../components/AvTooltip';
import CustomTooltip from '../../../../../components/Widgets/CustomTooltip';
import { ChartClickInteraction } from '../../../../../components/Widgets/types';
import { getPercent } from '../../../../../utils/Utils';
import { useGetDisplayName } from '../../../hooks';
import { tileTitleSX, TileTypeWidget } from '../../../types';
import { OrientationType } from '../../../Utils';

export interface ProgressWidgetProps {
  widget: TileTypeWidget;
  metricsValues: number[];
  orientation?: OrientationType;
  titleTextSx?: SxProps;
  barWidth?: number | string;
  clickInteractions?: ChartClickInteraction[];
}

const verticalSx = {
  px: 1,
  flexShrink: 0,
  ...flex.col,
  height: '100%',
  maxHeight: 300,
};
const horizontalSx = {
  ...flex.center,
  height: '100%',
};

const textSx = { color: theme => theme.palette.colors.neutrals[550], fontSize: '12px', fontWeight: 400, lineHeight: '18px' };
const numberSx = { color: theme => theme.palette.colors.neutrals[700], fontSize: '14px', fontWeight: 600, lineHeight: '20px' };

const ProgressTileWidget = ({
  widget,
  metricsValues,
  orientation = OrientationType.Vertical,
  titleTextSx = tileTitleSX,
  barWidth = '100%',
  clickInteractions,
}: ProgressWidgetProps) => {
  const [metric0, metric1, metric2] = metricsValues;
  const progressBarRef = useRef<HTMLDivElement>(null);
  const {
    select: {
      metrics: [{ name: metricName }],
    },
    projectionId: { name: projName },
  } = widget.requests[0];
  const getDisplayName = useGetDisplayName(projName);
  const { completionDisplayName } = widget.definition.custom;
  const isEnabledClickthrough = clickInteractions?.some(({ isEnabledForCell }) => isEnabledForCell({ dataKey: metricName }));

  const handleClick = e => {
    // where we determine which part of the bar's click through we want to activate.
    if (!progressBarRef.current) {
      return;
    }
    e.stopPropagation();
    const progressBarWidth = progressBarRef.current.offsetWidth;
    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const clickX = e.clientX - progressBarRect.left;

    if (clickX <= (progressBarWidth * metric0) / 100) {
      if (isEnabledClickthrough && clickInteractions?.length) {
        clickInteractions[0].onClick(e);
      }
    }
  };

  return (
    <Box sx={orientation === OrientationType.Vertical ? verticalSx : horizontalSx}>
      <Box sx={titleTextSx}>{getPercent(metric0)}</Box>
      <Box
        sx={{
          height: '100%',
          width: barWidth,
          maxHeight: 300,
          mb: orientation === OrientationType.Vertical ? 6 : 2.5,
          paddingLeft: orientation === OrientationType.Horizontal ? 2.5 : 0,
          ...flex.colEndCenter,
        }}>
        <Box sx={{ width: '100%' }} onClick={handleClick} className="draggableCancel">
          <AvTooltip
            variant="message"
            muiProps={{
              placement: 'right',
              componentsProps: {
                popper: { sx: { '& .MuiTooltip-tooltip': { p: 0 } } },
              },
            }}
            title={
              <CustomTooltip
                payload={[{ payload: { [metricName]: metric0 }, dataKey: metricName }]}
                showTitle={false}
                showContentLegend={false}
                clickInteractions={clickInteractions}
                metricFormatter={() => (getDisplayName ? getDisplayName(metricName) : metricName)}
                metricKey={metricName}
                sx={{ ml: 0 }}
              />
            }>
            <LinearProgress
              value={metric0}
              variant="determinate"
              ref={progressBarRef}
              sx={{
                height: '12px',
                maxWidth: 900,
                borderRadius: 20,
                width: '100%',
                background: theme => theme.palette.colors.neutrals[350],
                [`& .MuiLinearProgress-bar`]: {
                  background: theme => theme.palette.colors.primary[500],
                  borderRadius: 0,
                  transition: '0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '3px 6px 20px 3px rgba(0, 0, 0, 0.5)',
                    cursor: 'pointer',
                    transform: 'scale(1.02)',
                  },
                },
              }}
            />
          </AvTooltip>
        </Box>
        {metric1 !== undefined && metric2 !== undefined && (
          <Box sx={{ ...flex.justifyBetween, maxWidth: 900, width: '100%', mt: 0.5, px: 0.5 }}>
            <Box sx={{ ...flex.justifyBetweenEnd, gap: 0.5 }}>
              <Box sx={numberSx}> {metric1}</Box>
              <Box sx={textSx}>{completionDisplayName || ''} </Box>
            </Box>
            <Box sx={{ ...flex.justifyBetweenEnd, gap: 0.5 }}>
              <Box sx={textSx}> Out of</Box>
              <Box sx={numberSx}> {metric2} </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ProgressTileWidget;
