// eslint-disable-next-line import/prefer-default-export

const getRebrandingValue = () => {
  const rebrandingVal = localStorage.getItem('rebranding');
  return rebrandingVal !== 'false';
};

export const rebranding = getRebrandingValue();
export const setRebranding = (mode: boolean) => {
  localStorage.setItem('rebranding', mode.toString());
  localStorage.setItem('rebranding-changed', mode.toString());
  window.location.reload();
};

export const sendRebrandingPendoEvent = email => {
  const pendoEventValue = localStorage.getItem('rebranding-changed');
  if (pendoEventValue) {
    (window as any).pendo?.track('change look', {
      newLook: pendoEventValue,
      internal: (email as string).includes('@zscaler.com') || (email as string).includes('@avalor.io'),
    });
    localStorage.removeItem('rebranding-changed');
  }
};
