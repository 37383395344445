import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ClickAwayListener, IconButton, Pagination, PaginationItem } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import {
  CollapseArrow,
  ConditionalWrapper,
  deserializeSortItem,
  generateInnerDefaultFormatter,
  noop,
  serializeSortItem,
} from '../../utils/Utils';
import { NO_VALUE } from '../../views/CustomDashboards/constants';
import AvCheckbox from '../AvCheckbox';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import NoDataFound from '../NoDataFound';
import { TableContext } from './Context';
import EnhancedTableHead from './EnhancedTableHead';
import getRows from './getRows';
import SelectAllRowsButton from './SelectAllRowsButton';
import TableToolbar from './TableToolbar';
import { AvTableProps, GroupHeaderRowType, HeadCellType, LinkPropsType, SortType, TotalsProps } from './types';
import useResize from './useResize';
import {
  AvalorTableIds,
  cellBoxStyle,
  getInput,
  getStickyGroupRowStyle,
  inlineActionsHeader,
  isAuto,
  isAutoFit,
  isNumberType,
  loadingPercentages,
  PaginationRowsText,
  resizeResponsiveProps,
  totalCellStyle,
} from './Utils';

const emptyArray = [];

const AvTable: React.FC<AvTableProps> = ({
  storageKey = '',
  resizable = false,
  groupedHeadCells,
  headCells = [],
  rows = [],
  setRows = noop,
  selectedKeys = emptyArray,
  activeKey,
  isSelectionControlled = false,
  onSelected = noop,
  onSelectAllRows,
  onActive,
  onScroll,
  allowKeyboardNavigation = false,
  deleteProps,
  loading = false,
  multiSelect = false,
  allowEdit = true,
  allowCreate = false,
  showHeader = true,
  pagination = false,
  onEdit = noop,
  onSort,
  customActions = [],
  inlineActions = [],
  customGlobalActions = [],
  markedCells = {},
  expandedRowObj,
  innerSelectLink,
  innerEditLink,
  enableSelectionColor = true,
  rowLimit = 20,
  rowGroupByField,
  size = 'small',
  sx = {},
  tableContainerSx = {},
  paginationProps,
  totalProps = {},
  hasHeaderFilters = true,
  columnIconButtonProp,
  loadMoreLimit,
  onCancelColumn,
  customNewButton,
  noDataProps = {},
  disableUseEffect = false,
  shouldDisplayNull = false,
}) => {
  const { onPageChange, totalRows, pageNum } = paginationProps || ({} as any);
  const navigate = useNavigate();
  const [filters, setFilters] = useCustomSearchParams({ defaultFilter: { orderBy: [] }, shouldBeArray: () => true });
  const containerRef = useRef<any>();
  const keyName = headCells.find(({ isKey }) => isKey)!.id;

  const getRowByID = newVal => rows.find(({ [keyName]: id }) => id === newVal);
  const [collapsedGroup, setCollapsedGroup] = useState(
    rowGroupByField && activeKey ? { [rowGroupByField(getRowByID(activeKey))]: true } : {}
  );

  const getInitSortHeader = () => {
    const header = headCells.find(({ initSort }) => initSort);
    const orderByFilter = filters.orderBy?.map(deserializeSortItem).filter(({ property }) => keyName === property);
    return (hasHeaderFilters && orderByFilter?.[0]) || { property: header?.id || '', isAsc: !header || header.initSort === 'asc' };
  };

  const initSortHeader = getInitSortHeader();
  const [order, setOrder] = useState<SortType | undefined>(initSortHeader.isAsc ? SortType.ASC : SortType.DESC);
  const [orderBy, setOrderBy] = useState(initSortHeader.property);

  const [active, setActive] = useState(activeKey);
  const [selected, setSelected] = useState(selectedKeys);
  const [showSelectAllRowsButton, setShowSelectedAllRowsButton] = useState(false);
  const [isClickedAll, setAllClicked] = useState(false);
  const [statePage, setStatePage] = useState(0);
  const page = typeof pageNum === 'number' ? pageNum : statePage;
  const rowsPerPage = useMemo(() => rowLimit, [rowLimit]);
  const { setRunInfo } = useContext(NotificationContext);
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const freeSpaceRef = useRef({});
  const isInnerEditTable = headCells.some(({ editable }) => editable);
  const { columnWidths, columnRefs, resizingColId, onResizeColumn, autoFitCol, LocalColumnsWidth, columnsToAddFreeSpace, freeSpace } =
    useResize(storageKey, resizable, freeSpaceRef);

  const onError = err => enqueueSnackbar(`Failed To delete: ${err}`, { variant: 'error' });
  const handleDelete = async ids => {
    const afterDeleteSuccess = () => {
      enqueueSnackbar('Deleted Successfully', { variant: 'success' });
      setRows(rows.filter(({ [keyName]: id }) => !idList.includes(id)));
      deleteProps?.onDeleteSuccess?.();
      handleSelected([]);
    };
    const idList = ids.split(',');
    if (deleteProps?.uri) {
      const runInfo = await api(`${deleteProps.uri}/${ids}`, { options: { method: 'DELETE' }, onError });
      if (runInfo) {
        (Array.isArray(runInfo) ? runInfo : [runInfo]).forEach(({ workflowId, runId }) => {
          setRunInfo({
            wfId: workflowId,
            runId,
            returnStatusOnly: true,
            workflowType: deleteProps.workflowType,
            onSuccess: () => {
              handleSelected([]);
              deleteProps?.onDeleteSuccess?.();
            },
          });
        });
        return;
      }
    } else if (deleteProps?.query) {
      const { data } = await api(deleteProps.query, {
        options: { ids: idList },
        onError,
      });
      if (deleteProps.workflowType) {
        setRunInfo({
          runId: (Object.values(data)[0] as any).workflowRun.runId,
          wfId: (Object.values(data)[0] as any).workflowRun.id,
          returnStatusOnly: true,
          workflowType: deleteProps.workflowType,
          onSuccess: ({ successKeys }) => {
            if (successKeys.length === idList.length) {
              afterDeleteSuccess();
            }
          },
        });
      }
    }

    if (!deleteProps?.workflowType) {
      afterDeleteSuccess();
    }
  };

  const handleSelected = newVal => {
    setSelected(newVal);
    onSelected(newVal, rows);
  };

  const visibleHeaders: HeadCellType[] = [
    ...headCells.filter(({ hidden }) => !hidden),
    ...(!loading && resizable ? [{ id: AvalorTableIds.FreeSpaceId, style: { width: '100%' } }] : []),
    ...(!loading && (((allowEdit || deleteProps) && !isInnerEditTable) || inlineActions?.length)
      ? [
          inlineActionsHeader({
            allowEdit: allowEdit && !isInnerEditTable,
            keyName,
            inlineActions,
            deleteProps: deleteProps ? { onDelete: handleDelete, ...deleteProps } : undefined,
            onEditNavigation: innerEditLink,
            handleSelected,
            rows,
          }),
        ]
      : []),
  ];

  const setActiveRow = newVal => {
    setActive(newVal);
    onActive?.(newVal);
    if (newVal && rowGroupByField) {
      const row = getRowByID(newVal);
      toggleExpandGroup(row, true);
    }
  };

  const onToggleEditMode = id =>
    setRows(
      rows.map(({ isEditMode, avalor_SetFocus: _, ...others }) => ({
        ...others,
        ...(others[keyName] === id && !isEditMode ? { isEditMode: true } : {}),
      }))
    );

  const handleRequestSort = (event, property) => {
    if (onSort) {
      const currentState = headCells.find(({ id }) => id === property)!.sort;
      const next = !currentState ? SortType.DESC : currentState === SortType.ASC ? null : SortType.ASC;
      onSort(property, next, event);
    } else {
      handleChangePage(null, 0);
      const isActiveDesc = orderBy === property && order === SortType.DESC;
      setOrder(isActiveDesc ? SortType.ASC : SortType.DESC);
      setOrderBy(property);
      if (hasHeaderFilters) {
        setFilters({ ...filters, orderBy: serializeSortItem({ property, isAsc: isActiveDesc }) });
      }
    }
  };

  useEffect(() => {
    const onArrowKey = event => {
      const hasActiveKey = isSelectionControlled ? !!activeKey : !!active;
      if ((event.code === 'ArrowDown' || event.code === 'ArrowUp') && hasActiveKey) {
        const currentIndex = rows.findIndex(row => row[keyName] === active);
        if (currentIndex >= 0) {
          const nextKey = event.code === 'ArrowDown' ? rows[currentIndex + 1]?.[keyName] : rows[currentIndex - 1]?.[keyName];
          if (nextKey) {
            doSelect(event, nextKey, true);
          }
        }
      }
    };
    if (allowKeyboardNavigation) {
      window.addEventListener('keydown', onArrowKey);
    }

    return () => window.removeEventListener('keydown', onArrowKey);
  }, [active, activeKey, rows]);

  useEffect(() => {
    if (!loading && !isSelectionControlled && !disableUseEffect) {
      setActiveRow(getRowByID(activeKey) ? activeKey : undefined);
    }
  }, [activeKey]);

  useEffect(() => {
    if (!loading && !isSelectionControlled) {
      handleSelected(selectedKeys.filter(key => getRowByID(key)));
      if (isInnerEditTable && rows.length) {
        onToggleEditMode(selectedKeys[0]);
      }
    }
  }, [selectedKeys]);

  const clearSelect = () => {
    handleSelected([]);
    if (isInnerEditTable) {
      onToggleEditMode(null);
    }
    onSelectAllRows?.(false);
  };

  const onChange = (field, row) => value => {
    onEdit(row, field, value);
    setRows(rows.map(r => (r[keyName] === row[keyName] ? { ...r, [field]: value } : r)));
  };

  useEffect(() => {
    if (!onPageChange) {
      handleChangePage(null, 0);
    }
  }, [rows, onPageChange]);

  const handleSelectAllClick = (checked, { target }) => {
    const isAllRowsSelected = checked && target.dataset.indeterminate !== 'true';
    const visibleRowsKeys = visibleRows.map(r => r[keyName]);

    handleSelected(
      isAllRowsSelected
        ? [...(selectedKeys.length ? selectedKeys : selected), ...visibleRowsKeys]
        : (selectedKeys.length ? selectedKeys : selected).filter(key => !visibleRowsKeys.find(unselectedKey => key === unselectedKey))
    );
    const isClientPagination = !totalRows && rows.length > rowLimit;
    if (isClientPagination || (totalRows && totalRows > rowLimit && onSelectAllRows)) {
      setShowSelectedAllRowsButton(isAllRowsSelected);
      if (!isAllRowsSelected && onSelectAllRows) {
        onSelectAllRows(false);
      }
    }
  };

  const doSelect = (event, id, isSingleSelect?: boolean, row?) => {
    setShowSelectedAllRowsButton(false);
    if (isInnerEditTable) {
      if (selected[0] !== id) {
        handleSelected([id]);
        onToggleEditMode(id);
      }
    } else if (isSingleSelect) {
      const newVal = (isSelectionControlled ? activeKey : active) === id ? undefined : id;
      setActiveRow(newVal);
      if (enableSelectionColor && !onActive) {
        navigate(getMainNavigation(newVal, row));
      }
    } else if (event.shiftKey) {
      const lastSelected = selected.at(-1);
      const rangeIndexes = [
        rows.findIndex(({ [keyName]: key }) => key === lastSelected),
        rows.findIndex(({ [keyName]: key }) => key === id),
      ].sort((a, b) => a - b);
      const selectRange = rows.slice(rangeIndexes[0], rangeIndexes[1] + 1).map(({ [keyName]: key }) => key);
      handleSelected([...selected.filter(key => !selectRange.includes(key)), ...(selected.includes(id) ? [] : selectRange)]);
    } else {
      const selectedArr = isSelectionControlled ? selectedKeys : selected;
      handleSelected(selectedArr.includes(id) ? selectedArr.filter(item => item !== id) : [...selectedArr, id]);
    }

    event.stopPropagation();
  };

  const handleChangePage = (event, newPage) => {
    setStatePage(newPage);
    onPageChange?.(newPage);
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
    event?.stopPropagation();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = !loadMoreLimit && page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (totalRows || rows.length)) : 0;

  const getMainNavigation = (id, row?) => (innerSelectLink ? innerSelectLink(id, row) : `edit/${id}`);

  const renderCellContent = (row, editable, id, options, index, formattedContent, hasTooltip = true, linkProps?: LinkPropsType) => {
    if (editable) {
      return getInput(row, id, onChange, options, formattedContent);
    }
    const childWithTooltip =
      hasTooltip && formattedContent ? <AvTooltip title={linkProps?.tooltip}>{formattedContent}</AvTooltip> : formattedContent;

    return !linkProps?.shouldShowLink(row) && (index || !allowEdit) ? (
      childWithTooltip
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        // @ts-ignore
        to={linkProps?.onClickLink ? undefined : getMainNavigation(row[keyName])}
        onClick={e => {
          if (linkProps?.onClickLink) {
            e.preventDefault();
            linkProps.onClickLink(row);
          }
          e.stopPropagation();
        }}>
        {childWithTooltip}
      </Link>
    );
  };

  const hasExpandedRow = row => expandedRowObj && (!expandedRowObj.shouldExpand || expandedRowObj.shouldExpand(row));

  const toggleExpandGroup = (row, newValue?) =>
    rowGroupByField && setCollapsedGroup({ ...collapsedGroup, [rowGroupByField(row)]: newValue || !collapsedGroup[rowGroupByField(row)] });

  const renderRow = (row, index) => {
    const rowId = row[keyName];
    const isItemSelected = isClickedAll ? true : isSelectionControlled ? selectedKeys.includes(rowId) : selected.includes(rowId);
    const isItemActive = isSelectionControlled ? activeKey === rowId : active === rowId;

    const isMarkedCell = cellId => markedCells[rowId] && markedCells[rowId][cellId] !== row[cellId];
    const getClassName = (editable, id, row) =>
      [
        editable ? 'editable' : '',
        row.isEditMode ? 'edit-mode' : '',
        isMarkedCell(id) ? 'marked' : '',
        row.totalsTableConfig?.isTotalRow ? 'totals-row' : '',
      ].join(' ');
    const rowClasses = [
      hasExpandedRow(row) ? 'has-expandedRow' : undefined,
      !enableSelectionColor && isItemActive && hasExpandedRow(row) ? 'expanded-row' : undefined,
      isInnerEditTable ? 'editable-row' : undefined,
      enableSelectionColor && onActive && isItemActive ? 'active-row' : undefined,
    ]
      .filter(d => d)
      .join(' ');
    return (
      <React.Fragment key={rowId}>
        <TableRow
          className={rowClasses}
          sx={{
            cursor: onActive || enableSelectionColor ? 'pointer' : '',
            ...(GroupHeaderRowType.GROUP === row.groupHeaderType && !row.disableStickyRow ? getStickyGroupRowStyle(containerRef) : {}),
          }}
          hover
          onClick={event => doSelect(event, rowId, true, row)}
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={enableSelectionColor && isItemSelected}>
          {multiSelect ? (
            <TableCell padding="checkbox" onClick={event => doSelect(event, rowId)}>
              <AvCheckbox
                disabled={isClickedAll}
                value={isItemSelected}
                inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
              />
            </TableCell>
          ) : null}
          {visibleHeaders.map(({ formatter, id, type, options, editable, getValue, hasTooltip, style, rowStyle, linkProps }, i) => {
            const formattedContent = generateInnerDefaultFormatter({
              value: getValue ? getValue(row) : row[id],
              type,
              shouldDisplayNull,
            });
            return (
              <TableCell
                align={isNumberType(type) ? 'right' : 'left'}
                key={id}
                className={getClassName(editable, id, row)}
                sx={theme => ({
                  ...style,
                  ...rowStyle?.({ theme, row }),
                  ...(!rowStyle && row.totalsTableConfig?.isTotalRow ? totalCellStyle(theme) : {}),
                  ...(isAuto(columnWidths, id) || isAutoFit(columnWidths, id) ? { maxWidth: 'unset' } : resizeResponsiveProps),
                  ...(formattedContent === NO_VALUE ? { color: theme.palette.colors.neutrals[500] } : {}),
                })}
                style={([AvalorTableIds.InlineActionsId, AvalorTableIds.FreeSpaceId] as string[]).includes(id) ? { padding: 0 } : {}}>
                <Box sx={cellBoxStyle(id, resizable, columnWidths, type, size, freeSpace, columnsToAddFreeSpace)}>
                  {row.groupHeaderType === GroupHeaderRowType.GROUP && i === 0 && (
                    <IconButton
                      sx={{ mr: 1 }}
                      size="small"
                      onClick={e => {
                        e.stopPropagation();
                        toggleExpandGroup(row);
                      }}>
                      <CollapseArrow isExpanded={collapsedGroup[rowGroupByField?.(row)]} />
                    </IconButton>
                  )}{' '}
                  {formatter
                    ? formatter(row[id], row, setRows, index, rows, id)
                    : renderCellContent(row, editable, id, options, i, formattedContent, hasTooltip, linkProps)}
                </Box>
              </TableCell>
            );
          })}
        </TableRow>
        {hasExpandedRow(row) ? (
          <TableRow selected={enableSelectionColor && isItemActive} className="expanded-row-content">
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, height: 0 }} colSpan={visibleHeaders.length + (multiSelect ? 1 : 0)}>
              {expandedRowObj!.component(row, onChange, isItemActive)}
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  };

  const clearOnClickAwayWrapper = children => <ClickAwayListener onClickAway={clearSelect}>{children}</ClickAwayListener>;

  const { rowsContent, visibleRows, filteredRowsCount } = useMemo(() => {
    const visibleRows = getRows(
      rows,
      order,
      !onSort && orderBy,
      visibleHeaders,
      page,
      pagination ? rowsPerPage : null,
      !!totalRows,
      filters,
      hasHeaderFilters,
      rowGroupByField
    );

    const rowsToRender = rowGroupByField
      ? visibleRows.filter(
          row =>
            [GroupHeaderRowType.GROUP, GroupHeaderRowType.STANDALONE].includes(row.groupHeaderType) ||
            (rowGroupByField && collapsedGroup[rowGroupByField(row)])
        )
      : visibleRows;

    const rowsToRenderFinal =
      !pagination && loadMoreLimit && rowsToRender.length > loadMoreLimit
        ? rowsToRender.slice(0, (statePage + 1) * loadMoreLimit)
        : rowsToRender;

    const rowsContent = rowsToRenderFinal.map(renderRow);

    const filteredRowsCount = getRows(
      rows,
      order,
      !onSort && orderBy,
      visibleHeaders,
      page,
      null,
      !!totalRows,
      filters,
      hasHeaderFilters
    ).length;

    return { visibleRows, rowsContent, filteredRowsCount };
  }, [
    rows,
    order,
    orderBy,
    page,
    totalRows,
    activeKey,
    selectedKeys,
    active,
    selected,
    headCells,
    filters,
    resizingColId,
    collapsedGroup,
    columnsToAddFreeSpace,
    loading,
    statePage,
  ]);

  const contextValue = useMemo(
    () => ({
      headCells,
      containerRef,
      columnWidths,
      rows,
      filters,
      setFilters,
      hasHeaderFilters,
      resizingColId,
      onResizeColumn,
      autoFitCol,
      columnRefs,
      resizable,
      handleChangePage,
      freeSpaceRef,
      columnIconButtonProp,
    }),
    [headCells, columnWidths, rows, filters, setFilters, resizingColId]
  );
  const pageCount = Math.ceil((totalRows || filteredRowsCount) / rowsPerPage);
  const currentPage = page + 1;
  return (
    <TableContext.Provider value={contextValue}>
      <Box sx={{ ...flex.col, width: '100%', overflow: 'auto', ...sx }}>
        {((allowEdit || allowCreate || deleteProps) && !isInnerEditTable) || customActions.length ? (
          <TableToolbar
            keyName={keyName}
            activeKey={activeKey}
            rows={rows}
            setRows={setRows}
            handleSelected={handleSelected}
            selected={selected}
            deleteProps={deleteProps ? { onDelete: handleDelete, ...deleteProps } : undefined}
            customActions={customActions}
            allowEdit={allowEdit && !isInnerEditTable}
            allowCreate={allowCreate && !isInnerEditTable}
            customGlobalActions={customGlobalActions}
            customNewButton={customNewButton}
            innerEditLink={innerEditLink}
          />
        ) : (
          ''
        )}
        <TableContainer ref={containerRef} sx={tableContainerSx} onScroll={e => onScroll?.(e, containerRef)}>
          <Table
            stickyHeader
            size={size}
            sx={
              resizable && LocalColumnsWidth && visibleRows.length
                ? {
                    tableLayout: 'fixed',
                    width: 'initial',
                  }
                : {}
            }>
            {showHeader && (
              <EnhancedTableHead
                groupedHeadCells={groupedHeadCells}
                headCells={visibleHeaders}
                multiSelect={multiSelect}
                numSelected={
                  (isSelectionControlled ? selectedKeys : selected).filter(id => rows.find(({ [keyName]: key }) => id === key)).length
                }
                isSelectedAll={isClickedAll}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={isInnerEditTable ? undefined : handleRequestSort}
                rowCount={rows.length}
                onCancelColumn={onCancelColumn}
              />
            )}
            <ConditionalWrapper condition={isInnerEditTable} wrapper={clearOnClickAwayWrapper}>
              <TableBody>
                {loading ? (
                  [...Array(totalRows && totalRows > 0 ? (totalRows <= rowsPerPage ? totalRows : rowsPerPage) : 3)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index} tabIndex={-1}>
                      {multiSelect ? (
                        <TableCell padding="checkbox">
                          <AvCheckbox disabled />
                        </TableCell>
                      ) : null}
                      {visibleHeaders.map(({ id, style }) => (
                        <TableCell key={id} sx={style}>
                          {generateInnerDefaultFormatter({
                            value: loadingPercentages[index % 2],
                            type: 'loading',
                            size,
                            shouldDisplayNull,
                          })}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <>
                    {showSelectAllRowsButton && (
                      <SelectAllRowsButton
                        width={containerRef.current.clientWidth}
                        colspan={visibleHeaders.length}
                        totalRows={totalRows || rows.length}
                        onClearSelection={() => {
                          clearSelect();
                          setShowSelectedAllRowsButton(false);
                          setAllClicked(false);
                        }}
                        onSelectAllRows={() => (onSelectAllRows ? onSelectAllRows(true) : handleSelected(rows.map(r => r[keyName])))}
                        currentPageRows={visibleRows.length}
                        setAllClicked={setAllClicked}
                        isClickedAll={isClickedAll}
                      />
                    )}
                    {hasHeaderFilters && visibleRows.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={visibleHeaders.length}
                          align="center"
                          sx={{ '.MuiTable-root &:not(.MuiTableCell-paddingNone)': { p: 4 } }}>
                          <NoDataFound
                            headline={noDataProps?.headline}
                            callToAction={
                              Object.keys(filters).length ? (
                                <>
                                  Try to <Button onClick={() => setFilters({})}>Clear All filters</Button>
                                </>
                              ) : undefined
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {rowsContent}
                    {totalProps.cells?.length && (
                      <RenderTotalsRow {...totalProps} resizable={resizable} shouldDisplayNull={shouldDisplayNull} />
                    )}
                  </>
                )}
                {emptyRows > 0 && (
                  <TableRow sx={{ height: 33 * emptyRows, opacity: 0 }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {!pagination && loadMoreLimit && rows.length > rowsContent.length && (
                  <TableRow>
                    <TableCell colSpan={visibleHeaders.length}>
                      <Button sx={{}} onClick={() => setStatePage(prev => prev + 1)}>
                        show more
                      </Button>{' '}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ConditionalWrapper>
          </Table>
        </TableContainer>
        {pagination && pageCount > 0 ? (
          <Box sx={{ ...flex.row, ...flex.justifyEndCenter }}>
            <PaginationRowsText
              from={1 + (currentPage - 1) * rowsPerPage}
              to={Math.min(currentPage * rowsPerPage, totalRows || filteredRowsCount)}
              count={totalRows || filteredRowsCount}
            />
            <Pagination
              shape="rounded"
              count={pageCount}
              page={currentPage}
              onChange={(event, page) => handleChangePage(event, page - 1)}
              sx={{ py: 1, pr: 1 }}
              renderItem={item => (
                <PaginationItem
                  component="div"
                  {...item}
                  page={item.page?.toLocaleString()}
                  sx={{
                    fontSize: 12,
                    height: 24,
                    minWidth: 24,
                    margin: '0px 1px',
                    color: theme => (item.page === currentPage ? theme.palette.colors.neutrals[900] : theme.palette.colors.neutrals[600]),
                    fontWeight: item.page === currentPage ? 600 : 400,
                  }}
                />
              )}
            />
          </Box>
        ) : (
          ''
        )}
      </Box>
    </TableContext.Provider>
  );
};

export default AvTable;

interface RenderTotalsRowProps extends TotalsProps {
  resizable?: boolean;
  shouldDisplayNull: boolean;
}
export const RenderTotalsRow: React.FC<RenderTotalsRowProps> = ({ label, colspan, cells = [], row, resizable, shouldDisplayNull }) => (
  <TableRow
    className="totals-row"
    sx={{
      position: 'sticky',
      bottom: 0,
      background: theme => theme.palette.white.main,
      borderTop: '1px solid',
    }}>
    {label && <TableCell sx={totalCellStyle}>{label}</TableCell>}
    {!!(colspan && colspan > 0) && <TableCell sx={totalCellStyle} colSpan={colspan} />}
    {[...cells, ...(resizable ? [{ id: AvalorTableIds.FreeSpaceId }] : [])].map(({ id, type }) => (
      <TableCell key={id} align={isNumberType(type) ? 'right' : 'left'} sx={totalCellStyle}>
        <Box sx={{ pr: 1 }}>
          {generateInnerDefaultFormatter({
            value: row[id],
            type,
            shouldDisplayNull,
          })}
        </Box>
      </TableCell>
    ))}
  </TableRow>
);
