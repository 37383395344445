import React, { useRef, useState } from 'react';
import { Box, ClickAwayListener, Divider, Paper, Popper, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { useAvContext } from '../../context/AvContextProvider';
import useQuerySql from '../../hooks/useQuerySql';
import { rebranding } from '../../rebranding';
import { QueryKeysEnums } from '../../utils/queryKeysEnums';
import { iconSize } from '../../utils/Utils';
import AvItemWithLogoGroup, { IconSize } from '../AvItemWithLogoGroup';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import DataSourceSelect from '../DataSourceSelect';
import { ErrorTooltip } from '../InputTooltipWrapper';
import { IconVariant } from '../ItemWithLogo';
import SwitchToggle from '../SwitchToggle';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as Circle } from '../../assets/Circle.svg';
import { ReactComponent as Warning } from '../../assets/colorful/Info Full.svg';
import { ReactComponent as WarningNew } from '../../assets/colorful2/Info Full.svg';
import { ReactComponent as QuestionFull } from '../../assets/QuestionFull.svg';
import { ReactComponent as Unified } from '../../assets/Unified.svg';

const popupIcon = <ArrowDown style={iconSize(16)} />;
const questionFull = <QuestionFull />;
const unified = <Unified style={iconSize(20)} />;
const circle = <Circle style={iconSize(20)} />;
const warning = rebranding ? <WarningNew /> : <Warning />;

interface UnifiedIngressToggleProps {
  isIngressExp: boolean;
  onChangeIngressExp: () => void;
  onChangeSources: (source) => void;
  sourcesValue: string[];
  activeProjName?: string;
}

const paddingY = 8;

const UnifiedIngressToggle: React.FC<UnifiedIngressToggleProps> = ({
  isIngressExp,
  onChangeIngressExp,
  onChangeSources,
  sourcesValue,
  activeProjName = 'uber_assets',
}) => {
  const { palette } = useTheme();

  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const { pathAlias } = aggProjs[activeProjName];
  const sourcesField = `${pathAlias}.source_names`;

  const { data: availableSourceOption = [], isLoading: isLoadingDetectionSources } = useQuerySql({
    key: QueryKeysEnums.detectionSources,
    sql: `SELECT distinct EXPLODE(${sourcesField}) as name FROM ${activeProjName}`,
    options: { placeholderData: keepPreviousData },
  });
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [noSourcesSelectedError, setNoSourcesSelectedError] = useState<boolean>(false);
  const boxRef = useRef();
  const warningIcon = <Box sx={{ ...flex.justifyEndCenter, ml: 9.5 }}>{warning}</Box>;
  return (
    <Box sx={{ ...flex.itemsCenter }}>
      <Box
        onClick={() => setIsPopperOpen(prev => !prev)}
        ref={boxRef}
        sx={{
          ...flex.itemsCenter,
          border: `1px solid ${palette.colors.neutrals[400]}`,
          borderRadius: 2,
          pr: 1,
          height: 32,
          width: 144,
        }}>
        <Box
          sx={{
            ...flex.center,
            backgroundColor: palette.colors.neutrals[500],
            border: `1px solid ${palette.colors.neutrals[500]}`,
            borderBottomLeftRadius: 7,
            borderTopLeftRadius: 7,
            height: '100%',
            width: 40,
            mr: 0.5,
            px: 0.7,
            color: palette.colors.neutrals[100],
          }}>
          {isIngressExp ? circle : unified}
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
          }}>
          {isIngressExp ? (
            <AvItemWithLogoGroup variant={IconVariant.sourcesMapByName} items={sourcesValue} size={IconSize.collapsed} />
          ) : (
            <Box sx={{ ml: 0.5 }}>Unified</Box>
          )}
        </Box>
        <Box
          sx={{
            ...flex.justifyEndCenter,
            width: 16,
          }}>
          {popupIcon}
        </Box>
      </Box>
      <Popper open={isPopperOpen} anchorEl={boxRef.current} placement="bottom-start" sx={{ zIndex: theme => theme.zIndex.modal }}>
        <ClickAwayListener
          onClickAway={() => {
            if (sourcesValue.length === 0 && isIngressExp) {
              setNoSourcesSelectedError(true);
            } else {
              setIsPopperOpen(prev => !prev);
            }
          }}>
          <Paper
            sx={{
              ...flex.colItemsStart,
              backgroundColor: `${palette.colors.neutrals[100]}`,
              padding: 1,
              width: 300,
              borderRadius: 1,
              gap: 1.5,
            }}>
            <Box
              sx={{
                backgroundColor: palette.colors.neutrals[200],
                px: 1,
                py: 0.5,
                borderRadius: 1,
                ...flex.justifyStartCenter,
                gap: 1,
                width: '100%',
                height: 36,
                fontSize: 12,
                fontWeight: 600,
                color: palette.colors.neutrals[600],
              }}>
              {questionFull}
              Explanation of what unified means
            </Box>
            <Box sx={{ ...flex.justifyStartCenter }}>
              <SwitchToggle value={isIngressExp} onChange={onChangeIngressExp} sx={{ marginRight: 2, mb: 0.5 }} /> Use Original Source
              {isIngressExp &&
                (noSourcesSelectedError ? (
                  <ErrorTooltip error="You must choose at least one source" open={noSourcesSelectedError}>
                    {warningIcon}
                  </ErrorTooltip>
                ) : (
                  <AvTooltip title="You must choose at least one source">{warningIcon} </AvTooltip>
                ))}
            </Box>
            {isIngressExp && (
              <Box sx={{ ...flex.center, width: '100%' }}>
                <Divider orientation="horizontal" sx={{ width: 280 }} />
              </Box>
            )}
            {isIngressExp && (
              <Box
                sx={{
                  width: 284,
                  borderRadius: 1,
                }}>
                <DataSourceSelect
                  size="xSmall"
                  onChange={val => {
                    onChangeSources(val);
                    setNoSourcesSelectedError(false);
                  }}
                  options={availableSourceOption}
                  filterValue={sourcesValue}
                  selectProps={{
                    showOnlyAutoComplete: true,
                    loading: isLoadingDetectionSources,
                    muiProps: {
                      slotProps: {
                        paper: { sx: { boxShadow: 'unset', height: '200px', '.MuiAutocomplete-listbox': { height: 200 - 2 * paddingY } } },
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default UnifiedIngressToggle;
