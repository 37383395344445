import { keepPreviousData } from '@tanstack/react-query';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { lineQueryObject } from './utils';

// eslint-disable-next-line import/prefer-default-export
export const useFactorLineChart = ({ enabled = true }) =>
  useQueryObject({
    queryObject: lineQueryObject,
    options: { enabled, placeholderData: keepPreviousData, gcTime: 0 },
    onSuccess: d => d.reduce((acc, v) => ({ ...acc, [v['factor._key']]: [...(acc[v['factor._key']] || []), v] }), {}),
  });
