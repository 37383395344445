export const TicketType = {
  default: 'DEFAULT',
  AppSec: 'APP_SEC',
  AssetOriginated: 'ASSET_ORIGINATED',
  CrowdstrikeDetection: 'DETECTION',
  Sast: 'SAST',
  Legit: 'LEGIT',
  ApiiroSecrets: 'APIIRO_SECRETS',
  FileRisk: 'FILE_RISK',
  ManualIncident: 'MANUAL_INCIDENT',
  DefenderIncident: 'DEFENDER_INCIDENT',
  AlertToIncident: 'ALERT_TO_INCIDENT',
  Cyera: 'CYERA',
};

export const FilterTypes = {
  Tags: 'ticket.tags',
  Assignee: 'ticket.owner',
  Artifact: 'ticket.artifact',
  Asset: 'ticket.asset_keys',
  AssetType: 'ticket.asset_types',
  ArtifactType: 'ticket.artifact_type',
  Tactic: 'ticket.tactic',
  Technique: 'ticket.technique',
  Integration: 'ticket.integration_info.key',
  Exception: 'ticket.exception_status.status',
  Username: 'ticket.username',
  TagsV2: 'ticket.tags',
  AssigneeV2: 'ticket.assignee_id',
  ArtifactV2: 'ticket.primary_component.name',
  AssetV2: 'NEIGHBOR_ATTR(finding.asset_key)',
  AssetTypeV2: 'NEIGHBOR_ATTR(finding.asset_type)',
  ArtifactTypeV2: 'ticket.primary_component.type',
  TacticV2: 'ticket.tactic',
  TechniqueV2: 'ticket.technique',
  UsernameV2: 'ticket.username',
  IncidentAssigneeV2: 'incident.assignee_id',
  IncidentTacticV2: 'alert.tactic_name',
  IncidentTechniqueV2: 'alert.technique_name',
};

export const FilterTypesLabelV2 = {
  [FilterTypes.TagsV2]: 'Tags',
  [FilterTypes.AssigneeV2]: 'Assignee',
  [FilterTypes.AssetV2]: 'Asset',
  [FilterTypes.ArtifactV2]: 'Primary Component Name',
  [FilterTypes.AssetTypeV2]: 'Asset Type',
  [FilterTypes.ArtifactTypeV2]: 'Primary Component Type',
};

export const FilterIncidentEntityTypesLabelV2 = {
  [FilterTypes.IncidentAssigneeV2]: 'Assignee',
  [FilterTypes.IncidentTacticV2]: 'Tactic',
  [FilterTypes.IncidentTechniqueV2]: 'Technique',
};

export const defaultSortBy = (entityName = 'ticket') => [
  { property: `${entityName}.severity_score`, isAsc: false },
  { property: 'sla', isAsc: true },
  { property: `${entityName}._key`, isAsc: true },
];

export const defaultSortByTickets = (entityName = 'ticket') => [
  { property: `${entityName}.severity_score`, isAsc: false },
  { property: 'active_findings_risk_mass_granular', isAsc: false },
  { property: 'sla', isAsc: true },
  { property: `${entityName}._key`, isAsc: true },
];

export const defaultAlertSortBy = () => [
  { property: `alert.last_seen`, isAsc: false },
  { property: 'sla', isAsc: true },
  { property: `alert._key`, isAsc: true },
];

export const defaultExceptionsSortBy = () => [
  { property: 'exception._key', isAsc: true },
  { property: `ticket.severity_score`, isAsc: false },
  { property: 'ticket.sla', isAsc: true },
];

export const mapAliasesAndMetrics = {
  severityScore: {
    alias: 'severityScore',
    metric: 'max_risk_granular',
  },
  totalFindings: {
    alias: 'totalFindings',
    metric: 'active_findings_granular',
  },
};

export const mapAliasesAndDims = {
  Incident: {
    sla: {
      alias: 'sla',
      dim: 'incident.sla',
    },
  },
  Ticket: {
    sla: {
      alias: 'sla',
      dim: 'ticket.sla',
    },
    remediation: {
      alias: 'remediation',
      dim: '',
      filterFromClickthrough: true,
    },
  },
};
