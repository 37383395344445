import React from 'react';
import { ChartClickInteraction } from '../../../../../components/Widgets/types';
import { TileCategoryWidgetTypes, tileTitleSX } from '../../../types';
import ClickableTitle from './ClickableTitle';

interface Props {
  data: any[];
  widget: TileCategoryWidgetTypes;
  clickInteractions?: ChartClickInteraction[];
}

const NoneTileWidget = ({ data, widget, clickInteractions }: Props) => {
  const request = widget.requests[0];
  const firstMetric = request.select.metrics[0];
  const metric = firstMetric.alias || firstMetric.name;
  const activeProjName = request.projectionId.name;

  return (
    <ClickableTitle
      value={data[0][metric]}
      metricName={metric}
      titleSx={tileTitleSX}
      projName={activeProjName}
      clickInteractions={clickInteractions}
    />
  );
};

export default NoneTileWidget;
