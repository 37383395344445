import { datadogRum } from '@datadog/browser-rum';
import { Amplify } from 'aws-amplify';
import * as Auth from 'aws-amplify/auth';
import { useIdleTimer } from 'react-idle-timer';
import envVariables from '../shared/projectEnvVariables';
import { ErrorTypes } from '../types/query.types';
import { AuthStorageKeys } from './localStorageAuthUtils.utils';

export const shouldSkipUILogin = () => envVariables.VITE_SKIP_AUTH === 'true';
export const newLoginFlow = () => envVariables.VITE_NEW_LOGIN_FLOW === 'true';
export const loginFix = () => envVariables.VITE_FIX_LOGIN === 'true';
export const sharedWorkerAuthFlow = () => envVariables.VITE_SHARED_WORKER === 'true';
export const lock = () => envVariables.VITE_LOCK === 'true';

async function sleep(msec) {
  return new Promise(resolve => {
    setTimeout(resolve, msec);
  });
}

// eslint-disable-next-line consistent-return
export const overrideAccountIdUserAttribute = async (accountId, { logout, loginWithRedirect }, attempts = 0) => {
  try {
    await Auth.updateUserAttributes({
      userAttributes: {
        'custom:web_account_id': accountId,
      },
    });
  } catch (error) {
    if (attempts < 1) {
      const { accessToken } = (await fetchSession({})).tokens ?? {};
      if (accessToken) {
        await sleep(1000);
        await overrideAccountIdUserAttribute(accountId, { logout, loginWithRedirect }, attempts + 1);
      } else {
        // eslint-disable-next-line no-console
        console.debug('no token for updating the attribute');
        return undefined;
      }
    } else {
      console.error(
        `${ErrorTypes.Authentication}: Error changing account ID user attribute to: ${accountId} after 2 attempts ${JSON.stringify(
          (error as any).message || error
        )}`
      );
    }
  }
};

export const overrideAccountIdUserAttributeV2 = async (accountId: string, attempts = 0) => {
  try {
    await Auth.updateUserAttributes({
      userAttributes: {
        'custom:web_account_id': accountId,
      },
    });
  } catch (error) {
    if (attempts < 1) {
      await sleep(1000);
      await overrideAccountIdUserAttributeV2(accountId, attempts + 1);
    } else {
      console.error(
        `${ErrorTypes.Authentication}: Error changing account ID user attribute to: ${accountId} after 2 attempts ${JSON.stringify(
          (error as any).message || error
        )}`
      );
    }
  }
};

export const fetchSession = async ({ forceRefresh = true, firstLogin = false }: { forceRefresh?: boolean; firstLogin?: boolean }) => {
  try {
    const res = await Auth.fetchAuthSession({ forceRefresh });
    if (!res.tokens?.idToken && !firstLogin) {
      console.error(
        `${ErrorTypes.Authentication}: token is empty after refresh. response: ${JSON.stringify(res)}, forceRefresh: ${forceRefresh}`
      );
    }
    return res;
  } catch (error) {
    console.error(`${ErrorTypes.Authentication}: Fetch session error: ${(error as any).message} ${(error as any).underlyingError} `);
    return { error: (error as any).underlyingError, tokens: { accessToken: undefined, idToken: undefined } };
  }
};

export const getParsedAccountTokenMap = () => JSON.parse(localStorage.getItem(AuthStorageKeys.accessTokenByAccountId) || '{}');

export const getAccountTokenFromLS = accountId => getParsedAccountTokenMap()[accountId];

export const updateTokenInLS = ({ token, accountId }) => {
  const accessTokenByAccountIdValue = getParsedAccountTokenMap();
  localStorage.setItem(AuthStorageKeys.accessTokenByAccountId, JSON.stringify({ ...accessTokenByAccountIdValue, [accountId]: token }));
};

export const useUserIsActive = ({ setIsNotIdle, accessTokenTimeout }) => {
  const timeout = accessTokenTimeout - 10000; // 10 seconds before the token expires
  useIdleTimer({
    timeout,
    onIdle: () => {
      setIsNotIdle(false);
    },
    onActive: () => {
      setIsNotIdle(true);
    },
  });
};

export const setDDUser = user =>
  datadogRum.setUser({
    ...datadogRum.getUser(),
    userId: user.userId,
    name: user.userName,
    email: user.email,
    roleName: user.roleName,
    accountId: user.accountId,
    role: user.roleId,
  });

export const removeCognitoLS = () => {
  const cognitoLSValues = Object.keys(localStorage).filter(key => /^CognitoIdentityServiceProvider(.*)$/.test(key));
  for (let i = 0; i < cognitoLSValues.length; i++) {
    const key = cognitoLSValues[i];
    localStorage.removeItem(key);
  }
};

export const signIn = async (state?) => {
  const authConfig = JSON.parse(localStorage.getItem(AuthStorageKeys.authConfig) || '{}');
  const signInDetails: any = {
    customState: `${encodeURIComponent(state || window.location.pathname + window.location.search)}`,
  };
  if (authConfig.userPoolIdentityProviderName) {
    signInDetails.provider = { custom: authConfig.userPoolIdentityProviderName };
  }
  return Auth.signInWithRedirect(signInDetails).catch(e => console.error(`${ErrorTypes.Authentication}: ${e}`));
};

export const configureAmplify = authConfig => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: authConfig.userPoolWebClientId,
        userPoolId: authConfig.userPoolId,
        loginWith: {
          oauth: {
            domain: authConfig.userPoolDomain,
            scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}`],
            redirectSignOut: [`${window.location.origin}`],
            responseType: 'code',
          },
        },
      },
    },
  });
};
