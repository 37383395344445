import React, { useCallback } from 'react';
import { Button, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import EmptyQuery from '../../../../assets/EmptyQuery.png';
import { flex } from '../../../../components/AvThemeProvider';
import ColumnReorder from '../../../../components/ColumnReorder';
import ExportButton from '../../../../components/ExportButton';
import NoDataFound from '../../../../components/NoDataFound';
import AvTable from '../../../../components/Table/AvTable';
import { ActionButton } from '../../../../components/Table/Utils';
import { useAvContext } from '../../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../../hooks/UseCustomSearchParams';
import { useExportQueryObjectWorkflow } from '../../../../hooks/useExportQueryWorklflow';
import { rebranding } from '../../../../rebranding';
import { APP_PATHS, PAGE_PATHS } from '../../../../types';
import { Filter } from '../../../../types/filter.types';
import { entityViewConfig } from '../../../../utils/entityViewConfig';
import { cleanEmptyFilters } from '../../../../utils/filterUtils';
import { iconSize, serializeSortItem } from '../../../../utils/Utils';
import { AllTypeWidgets, WidgetCategory } from '../../types';
import { clickthroughHeadCells, clickthroughOrderBy, clickthroughTablePage, rowLimit } from './consts';
import { useGetClickthroughData, useGetClickthroughDimsFromFilters } from './hooks';
import { getFiltersWithoutKeyPrefix } from './utils';
import EmptyQueryIMG from '../../../../assets/emptyStates/Get started by creating a new query.svg';
import { ReactComponent as External } from '../../../../assets/External.svg';
import { ReactComponent as X } from '../../../../assets/X.svg';

interface ClickthroughTableProps {
  widget: AllTypeWidgets;
  globalFilters: Filter;
  widgetData: Record<string, unknown>[];
}

const ClickthroughTable: React.FC<ClickthroughTableProps> = ({ widget, globalFilters, widgetData }) => {
  const {
    getPathName,
    accountEntities: { aggProjs },
  } = useAvContext();
  const [filters, setFilters] = useCustomSearchParams({ shouldBeArray: () => true });
  const clickthroughDims = useGetClickthroughDimsFromFilters();

  const {
    activeProjName,
    initHeadCells,
    headCells,
    orderBy,
    page,
    // disableEntityPageNavigation,
    queryObject,
    rows,
    isLoadingTable,
    isRefetchingTable,
    totalRowCount,
    keyField,
    isDisabledClickthroughQuery,
    isLoadingMeasurementFilter,
  } = useGetClickthroughData({ widget, globalFilters });

  const { mutate: exportQueryObjectWorkflow } = useExportQueryObjectWorkflow();

  const updateFilters = useCallback(
    (key, val, isPageChange = false) => {
      const newFilters = { ...filters, [key]: val, ...(isPageChange ? {} : { [clickthroughTablePage]: ['0'] }) };
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const onColumnPickerApply = items =>
    setFilters({ ...filters, [clickthroughHeadCells]: items.filter(({ hidden }) => !hidden).map(({ id }) => id) });

  const onSort = (property, direction) => {
    const isAsc = direction === 'asc';
    const nextOrderBy = [...orderBy.filter(item => item.property !== property).slice(0, 0), ...(direction ? [{ property, isAsc }] : [])];
    updateFilters(clickthroughOrderBy, nextOrderBy.map(serializeSortItem));
  };

  const entityPageConfig = Object.values(entityViewConfig).find(({ projectionName: p }) => p === activeProjName);
  const getRowPath = (id?) =>
    entityPageConfig
      ? getPathName(
          entityPageConfig.path,
          id ? `/${id}` : '',
          [PAGE_PATHS.INCIDENTS, PAGE_PATHS.ALERTS].includes(entityPageConfig.path) ? APP_PATHS.INCIDENTS : APP_PATHS.VULNERABILITIES
        )
      : getPathName(PAGE_PATHS.ENTITIES, '', APP_PATHS.EXPLORE);
  const gotoEntityRowInlineAction = row =>
    ActionButton(
      `Open row in ${entityPageConfig ? `${entityPageConfig.entityTypeId.toLowerCase()}s` : 'Entities'} page`,
      () =>
        window.open(
          getRowPath(row[keyField]) +
            (entityPageConfig ? '' : `?activeEntityId=${aggProjs[activeProjName].entityTypeId.name}&${keyField}=${row[keyField]}`),
          '_blank'
        ),
      <External />
    );

  // const allAppliedFiltersObject = useMemo(() => {
  //   const queryObjectFilterObject = queryObject.filter
  //     ? expressionToFilter(fieldTypeMap, queryObject.filter, featureFlags[FeatureFlags.NewDynamicRange])
  //     : {};
  //   const cleanedGlobalFilters = cleanEmptyFilters(globalFilters);
  //   if (!cleanedGlobalFilters) {
  //     return queryObjectFilterObject;
  //   }
  //   const globalFiltersFilterObject = expressionToFilter(fieldTypeMap, cleanedGlobalFilters, featureFlags[FeatureFlags.NewDynamicRange]);
  //   const entityRelevantGlobalFilters = Object.keys(globalFiltersFilterObject)
  //     .filter(fieldKey => aggProjs[activeProjName].fieldList.INTERACTIVE.some(({ value }) => value === fieldKey))
  //     .reduce((acc, cur) => ({ ...acc, [cur]: globalFiltersFilterObject[cur] }), {});
  //
  //   return Object.keys(entityRelevantGlobalFilters).reduce(
  //     (acc, cur) => ({ ...acc, [cur]: [...(acc[cur] || []), ...entityRelevantGlobalFilters[cur]] }),
  //     queryObjectFilterObject
  //   );
  // }, [queryObject.filter, globalFilters, activeProjName]);

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', p: 3, pt: 2, gap: 1, ...flex.col }}>
      <Box sx={{ ...flex.justifyBetweenCenter }}>
        <Typography variant="h5">
          {widget.definition.title.title}
          {widget.definition.title.title
            ? ` - ${widget.category === WidgetCategory.Tile && widgetData[0] ? Object.values(widgetData[0])[0]?.toLocaleString() : ''}`
            : ''}
          {Object.values(clickthroughDims)?.[0]?.join(', ')}
        </Typography>
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {/* {false && ( */}
          {/*  <> */}
          {/*    <Button */}
          {/*      component={Link} */}
          {/*      target="_blank" */}
          {/*      to={{ */}
          {/*        pathname: getRowPath(), */}
          {/*        search: disableEntityPageNavigation ? '' : constructSearchParams(allAppliedFiltersObject), */}
          {/*      }}> */}
          {/*      <Box */}
          {/*        sx={{ */}
          {/*          color: theme => theme.palette.colors.primary[500], */}
          {/*          fontWeight: 600, */}
          {/*          ...flex.row, */}
          {/*          gap: 0.25, */}
          {/*          fontSize: '13px', */}
          {/*        }}> */}
          {/*        /!* Open list in {entityPageConfig ? `${entityPageConfig.entityTypeId.toLowerCase()}s` : 'entities'} page *!/ */}
          {/*        <External /> */}
          {/*      </Box> */}
          {/*      <Divider /> */}
          {/*    </Button> */}
          {/*    <Divider orientation="vertical" variant="middle" sx={{ height: 16 }} flexItem /> */}
          {/*  </> */}
          {/* )} */}
          <ExportButton
            width={16}
            onClick={() => {
              const { top, ...rest } = queryObject;
              exportQueryObjectWorkflow({
                queryObject: rest,
                name: widget.definition.title.title,
                contextFilter: cleanEmptyFilters(globalFilters),
              });
            }}
          />
          <ColumnReorder items={headCells} setItems={onColumnPickerApply} initialColumns={initHeadCells()} width={16} />
          <Divider orientation="vertical" variant="middle" sx={{ height: 16 }} flexItem />
          <Button
            variant="filter"
            className="transparent"
            sx={{ px: 1, mr: -1 }}
            onClick={() => setFilters(getFiltersWithoutKeyPrefix(filters))}>
            <X style={iconSize(20)} />
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: '100%', flex: 1, overflow: 'hidden' }}>
        {isDisabledClickthroughQuery && !isLoadingMeasurementFilter ? (
          <NoDataFound headline="Clickthrough is not supported" img={rebranding ? EmptyQueryIMG : EmptyQuery} />
        ) : rows.length || isLoadingTable || isRefetchingTable ? (
          <AvTable
            storageKey={widget.id}
            resizable
            rows={rows}
            headCells={headCells}
            size="medium"
            allowEdit={false}
            enableSelectionColor={false}
            hasHeaderFilters={false}
            loading={isLoadingTable || isRefetchingTable}
            pagination
            inlineActions={[gotoEntityRowInlineAction]}
            rowLimit={rowLimit}
            onSort={onSort}
            sx={{ height: '100%' }}
            paginationProps={{
              onPageChange: page => updateFilters(clickthroughTablePage, [page], true),
              totalRows: totalRowCount,
              pageNum: page,
            }}
          />
        ) : (
          <NoDataFound headline="No Data Found" img={rebranding ? EmptyQueryIMG : EmptyQuery} />
        )}
      </Box>
    </Box>
  );
};

export default ClickthroughTable;
