import React, { useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { Box, Button, Collapse, LinearProgress, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAvQuery } from '../API/useAvQuery';
import { flex } from '../components/AvThemeProvider';
import AvTooltip from '../components/AvTooltip';
import Loading from '../components/Loading';
import useHandleError from '../hooks/useHandleError';
import useZendesk from '../hooks/useZendesk';
import { rebranding } from '../rebranding';
import { APP_PATHS, PAGE_PATHS } from '../types';
import { entityViewConfig } from '../utils/entityViewConfig';
import { useAvContext } from './AvContextProvider';
import { WorkflowType } from './SnackBar.types';
import { AvContextType } from './types';
import { ReactComponent as Error } from '../assets/colorful/Fail_full.svg';
import { ReactComponent as ErrorFull } from '../assets/colorful/Fail_full2.svg';
import { ReactComponent as Success } from '../assets/colorful/Success_full.svg';
import { ReactComponent as SuccessFull } from '../assets/colorful/Success_full2.svg';
import { ReactComponent as SuccessNew } from '../assets/colorful2/Success_full.svg';
import { ReactComponent as SuccessFullNew } from '../assets/colorful2/Success_full2.svg';
import { ReactComponent as External } from '../assets/External.svg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Maximize } from '../assets/Maximize.svg';
import { ReactComponent as Minimize } from '../assets/Minimize.svg';
import { ReactComponent as X } from '../assets/X.svg';

const success2Icon = rebranding ? <SuccessFullNew style={{ width: 24, height: 24 }} /> : <SuccessFull style={{ width: 24, height: 24 }} />;
const error2Icon = <ErrorFull style={{ width: 24, height: 24 }} />;
const successIcon = rebranding ? <SuccessNew style={{ width: 24, height: 24 }} /> : <Success style={{ width: 24, height: 24 }} />;
const errorIcon = <Error style={{ width: 24, height: 24 }} />;

const GET_PROCESSES = gql`
  query ($wfId: String!, $runId: String, $returnStatusOnly: Boolean) {
    getWFStatus(wfId: $wfId, runId: $runId, returnStatusOnly: $returnStatusOnly)
  }
`;

export enum WorkflowStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  TIMED_OUT = 'TIMED_OUT',
}

type RunsPathType = (param: {
  getPathName: AvContextType['getPathName'];
  result?: string[];
  triggerIds?: string;
  failedKeys?: string[];
}) => {
  pathname?: string;
  search?: string;
};

type WFTextType = {
  num?: number;
  message?: string;
  entityName?: string;
};

const wfTexts: {
  [key in WorkflowType]: {
    success: ({ num, entityName }: WFTextType) => string;
    error: ({ num, entityName }: WFTextType) => string;
    warning?: ({ num, entityName }: WFTextType) => string;
    loading: ({ num, message, entityName }: WFTextType) => any;
    getHeader?: ({ num, entityName }: WFTextType) => string;
    action?:
      | {
          text?: string;
          result?: string[];
          urlSearchParam?: string;
          searchKey?: string;
          runsPath?: RunsPathType;
          pathName?: string;
        }
      | false;
  };
} = {
  [WorkflowType.BulkUpdateTickets]: {
    success: ({ num }) => `${num} Tickets Updated`,
    error: ({ num }) => `Failed to Update ${num} Tickets`,
    loading: () => 'Updating Tickets...',
  },
  [WorkflowType.ManualOverride]: {
    success: ({ num }) => `${num} Entities Updated`,
    error: ({ num }) => `Failed to Update ${num} Entities`,
    loading: () => 'Processing Entities...',
  },
  [WorkflowType.BulkCreateIssues]: {
    success: ({ num }) => `${num} Issues Created`,
    error: ({ num }) => `Failed to Create Issues for ${num} Tickets`,
    loading: () => 'Creating Issues...',
    action: {
      runsPath: ({ getPathName, triggerIds, failedKeys }) => ({
        pathname: getPathName(`${PAGE_PATHS.TARGETS}/activityLog/`, triggerIds, `${APP_PATHS.PLATFORM}`),
        search: failedKeys ? `?${failedKeys.map(key => `entityKey=${key}`).join('&')}` : '',
      }),
    },
  },
  [WorkflowType.SplitTicket]: {
    success: ({ num }) => `${num} Findings Split`,
    error: ({ num }) => `Failed to Split ${num} Findings`,
    loading: () => 'Splitting Findings...',
  },
  [WorkflowType.MergeTickets]: {
    success: ({ num }) => `${num} Tickets Merged`,
    error: ({ num }) => `Failed to Merge ${num} Tickets`,
    loading: () => `Merging Tickets...`,
  },
  [WorkflowType.ManualGrouping]: {
    success: ({ num, entityName }) => `From ${num} ${entityName}s`,
    error: ({ num, entityName }) => `Failed to Merge ${num} ${entityName}s`,
    loading: ({ entityName }) => `Merging ${entityName}s...`,
    getHeader: ({ entityName }) => `1 ${entityName} Created`,
  },
  [WorkflowType.ManualCreateIncident]: {
    success: () => 'Incident Ticket Created',
    error: () => 'Failed to Create Incident Ticket',
    loading: () => 'Creating Incident Ticket...',
    action: {
      urlSearchParam: `ticketCategory=${entityViewConfig.Incident.app}`,
      searchKey: 'incident._key',
      text: 'View Incident',
      pathName: PAGE_PATHS.INCIDENTS,
    },
  },
  [WorkflowType.ReprocessAggProj]: {
    success: () => 'Entities Processed Successfully',
    error: () => 'Processing Failed',
    loading: () => 'Processing Entities...',
    action: {
      runsPath: ({ getPathName, result }) => ({
        pathname: getPathName(`${PAGE_PATHS.MODEL_MANAGEMENT}/runs`),
        search: `?guidId=${result ? result[0] : ''}`,
      }),
    },
  },
  [WorkflowType.IntegrationAttachment]: {
    success: () => 'File Attached Successfully',
    error: () => 'Failed to Attach File',
    loading: ({ message }) => message,
  },
  [WorkflowType.DataDeletion]: {
    success: () => 'Deleted Successfully',
    error: () => 'Failed to Delete',
    loading: () => 'Deleting data...',
  },
  [WorkflowType.AccountProvisioning]: {
    success: () => 'Successfully Provisioned Account',
    error: () => 'Account was Partially Provisioned',
    loading: () => 'Loading Account Provisioning...',
    action: false,
  },
  [WorkflowType.AccountDeprovisioning]: {
    success: () => 'Successfully Deprovisioned Account',
    error: () => 'Account was Partially Deprovisioned',
    loading: () => 'Loading Account Deprovisioning...',
    action: false,
  },
  [WorkflowType.ExportDashboard]: {
    success: () => 'Export completed.',
    error: () => 'An error occurred. Please try again later',
    loading: () => 'Exporting dashboard. Please wait.',
    action: false,
  },
  [WorkflowType.ExportQuery]: {
    success: () => 'Export completed.',
    error: () => 'An error occurred.',
    loading: () => 'Export in progress. Please wait.',
    warning: () => 'Export completed.',
    action: false,
  },
  [WorkflowType.Default]: {
    success: num => `${num} Completed Successfully`,
    error: num => `${num || ''} Failed`,
    loading: () => 'Processing...',
  },
  [WorkflowType.RunFactorRules]: {
    success: () => `Factor Rules Run Completed Successfully`,
    error: () => `Factor Rules Run Failed`,
    loading: () => 'Processing...',
  },
  [WorkflowType.RunPolicyRules]: {
    success: () => `Policies Run Completed Successfully`,
    error: () => `Policies Run Failed`,
    loading: () => 'Processing...',
  },
  [WorkflowType.GlobalFactorsPublish]: {
    success: () => `Global Factors were Published Successfully`,
    error: () => `Global Factors Publishing Failed`,
    loading: () => 'Processing...',
  },
  [WorkflowType.DeletePolicyRules]: {
    success: () => `Policy has Been Successfully Deleted`,
    error: () => `Policy Deletion Failed`,
    loading: () => 'Loading Policy Deletion...',
  },
};
const getMessage = ({
  runId,
  wfType = WorkflowType.Default,
  type,
  total,
  result,
  errorMessage,
  runningMessage,
  entityName,
  aggregatedEntityName,
  triggerIds,
  failedKeys,
}: {
  runId: string | null;
  wfType?: WorkflowType;
  type: string;
  total?: number;
  result?: any[];
  errorMessage?: string;
  runningMessage?: string;
  entityName?: string;
  aggregatedEntityName?: string;
  triggerIds?: string[];
  failedKeys?: string[];
}) => {
  const process = wfTexts[wfType];
  const message = process[type]({ num: total, message: runningMessage, entityName });
  const header = process.getHeader?.({ num: total, entityName: aggregatedEntityName }) || message;
  return {
    id: `${runId}_${type}`,
    type,
    header: type !== 'loading' && header,
    message,
    errorMessage,
    action: process.action === false ? { result, triggerIds } : { text: 'View Ticket', result, triggerIds, failedKeys, ...process.action },
  };
};

interface AvSnackBarProps {
  runInfo: RunInfo[];
  setRunInfo: (runInfo: RunInfo, shouldDelete?: boolean) => void;
  workflowType?: WorkflowType;
  instantMessages: object[];
  setInstantMessages: any;
}
const AvSnackBar: React.FC<AvSnackBarProps> = ({ runInfo, setRunInfo, instantMessages, setInstantMessages }) => {
  const { palette } = useTheme();
  const handleError = useHandleError();
  const {
    api,
    accountEntities: { aggProjs },
  } = useAvContext();
  const [collapsed, setCollapsed] = useState(false);
  const [messageList, setMessageList] = useState<any[]>([]);

  const { isLoading } = useAvQuery({
    queryKey: ['process_status', runInfo],
    queryFn: () =>
      Promise.all(runInfo.map(({ workflowType, onSuccess, ...ri }) => api(GET_PROCESSES, { options: { ...ri } }))).then(results => {
        results.forEach(
          (
            {
              data: {
                getWFStatus: {
                  failedKeys,
                  status,
                  successKeys,
                  runningMessage,
                  result,
                  totalItems,
                  errorMessage,
                  triggerIds,
                  workflowInternalGuid,
                  warningMessage,
                },
              },
            },
            index
          ) => {
            const { runId, workflowType: wfType, projectionName = 'tickets' } = runInfo[index];
            const entityName = aggProjs[projectionName].projDisplayName;
            const aggregatedEntity = Object.values(aggProjs).find(proj => proj.aggregates.name === projectionName);
            const isDone = [WorkflowStatus.COMPLETED, WorkflowStatus.FAILED].includes(status);
            setMessageList(prev =>
              [
                isDone && (successKeys.length || result.length)
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'success',
                      total: successKeys.length,
                      result,
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                    })
                  : undefined,
                isDone && failedKeys.length
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'error',
                      total: failedKeys.length,
                      triggerIds,
                      failedKeys,
                      errorMessage,
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                    })
                  : undefined,
                status === WorkflowStatus.FAILED && !failedKeys.length
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'error',
                      total: totalItems,
                      result: [workflowInternalGuid] as any,
                      errorMessage,
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                    })
                  : undefined,
                status === WorkflowStatus.COMPLETED && !(successKeys.length || result.length)
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'success',
                      total: totalItems || '',
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                    })
                  : undefined,
                status === WorkflowStatus.COMPLETED && warningMessage
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'warning',
                      total: totalItems || '',
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                      errorMessage: warningMessage,
                    })
                  : undefined,
                status === WorkflowStatus.RUNNING
                  ? getMessage({
                      runId,
                      wfType,
                      type: 'loading',
                      total: totalItems,
                      runningMessage,
                      entityName,
                      aggregatedEntityName: aggregatedEntity?.projDisplayName,
                    })
                  : undefined,
                ...prev.filter(({ id }) => !id.startsWith(runId)),
              ].filter(d => d)
            );

            if (isDone) {
              setRunInfo(runInfo[index], true);
              runInfo[index].onSuccess?.({ result, successKeys, status });
            }

            if (status === WorkflowStatus.UNSPECIFIED) {
              setRunInfo(runInfo[index], true);
              handleError(`${runId} returned UNSPECIFIED status`);
            }
          }
        );
        return results;
      }),
    refetchInterval: 3000,
    gcTime: 0,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    enabled: !!runInfo.length,
  });

  useEffect(() => {
    if (instantMessages.length) {
      setMessageList(prev => [...prev, ...instantMessages]);
      setInstantMessages([]);
    }
  }, [instantMessages]);

  return isLoading || messageList.length ? (
    <Box
      sx={{ position: 'absolute', bottom: 90, right: 40, width: 400, zIndex: theme => theme.zIndex.snackbar }}
      onClick={e => e.stopPropagation()}>
      <Box
        sx={{
          ...flex.itemsCenter,
          gap: 1,
          height: 32,
          backgroundColor: palette.colors.primary[900],
          px: 1,
          py: 1,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          borderBottomLeftRadius: collapsed ? 12 : undefined,
          borderBottomRightRadius: collapsed ? 12 : undefined,
          boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.2)',
          '&, .MuiButton-root': {
            fontSize: 12,
            color: palette.white.main,
          },
          '.MuiButton-root:hover': {
            color: palette.colors.neutrals[400],
          },
          '> div:first-of-type': {
            height: 20,
            marginLeft: '4px',
          },
        }}>
        {messageList.find(({ type }) => type === 'loading') || isLoading ? (
          <Loading height={20} />
        ) : messageList.filter(({ type }) => type === 'error').length === 0 ? (
          successIcon
        ) : messageList.filter(({ type }) => type === 'success').length === 0 ? (
          errorIcon
        ) : (
          <Logo style={{ marginLeft: '4px' }} />
        )}
        <span style={{ flexGrow: 1 }}>
          {messageList.filter(({ type }) => type !== 'loading').length}/{messageList.length} Processes Done
        </span>
        <Button onClick={() => setMessageList([])} sx={{ px: 1 }}>
          Dismiss
        </Button>
        <AvTooltip title={collapsed ? 'Expand' : 'Collapse'}>
          <Button onClick={() => setCollapsed(!collapsed)}>{collapsed ? <Maximize /> : <Minimize />}</Button>
        </AvTooltip>
      </Box>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {[...(isLoading ? [getMessage({ runId: null, type: 'loading' })] : []), ...messageList].map(
          ({ id, type, message, errorMessage, header, action, Component = alertTypes[type] }) => (
            <Box
              key={id}
              sx={{
                ...flex.itemsStart,
                gap: 2,
                borderRadius: '2px',
                boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.2)',
                backgroundColor: palette.white.main,
                py: '12px',
                pl: 1,
                pr: 2,
                my: 1,
              }}>
              <Component message={message} header={header} failed={type === 'error'} errorMessage={errorMessage} action={action} />
              <Button onClick={() => setMessageList(messageList.filter(({ id: mid }) => mid !== id))}>
                <X />
              </Button>
            </Box>
          )
        )}
      </Collapse>
    </Box>
  ) : null;
};

interface LoadingAlertProps {
  message: string;
}

const LoadingAlert = ({ message }: LoadingAlertProps) => (
  <Box sx={{ ...flex.col, gap: 2, width: '100%', fontSize: 12, paddingLeft: 1 }}>
    {message}
    <LinearProgress sx={{ height: '6px', borderRadius: '3px' }} />
  </Box>
);

interface StatusAlertProps {
  message: string;
  header: string;
  failed: boolean;
  errorMessage?: string;
  action: {
    text?: string;
    result?: string[];
    urlSearchParam?: string;
    searchKey?: string;
    runsPath?: RunsPathType;
    triggerIds?: string;
    failedKeys?: string[];
    pathName?: string;
  };
}

function StatusAlert({
  message,
  header,
  errorMessage,
  failed,
  action: { result, text, urlSearchParam, runsPath, searchKey, triggerIds, failedKeys, pathName = PAGE_PATHS.TICKETS },
}: StatusAlertProps) {
  const {
    palette: { colors },
  } = useTheme();
  const { getPathName } = useAvContext();
  const { openZendeskWidget } = useZendesk();
  return (
    <Box sx={{ ...flex.itemsStart, gap: 1, width: '100%' }}>
      {failed ? error2Icon : success2Icon}
      <Box sx={{ ...flex.col }}>
        <Box sx={{ color: colors.neutrals[850], fontWeight: 600 }}>{header}</Box>
        <Box sx={{ fontSize: 12, mt: '1px', mb: '5px' }}>{errorMessage || message}</Box>
        <Box sx={{ ...flex.row, gap: 1 }}>
          {failed && runsPath && (result || failedKeys) && (
            <Button
              sx={{ alignSelf: 'flex-start', pl: 0, color: colors.primary[500] }}
              component={Link}
              size="xSmall"
              to={runsPath({ getPathName, result, triggerIds, failedKeys })}>
              Show Logs
            </Button>
          )}
          {failed && (
            <Button sx={{ alignSelf: 'flex-start', pl: 0, color: colors.primary[500] }} onClick={openZendeskWidget} size="xSmall">
              Contact Support
            </Button>
          )}
        </Box>
        <Box sx={{ ...flex.row, gap: 2 }}>
          {!failed && result ? (
            <Button
              sx={{ color: colors.primary[500], fontSize: 12, gap: '4px' }}
              component={Link}
              target="_blank"
              to={{
                pathname: `${getPathName(pathName)}/${result.length === 1 ? result[0] : ''}`,
                search: `?${[urlSearchParam, ...result.map(id => `${searchKey || 'ticket._key'}=${id}`)].filter(d => d).join('&')}`,
              }}>
              {text}
              {result.length > 1 ? 's' : ''}
              {text && <External style={{ width: 16, height: 16 }} />}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

const alertTypes = {
  loading: LoadingAlert,
  success: StatusAlert,
  error: StatusAlert,
  warning: StatusAlert,
};

type RunInfo = {
  runId: string;
  wfId: string;
  returnStatusOnly: boolean;
  workflowType?: WorkflowType;
  projectionName?: string;
  onSuccess?: (arg: { result: any; successKeys: any; status: any }) => unknown;
};
export const NotificationContext = React.createContext<AvSnackBarProps>({} as any);

interface AvSnackBarProviderProps {
  children: React.ReactElement;
}
export default function AvSnackBarProvider({ children }: AvSnackBarProviderProps) {
  const [runInfo, setRunInfoDirect] = useState<RunInfo[]>([]);
  const [instantMessages, setInstantMessages] = useState([]);

  const setRunInfo = (runInfo, shouldDelete) =>
    setRunInfoDirect(prev => (shouldDelete ? prev.filter(item => item !== runInfo) : [...prev, runInfo]));
  const value = useMemo(() => ({ runInfo, setRunInfo, instantMessages, setInstantMessages }), [runInfo, instantMessages]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <AvSnackBar runInfo={runInfo} setRunInfo={setRunInfo} instantMessages={instantMessages} setInstantMessages={setInstantMessages} />
    </NotificationContext.Provider>
  );
}
