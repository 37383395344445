import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import AvLegend from '../../../../components/AvLegend';
import { ChartClickInteraction } from '../../../../components/Widgets/types';
import AvPie from '../../../../components/Widgets/VisualizationWidgets/AvPie';
import { useCustomSearchParams } from '../../../../hooks/UseCustomSearchParams';
import { rebranding } from '../../../../rebranding';
import {
  isValidSeverity,
  severityBackgroundColorOrUndefined,
  SeverityLabels,
  useFactorSeverityByScore,
} from '../../../../utils/severity.utils';
import { generateInnerDefaultFormatter } from '../../../../utils/Utils';
import { FormatterTypes, NO_VALUE } from '../../constants';
import { hasTimeBucket } from '../../EditCustomDashboard/TimeBucketBreakdown/utils';
import { useCustomPalette, useGetDisplayName, useGetFieldType } from '../../hooks';
import {
  CommonWidgetProps,
  DonutTypeWidget,
  PieBasicLegendPosition,
  PieCategoryWidgetTypes,
  PieLegendStyles,
  PieSubType,
} from '../../types';
import { defaultDrillDownHierarchy, fieldFormatter } from '../../Utils';

interface PieWidgetProps extends CommonWidgetProps {
  widget: PieCategoryWidgetTypes;
  clickInteractions?: ChartClickInteraction[];
  customPalette?: string[];
}

const PieWidget: React.FC<PieWidgetProps> = ({
  widget,
  data = [],
  responseTotals,
  isLoading,
  selected = [],
  onSelect,
  clickInteractions,
  customPalette,
}) => {
  const theme = useTheme();
  const [filters] = useCustomSearchParams({ shouldBeArray: () => true });
  const {
    drillDownHierarchy = defaultDrillDownHierarchy,
    requests: [
      {
        select: { dims, metrics },
        projectionId: { name: projectionIdName },
      },
    ],
  } = widget;

  const { activeIndex } = drillDownHierarchy;
  const dim = activeIndex !== 0 ? drillDownHierarchy.fields[activeIndex - 1]?.name : dims?.[0]?.name;
  const metric = metrics?.[0]?.alias || metrics[0]?.name;
  const metricDisplayName = useGetDisplayName(projectionIdName)(metric);

  const shouldDisplayNull = true;

  const getHeadCellType = useGetFieldType(projectionIdName);
  const parsedData = useMemo(
    () =>
      widget.type === PieSubType.PieWithNeedle
        ? [
            { [dim]: 'Low', [metric]: 25 },
            { [dim]: 'Medium', [metric]: 25 },
            { [dim]: 'High', [metric]: 25 },
            { [dim]: 'Critical', [metric]: 25 },
          ]
        : data?.map(datum => ({
            ...datum,
            [metricDisplayName]: datum[metric],
            [dim]: widget.requests[0].timeRange
              ? generateInnerDefaultFormatter({
                  value: datum[dim],
                  type: hasTimeBucket(dim) ? FormatterTypes.historicDate : getHeadCellType({ entityFieldKey: dim }),
                  fieldName: dim,
                  shouldDisplayNull,
                })
              : [null, 'null', ''].includes(datum[dim])
                ? generateInnerDefaultFormatter({
                    value: datum[dim],
                    type: getHeadCellType({ entityFieldKey: dim }),
                    shouldDisplayNull,
                  })
                : datum[dim],
          })),
    [data, activeIndex]
  );
  const defaultPalette = useCustomPalette(data);
  const gaugeCustomPalette = rebranding
    ? [
        severityBackgroundColorOrUndefined(SeverityLabels.Critical, theme),
        severityBackgroundColorOrUndefined(SeverityLabels.High, theme),
        severityBackgroundColorOrUndefined(SeverityLabels.Medium, theme),
        severityBackgroundColorOrUndefined(SeverityLabels.Low, theme),
      ]
    : [
        theme.palette.colors.negative[400],
        theme.palette.colors.orange[400],
        theme.palette.colors.yellow[400],
        theme.palette.colors.blue[400],
      ];
  const finalCustomPalette = customPalette || (widget.type === PieSubType.PieWithNeedle ? gaugeCustomPalette : defaultPalette);
  const factorSeverityByScore = useFactorSeverityByScore();
  const commonProps = {
    data: parsedData,
    customPalette: finalCustomPalette,
    severitySetting: widget.type === PieSubType.PieWithNeedle ? factorSeverityByScore : undefined,
    legendProps: {
      showValue:
        [PieLegendStyles.Table, PieLegendStyles.Aside].includes(widget.definition.custom.legend.style) &&
        widget.definition.custom.legend.showValues,
      showShare:
        [PieLegendStyles.Table, PieLegendStyles.Aside].includes(widget.definition.custom.legend.style) &&
        widget.definition.custom.legend.showPercentage,
      variant: 'static',
      metricNameFormatter: v => fieldFormatter(v, getHeadCellType({ entityFieldKey: dim })),
      maxMetricWidth: 110,
    } as const,
    ...(widget.type === PieSubType.Donut && { showTotals: !!(widget as DonutTypeWidget).definition.custom.showTotals }),
    responseTotals: responseTotals?.[metric],
    showTable: widget.definition.custom.legend.style === PieLegendStyles.Table,
    showLabels: widget.definition.custom.legend.style === PieLegendStyles.Aside,
    className: 'draggableCancel',
    sx: {
      justifyContent: 'center',
      ...(widget.definition.custom.legend.style !== PieLegendStyles.Basic && { py: 1.5 }),
      ...(widget.type === PieSubType.PieWithNeedle && { pt: 7.5 }),
    },
    onSelect,
    selected: selected.map(v => ({
      ...v,
      index: parsedData?.findIndex(
        d =>
          ([null, 'null'].includes(d[dim])
            ? null
            : typeof d[dim] === 'object' && !Array.isArray(d[dim])
              ? JSON.stringify(d[dim])
              : d[dim]?.toString()) === v.value
      ),
    })),
    clickInteractions,
  };

  const customPieProps = {
    metricKey: dim,
    valKey: metric,
    valLabel: metricDisplayName,
    tooltipFields: [metricDisplayName, 'share'],
  };

  const pieTypeWidget = useMemo(
    () => renderPieWidget(widget, defaultPalette, { ...commonProps, ...customPieProps }),
    [data, defaultPalette, widget, isLoading, filters, activeIndex]
  );

  const legends = data && (
    <Box
      sx={{
        pb:
          widget.definition.custom.legend.style === PieLegendStyles.Basic &&
          widget.definition.custom.legend.position === PieBasicLegendPosition.Bottom
            ? 2
            : 1,
        pt: '10px',
        cursor: 'default',
      }}
      className="draggableCancel">
      <AvLegend
        series={
          data.map((datum, i) => ({
            ...datum,
            color: isValidSeverity(datum[dim]) ? severityBackgroundColorOrUndefined(datum[dim], theme) : finalCustomPalette[i],
            ...(!dim && metric && { component: <Box>{metricDisplayName}</Box> }),
            ...(dim && {
              component: <Box>{datum[dim] === null ? NO_VALUE : datum[dim]}</Box>,
            }),
          })) as any
        }
        isHorizontal
        nameKey={dim}
      />
    </Box>
  );

  return (
    data && (
      <>
        {widget.definition.custom.legend.style === PieLegendStyles.Basic &&
          widget.definition.custom.legend.position === PieBasicLegendPosition.Top &&
          legends}
        {pieTypeWidget[widget.type]()}
        {widget.definition.custom.legend.style === PieLegendStyles.Basic &&
          widget.definition.custom.legend.position === PieBasicLegendPosition.Bottom &&
          legends}
      </>
    )
  );
};

export default PieWidget;

const renderPieWidget = (widget, defaultColors, commonProps) => ({
  [PieSubType.Pie]: () => <AvPie {...commonProps} />,
  [PieSubType.Donut]: () => <AvPie {...commonProps} type={PieSubType.Donut} />,
  [PieSubType.PieWithNeedle]: () => <AvPie {...commonProps} type={PieSubType.PieWithNeedle} />,
});
