import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import DashboardAssetCoveragePage from '../../views/AssetsCoverage/DashboardAssetCoveragePage';
import DashboardOverviewPage from '../../views/DashboardOverviewPage';
import DashboardROIPage from '../../views/DashboardROIPage';
import DashboardRemediationPage from '../../views/Remediation/DashboardRemediationPage';
import DashboardRiskPage from '../../views/Risk/DashboardRiskPage';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import ExploreRoute from '../ExploreRoute';
import GeneralEntityRoute from '../GeneralEntityRoute';
import ReportsRoute from '../ReportsRoute';
import VulnerabilitiesSettings from '../VulnerabilitiesSettings';

const Vulnerabilities = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const appPath = APP_PATHS.VULNERABILITIES;
  return (
    <Route path={appPath}>
      {GeneralEntityRoute(entityViewConfig.Ticket.projectionName)}
      {GeneralEntityRoute(entityViewConfig.Asset.projectionName)}
      {GeneralEntityRoute(entityViewConfig.Finding.projectionName)}
      {GeneralEntityRoute(entityViewConfig.Exception.projectionName)}
      {VulnerabilitiesSettings()}
      {ExploreRoute(entityViewConfig.Ticket.projectionName, APP_PATHS.VULNERABILITIES)}
      <Route
        path={PAGE_PATHS.REMEDIATION}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.REMEDIATION })}>
            <DashboardRemediationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PAGE_PATHS.RISK}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.RISK })}>
            <DashboardRiskPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PAGE_PATHS.ASSET_COVERAGE}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.ASSET_COVERAGE })}>
            <DashboardAssetCoveragePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PAGE_PATHS.ROI}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.ROI })}>
            <DashboardROIPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={PAGE_PATHS.OVERVIEW}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.OVERVIEW })}>
            <DashboardOverviewPage />
          </ProtectedRoute>
        }
      />
      {ReportsRoute()}
      {CustomDashboardsRoute()}
    </Route>
  );
};
export default Vulnerabilities;
