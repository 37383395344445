import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Grow, IconButton, Paper, Popper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/system';
import { dotStyle, iconSize } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { ReactComponent as More } from '../assets/More.svg';

const moreIcon = (
  <More
    style={{
      ...iconSize(16),
      transform: `rotate(90deg)`,
      fill: '#7E8095',
    }}
  />
);

const boxStyle = (theme, isHorizontal, isOutlined) => ({
  display: 'flex',
  flexDirection: isHorizontal ? 'row' : 'column',
  columnGap: isOutlined ? 1 : 1.75,
  rowGap: isHorizontal ? '2px' : 2,
  flexWrap: 'wrap',
  color: theme.palette.colors.neutrals[500],
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'row',
  },
  h5: {
    color: theme.palette.colors.neutrals[800],
    paddingLeft: 3,
  },
});

const outlinedStyle = {
  px: 2,
  py: '2px',
  border: theme => `1px solid ${theme.palette.colors.neutrals[400]}`,
  color: theme => theme.palette.colors.neutrals[800],
  fontWeight: 600,
  borderRadius: '8px',
  lineHeight: 1.5,
};

interface AvLegendProps {
  series: ISeries[];
  nameKey?: string;
  isHorizontal?: boolean;
  isOutlined?: boolean;
}

interface ISeries {
  name: string;
  component?: ReactElement | string;
  color?: string;
  value?: number;
  shape?: ReactElement;
}

function calculateMaxLegendsToShow(mainDivWidth, legendTexts, paddingPerItem = 5) {
  const maxRowsWithLegends = 2;
  const paddingOnLastRowForEllipsis = 80;
  const legendWidths = legendTexts.map(text => getTextWidth(text));
  const { index } = legendWidths.reduce(
    (acc, width, currentIndex) => {
      const sumWidth = acc.sumWidth + width + paddingPerItem;
      return {
        index: sumWidth <= maxRowsWithLegends * mainDivWidth - paddingOnLastRowForEllipsis ? currentIndex + 1 : acc.index,
        sumWidth,
      };
    },
    { index: 0, sumWidth: 0 }
  );

  return index + 1;
}

function getTextWidth(text) {
  const widthOfDotIncludingPadding = 26;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  if (!context) {
    return 40;
  }
  context.font = '12px Inter';
  return context.measureText(text).width + widthOfDotIncludingPadding;
}

const AvLegend: React.FC<AvLegendProps> = ({ series, nameKey = 'name', isHorizontal = false, isOutlined = false }) => {
  const mainDivRef = useRef(null);
  const [mainDivWidth, setMainDivWidth] = useState(100);
  const [anchorEl, setAnchorEl] = useState<Element>();

  const disableEllipsis = isOutlined || !isHorizontal;
  useEffect(() => {
    if (disableEllipsis) {
      return undefined;
    }
    const observer = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      setMainDivWidth(width);
    });

    if (mainDivRef.current) {
      observer.observe(mainDivRef.current);
    }

    return () => {
      if (mainDivRef.current) {
        observer.unobserve(mainDivRef.current);
      }
    };
  }, []);

  const handleHoverEllipses = ({ currentTarget }) => setAnchorEl(anchorEl ? undefined : currentTarget);

  const legendTexts = series.map(item => {
    if (nameKey in item && typeof item[nameKey] === 'string') {
      return item[nameKey];
    }
    return '';
  });
  const maxLegendsToShow = useMemo(
    () => (disableEllipsis ? series.length : calculateMaxLegendsToShow(mainDivWidth, legendTexts)),
    [disableEllipsis, series, mainDivWidth, legendTexts]
  );

  const visibleLegends = series.slice(0, maxLegendsToShow);
  const hiddenLegends = series.slice(maxLegendsToShow);

  const theme = useTheme();
  return (
    <Box sx={theme => boxStyle(theme, isHorizontal, isOutlined)} ref={mainDivRef}>
      {visibleLegends.map(item => {
        if (!item[nameKey]) {
          return null;
        }
        const { color, value, component, shape } = item;
        return (
          <div key={item[nameKey]}>
            <Box sx={{ ...flex.itemsBaseline, gap: '6px', ...(isOutlined ? outlinedStyle : {}) }}>
              {color && (shape || <div style={{ ...dotStyle(color), flexShrink: 0 }} />)}
              <Box
                sx={theme => ({
                  color: theme.palette.colors.neutrals[isOutlined ? 800 : 550],
                  fontSize: 12,
                })}>
                {component || item[nameKey]}
              </Box>
            </Box>
            {value ? <Typography variant="h5">{value}</Typography> : null}
          </div>
        );
      })}
      {Boolean(hiddenLegends.length) && (
        <Box onMouseEnter={handleHoverEllipses} onMouseLeave={handleHoverEllipses}>
          {/* @ts-ignore */}
          <IconButton active={Boolean(anchorEl)} size="small" sx={{ pb: 2 }}>
            {moreIcon}
          </IconButton>
          <Popper
            open={Boolean(anchorEl)}
            placement="bottom-start"
            anchorEl={anchorEl}
            transition
            sx={{
              zIndex: ({ zIndex }) => zIndex.modal,
              boxShadow: '0px 6px 20px 0px #00000033',
            }}>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                <Paper
                  sx={{
                    ...flex.col,
                    width: 275,
                    boxShadow: 'none',
                    maxHeight: '300px',
                    overflow: 'auto',
                    py: 1,
                  }}>
                  {hiddenLegends.map(item => {
                    const { color, value, component, shape } = item;
                    return !item[nameKey] ? null : (
                      <Box
                        key={item[nameKey]}
                        sx={{
                          ...flex.row,
                          alignItems: 'center',
                          mx: 1,
                          py: 0.5,
                          ':hover': { backgroundColor: theme.palette.colors.neutrals[200] },
                        }}>
                        <Box sx={{ ...flex.itemsCenter, gap: 1.5, minHeight: '24px' }}>
                          {color &&
                            (shape || (
                              <div
                                style={{
                                  ...dotStyle(color),
                                  flexShrink: 0,
                                  marginLeft: '8px',
                                }}
                              />
                            ))}
                          <Box
                            sx={theme => ({
                              color: theme.palette.colors.neutrals[800],
                              fontWeight: 400,
                              fontSize: 12,
                            })}>
                            {component || item[nameKey]}
                          </Box>
                        </Box>
                        {value ? <Typography variant="h5">{value}</Typography> : null}
                      </Box>
                    );
                  })}
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};

export default AvLegend;
