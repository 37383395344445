import React from 'react';
import { Box, capitalize, Divider, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { rebranding } from '../../rebranding';
import { severityBackgroundColor } from '../../utils/severity.utils';
import { abbreviateNumber, dotStyle } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';

interface IStackedBarTooltip {
  payload: Payload<ValueType, NameType>[] | undefined;
  hiddenMetrics: (string | number)[];
}

const StackedBarTooltip: React.FC<IStackedBarTooltip> = ({ payload, hiddenMetrics }) => {
  const date = payload?.[0]?.payload?.date || new Date().toISOString();
  const theme = useTheme();
  const renderSeverities = ({ severity, value }) => (
    <Box key={severity} sx={{ ...flex.justifyBetween, gap: 3 }}>
      <Box sx={flex.itemsCenter}>
        <Box
          sx={{
            ...dotStyle(severityBackgroundColor(severity, theme, true)),
            marginRight: 1,
            ...(rebranding && { border: theme => `1px solid ${theme.palette.colors.neutrals[300]}` }),
          }}
        />
        <Box
          sx={{
            color: theme.palette.colors.neutrals[350],
            fontSize: 13,
          }}>
          {capitalize(severity.toLowerCase())} Findings
        </Box>
      </Box>
      <Box sx={{ fontWeight: 600, fontSize: 12 }}>{abbreviateNumber(value)?.toLocaleString()}</Box>
    </Box>
  );

  const renderContent = ({ metric, value, severity }) => {
    const formattedValue = abbreviateNumber(value)?.toLocaleString();
    return (
      <Box key={metric} sx={{ ...flex.justifyBetween, gap: 3 }}>
        <Box sx={flex.itemsCenter}>
          <Box sx={{ color: theme.palette.colors.neutrals[350], fontSize: 13 }}>{metric}</Box>
        </Box>
        {severity ? (
          <Box sx={{ ...flex.row, fontWeight: 600, color: severityBackgroundColor(severity, theme, true) }}>
            {formattedValue} {capitalize(severity.toLowerCase())}
          </Box>
        ) : (
          <Box sx={{ fontWeight: 600, fontSize: 12 }}>{formattedValue}</Box>
        )}
      </Box>
    );
  };

  const currentPayload = payload?.[0]?.payload;
  const severities: { severity: string; value: string | number }[] = Object.keys(currentPayload || {})
    .filter(key => ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'].includes(key))
    .map(key => ({ severity: key, value: currentPayload[key] })) as { severity: string; value: string | number }[];

  const metrics: { metric: string; value: string | number; severity: string }[] = (payload || [])
    .map(
      item =>
        !hiddenMetrics.includes(item.dataKey || '') && {
          metric: item.name || capitalize((item.dataKey || '') as string).toLowerCase(),
          value: item.payload[item.dataKey || ''],
        }
    )
    .filter(Boolean) as { metric: string; value: string | number; severity: string }[];

  const divider = <Divider sx={{ borderColor: theme => theme.palette.colors.neutrals[650] }} />;
  return (
    <Box
      sx={{
        ...flex.col,
        gap: 0.5,
        px: 1.5,
        py: 1.2,
        background: theme => theme.palette.colors.neutrals[850],
        borderRadius: 2.5,
        color: theme => theme.palette.colors.neutrals[100],
      }}>
      <Box sx={{ ...flex.itemsCenter, fontWeight: 600, mb: 0.5 }}>{format(new Date(date), 'MMMM yyyy')}</Box>
      {!!severities.length && divider}
      {severities.map(renderSeverities)}
      {divider}
      {metrics.map(renderContent)}
    </Box>
  );
};

export default StackedBarTooltip;
