import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';

const FormattingRules = avLazy(() => import('../views/FormattingRules/FormattingRules'));

const FormattingRulesRoute = () => {
  const path = PAGE_PATHS.FORMATTING_RULES;
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();

  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <FormattingRules />
          </ProtectedRoute>
        }
      />

      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <FormattingRules mode={1} />
          </ProtectedRoute>
        }
      />

      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <FormattingRules mode={2} />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default FormattingRulesRoute;
