import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { iconSize } from '../../utils/Utils';
import AvDialog from '../AvDialog';
import { flex } from '../AvThemeProvider';
import TextInput from '../TextInput';
import { DeleteProps } from './types';
import { ReactComponent as Delete } from '../../assets/Delete.svg';

const deleteIcon = size => <Delete style={iconSize(size)} />;

interface DeleteButtonWithDialogProps {
  selected: (string | number)[];
  handleSelected?: (v) => void;
  button: any;
  deleteProps: DeleteProps;
}

const DeleteButtonWithDialog: React.FC<DeleteButtonWithDialogProps> = ({ button, selected, handleSelected, deleteProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleCloseAndDelete = () => {
    deleteProps.onDelete?.(selected.join(','));
    handleClose();
    handleSelected?.([]);
  };

  const isDeleteDisabled = deleteProps.isConfirmDeletion && inputValue !== deleteProps.message;
  const styledMessage = <span style={{ fontWeight: 600 }}>{deleteProps.message}</span>;
  const text = deleteProps.isConfirmDeletion ? (
    <>Please type {styledMessage} to confirm</>
  ) : (
    <>Are you sure you want to delete {styledMessage}?</>
  );
  const dialogTitle = deleteProps.title ? `${deleteProps.title}${selected.length > 1 ? 's' : ''}?` : 'Confirm Deletion';
  return (
    <>
      <AvDialog
        open={isOpen}
        onClose={handleClose}
        title={dialogTitle}
        titleVariant="error"
        noPadding
        footer={
          <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="error" onClick={handleCloseAndDelete} disabled={isDeleteDisabled}>
              {deleteIcon(20)}
              <span>Delete</span>
            </Button>
          </Box>
        }>
        <Box sx={{ px: 3, ...flex.col, gap: 3 }}>
          <Box sx={{ userSelect: 'none' }}>{text}</Box>
          {deleteProps.isConfirmDeletion && (
            <TextInput
              sx={{ width: '75%', maxWidth: 'unset' }}
              isRequired
              onChange={setInputValue}
              value={inputValue}
              placeholder="Type the text above"
            />
          )}
        </Box>
      </AvDialog>
      {button(handleOpen)}
    </>
  );
};

export default DeleteButtonWithDialog;
