import React, { useState } from 'react';
import { Button, ClickAwayListener, Divider, Grow, IconButton, Paper, Popper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import AvCheckbox from '../../components/AvCheckbox.tsx';
import { flex } from '../../components/AvThemeProvider.tsx';
import TextInput from '../../components/TextInput.tsx';
import { noop } from '../../utils/Utils';
import { ReactComponent as X } from '../../assets/X.svg';

const clearIcon = <X />;

export default function InputPopper({
  anchorEl,
  showOptionBody,
  onClose,
  onApply,
  texts,
  checkboxesOptions,
  defaultValue,
  sx,
  placement: { popper, transformOrigin },
}) {
  const [value, setValue] = useState(defaultValue);

  const setInput = val => setValue({ ...value, input: val });
  const setCheckboxes = (name, val) => setValue({ ...value, checkboxes: { ...value.checkboxes, [name]: val } });

  const apply = () => {
    onApply(value);
    onClose();
  };

  return (
    <Popper
      open={showOptionBody}
      placement={popper}
      anchorEl={anchorEl}
      transition
      sx={{ width: 360, zIndex: ({ zIndex }) => zIndex.modal, borderRadius: '2px', ...sx }}>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: transformOrigin(placement) }}>
          <Paper sx={{ ...flex.col }}>
            <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
              <Box>
                <Box sx={{ padding: 3, ...flex.col, gap: 2 }}>
                  {texts.header && (
                    <>
                      <IconButton sx={{ position: 'absolute', right: 22, top: 22 }} onClick={onClose}>
                        {clearIcon}
                      </IconButton>
                      <Typography sx={{ fontWeight: 600 }}>{texts.header}</Typography>
                    </>
                  )}
                  <TextInput
                    label={texts.input}
                    value={value?.input}
                    size="small"
                    onChange={setInput}
                    sx={{ maxWidth: 'inherit' }}
                    autoFocus
                    onFocus={e => e.stopPropagation()}
                  />
                  {value.checkboxes &&
                    checkboxesOptions &&
                    checkboxesOptions.map(({ value: checkboxValue, title }) => (
                      <AvCheckbox
                        key={checkboxValue}
                        label={title}
                        value={value.checkboxes[checkboxValue]}
                        onChange={val => setCheckboxes(checkboxValue, val)}
                      />
                    ))}
                </Box>
                <Divider />
                <Box sx={{ ...flex.justifyEnd, gap: 3, padding: '12px 24px' }}>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button onClick={apply} disabled={!value?.input?.length} variant="contained" size="small">
                    {texts.button}
                  </Button>
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

InputPopper.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  showOptionBody: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func.isRequired,
  texts: PropTypes.shape({
    header: PropTypes.string,
    input: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    button: PropTypes.string,
  }).isRequired,
  checkboxesOptions: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, value: PropTypes.string })),
  defaultValue: PropTypes.shape(),
  sx: PropTypes.shape(),
  placement: PropTypes.shape({
    popper: PropTypes.string,
    transformOrigin: PropTypes.func,
  }),
};

InputPopper.defaultProps = {
  anchorEl: null,
  showOptionBody: true,
  onClose: noop,
  defaultValue: { checkboxes: [] },
  checkboxesOptions: [],
  sx: {},
  placement: {
    transformOrigin: placement => (placement === 'bottom' ? 'center top' : 'center bottom'),
  },
};
