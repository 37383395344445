import React from 'react';
import { Box, Button, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { useAvContext } from '../context/AvContextProvider';
import { rebranding } from '../rebranding';
import { FeatureFlags } from '../types';
import { FieldType } from '../views/CustomDashboards/types/types';
import AvMenu from './AvMenu';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import { ReactComponent as FilterActive } from '../assets/colorful/FilterActive.svg';
import { ReactComponent as FilterActiveNew } from '../assets/colorful2/FilterActive.svg';
import { ReactComponent as Dimension } from '../assets/Dimension.svg';
import { ReactComponent as Filter } from '../assets/Filter.svg';
import { ReactComponent as Measurements } from '../assets/Measurements.svg';

const filterIcon = <Filter style={{ width: 20, height: 20 }} />;
const filterActiveIcon = <FilterActive style={{ width: 20, height: 20 }} />;
const filterActiveNewIcon = <FilterActiveNew style={{ width: 20, height: 20 }} />;

export enum ButtonTypes {
  Measurements = 'Measurements',
  Dimensions = 'Dimensions',
  All = 'All',
  InUse = 'InUse',
}

const INCLUDE_EMPTY_FIELDS = 'Include Empty Fields';
const includeUnpopulatedFieldsOption = { title: INCLUDE_EMPTY_FIELDS };

interface Props {
  activeFilterType: FieldType;
  topFields: {
    isTopFieldsFilterActive: boolean;
    filterByTopFields: VoidFunction;
  };
  inUse?: {
    isInUseFilterActive: boolean;
    filterByUsedFields: VoidFunction;
  };
  unpopulated: {
    isUnpopulatedFieldsActive: boolean;
    filterByUnpopulatedFields: VoidFunction;
  };
  onClickFilterType: (filterType: FieldType) => void;
  hiddenButtons?: Set<ButtonTypes>;
  maxWidth?: string;
}

export default function FiltersListToolbar({
  activeFilterType,
  onClickFilterType,
  topFields: { isTopFieldsFilterActive, filterByTopFields },
  inUse,
  unpopulated: { isUnpopulatedFieldsActive, filterByUnpopulatedFields },
  hiddenButtons = new Set([ButtonTypes.InUse]),
  maxWidth = '300px',
}: Props) {
  const theme = useTheme();
  const { featureFlags } = useAvContext();

  const menuOnChange = () => {
    filterByUnpopulatedFields();
  };

  return (
    <Box
      sx={{
        ...flex.justifyBetweenCenter,
        mt: 1,
        color: isTopFieldsFilterActive ? theme.palette.colors.neutrals[800] : theme.palette.colors.neutrals[600],
        maxWidth,
      }}>
      <Box sx={{ ...flex.center, gap: '7px' }}>
        {isTopFieldsFilterActive ? (rebranding ? filterActiveNewIcon : filterActiveIcon) : filterIcon}

        <Button
          size="xSmall"
          variant="filter"
          onClick={filterByTopFields}
          className={isTopFieldsFilterActive ? 'active-filter' : undefined}>
          Top Fields
        </Button>

        {!hiddenButtons.has(ButtonTypes.InUse) && (
          <Button
            size="xSmall"
            variant="filter"
            onClick={inUse?.filterByUsedFields}
            className={inUse?.isInUseFilterActive ? 'active-filter' : undefined}>
            In Use
          </Button>
        )}
      </Box>

      <Box sx={{ ...flex.row, gap: 1 / 2 }}>
        <ToggleButtonGroup
          color="white"
          size="xSmall"
          value={activeFilterType}
          onChange={(event, fieldType) => onClickFilterType(fieldType)}
          exclusive>
          {!hiddenButtons.has(ButtonTypes.All) && (
            <ToggleButton value={FieldType.All}>
              <AvTooltip title="All">
                <Box sx={{ ...flex.col, fontSize: 12, lineHeight: 1 }}>All</Box>
              </AvTooltip>
            </ToggleButton>
          )}
          {!hiddenButtons.has(ButtonTypes.Measurements) && (
            <ToggleButton value={FieldType.Measurements}>
              <AvTooltip title="Measurements">
                <Box sx={flex.col}>
                  <Measurements />
                </Box>
              </AvTooltip>
            </ToggleButton>
          )}
          {!hiddenButtons.has(ButtonTypes.Dimensions) && (
            <ToggleButton value={FieldType.Dimensions}>
              <AvTooltip title="Dimensions">
                <Box sx={flex.col}>
                  <Dimension />
                </Box>
              </AvTooltip>
            </ToggleButton>
          )}
        </ToggleButtonGroup>

        {featureFlags[FeatureFlags.PopulatedFields] && (
          <AvMenu
            options={[includeUnpopulatedFieldsOption]}
            isMultiple
            value={isUnpopulatedFieldsActive ? [INCLUDE_EMPTY_FIELDS] : []}
            onChange={menuOnChange}
            showSelection={false}
            sx={{ padding: 0 }}
          />
        )}
      </Box>
    </Box>
  );
}
