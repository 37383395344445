import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { FeatureFlags } from '../../types';
import AvLegend from '../AvLegend';
import { IconVariant, ItemWithLogo } from '../ItemWithLogo';

const sourcesStyle = ShowAssetsDashboardOverview => ({
  display: 'grid',
  gridTemplateColumns: ShowAssetsDashboardOverview ? '2fr 1fr 1fr' : '2fr 1fr',
  columnGap: 3,
  rowGap: '20px',
  alignItems: 'center',
  fontWeight: 600,
  '.bar-small': {
    marginTop: '6px',
    height: 6,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
});
const getWidth = (a, b) => (a === 0 ? 0 : Math.max(1, (a / b) * 100));

interface SourcesStatusProps {
  data?: any[];
}

const SourcesStatus: React.FC<SourcesStatusProps> = ({ data = [] }) => {
  const {
    palette: { colors },
  } = useTheme();
  const { featureFlags } = useAvContext();
  const ShowAssetsDashboardOverview = featureFlags[FeatureFlags.ShowAssetsDashboardOverview];
  const maxFindings = Math.max(...data.map(({ findingCount }) => findingCount));
  const maxAssets = Math.max(...data.map(({ assetCount }) => assetCount));

  const colorPalette = [
    {
      color: colors.primary[500],
      background: `linear-gradient(270deg, ${colors.primary[500]} 5.56%, ${colors.primary[300]} 100%)`,
    },
    {
      color: colors.yellow[500],
      background: `linear-gradient(90deg, ${colors.yellow[300]} 0%, ${colors.yellow[500]} 100%)`,
    },
  ];

  return (
    <Box sx={sourcesStyle(ShowAssetsDashboardOverview)}>
      <span />
      <AvLegend isHorizontal series={[{ name: 'Findings', color: colorPalette[0].color }]} />
      {ShowAssetsDashboardOverview && <AvLegend isHorizontal series={[{ name: 'Assets', color: colorPalette[1].color }]} />}
      {data.map(({ name, findingCount, assetCount }) => (
        <React.Fragment key={name}>
          <Box sx={{ '~ span': { fontSize: 13, paddingTop: '14px' } }}>
            <ItemWithLogo type={name} variant={IconVariant.sourcesMapByName} logoWidth={33} />
          </Box>
          <span>
            {findingCount.toLocaleString()}
            <div
              className="bar-small"
              style={{
                width: getWidth(findingCount, maxFindings),
                background: colorPalette[0].background,
              }}
            />
          </span>
          {ShowAssetsDashboardOverview && (
            <span>
              {assetCount.toLocaleString()}
              <div
                className="bar-small"
                style={{
                  width: getWidth(assetCount, maxAssets),
                  background: colorPalette[1].background,
                }}
              />
            </span>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SourcesStatus;
