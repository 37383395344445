import React, { FC, useContext } from 'react';
import { Box, Divider, Menu, MenuItem } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import { useExportQueryObjectWorkflow } from '../../../hooks/useExportQueryWorklflow';
import { Filter } from '../../../types/filter.types';
import { cleanEmptyFilters } from '../../../utils/filterUtils';
import { CustomDashboardContext } from '../CustomDashboardContext';
import {
  AllTypeWidgets,
  BarTypeWidget,
  CustomDashboardDto,
  DisplayModeOptions,
  DwQueryRequest,
  TextSubType,
  TileSubType,
  WidgetCategory,
} from '../types';
import { generateTempIdForWidget } from '../Utils';
import { ReactComponent as Delete } from '../../../assets/Delete.svg';
import { ReactComponent as Duplicate } from '../../../assets/Duplicate.svg';
import { ReactComponent as Export } from '../../../assets/Export.svg';
import { ReactComponent as FitWidget } from '../../../assets/FitWidget.svg';
import { ReactComponent as Maximize } from '../../../assets/MaximizeFullScreen.svg';
import { ReactComponent as Minimize } from '../../../assets/MinimizeFullScreen.svg';
import { ReactComponent as Script } from '../../../assets/Script.svg';

const maximizeIcon = <Maximize />;
const minimizeIcon = <Minimize />;

interface EditDashboardDetailsPopperProps {
  anchorEl: Element;
  closeMenu: () => void;
  widget: AllTypeWidgets;
  globalFilters?: Filter;
  isEditMode?: boolean;
  handleExpand: () => void;
}

const EditDashboardDetailsPopper: FC<EditDashboardDetailsPopperProps> = ({
  anchorEl,
  widget,
  isEditMode,
  closeMenu,
  globalFilters,
  handleExpand,
}) => {
  const { setNewDashboard, expandedWidgetId } = useContext(CustomDashboardContext);
  const { mutate: exportQueryObjectWorkflow } = useExportQueryObjectWorkflow();
  const isMaximized = expandedWidgetId === widget.id;
  const isScroll = (widget as BarTypeWidget).definition.custom?.displayMode === DisplayModeOptions.SCROLL;

  const deleteWidget = () => {
    closeMenu();
    setNewDashboard(prev => ({ ...prev, widgets: prev.widgets.filter(w => w.id !== widget.id) }));
  };
  const duplicateWidget = () => {
    closeMenu();
    setNewDashboard(
      prev =>
        ({
          ...prev,
          widgets: [
            ...prev.widgets,
            {
              ...widget,
              id: generateTempIdForWidget(),
              definition: {
                ...widget.definition,
                coords: {
                  ...widget.definition.coords,
                  y: widget.definition.coords.y + 1,
                },
              },
            },
          ],
        }) as CustomDashboardDto
    );
  };

  const setDisplayMode = () => {
    setNewDashboard(prev => ({
      ...prev,
      widgets: prev.widgets.map(w => {
        if (w.id === widget.id) {
          return {
            ...(w as BarTypeWidget),
            definition: {
              ...w.definition,
              custom: {
                ...(w as BarTypeWidget).definition.custom,
                displayMode: isScroll ? DisplayModeOptions.FIT : DisplayModeOptions.SCROLL,
              },
            },
          };
        }
        return w;
      }),
    }));
  };

  const query: DwQueryRequest = {
    ...widget.requests[0],
    top: widget.requests[0].top ? { ...widget.requests[0].top, size: 40000, offset: 0 } : undefined,
  };

  return (
    <Menu
      className="draggableCancel"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        pointerEvents: 'none',
        '.MuiMenu-paper': {
          pointerEvents: 'auto',
          minWidth: 200,
        },
        '.MuiMenuItem-root': {
          paddingLeft: 1,
          paddingRight: 1,
          minHeight: 36,
          svg: {
            width: 18,
          },
        },
      }}
      open={Boolean(anchorEl)}>
      {widget.category !== WidgetCategory.Text && (
        <MenuItem onClick={handleExpand}>
          <Box sx={{ ...flex.row, gap: 1 }}>
            {isMaximized ? minimizeIcon : maximizeIcon}
            {isMaximized ? 'Minimize' : 'Full Screen'}
          </Box>
        </MenuItem>
      )}
      {isEditMode && (
        <MenuItem onClick={duplicateWidget}>
          <Box sx={{ ...flex.row, gap: 1 }}>
            <Duplicate />
            Duplicate
          </Box>
        </MenuItem>
      )}
      {isEditMode && widget.category === WidgetCategory.Bar && (
        <MenuItem onClick={setDisplayMode}>
          <Box sx={{ ...flex.row, gap: 1 }}>
            {isScroll ? <FitWidget /> : <Script />}
            {isScroll ? 'Fit to Widget Size' : 'Enable Scrolling '}
          </Box>
        </MenuItem>
      )}

      {![TextSubType.Text, TileSubType.Tile].includes(widget.type as any) && !widget.drillDownHierarchy?.activeIndex && (
        <MenuItem
          onClick={() => {
            const { top, ...rest } = query;
            exportQueryObjectWorkflow({
              contextFilter: cleanEmptyFilters(globalFilters),
              queryObject: rest,
              name: widget.definition.title.title,
            });
          }}>
          <Box sx={{ ...flex.row, gap: 1 }}>
            <Export />
            Export As CSV
          </Box>
        </MenuItem>
      )}
      {isEditMode && (
        <>
          <Divider />
          <MenuItem onClick={deleteWidget}>
            <Box sx={{ ...flex.row, gap: 1 }}>
              <Delete />
              Delete
            </Box>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default EditDashboardDetailsPopper;
