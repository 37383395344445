const FactorRulesConfig = {
  fields: {
    raw_score: {
      title: 'Score',
      includeInGroupFactor: false,
      required: true,
      editorProps: {
        label: 'Score script',
      },
      field: 'raw_score',
    },
    raw_zscore: {
      title: 'ZScore',
      includeInGroupFactor: false,
      required: false,
      editorProps: {
        label: 'ZScore script',
      },
      field: 'raw_zscore',
    },
    recommendation: {
      title: 'Recommended Actions',
      includeInGroupFactor: true,
      required: true,
      textInputProps: {
        label: 'Text',
      },
      editorProps: {
        label: 'Script',
      },
      field: 'recommendation',
    },
    note: {
      title: 'Notes',
      includeInGroupFactor: true,
      required: true,
      textInputProps: {
        label: 'Text',
      },
      editorProps: {
        label: 'Script',
      },
      field: 'note',
    },
    is_licensed: {
      includeInGroupFactor: true,
      required: true,
      editorProps: {
        label: 'Licenses script',
      },
      title: 'Licenses',
      field: 'is_licensed',
    },
  },
};

export default FactorRulesConfig;
