import React from 'react';
import { SxProps } from '@mui/material';
import { emptyArray } from '../../utils/Utils';
import AvComposedWidget from './AvComposedWidget';

interface Props {
  data?: any[];
  series?: any[];
  dateFormat?: string;
  sx?: SxProps;
  className?: string;
  isDate: boolean;
  xAxisKey?: string;
}

export default function LineWidget({
  data = emptyArray,
  series = emptyArray,
  dateFormat = 'MMM',
  sx,
  className,
  isDate,
  xAxisKey = 'date',
}: Props) {
  const dataKeyMap = series.reduce((map, { name, dataKey }) => ({ ...map, [dataKey]: name }), {});
  const topDataPlusOthers = data.map(({ [xAxisKey]: name, ...values }) => ({
    [xAxisKey]: name,
    ...Object.keys(values).reduce(
      (obj, key) => ({ ...obj, ...(dataKeyMap[key] ? { [key]: values[key] } : { OTHERS: (obj.OTHERS || 0) + values[key] }) }),
      {
        OTHERS: 0,
      }
    ),
  }));
  return (
    <AvComposedWidget
      xAxisKey={xAxisKey}
      isDate={isDate}
      series={series}
      data={topDataPlusOthers}
      dateFormat={dateFormat}
      sx={sx}
      className={className}
    />
  );
}
