// eslint-disable-next-line import/prefer-default-export

export const SearchContextKeys = {
  Tickets: 'tickets',
  Incidents: 'incidents',
  Assets: 'assets',
  Findings: 'findings',
  Exceptions: 'exceptions',
  Pivot: 'pivot',
  RemediationDashboard: 'remediation',
  RiskDashboard: 'risk',
  Factor: 'factor',
  PolicyPopulation: 'policy_population',
  Alerts: 'alerts',
  SmallTicketsTable: 'smallTicketsTable',
  Logs: 'logs',
};

export type SearchContextKeysType = (typeof SearchContextKeys)[keyof typeof SearchContextKeys];
