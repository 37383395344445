import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext, WithExternalProjections } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import avLazy from '../utils/AvLazy';
import { ReportsTypes } from '../views/CustomDashboards/types';
import AuditLogsDashboardReport from '../views/Reports/AuditLogsDashboardReport';

const Reports = avLazy(() => import('../views/Reports/Reports'));
const EditReports = avLazy(() => import('../views/Reports/EditReports'));
const ReportRuns = avLazy(() => import('../views/Reports/ReportRuns'));

const ReportsRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();

  const path = PAGE_PATHS.REPORTS;
  return (
    <Route path={path} element={<WithExternalProjections />}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <Reports />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditReports />
          </ProtectedRoute>
        }
      />
      <Route
        path="runs/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <ReportRuns type={ReportsTypes.TABULAR} />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditReports />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditLogsDashboardReport />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default ReportsRoute;
