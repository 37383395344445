import React, { useRef, useState } from 'react';
import { Box, Button, Popover, useTheme } from '@mui/material';
import { ellipsis } from '../utils/Utils';
import AvTag from './AvTag';
import { flex } from './AvThemeProvider';

const wrapperStyle = {
  minHeight: 32,
  display: 'contents',
  '> span:first-of-type': {
    lineHeight: '32px',
    alignSelf: 'start',
    flexShrink: 0,
  },
  '> *': {
    ...flex.itemsCenter,
  },
  a: {
    textDecoration: 'none',
  },
};

interface IAvTagsList {
  tags: string[];
  numOfTags?: number;
  tagBg?: string;
}

const AvTagsList: React.FC<IAvTagsList> = ({ tags = [], numOfTags = 1, tagBg }) => {
  const theme = useTheme();
  const sx = {
    ...flex.row,
    ...ellipsis,
    WebkitLineClamp: '1',
    columnGap: 1,
    rowGap: '3px',
    '.extra-items': {
      height: 20,
      padding: '1px 7px',
      backgroundColor: tagBg?.startsWith('#') ? tagBg : theme.palette.colors.neutrals[350],
      border: 0,
      borderRadius: '8px',
      fontSize: 13,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: tagBg?.startsWith('#') ? tagBg : theme.palette.colors.neutrals[350],
        border: 0,
        borderRadius: '8px',
      },
    },
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const handleClick = () => setAnchorEl(buttonRef.current);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const generateTag = (label, bg) => (
    <div key={label}>
      <AvTag oneLine text={label} bg={bg} />
    </div>
  );

  const paperStyle = {
    ...flex.col,
    gap: 2,
    pl: 1,
    py: 2,
    pr: 4,
    backgroundColor: theme.palette.white.main,
    minWidth: 'fit-content',
  };

  return (
    <Box sx={wrapperStyle}>
      <Box sx={sx}>
        {tags.slice(0, numOfTags).map(label => generateTag(label, tagBg))}
        {numOfTags < tags.length && (
          <>
            <Button ref={buttonRef} variant="outlined" className="extra-items" onMouseEnter={handleClick} onMouseLeave={handleClose}>
              +{tags.length - numOfTags}
            </Button>
            <Popover
              onClick={e => e.stopPropagation()}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{ sx: paperStyle }}
              sx={{ pointerEvents: 'none', ml: 2 }}
              anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
              transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
              {tags.slice(numOfTags).map(label => generateTag(label, tagBg))}
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AvTagsList;
