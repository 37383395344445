import { useAvContext } from '../../../../context/AvContextProvider';
import { FeatureFlags } from '../../../../types';
import { prettyFieldName, uniqBy } from '../../../../utils/Utils';
import { useGetFieldType } from '../../hooks';

export default function useInitHeadcells({ activeProjName, entityConfig, headCellsIds, projId, headCellsHiddenByView }) {
  const {
    measurements,
    accountEntities: { aggProjs },
    featureFlags,
  } = useAvContext();
  const getHeadCellType = useGetFieldType(activeProjName);

  const { pathAlias } = aggProjs[activeProjName];

  const initHeadCells = () => {
    const visibleHeadCells = headCellsIds.reduce((acc, id) => {
      const fieldHeadCell = aggProjs[projId.name].fieldList.INTERACTIVE.find(
        field => field[featureFlags[FeatureFlags.ClickthroughRelatedFields] ? 'value' : 'id'] === id
      );
      if (fieldHeadCell) {
        const { name: entityFieldKey, title, value } = fieldHeadCell;
        return [
          ...acc,
          {
            hidden: !!headCellsHiddenByView?.find(({ id }) => id),
            id: value || `${activeProjName}.${entityFieldKey}`,
            label: title || prettyFieldName(entityFieldKey),
            type: getHeadCellType({ entityFieldKey: `${pathAlias}.${entityFieldKey}` }),
            isKey: entityFieldKey === '_key',
          },
        ];
      }

      const metricHeadCell = measurements.visible.find(metric => metric.systemName === id);
      if (metricHeadCell) {
        return [
          ...acc,
          {
            hidden: !!headCellsHiddenByView?.find(({ id }) => id),
            id: metricHeadCell.systemName,
            label: metricHeadCell.displayName,
            type: getHeadCellType({ entityFieldKey: metricHeadCell.systemName }),
            isKey: false,
          },
        ];
      }

      return acc;
    }, []);

    const hiddenFieldHeadCells = [
      ...aggProjs[projId.name].fieldList.INTERACTIVE.filter(({ id }) => !headCellsIds.includes(id)).reduce<any>(
        (acc, { name: entityFieldKey, title, value }) => [
          ...acc,
          {
            hidden: true,
            id: value || `${activeProjName}.${entityFieldKey}`,
            label: title || prettyFieldName(entityFieldKey),
            type: getHeadCellType({ entityFieldKey: `${pathAlias}.${entityFieldKey}` }),
            isKey: entityFieldKey === '_key',
          },
        ],

        []
      ),
    ];

    return uniqBy(
      [
        ...visibleHeadCells,
        ...hiddenFieldHeadCells,
        measurements.visible
          .filter(({ context, systemName }) => context.includes(entityConfig?.entityTypeId) && !headCellsIds.includes(systemName))
          .reduce(
            (acc, { systemName, displayName }) => [
              ...acc,
              {
                hidden: true,
                id: systemName,
                label: displayName,
                type: getHeadCellType({ entityFieldKey: systemName }),
                isKey: false,
              },
            ],
            []
          ),
      ],
      f => f.id
    );
  };

  return initHeadCells;
}
