import { PeriodBehavior } from '../../../components/DatePicker/AvDateRangePicker.constants';
import { TimeBucket } from '../../Reports/types';

const ASSET_INVENTORY_GRID_LAYOUT = {
  isXsmallScreen: [
    { i: 'a', x: 0, y: 0, w: 12, h: 6, static: true },
    { i: 'b', x: 0, y: 6, w: 6, h: 6, static: true },
    { i: 'c', x: 6, y: 6, w: 6, h: 6, static: true },
    { i: 'd', x: 0, y: 12, w: 6, h: 6, static: true },
    { i: 'e', x: 6, y: 12, w: 6, h: 6, static: true },
    { i: 'f', x: 0, y: 18, w: 6, h: 6, static: true },
    { i: 'g', x: 6, y: 18, w: 6, h: 6, static: true },
  ],
  isSmallScreen: [
    { i: 'a', x: 0, y: 0, w: 12, h: 6, static: true },
    { i: 'b', x: 0, y: 6, w: 6, h: 6, static: true },
    { i: 'c', x: 6, y: 6, w: 6, h: 6, static: true },
    { i: 'd', x: 0, y: 12, w: 6, h: 6, static: true },
    { i: 'e', x: 6, y: 12, w: 6, h: 6, static: true },
    { i: 'f', x: 0, y: 18, w: 6, h: 6, static: true },
    { i: 'g', x: 6, y: 18, w: 6, h: 6, static: true },
  ],
  defaultScreen: [
    { i: 'a', x: 0, y: 0, w: 12, h: 6, static: true },
    { i: 'b', x: 0, y: 6, w: 6, h: 6, static: true },
    { i: 'c', x: 6, y: 6, w: 6, h: 6, static: true },
    { i: 'd', x: 0, y: 12, w: 6, h: 6, static: true },
    { i: 'e', x: 6, y: 12, w: 6, h: 6, static: true },
    { i: 'f', x: 0, y: 18, w: 6, h: 6, static: true },
    { i: 'g', x: 6, y: 18, w: 6, h: 6, static: true },
  ],
};
const defaultTimeRange = {
  relative: {
    periodBehaviour: PeriodBehavior.last,
    value: 6,
    unit: 'MONTHS',
  },
};
export const barBreakdownSuffix = '_BarBreakdown';
export const pieBreakdownSuffix = '_PieBreakdown';
export const multiLineSuffix = '_MultiLineBreakdown';
export const defaultFilter = {
  dateGap: [TimeBucket.BUCKET_MONTH],
  dateObject: [JSON.stringify(defaultTimeRange)],
  [`first${pieBreakdownSuffix}`]: 'asset.type',
  [`second${pieBreakdownSuffix}`]: 'asset.os',
  [`first${barBreakdownSuffix}`]: 'asset.source_names',
  [`second${barBreakdownSuffix}`]: 'asset.type',
  [`first${multiLineSuffix}`]: 'asset.type',
  [`second${multiLineSuffix}`]: 'asset.os',
};

export const firstPieBreakdownSuffix = `first${pieBreakdownSuffix}`;
export const secondPieBreakdownSuffix = `second${pieBreakdownSuffix}`;
export const firstBarBreakdownSuffix = `first${barBreakdownSuffix}`;
export const secondBarBreakdownSuffix = `second${barBreakdownSuffix}`;
export const firstMultiLineSuffix = `first${multiLineSuffix}`;
export const secondMultiLineSuffix = `second${multiLineSuffix}`;

export default ASSET_INVENTORY_GRID_LAYOUT;
