import React from 'react';
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import RGL, { WidthProvider } from 'react-grid-layout';
import AvFilters from '../../../components/AvFilters';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import Select from '../../../components/Select';
import { HistoricDataIndicator } from '../../../components/Widgets/layout.components';
import { useAvContext } from '../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../hooks/UseCustomSearchParams';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { entityViewConfig } from '../../../utils/entityViewConfig';
import { cleanEmptyFilters, filterToExpression } from '../../../utils/filterUtils';
import DraggableWidget from '../../CustomDashboards/components/DraggableWidget';
import renderWidget from '../../CustomDashboards/components/Widgets/RenderWidget';
import { getArrowIndication, getComparisonDifference } from '../../CustomDashboards/components/Widgets/TileWidget/TrendTileWidget';
import {
  AllTypeWidgets,
  ColoringRules,
  Field,
  SortDir,
  TileSubType,
  TileTypeWidget,
  TileWidgetVisualization,
  WidgetCategory,
} from '../../CustomDashboards/types';
import { getTimeRangeDescription } from '../../CustomDashboards/Utils';
import { filtersFieldToIgnore } from '../../Tickets/ticket.types';
import TimeRangeComp from '../components/TimeRangeComp';
import { getTimeRangeAndDatePickerValue } from '../utils';
import getAssetsOverTimeQuery, { getSideTileWidgetQuery } from './Queries';
import ASSET_INVENTORY_GRID_LAYOUT, {
  barBreakdownSuffix,
  defaultFilter,
  firstBarBreakdownSuffix,
  firstMultiLineSuffix,
  firstPieBreakdownSuffix,
  multiLineSuffix,
  pieBreakdownSuffix,
  secondBarBreakdownSuffix,
  secondMultiLineSuffix,
  secondPieBreakdownSuffix,
} from './utils';
import { getAssetOverTimeWidget, getTotalAssetBreakDownBarWidget, getTotalAssetsBrokenDownWidget } from './Widgets';

const dateFilters = ['dateGap', 'dateObject'];
const newAssetsMetricName = 'total_assets_granular';
const decomissionedMetricName = 'total_assets_granular';
const tileWidget: TileTypeWidget = {
  id: '',
  definition: {
    title: {
      title: '',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 4,
    },
    custom: {
      visualization: TileWidgetVisualization.None,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Tile,
  requests: [
    {
      select: {
        dims: [],
        metrics: [
          {
            name: 'total_assets_granular',
            alias: 'total_assets_granular',
          },
        ],
      },
      groupBy: [],
      sorting: [
        {
          name: 'total_assets_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
        offset: 0,
      },
      projectionId: {
        name: 'uber_assets',
        builtIn: true,
      },
      distinct: false,
      metricsFilterThreshold: 0,
    },
  ],
};

const WidgetComponent = ({
  value,
  onChange,
  widget,
  options,
  title,
  tileWidget,
  selectAtTheEnd,
}: {
  title?: string;
  options: Field[];
  value: Field;
  onChange: (v: Field) => void;
  widget: AllTypeWidgets;
  tileWidget?: TileTypeWidget;
  selectAtTheEnd?: boolean;
}) => (
  <DraggableWidget
    widget={widget}
    sx={{ border: 0 }}
    customToolbar={
      <Box sx={{ ...flex.justifyStartCenter, gap: 2 }}>
        {tileWidget && (
          <DraggableWidget widget={tileWidget} showToolbar={false} sx={{ p: 0, border: 0, mb: 1, flexShrink: 0 }}>
            {renderWidget({ widget: tileWidget })}
          </DraggableWidget>
        )}
        {title && (
          <Typography sx={{ whiteSpace: 'nowrap', mb: 2 }} variant="h5">
            {title}
          </Typography>
        )}
        {selectAtTheEnd && <Box sx={{ ml: 'auto', mb: 1.5, ...flex.justifyCenter, fontSize: '13px', fontWeight: 600 }}>Broken down By</Box>}
        <Box width={140} sx={{ ...flex.justifyBetweenCenter, mb: 1.5 }}>
          <Select options={options} size="xSmall" value={value} onChange={onChange} isRequired />
        </Box>
        {selectAtTheEnd && (
          <Box sx={{ ...flex.justifyEndCenter, width: 20, height: '100%', mb: 2, ml: 1 }}>
            <HistoricDataIndicator hideText />
          </Box>
        )}
      </Box>
    }>
    {renderWidget({ widget })}
  </DraggableWidget>
);

const ReactGridLayout = WidthProvider(RGL);

const AssetInventory = () => {
  const theme = useTheme();

  const {
    accountEntities: { aggProjs, fieldTypeMap },
  } = useAvContext();
  const aggProjFieldList = aggProjs[entityViewConfig.Asset.projectionName].fields;
  const activeProjName = entityViewConfig.Asset.projectionName;

  const [filters, setFilters] = useCustomSearchParams({ defaultFilter });

  const onFilterChange = newFilters => {
    setFilters({ ...filters, ...newFilters });
  };
  const filteredFilters = Object.keys(filters)
    .filter(key => !key.endsWith(pieBreakdownSuffix) && !key.endsWith(barBreakdownSuffix) && !key.endsWith(multiLineSuffix))
    .reduce(
      (acc, field) =>
        ![...filtersFieldToIgnore, ...dateFilters].includes(field) && !!filters[field].length
          ? {
              ...acc,
              [field]: filters[field],
            }
          : acc,
      {}
    );
  const { dateObject, dateGap } = filters;
  const { timeRange, datePickerValue } = getTimeRangeAndDatePickerValue(dateObject);

  const newAssetsQuery = getSideTileWidgetQuery(newAssetsMetricName, timeRange, dateGap);
  const decommissionedQuery = getSideTileWidgetQuery(decomissionedMetricName, timeRange, dateGap);
  const regularFilters = filterToExpression(fieldTypeMap, filteredFilters);
  const cleanFilters = cleanEmptyFilters(regularFilters);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down(1310));

  const screenSize = isXsmallScreen ? 'isXsmallScreen' : isSmallScreen ? 'isSmallScreen' : 'defaultScreen';
  const { isLoading: isLoadingNewAssets, data: newAssetsData = [] } = useQueryObject({
    queryObject: newAssetsQuery,
  });
  const { isLoading: isLoadingDecomissionedAssets, data: decomissionedAssetsData = [] } = useQueryObject({
    queryObject: decommissionedQuery,
  });

  return (
    <Box sx={flex.col}>
      <Typography variant="h3" sx={{ pl: 2 }}>
        Asset Inventory
      </Typography>
      <Box sx={{ ...flex.justifyBetweenCenter, px: 2, pb: 2 }}>
        <AvFilters
          filters={filters}
          updateFilters={(field, val) => onFilterChange({ [field]: val })}
          setFilters={setFilters}
          activeProjName={activeProjName}
          dims={aggProjs[activeProjName].fieldList.INTERACTIVE}
        />
        <TimeRangeComp datePickerValue={datePickerValue} dateGap={dateGap} onFilterChange={onFilterChange} />
      </Box>
      <Box sx={{ overflow: 'auto', position: 'relative' }}>
        <ReactGridLayout
          useCSSTransforms={false}
          className="layout"
          margin={[12, 12]}
          layout={ASSET_INVENTORY_GRID_LAYOUT[screenSize]}
          cols={12}
          rowHeight={35}>
          <Box key="a" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetComponent
              title="Total Reported Assets, broken down by"
              options={aggProjFieldList}
              value={filters[firstBarBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [firstBarBreakdownSuffix]: val })}
              widget={getTotalAssetBreakDownBarWidget(filters[firstBarBreakdownSuffix], cleanFilters)}
              tileWidget={tileWidget}
            />
          </Box>
          <Box key="b" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetComponent
              title="Total Assets, broken down by"
              options={aggProjFieldList}
              value={filters[firstPieBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [firstPieBreakdownSuffix]: val })}
              widget={getTotalAssetsBrokenDownWidget(filters[firstPieBreakdownSuffix], cleanFilters)}
            />
          </Box>
          <Box key="c" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <DraggableWidget
              widget={getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters))}
              customToolbar={
                <Box sx={flex.justifyBetweenCenter}>
                  <Typography sx={{ whiteSpace: 'nowrap', mb: 2 }} variant="h5">
                    Assets Over Time
                  </Typography>
                  <Box sx={{ ...flex.justifyEndCenter, width: 20, height: '100%', mb: 2, ml: 1 }}>
                    <HistoricDataIndicator hideText />
                  </Box>
                </Box>
              }>
              {renderWidget({
                widget: getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters)),
              })}
            </DraggableWidget>
          </Box>
          <Box key="d" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetComponent
              title="Total Assets, broken down by"
              options={aggProjFieldList}
              value={filters[secondPieBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [secondPieBreakdownSuffix]: val })}
              widget={getTotalAssetsBrokenDownWidget(filters[secondPieBreakdownSuffix], cleanFilters)}
            />
          </Box>

          <Box key="e" sx={{ ...flex.justifyStart, backgroundColor: theme.palette.white.main }}>
            {isLoadingNewAssets ? (
              <Skeleton width="30%" height="100%" variant="rectangular" />
            ) : (
              <Box sx={{ width: '30%', gap: 0.5, ...flex.colItemsStart, pl: 2, pt: 2 }}>
                <Box sx={{ fontSize: '40px' }}>{newAssetsData[newAssetsData.length - 1][newAssetsMetricName]}</Box>
                <Typography sx={{ mb: 1, pl: 0.2 }} variant="h5">
                  New Assets
                </Typography>
                <Box sx={{ ...flex.colItemsStart, gap: 1, fontSize: '12px' }}>
                  {getArrowIndication(ColoringRules.UP_IS_GOOD, getComparisonDifference(newAssetsData, newAssetsMetricName), theme)}{' '}
                  <AvTooltip hasWidthLimit sx={{ width: 150 }}>
                    {getTimeRangeDescription(newAssetsQuery.timeRange)}
                  </AvTooltip>
                </Box>
              </Box>
            )}
            <Box sx={{ width: '70%', height: '100%' }}>
              <WidgetComponent
                options={aggProjFieldList}
                value={filters[firstMultiLineSuffix]}
                onChange={(val: Field) => onFilterChange({ [firstMultiLineSuffix]: val })}
                widget={getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters, filters[firstMultiLineSuffix]))}
                selectAtTheEnd
              />
            </Box>
          </Box>
          <Box key="f" sx={{ ...flex.col, backgroundColor: theme.palette.white.main }}>
            <WidgetComponent
              title="Total Reported Assets, broken down by"
              options={aggProjFieldList}
              value={filters[secondBarBreakdownSuffix]}
              onChange={(val: Field) => onFilterChange({ [secondBarBreakdownSuffix]: val })}
              widget={getTotalAssetBreakDownBarWidget(filters[secondBarBreakdownSuffix], cleanFilters)}
              tileWidget={tileWidget}
            />
          </Box>
          <Box key="g" sx={{ ...flex.justifyStart, backgroundColor: theme.palette.white.main }}>
            {isLoadingDecomissionedAssets ? (
              <Skeleton width="30%" height="100%" variant="rectangular" />
            ) : (
              <Box sx={{ width: '30%', gap: 0.5, ...flex.colItemsStart, pl: 2, pt: 2 }}>
                <Box sx={{ fontSize: '40px' }}>{decomissionedAssetsData[decomissionedAssetsData.length - 1][decomissionedMetricName]}</Box>
                <Typography sx={{ mb: 1, pl: 0.2 }} variant="h5">
                  Decommissioned Assets
                </Typography>
                <Box sx={{ ...flex.colItemsStart, gap: 1, fontSize: '12px' }}>
                  {getArrowIndication(ColoringRules.UP_IS_GOOD, getComparisonDifference(newAssetsData, decomissionedMetricName), theme)}
                  <AvTooltip hasWidthLimit sx={{ width: 150 }}>
                    {getTimeRangeDescription(decommissionedQuery.timeRange)}
                  </AvTooltip>
                </Box>
              </Box>
            )}
            <Box sx={{ width: '70%', height: '100%' }}>
              <WidgetComponent
                options={aggProjFieldList}
                selectAtTheEnd
                value={filters[secondMultiLineSuffix]}
                onChange={(val: Field) => onFilterChange({ [secondMultiLineSuffix]: val })}
                widget={getAssetOverTimeWidget(getAssetsOverTimeQuery(timeRange, dateGap, cleanFilters, filters[secondMultiLineSuffix]))}
              />
            </Box>
          </Box>
        </ReactGridLayout>
      </Box>
    </Box>
  );
};

export default AssetInventory;
