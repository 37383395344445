import React, { useContext, useState } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/system';
import { useSnackbar } from 'notistack';
import { NotificationContext } from '../context/AvSnackBarProvider';
import { getDefaultValues, useFormMutate } from '../utils/form.utils';
import { useOnSuccessAccountManagement } from '../views/AccountManagement/hooks';
import AvFormCard from './AvFormCard';
import AvFormComponent from './AvFormComponent';
import { flex } from './AvThemeProvider';
import { Form } from './formInputTypes';
import TextInput from './TextInput';
import { ReactComponent as Copy } from '../assets/Copy.svg';

interface AvActionCardsProps {
  forms: Form[];
  title: string;
}

const AvActionCards: React.FC<AvActionCardsProps> = ({ forms, title }) => (
  <Box sx={{ maxWidth: '1200px' }}>
    <Typography variant="h3" sx={{ ...flex.itemsCenter, gap: 2, whiteSpace: 'pre' }}>
      {title}
    </Typography>
    {forms.map(form => (
      <AvSubForm form={form} />
    ))}
  </Box>
);

export default AvActionCards;

interface AvSubFormProps {
  form: Form;
}

const AvSubForm: React.FC<AvSubFormProps> = ({
  form: { title, saveButtonText, formComponents, queryOptions, handleResponse, query, propagateTo },
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [responseValues, setResponseValues] = useState();
  const theme = useTheme();

  const { setRunInfo } = useContext(NotificationContext);
  const [newData, setNewData] = useState(getDefaultValues(formComponents));

  const onConfigChange = (updatedFields = {}) => {
    setNewData({ ...newData, ...updatedFields });
  };

  const rightButtonsStyle = {
    ...flex.justifyEndCenter,
    gap: 2,
  };

  const reset = () => setNewData(getDefaultValues(formComponents));

  const onSuccess = useOnSuccessAccountManagement(reset, handleResponse, setRunInfo, setResponseValues);
  const { mutate, isPending } = useFormMutate(query, propagateTo, formComponents, onSuccess, queryOptions);

  const copyTextToClipboard = textToCopy => {
    navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar('Copied!', { variant: 'success' });
  };

  return (
    <>
      <AvFormCard title={title}>
        <AvFormComponent formComponents={formComponents} data={newData} onFormChange={newValue => onConfigChange(newValue)} />
        {responseValues && (
          <Box sx={{ gap: 2, ...flex.col, pt: 2 }}>
            <Typography> Response: </Typography>
            {Object.keys(responseValues)
              .filter(key => !key.startsWith('__'))
              .map(key => (
                <Box key={key} sx={{ ...flex.itemsCenter, gap: 1 }}>
                  <TextInput
                    size="small"
                    label={key}
                    value={responseValues[key]}
                    disabled
                    inputProps={{
                      sx: {
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: theme.palette.text.primary,
                        },
                      },
                    }}
                  />
                  <Button
                    size="small"
                    onClick={() => copyTextToClipboard(responseValues[key])}
                    sx={{ padding: 'unset', paddingTop: '25px' }}>
                    <Copy />
                  </Button>
                </Box>
              ))}
          </Box>
        )}
        <Box sx={rightButtonsStyle}>
          <Button onClick={reset}>Reset</Button>
          <Button variant="contained" onClick={() => mutate(newData)} disabled={isPending}>
            {saveButtonText || 'Save'}
          </Button>
        </Box>
      </AvFormCard>
      {isPending && <LinearProgress />}
    </>
  );
};
