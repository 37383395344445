import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';
import PolicyRuns from '../views/Policies/PolicyRuns';
import { EditPageModes } from '../views/Policies/utils';

const Policies = avLazy(() => import('../views/Policies/Policies'));
const EditPolicies = avLazy(() => import('../views/Policies/EditPolicy'));

const PoliciesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();

  const path = PAGE_PATHS.POLICIES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <Policies />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <EditPolicies mode={EditPageModes.CREATE} />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <EditPolicies mode={EditPageModes.EDIT} />
          </ProtectedRoute>
        }
      />
      <Route
        path="runs/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <PolicyRuns />
          </ProtectedRoute>
        }
      />
      <Route
        path="duplicate/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <EditPolicies mode={EditPageModes.DUPLICATE} />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default PoliciesRoute;
