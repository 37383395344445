import { useEffect, useMemo, useState } from 'react';
import { ConditionType, getExpression } from '../../../../components/filters/Utils';
import { isNumberType } from '../../../../components/Table/Utils';
import useQueryObject from '../../../../hooks/useQueryObjectSql';
import { Filter } from '../../../../types/filter.types';
import { NO_VALUE, OTHERS } from '../../constants';
import { useGetFieldType } from '../../hooks';
import { AllTypeWidgets, Custom, DwQueryRequest, SortBy, WidgetCategory } from '../../types';
import { defaultDrillDownHierarchy, shouldShowTotals } from '../../Utils';

export const useDrillDown = ({
  widget,
  setWidget,
  enable = false,
  contextFilter,
}: {
  widget: AllTypeWidgets;
  setWidget: ((w) => void) | undefined;
  enable: boolean;
  contextFilter: Filter | undefined;
}) => {
  const {
    drillDownHierarchy = defaultDrillDownHierarchy,
    requests: [
      {
        select: { dims },
      },
    ],
  } = widget;
  const { activeIndex, drillDownValues = [] } = drillDownHierarchy;

  const {
    data: drillDownData,
    isLoadingDrillDown,
    totalsDrillDown,
    allDrillDownFields,
    newQueryObject,
  } = useDrillDownData({
    widget,
    contextFilter,
    enabled: activeIndex !== 0 && enable,
  });

  const dim = activeIndex !== 0 ? allDrillDownFields[activeIndex].name : dims?.[0]?.name;
  const nextDrillDownField = allDrillDownFields[activeIndex + 1];

  const getValue = obj => {
    if (widget.category === WidgetCategory.Pie) {
      return obj[dim];
    }

    if (widget.category === WidgetCategory.Bar) {
      return obj.name;
    }

    return obj;
  };

  const getPressedValue = data => {
    const value = getValue(data.d);
    if (widget.category === WidgetCategory.Pie || widget.category === WidgetCategory.Bar) {
      return value === NO_VALUE ? null : value;
    }
    return data;
  };

  const onSelectedDrillDown = data => {
    if (setWidget && nextDrillDownField) {
      const value = getPressedValue(data);
      setWidget({
        ...widget,
        drillDownHierarchy: {
          ...drillDownHierarchy,
          activeIndex: activeIndex + 1,
          drillDownValues: drillDownValues[activeIndex] ? drillDownValues.with(activeIndex, value) : [...drillDownValues, value],
          notOthersDrillDownValues:
            value === OTHERS ? data.widgetData.filter(obj => getValue(obj) !== 'Others').map(obj => getValue(obj)) : [],
        },
      });
    }
  };

  return { drillDownData, totalsDrillDown, isLoadingDrillDown, allDrillDownFields, activeIndex, onSelectedDrillDown, newQueryObject };
};

export const useDrillDownData = ({
  widget,
  enabled,
  currentIndex,
  contextFilter,
}: {
  widget: AllTypeWidgets;
  enabled: boolean;
  currentIndex?: number;
  contextFilter: Filter | undefined;
}) => {
  const request = widget.requests[0];
  const { drillDownHierarchy = defaultDrillDownHierarchy } = widget;
  const { activeIndex: aI, fields, drillDownValues = [], notOthersDrillDownValues = [] } = drillDownHierarchy;
  const activeIndex = currentIndex || aI;
  const getFieldType = useGetFieldType(request.projectionId.name);

  const [drillDownSort, setDrillDownSort] = useState<SortBy>(request.sorting[0]);
  const allDrillDownFields = [{ name: request.select.dims[0]?.name }, ...fields];
  useEffect(() => {
    setDrillDownSort(request.sorting[0]);
  }, [activeIndex]);
  const newFilter: Filter[] = useMemo(
    () =>
      Array.from(Array(activeIndex), (_, i) => {
        const type = getFieldType({ entityFieldKey: allDrillDownFields[i].name }) || 'string';
        const value = drillDownValues[i];
        if (value === OTHERS) {
          return {
            not: {
              or: {
                operands: notOthersDrillDownValues.map(notOthersValue =>
                  getExpression({
                    fieldName: allDrillDownFields[i].name,
                    typeCondition: ConditionType[['percentage', 'number'].includes(type) ? 'number' : type === 'bool' ? 'bool' : 'string'],
                    operator: notOthersValue === null ? 'empty' : 'equals',
                    value:
                      notOthersValue === null ? {} : typeof notOthersValue === 'object' ? JSON.stringify(notOthersValue) : notOthersValue,
                  })
                ),
              },
            },
          };
        }

        return getExpression({
          fieldName: allDrillDownFields[i].name,
          typeCondition: ConditionType[isNumberType(type) ? 'number' : type || 'string'],
          operator: value === null ? 'empty' : 'equals',
          value: value === null ? {} : typeof value === 'object' ? JSON.stringify(value) : value,
        });
      }),
    [activeIndex, drillDownValues]
  );

  const isCrossTableView = !!(widget.definition as { custom: Custom }).custom?.crossTableView;

  const newQueryObject: DwQueryRequest = useMemo(() => {
    const select = isCrossTableView
      ? { ...request.select, dims: [...fields.slice(0, activeIndex).reverse(), ...request.select.dims] }
      : { ...request.select, dims: [[...request.select.dims, ...fields][activeIndex]] };
    return {
      ...request,
      select,
      filter: request.filter ? { and: { operands: [request.filter, ...newFilter] } } : { and: { operands: newFilter } },
      sorting: [...select.metrics, ...select.dims].some(dim => dim?.name === drillDownSort?.name) ? [drillDownSort] : [],
      groupBy: isCrossTableView
        ? [...fields.slice(0, activeIndex).reverse(), ...request.select.dims].map(({ name }) => name)
        : [...([...request.select.dims, ...fields].length ? [[...request.select.dims, ...fields][activeIndex].name] : [])],
    };
  }, [activeIndex, newFilter, drillDownSort, request.top]);
  const showTotals = shouldShowTotals(widget);

  const {
    data,
    totalRowCount: totalRowCountDrillDown,
    totals: totalsDrillDown,
    isLoading: isLoadingDrillDown,
    isRefetching: isRefetchingDrillDown,
    errors: errorsDrillDown,
  } = useQueryObject({
    queryObject: newQueryObject,
    totalRowCount: true,
    contextFilter,
    showTotals,
    options: {
      enabled: enabled && activeIndex !== 0 && !!allDrillDownFields[activeIndex] && drillDownValues.length === activeIndex,
      gcTime: 0,
    },
  });

  return {
    newQueryObject,
    data,
    totalRowCountDrillDown,
    totalsDrillDown,
    isLoadingDrillDown,
    isRefetchingDrillDown,
    errorsDrillDown,
    drillDownSort,
    setDrillDownSort,
    allDrillDownFields,
  };
};
