import React, { useContext, useEffect, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/system';
import { flex } from '../../../components/AvThemeProvider';
import { iconSize } from '../../../utils/Utils';
import { CustomDashboardContext } from '../CustomDashboardContext';
import { FieldType } from '../types/types';
import ReorderFieldsList from './ReorderFieldsList';
import { getKeyByValue } from './SelectWidgetType/SelectWidgetType';
import { ReactComponent as ArrowDown } from '../../../assets/Arrow Down.svg';
import { ReactComponent as Edit } from '../../../assets/Edit.svg';
import { ReactComponent as Plus } from '../../../assets/Plus.svg';

const plusIcon = <Plus style={iconSize(16)} />;
const editIcon = <Edit style={iconSize(16)} />;
export const ArrowDownIcon = (isCollapse, isFocus, theme) => (
  <ArrowDown
    style={{
      ...iconSize(16),
      transform: `rotate(${isCollapse ? 0 : -90}deg)`,
      color: isFocus ? theme.palette.colors.neutrals[600] : theme.palette.colors.neutrals[500],
    }}
  />
);

const HEIGHT = 42;
type SelectedFieldsReordersProps = {
  fieldType: FieldType;
  isFocus: boolean;
  onClickAdd: any;
};
const SelectedFieldsReorder: React.FC<SelectedFieldsReordersProps> = ({ fieldType, isFocus, onClickAdd }) => {
  const theme = useTheme();
  const { newWidget } = useContext(CustomDashboardContext);
  const fieldsLength = newWidget.requests[0].select[fieldType].length;
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (!isCollapse) {
      setIsCollapse(isFocus);
    }
  }, [isFocus]);

  return (
    <Box
      sx={{
        ...flex.col,
        backgroundColor: isFocus ? theme.palette.colors.primary[100] : theme.palette.colors.neutrals[100],
        borderRadius: '12px',
      }}>
      <Box
        onClick={() => setIsCollapse(!isCollapse)}
        sx={{
          ...flex.itemsCenter,
          px: '10px',
          height: HEIGHT,
          gap: 1,
        }}>
        {ArrowDownIcon(isCollapse, isFocus, theme)}
        <Typography
          sx={{ fontSize: 11, color: isFocus ? theme.palette.colors.neutrals[600] : theme.palette.colors.neutrals[500] }}
          variant="h7">
          {getKeyByValue(FieldType, fieldType)} ({fieldsLength})
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* @ts-ignore */}
        <Button onClick={onClickAdd} sx={{ p: '3px' }} active={isFocus} size="xSmall" variant="outlined" type="icon">
          {fieldsLength ? editIcon : plusIcon}
        </Button>
      </Box>
      <Collapse in={isCollapse} timeout="auto" unmountOnExit>
        <ReorderFieldsList fieldType={fieldType} isFocus={isFocus} />
      </Collapse>
    </Box>
  );
};

export default SelectedFieldsReorder;
