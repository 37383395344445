import React, { useEffect, useRef } from 'react';
import { capitalize, Divider, MenuItem, Paper, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { format, isValid } from 'date-fns';
import { rebranding } from '../../rebranding';
import { isPercentageMetric } from '../../utils/dashboardDataUtils';
import { cellContentStyle, dotStyle, emptyArray, emptyObject } from '../../utils/Utils';
import { NO_VALUE } from '../../views/CustomDashboards/constants';
import { flex } from '../AvThemeProvider';
import { ChartClickInteraction } from './types';

interface Props {
  payload: any;
  isDate?: boolean;
  itemSorter?: (payload: any) => any;
  metricKey?: string;
  titleFormatter?: (title: any) => string;
  metricFormatter?: (metric: any) => string | (() => string);
  showContentLegend?: boolean;
  showTitleLegend?: boolean;
  showTitle?: boolean;
  tooltipFields?: string[];
  metricsToHide?: string[];
  dateFormat?: string;
  series100Percent?: any[];
  clickInteractions?: ChartClickInteraction[];
  areClickOptionsVisible?: boolean;
  setAreClickOptionsVisible?: (v) => void;
  sx?: object;
  widgetData?: Array<Record<string, unknown>>;
}
const CustomTooltip: React.FC<Props> = ({
  payload,
  isDate = false,
  itemSorter,
  metricKey,
  titleFormatter = d => d,
  metricFormatter = d => d,
  showContentLegend = true,
  showTitleLegend,
  showTitle = true,
  tooltipFields,
  metricsToHide = emptyArray,
  dateFormat,
  series100Percent = emptyArray,
  clickInteractions,
  areClickOptionsVisible,
  setAreClickOptionsVisible,
  sx = emptyObject,
  widgetData,
}) => {
  const theme = useTheme();

  const tooltipRef = useRef<any>(null);

  const handleOutsideClick = event => {
    if (setAreClickOptionsVisible && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setAreClickOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  if (!payload || payload.length === 0) {
    return null;
  }

  const availableCellClickInteractions = clickInteractions
    ? clickInteractions.filter(v => v.isEnabledForCell({ dataKey: payload[0].dataKey, ...payload[0]?.payload }))
    : clickInteractions;
  const renderContent = series => {
    const item = series.payload.payload;
    if (item) {
      const content = tooltipFields
        ? Object.keys(item).reduce((acc, key) => {
            if (tooltipFields.includes(key)) {
              acc[capitalize(key)] = item[key];
            }
            return acc;
          }, {})
        : null;
      if (content) {
        return Object.keys(content).map(key => {
          const val = content[key];
          const value = metricFormatter(val.toLocaleString());
          return (
            <Box key={key} sx={{ ...flex.justifyBetween, gap: 3 }}>
              <Box sx={flex.itemsCenter}>
                <Box sx={{ color: theme => theme.palette.colors.neutrals[300] }}>{key}</Box>
              </Box>
              <Box sx={{ fontWeight: 600 }}>{value}</Box>
            </Box>
          );
        });
      }
    }

    const isPercentage = isPercentageMetric(series.dataKey);
    const metric = metricFormatter(metricKey ? series.payload[metricKey] : series.name);
    const val = series.payload[series.dataKey];
    if (metricsToHide.includes(metric)) {
      return null;
    }

    return (
      <Box key={metric} sx={{ ...flex.justifyBetween, gap: 3 }}>
        <Box sx={{ ...flex.itemsCenter }}>
          {showContentLegend && (
            <Box
              sx={{
                ...dotStyle(
                  metricKey
                    ? series.payload.fill
                    : series.color === 'transparent'
                      ? theme.palette.colors.neutrals[650]
                      : series.color || series.payload.color
                ),
                mr: 2,
                ...(rebranding && { border: theme => `1px solid ${theme.palette.colors.neutrals[300]}` }),
              }}
            />
          )}
          <Box sx={{ color: theme => theme.palette.colors.neutrals[300] }}>{capitalize(metric || '')}</Box>
        </Box>
        <Box sx={{ fontWeight: 600 }}>
          {series100Percent.some(({ dataKey, is100Percent }) => dataKey === metric && is100Percent)
            ? `${val}%`
            : isPercentage
              ? `${(+val || 0).toFixed(2)}%`
              : val?.toLocaleString()}
        </Box>
      </Box>
    );
  };
  const title = titleFormatter(metricKey || payload[0]?.payload.name);
  return (
    <Box
      ref={tooltipRef}
      sx={{
        ml: 5,
        mb: 5,
        ...flex.col,
        gap: 0.5,
        background: theme => theme.palette.colors.neutrals[850],
        borderRadius: 2.5,
        color: theme => theme.palette.colors.neutrals[100],
        fontSize: 12,
        pb: availableCellClickInteractions?.length ? 0 : 1,
        ...sx,
      }}>
      {showTitle && (
        <>
          <Box sx={{ ...flex.itemsCenter, fontWeight: 600, marginBottom: '2px', px: 1, pt: 1 }}>
            {showTitleLegend && (
              <Box
                sx={{
                  ...dotStyle(metricKey ? payload[0]?.payload.fill : payload[0]?.color || payload[0]?.payload.color),
                  ...(rebranding && { border: theme => `1px solid ${theme.palette.colors.neutrals[300]}` }),
                }}
              />
            )}
            <Box
              sx={{
                width: '100%',
                maxWidth: 300,
                ...cellContentStyle,
                WebkitLineClamp: 2,
                pl: 1,
              }}>
              {isDate && isValid(new Date(payload[0]?.payload?.date))
                ? format(new Date(payload[0]?.payload?.date), dateFormat || 'MMMM yyyy')
                : title === null
                  ? NO_VALUE
                  : title}
            </Box>
          </Box>
          {/* @ts-ignore */}
          <Divider variant="center" sx={{ borderColor: theme => theme.palette.colors.neutrals[800] }} />
        </>
      )}
      <Box sx={{ px: 1, pt: showTitle ? 0 : 1 }}>{(itemSorter ? itemSorter(payload) : payload).map(series => renderContent(series))}</Box>
      {availableCellClickInteractions?.length ? (
        <>
          {/* @ts-ignore */}
          <Divider variant="center" sx={{ borderColor: theme => theme.palette.colors.neutrals[800] }} />
          {areClickOptionsVisible ? (
            <Paper
              sx={{
                ...flex.col,
                pointerEvents: 'all',
                mt: -0.5,
                borderRadius: 0,
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
                p: 1,
              }}>
              {availableCellClickInteractions.map(({ title, onClick, icon }) => (
                <MenuItem
                  key={title}
                  onClick={() => onClick({ d: payload[0]?.payload, dataKey: payload[0].dataKey, widgetData })}
                  sx={{ background: 'white', fontSize: '12px', px: 1 }}>
                  {icon}
                  {title}
                </MenuItem>
              ))}
            </Paper>
          ) : (
            <Box
              sx={{
                fontSize: '12px',
                color: theme.palette.colors.neutrals[400],
                pb: 0.5,
                px: 1,
              }}>
              Click{' '}
              {availableCellClickInteractions.length === 1 ? (
                <span>
                  to <span style={{ fontWeight: 500 }}>{availableCellClickInteractions[0].title.toLowerCase()}</span>
                </span>
              ) : (
                'for interactions'
              )}
            </Box>
          )}
        </>
      ) : null}
    </Box>
  );
};

export default CustomTooltip;
