import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { formatPercent } from '../../utils/dashboardDataUtils';
import { emptyObject } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ExploreLink } from './layout.components';
import { ReactComponent as CaretDown } from '../../assets/CaretDown.svg';

const caretDown = <CaretDown width={18} height={18} transform="rotate(180)" />;

enum Format {
  amount = 'amount',
  percent = 'percent',
  both = 'both',
}

interface StatusWithChartOverviewProps {
  data?: { count: number; total: number };
  title?: string;
  icon: React.JSX.Element;
  format?: Format;
  actionHref?: string;
  formatFunc?: (v) => any;
}

const StatusWithChartOverview: React.FC<StatusWithChartOverviewProps> = ({
  data = emptyObject,
  title,
  icon,
  format = Format.percent,
  actionHref,
  formatFunc,
}) => {
  const { palette } = useTheme();

  const percentValue = formatFunc?.(data.count) || formatPercent(data.count, data.total);
  const separator = <div style={{ backgroundColor: palette.colors.neutrals[350], borderRadius: 5, width: '1px' }} />;
  const entireData = [
    { a1: 'last month', value: 0 },
    { a1: 'now', value: data.count },
  ];
  return (
    <Box sx={{ ...flex.row, gap: 2, height: '100%', position: 'relative' }}>
      <Box sx={{ svg: { width: 56, height: 56 } }}>{icon}</Box>
      <Box sx={{ ...flex.col, gap: 1, flexShrink: 0 }}>
        <Typography sx={{ color: palette.colors.neutrals[500], fontWeight: 500 }}>{title}</Typography>
        <span style={{ ...flex.row, gap: '12px' }}>
          <Typography variant="h3" sx={{ mb: 0 }}>
            {format === Format.percent ? percentValue : data.count}
          </Typography>
          {format === Format.both ? (
            <>
              {separator}
              <Typography variant="h4" style={{ color: palette.colors.neutrals[500] }}>
                {percentValue}
              </Typography>
            </>
          ) : (
            ''
          )}
        </span>
        {entireData[0].value !== 0 && (
          <span style={{ color: palette.success.main, fontWeight: 600, ...flex.itemsCenter, gap: '3px' }}>
            {caretDown}
            {formatPercent(entireData[1].value, entireData[0].value)}
            <span style={{ color: palette.colors.neutrals[500], fontWeight: 500 }}>Last Month</span>
          </span>
        )}
      </Box>
      {actionHref ? <ExploreLink actionHref={actionHref} title="Tickets" sx={{ position: 'absolute', right: 0, bottom: 0 }} /> : null}
    </Box>
  );
};

export default StatusWithChartOverview;
