import React, { useMemo } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useAvContext } from '../../context/AvContextProvider';
import { useExportQuerySqlWorkflow } from '../../hooks/useExportQueryWorklflow';
import { isPercentageMetric, ticketDimensionsOptions } from '../../utils/dashboardDataUtils';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { emptyArray, noop, orderByKey, refetchingStyle } from '../../utils/Utils';
import { NO_VALUE } from '../../views/CustomDashboards/constants';
import { explodeFields } from '../../views/Tickets/ticket.types';
import { getOptionsFromFields } from '../AvFilters';
import AvLegend from '../AvLegend';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import ExportButton from '../ExportButton';
import Select from '../Select';
import AvTable from '../Table/AvTable';
import { HeadCellType, SortType } from '../Table/types';
import { WidgetTitle } from './layout.components';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as X } from '../../assets/X.svg';

const PAGE_SIZE = 10;
const limit = 8;

interface OverTimeTableHeaderProps {
  metrics: string[];
  sql: string;
  updateFilters: (v: any, d: any) => void;
  rowDim: string;
  colDim: string;
  title: string;
  metricOptions: any[];
}

export function OverTimeTableHeader({
  metrics,
  sql: exportSql,
  updateFilters,
  rowDim,
  colDim,
  title,
  metricOptions,
}: OverTimeTableHeaderProps) {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const { mutate: exportSqlWorkflow, isPending: isExportingWorkflow } = useExportQuerySqlWorkflow();

  const FilterTypesLabel = entityViewConfig.Ticket.labelsV2!;
  const updateAnalyzeProps = (field, value) => {
    const fullProps = { metrics, rowDim, colDim, [field]: value };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };
  const dimensionOptions = ticketDimensionsOptions(FilterTypesLabel);

  return (
    <Box sx={{ ...flex.colItemsStart }}>
      <WidgetTitle marginBottom={2}>{title}</WidgetTitle>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Select
          variant="outlined"
          type="icon"
          isRequired
          label={<Plus />}
          options={metricOptions}
          size="xSmall"
          isMultiple
          onChange={v => updateAnalyzeProps('metrics', v.length ? v : metricOptions[0].value)}
          value={metrics}
          showSelection={false}
          disabled={metrics.length >= limit}
          disabledTooltipText={`Selection is limited to ${limit} metrics`}
          shouldCloseOnChange={newVal => newVal?.length >= limit}
        />
        <AvLegend
          isHorizontal
          isOutlined
          series={metrics.map((m, index, arr) => {
            const name = metricOptions.find(({ value }) => value === m)?.title;
            return {
              name,
              component: (
                <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                  {name}
                  {arr.length > 1 && (
                    <IconButton
                      onClick={() =>
                        updateAnalyzeProps(
                          'metrics',
                          metrics.filter(metric => metric !== m)
                        )
                      }>
                      <X />
                    </IconButton>
                  )}
                </Box>
              ),
            };
          })}
        />
        <Box sx={{ px: '2px' }}>By</Box>
        <Select
          variant="outlined"
          isRequired
          options={[...dimensionOptions, ...getOptionsFromFields(aggProjs.tickets.fields, null, 'ticket')]}
          size="xSmall"
          onChange={v => updateAnalyzeProps('rowDim', v.length ? v : metricOptions[0].value)}
          value={rowDim}
        />
        <Box>
          <ExportButton
            onClick={() => exportSqlWorkflow({ querySql: exportSql, projectionID: { name: 'tickets', builtIn: true } })}
            loading={isExportingWorkflow}
            width={18}
          />
        </Box>
      </Box>
    </Box>
  );
}

interface OverTimeTableProps {
  data?: any[][];
  totals?: number;
  metrics: string[];
  rowDim: string;
  colDim: string;
  page: string;
  isLoading?: boolean;
  dateFormat?: string;
  sort: { property: string; isAsc: boolean }[];
  metricOptions?: any[];
  updateFilters?: (v: any, d: any) => void;
}

function OverTimeTable({
  data = emptyArray,
  totals = 0,
  metrics,
  rowDim,
  colDim,
  page,
  isLoading,
  dateFormat = 'MMM',
  sort = emptyArray,
  metricOptions = emptyArray,
  updateFilters = noop,
}: OverTimeTableProps) {
  const theme = useTheme();
  const FilterTypesLabel = entityViewConfig.Ticket.labelsV2!;
  const groupedHeadCells = [...new Set(data[0].map(r => (colDim === 'date' ? format(new Date(r[colDim]), dateFormat) : r[colDim])))];
  const { rows, headCells } = useMemo(() => {
    const { rows, headCells } = convertRowsToPivot(data[0], rowDim, colDim, groupedHeadCells, metrics, metricOptions, explodeFields());
    const { property, isAsc } = sort?.[0] || {};
    return { rows: property ? orderByKey(rows, property, isAsc) : rows, headCells };
  }, [data]);

  const { rows: totalRow, headCells: totalHeadCells } = useMemo(
    () => convertRowsToPivot(data[1], rowDim, colDim, groupedHeadCells, metrics, metricOptions, explodeFields()),
    [data]
  );
  const rowDimLength = [1];
  const smallHeaderFormat = { width: 100, span: { whiteSpace: 'normal !important' } };
  const borderFormat = { borderRight: `1px solid ${theme.palette.colors.neutrals[350]}`, ...smallHeaderFormat };

  const rowDimId = explodeFields()[rowDim]?.group || rowDim;
  const tableHeadCells: HeadCellType[] = [
    {
      id: rowDimId,
      label: FilterTypesLabel[rowDim],
      isKey: true,
      sx: borderFormat,
      style: {
        ...borderFormat,
        maxWidth: 200,
        maxHeight: 200,
        borderRight: `1px solid ${theme.palette.colors.neutrals[350]}`,
      },
      formatter: value => tooltip(value),
      ...(sort[0]?.property === rowDimId ? { sort: sort[0]?.isAsc ? SortType.ASC : SortType.DESC } : {}),
    },
    ...headCells.map(({ key, label }, index, arr) => {
      const style = (index + rowDimLength.length) % metrics.length === 0 && index !== arr.length - 1 ? borderFormat : smallHeaderFormat;
      return {
        id: key,
        label,
        sx: style,
        style,
        type: isPercentageMetric(key) ? 'percentage' : 'number',
        ...(sort[0]?.property === key ? { sort: sort[0]?.isAsc ? SortType.ASC : SortType.DESC } : {}),
      };
    }),
  ];

  const updateAnalyzeProps = (field, value) => {
    const fullProps = { metrics, rowDim, colDim, [field]: value };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };

  const handleSort = (property, direction) => {
    const isAsc = direction === 'asc';
    const fullProps = { metrics, rowDim, colDim, sort: [{ property, isAsc }] };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };

  return (
    <AvTable
      hasHeaderFilters={false}
      size="xSmall"
      rowLimit={PAGE_SIZE}
      enableSelectionColor={false}
      allowEdit={false}
      headCells={tableHeadCells}
      loading={isLoading}
      rows={rows}
      onSort={handleSort}
      pagination
      paginationProps={{
        onPageChange: page => updateAnalyzeProps('page', page),
        totalRows: Math.ceil(totals / groupedHeadCells.length),
        pageNum: parseInt(page),
      }}
      groupedHeadCells={{ cells: groupedHeadCells, innerCols: metrics.length }}
      totalProps={
        data?.[1]
          ? {
              colspan: 0,
              label: 'Summary',
              row: totalRow[0],
              cells: totalHeadCells.map(({ key }) => ({
                id: key,
                type: isPercentageMetric(key) ? 'percentage' : 'number',
              })),
            }
          : {}
      }
      sx={{ ...(isLoading && refetchingStyle) }}
    />
  );
}

export default OverTimeTable;

const convertRowsToPivot = (
  data: any[],
  rowDim: string,
  colDim: string,
  groupedHeadCells: HeadCellType[],
  metricKeys: any[],
  metricOptions: any[],
  explodeFields: any
) => {
  // const rowValues = [...new Set(data.map(r => r[rowDim]))];
  const orderedHeadCells: { key: string; label: any }[] = [];
  const headCellsKeys = {};
  const rows = data.reduce((acc, row) => {
    const rowDimKey = explodeFields[rowDim]?.group || rowDim;
    if (!acc[row[rowDimKey]]) {
      const boolFieldValue = typeof row[rowDimKey] === 'boolean' ? (row[rowDimKey] ? 'True' : 'False') : undefined;
      acc[row[rowDimKey]] = {
        [rowDimKey]: boolFieldValue || row[rowDimKey],
      };
    }

    const metricValues = {};
    metricKeys.forEach(m => {
      const key = `${m}-${row[colDim]}`;
      metricValues[key] = row[m];
      if (!headCellsKeys[key]) {
        headCellsKeys[key] = true;
        orderedHeadCells.push({ key, label: metricOptions?.find(o => o.value === m).title || m });
      }
    });

    acc[row[rowDimKey]] = {
      ...acc[row[rowDimKey]],
      ...metricValues,
    };
    return acc;
  }, {});
  return { rows: Object.values(rows), headCells: orderedHeadCells };
};

const tooltip = v => <AvTooltip>{v || NO_VALUE}</AvTooltip>;
