import React, { useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAvContext } from '../../context/AvContextProvider';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import { PAGE_PATHS } from '../../types';
import { expressionToFilter, filterToExpression } from '../../utils/filterUtils';
import { isDeepEqual, setNestedValue } from '../../utils/Utils';
import { clickthroughPrefix } from './components/Clickthrough/consts';
import { useDashboard, useGetDisplayName } from './hooks';
import { AllTypeWidgets, CustomDashboardDto, TileSubType, TileWidgetVisualization } from './types';
import { FieldType, TabsType } from './types/types';
import { defaultDashboard, defaultTable, defaultWidget } from './Utils';

type SetNewWidgetOptions = { resetSize?: boolean };

type CustomDashboardContextType = {
  newWidget: AllTypeWidgets;
  setNewWidget: (v: AllTypeWidgets, options?: SetNewWidgetOptions) => void;
  newDashboard: CustomDashboardDto;
  setNewDashboard: React.Dispatch<React.SetStateAction<CustomDashboardDto>>;
  isLoadingCustomDashboard: boolean;
  filters: any;
  setFilters: any;
  expandedWidgetId: string | undefined;
  setExpandedWidgetId: React.Dispatch<React.SetStateAction<string | undefined>>;
  getDisplayName: (alias: string) => string;
  isDashboardChanged: boolean;
  initDashboard: CustomDashboardDto;
  setInitDashboard: (dashboard: CustomDashboardDto) => void;
  globalFilters: Record<string, any>;
  isAllowedEdit: boolean;
};

export const defaultFilters = {
  date: [],
  tab: [TabsType.Data],
  dataSelectTypes: [FieldType.All],
  isEditWidgetOpen: ['false'],
  isEditMode: ['false'],
};

export const CustomDashboardContext = React.createContext<CustomDashboardContextType>({
  setNewWidget(): void {},
  newWidget: defaultWidget,
  newDashboard: defaultDashboard,
  setNewDashboard(): void {},
  filters: defaultFilters,
  setFilters(): void {},
  isLoadingCustomDashboard: false,
  expandedWidgetId: undefined,
  setExpandedWidgetId: () => {},
  getDisplayName: () => '',
  isDashboardChanged: false,
  initDashboard: defaultDashboard,
  setInitDashboard: () => {},
  globalFilters: {},
  isAllowedEdit: false,
});

// eslint-disable-next-line react/prop-types
const CustomDashboardContextProvider = ({ children }) => {
  const {
    userPermissions: { hasAllowedEditResourcePermission },
    accountEntities: { fieldTypeMap },
    getPathName,
  } = useAvContext();
  const { id } = useParams();
  const [newWidget, setNewWidget] = useState<AllTypeWidgets>(defaultTable());
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter: defaultFilters,
    dateType: ['date'],
    shouldBeArray: () => true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [expandedWidgetId, setExpandedWidgetId] = useState<string | undefined>();
  const location = useLocation();
  const dashboardInfo: Partial<CustomDashboardDto> = location.state;
  const [newDashboard, setNewDashboard] = useState<CustomDashboardDto>({ ...defaultDashboard, ...dashboardInfo });
  const initDashboardRef = useRef<CustomDashboardDto>(defaultDashboard);

  const isAllowedEdit = hasAllowedEditResourcePermission({
    path: PAGE_PATHS.CUSTOM_DASHBOARDS,
    isNew: !id,
  });
  const globalFilters = useMemo(
    () =>
      filterToExpression(
        fieldTypeMap,
        Object.keys(filters).reduce(
          (acc, cur) =>
            Object.prototype.hasOwnProperty.call(defaultFilters, cur) || cur.startsWith(clickthroughPrefix)
              ? acc
              : { ...acc, [cur]: filters[cur]?.map(v => (v === 'null' ? null : v)) },
          {}
        )
      ),
    [filters]
  );

  const setInitDashboard = (dashboard: CustomDashboardDto) => (initDashboardRef.current = { ...dashboard, globalFilters });

  const isDashboardChanged = !isDeepEqual({ ...newDashboard, globalFilters }, initDashboardRef.current);

  const onSuccess = (data: CustomDashboardDto) => {
    const { globalFilters: globalFiltersFromDTO, ...rest } = data;
    setNewDashboard(rest);
    const shouldTakeGlobalFiltersFromURL = globalFilters && Object.keys(globalFilters).length > 0;
    const relevantGlobalFilters = shouldTakeGlobalFiltersFromURL ? globalFilters : globalFiltersFromDTO;
    setInitDashboard({ ...rest, globalFilters: relevantGlobalFilters });
    setFilters({ ...filters, ...expressionToFilter(fieldTypeMap, relevantGlobalFilters) });
    return data;
  };

  const onError = errors => {
    if (errors[0].extensions.status === 403) {
      enqueueSnackbar(errors[0].message, { variant: 'error' });
      navigate(getPathName(PAGE_PATHS.CUSTOM_DASHBOARDS));
    }
  };

  const { isLoading: isLoadingCustomDashboard } = useDashboard({ id, onSuccess, onError });

  const getDisplayName = useGetDisplayName(newWidget.requests[0].projectionId.name);

  const handleSetNewWidget = (newWidget: AllTypeWidgets, { resetSize }: SetNewWidgetOptions = { resetSize: true }) => {
    setNewWidget({
      ...newWidget,
      requests: resetSize
        ? newWidget.requests.map(r => ({ ...r, ...(r.top ? { top: { ...r.top, offset: 0 } } : r.top) }))
        : newWidget.requests,
      definition:
        newWidget.type === TileSubType.Tile && newWidget.requests[0].select.metrics.length === 0
          ? setNestedValue('custom.visualization', newWidget.definition, TileWidgetVisualization.None)
          : newWidget.definition,
    });
  };

  const value = useMemo(
    () => ({
      newWidget,
      setNewWidget: handleSetNewWidget,
      newDashboard,
      setNewDashboard,
      isLoadingCustomDashboard,
      filters,
      setFilters,
      expandedWidgetId,
      setExpandedWidgetId,
      getDisplayName,
      isDashboardChanged,
      initDashboard: initDashboardRef.current,
      setInitDashboard,
      globalFilters,
      isAllowedEdit,
    }),
    [newWidget, filters, newDashboard, isLoadingCustomDashboard, expandedWidgetId, isAllowedEdit]
  );

  return <CustomDashboardContext.Provider value={value}>{children}</CustomDashboardContext.Provider>;
};

export default CustomDashboardContextProvider;
