import React from 'react';
import { Box, Skeleton, useTheme } from '@mui/material';
import { flex } from '../../../../../components/AvThemeProvider';
import { rebranding } from '../../../../../rebranding';
import { isPercentageMetric } from '../../../../../utils/dashboardDataUtils';
import { severityBackgroundColorOrUndefined, SeverityLabels, useFactorSeverityByScore } from '../../../../../utils/severity.utils';
import { abbreviateNumber, getPercent, iconSize } from '../../../../../utils/Utils';
import { FactorCategory, factorCategoryToIcon } from '../../../../Tickets/Factors/utils';
import { useGetFieldType } from '../../../hooks';

const ScoreTileWidget = ({ widget, data, isLoading }) => {
  const theme = useTheme();
  const request = widget.requests[0];
  const firstMetric = request.select.metrics[0];
  const metric = firstMetric.alias || firstMetric.name;
  const dim = request.select.dims[0]?.alias || request.select.dims[0]?.name;
  const number = data[0]?.[metric] || data[0]?.[metric];
  const activeProjName = request.projectionId.name;

  const getMetricType = useGetFieldType(activeProjName);

  const metricType = getMetricType({ entityFieldKey: metric });
  const factorSeverityByScore = useFactorSeverityByScore();

  return (
    <Box sx={{ ...flex.col, gap: 1 }}>
      <Box sx={{ ...flex.justifyBetweenCenter }}>
        <Box sx={{ fontSize: 12, ...flex.row, gap: 1, svg: { color: theme.palette.colors.neutrals[600], ...iconSize(18) } }}>
          {FactorCategory[widget.definition.title.title] && factorCategoryToIcon[FactorCategory[widget.definition.title.title]]}
          {widget.definition.title.title}
        </Box>
        {isLoading ? (
          <Skeleton variant="text" height={18} width={20} />
        ) : (
          <Box sx={{ fontSize: 12, fontWeight: 600 }}>
            {isPercentageMetric(metric || dim) || metricType === 'percentage' ? getPercent(number) : abbreviateNumber(number)}
          </Box>
        )}
      </Box>
      <Box sx={{ ...flex.center, position: 'relative' }}>
        <Box
          sx={{
            height: 8,
            width: 52,
            backgroundColor: rebranding ? severityBackgroundColorOrUndefined(SeverityLabels.Low, theme) : theme.palette.colors.blue[400],
            borderBottomLeftRadius: 10,
            borderTopLeftRadius: 10,
          }}
        />
        <Box
          sx={{
            height: 8,
            width: 52,
            backgroundColor: rebranding
              ? severityBackgroundColorOrUndefined(SeverityLabels.Medium, theme)
              : theme.palette.colors.yellow[400],
          }}
        />
        <Box
          sx={{
            height: 8,
            width: 52,
            backgroundColor: rebranding ? severityBackgroundColorOrUndefined(SeverityLabels.High, theme) : theme.palette.colors.orange[400],
          }}
        />
        <Box
          sx={{
            height: 8,
            width: 52,
            backgroundColor: rebranding
              ? severityBackgroundColorOrUndefined(SeverityLabels.Critical, theme)
              : theme.palette.colors.negative[400],
            borderBottomRightRadius: 10,
            borderTopRightRadius: 10,
          }}
        />
        {!isLoading && (
          <Box
            sx={{
              position: 'absolute',
              left: number * 2,
              height: 18,
              width: 8,
              backgroundColor: factorSeverityByScore(number).color,
              borderRadius: 10,
              border: `2px solid ${theme.palette.white.main}`,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ScoreTileWidget;
