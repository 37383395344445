import React, { Component, ReactNode } from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { rebranding } from '../rebranding';
import { ErrorTypes } from '../types/query.types';
import { noop } from '../utils/Utils';
import PageNotFound from '../assets/emptyStates/Page not found.svg';
import SomethingWentWrong from '../assets/emptyStates/Something went Wrong.svg';
import FatalError from '../assets/FatalError.svg';
import NotFoundSVG from '../assets/NotFound.svg';

const notFoundStyle = {
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  animation: 'fadeIn 0.5s',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '24px',
  backgroundColor: theme => theme.palette.background.default,
  h3: {
    color: theme => theme.palette.colors.neutrals[600],
    marginBottom: 0,
  },
};
// TODO merge with NoDataFound component

interface NotFoundProps {
  text?: string;
  image?: string;
  buttonProps?: ButtonProps;
}

export function NotFound({ text = 'Page Not Found', image = rebranding ? PageNotFound : NotFoundSVG, buttonProps = {} }: NotFoundProps) {
  const navigate = useNavigate();
  const navi = () => navigate('/');
  return (
    <main>
      <Box sx={notFoundStyle}>
        <img src={image} alt="not found" style={{ marginBottom: '8px' }} />
        <Typography variant="h3">{text}</Typography>
        <Button variant="text" onClick={navi} sx={{ color: ({ palette }) => palette.primary.main, fontWeight: 600 }} {...buttonProps}>
          {buttonProps.children || 'Return to Homepage'}
        </Button>
      </Box>
    </main>
  );
}

interface ErrorBoundaryProps {
  children: ReactNode;
  location: object;
  fallbackComponent?: ReactNode;
  onError: () => void;
}

interface ErrorBoundaryState {
  errorType?: (typeof ErrorTypes)[keyof typeof ErrorTypes];
}
class ErrorBoundaryClass extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { errorType: undefined };
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { errorType } = prevState;
    if (errorType && prevProps.location !== location) {
      this.setState(({ errorType, ...prev }) => prev);
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { errorType: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    this.props.onError();
  }

  render() {
    const { errorType } = this.state;
    const { children, fallbackComponent } = this.props;

    if (errorType) {
      return (
        fallbackComponent || (
          <NotFound
            text="Something Went Wrong..."
            image={rebranding ? SomethingWentWrong : FatalError}
            buttonProps={
              errorType === ErrorTypes.AvContext
                ? { variant: 'contained', children: 'Refresh to reload', onClick: () => window.location.reload(), sx: { fontWeight: 600 } }
                : undefined
            }
          />
        )
      );
    }
    return children;
  }
}

export function ErrorBoundary({ children, onError = noop, ...props }) {
  const location = useLocation();
  return (
    <ErrorBoundaryClass location={location} onError={onError} {...props}>
      {children}
    </ErrorBoundaryClass>
  );
}
