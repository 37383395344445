import React from 'react';
import { ArrayResolution, Filter, StringConditionType } from '../../types/filter.types';
import { useGetValuesOptions } from '../../views/Logs/hooks';
import { FilterInput } from '../FieldFilter';
import Select from '../Select';
import { booleanOptions2, ConditionType, FilterOption, getCondition, isNullOperator, selectorWidth } from './Utils';

interface StringNumberTypeBoxProps {
  filter: Filter;
  setFilter: any;
  size: any;
  isVertical: boolean;
  typeCondition: ConditionType.string | ConditionType.number | ConditionType.bool;
  options: FilterOption[];
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  disabled?: boolean;
}
const FilterTypeBox: React.FC<StringNumberTypeBoxProps> = ({
  filter,
  setFilter,
  size,
  isVertical,
  typeCondition,
  options,
  supportParser,
  autocompleteFilter,
  disabled = false,
}) => {
  const compoundOperator = getCondition(filter, typeCondition, supportParser ? StringConditionType.EQUALS : undefined);
  const [operator, resolution = ArrayResolution.ANY] = compoundOperator.split('|');

  const innerExpression = filter.expression?.arrayCondition?.underlying || filter.expression;
  const setValue = value => {
    const underlying = { fieldName: innerExpression?.fieldName, [typeCondition]: { [operator]: value } };
    return setFilter({
      ...filter,
      expression: filter.expression?.arrayCondition ? { arrayCondition: { underlying, resolution } } : underlying,
    });
  };

  const value = isNullOperator(operator) ? '' : innerExpression?.[typeCondition]?.[operator];

  const width = isVertical ? selectorWidth : 250;
  const commonProps = {
    size,
    disabled: isNullOperator(operator) || !innerExpression?.fieldName || disabled,
    value,
    onChange: setValue,
    placeholder: 'Type Value',
  };

  const enableAutoCompleteValue =
    !!supportParser &&
    [StringConditionType.EQUALS, StringConditionType.NOT_EQUAL].includes(operator as StringConditionType) &&
    !!innerExpression?.fieldName;
  const { data: openSelectOptions, isLoading } = useGetValuesOptions({
    fieldName: innerExpression?.fieldName,
    enabled: enableAutoCompleteValue,
    autocompleteFilter,
  });
  return typeCondition !== ConditionType.bool ? (
    <FilterInput
      {...commonProps}
      width={width}
      numberField={typeCondition === ConditionType.number}
      operator={compoundOperator}
      options={options}
      optionsUseQueryProps={enableAutoCompleteValue ? { options: openSelectOptions, isLoading } : undefined}
      skeletonLoading
    />
  ) : (
    <Select
      {...commonProps}
      style={{ width }}
      isRequired
      muiProps={{ disablePortal: true }}
      options={[...booleanOptions2, ...(isNullOperator(operator) ? [{ title: '', value: '' }] : [])]}
    />
  );
};

export default FilterTypeBox;
