import React, { useMemo } from 'react';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../context/AvContextProvider';
import useUpdateAccountDefaultView from '../hooks/useUpdateAccountDefaultView';
import { Permission, PermissionEntitiesNames } from '../types';
import { ViewType } from '../types/savedViews.types';
import { iconSize, noop, uniqBy } from '../utils/Utils';
import AvTag from './AvTag';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import Select from './Select';
import { ActionButton } from './Table/Utils';
import { ReactComponent as AccountDefault } from '../assets/AccountsDefault.svg';
import { ReactComponent as CheckEmpty } from '../assets/CheckEmpty.svg';
import { ReactComponent as Delete } from '../assets/Delete.svg';
import { ReactComponent as Edit } from '../assets/Edit.svg';
import { ReactComponent as StarFull } from '../assets/StarFull.svg';
import { ReactComponent as StarLine } from '../assets/StarLine.svg';

const starFullIcon = <StarFull style={iconSize(20)} />;
const starLineIcon = <StarLine style={iconSize(20)} />;
const deleteIcon = <Delete />;
const editIcon = <Edit />;
const checkIcon = <CheckEmpty />;
const accountDefault = <AccountDefault />;

const getValueFunc = ({ id }) => id;

enum ViewPreferences {
  FAVORITE = 'favorite',
  DEFAULT = 'default',
}

const svgFillPurple = { svg: { color: theme => theme.palette.primary.main } };
const defaultViewContainerStyle = { ...flex.justifyBetweenCenter, ...svgFillPurple };

export interface ISavedViewSelectProps {
  selectedViewId: string;
  onChange: (e: any) => void;
  options?: any[];
  size?: string;
  viewsRefetch: () => void;
  onDeleteView: () => void;
  openViewPopper: () => void;
  userToDisplayName?: any;
  defaultEnabled?: boolean;
  loading?: boolean;
}
const SavedViewSelect: React.FC<ISavedViewSelectProps> = ({
  selectedViewId,
  onChange,
  options = [],
  size = 'small',
  viewsRefetch = noop,
  onDeleteView = noop,
  openViewPopper = noop,
  userToDisplayName = {},
  defaultEnabled = true,
  loading = false,
}) => {
  const sortedOptions = useMemo(() => {
    const view = options.find(option => option.id === selectedViewId);
    return options.length
      ? uniqBy(
          [
            view,
            ...options
              .sort((opt1, opt2) => -opt1.name.localeCompare(opt2.name))
              .sort((opt1, opt2) => (opt1.favorite < opt2.favorite ? 1 : -1)),
          ],
          getValueFunc
        )
      : options;
  }, [options, selectedViewId]);

  return (
    <Select
      muiProps={{
        sx: { maxWidth: 'unset' },
        componentsProps: { popper: { sx: { width: 385 } } },
      }}
      placeholder={loading ? 'Loading Saved Views...' : 'Select Saved View...'}
      size={size}
      value={selectedViewId}
      onChange={onChange}
      options={sortedOptions}
      getValueFunc={getValueFunc}
      getLabelFunc={({ name }) => name}
      renderOption={view => (
        <SavedViewItem
          view={view}
          viewsRefetch={viewsRefetch}
          openViewPopper={openViewPopper}
          selectedViewId={selectedViewId}
          onDeleteView={onDeleteView}
          userToDisplayName={userToDisplayName}
          defaultEnabled={defaultEnabled}
        />
      )}
      variant="filter"
      showClearSelection
      showSelection
    />
  );
};

export default SavedViewSelect;

interface ISavedViewItemProps {
  view: ViewType;
  viewsRefetch: () => void;
  openViewPopper: () => void;
  onDeleteView: () => void;
  selectedViewId: string;
  userToDisplayName: any;
  defaultEnabled: boolean;
}

const SavedViewItem: React.FC<ISavedViewItemProps> = ({
  view,
  viewsRefetch,
  openViewPopper,
  selectedViewId,
  onDeleteView,
  userToDisplayName,
  defaultEnabled = true,
}) => {
  const {
    api,
    user,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateAccountDefaultView } = useUpdateAccountDefaultView(view.accountDefaultView ? 'DELETE' : 'UPDATE');
  const isAllowToEditAccountDefaultView = hasAllowedPermissionToResource({
    resource: PermissionEntitiesNames.ACCOUNT,
    permission: Permission.UPDATE,
  });
  const updatePreference = preferenceType => e => {
    e.stopPropagation();
    const currentValue = preferenceType === ViewPreferences.DEFAULT ? view.defaultView : view.favorite;
    api(`view/preferences/${preferenceType}/${view.id}?value=${!currentValue}`, { options: { method: 'PUT' } }).then(() => {
      viewsRefetch();
      enqueueSnackbar(`Successfully updated ${preferenceType} view`, { variant: 'success' });
    });
  };

  const deleteView = () => {
    api(`view/${view.id}`, { options: { method: 'DELETE' } }).then(onDeleteView);
  };

  const isCurrentView = selectedViewId === view.id;
  const isEditEnabled = !view.global && user.userId === view.createdByUserId && isCurrentView;

  const updateAccountDefaultView = e => {
    e.stopPropagation();
    mutateAccountDefaultView({
      accountViewDto: { accountId: user.accountId, viewId: view.id, screenType: view.screenType },
      onSuccess: () => {
        viewsRefetch();
        enqueueSnackbar(`Successfully updated default account view`, { variant: 'success' });
      },
      onError: e => {
        console.error(e.message);
        enqueueSnackbar(`Failed to update default account view`, { variant: 'error' });
      },
    });
  };

  return (
    <Box sx={{ ...flex.col, width: '100%', pt: 0.5, pb: 0.5, gap: '3px' }}>
      <Box className="header" sx={{ ...flex.justifyBetweenCenter, flex: 1, width: '100%', gap: 1 }}>
        <Box
          className="info"
          sx={{
            ...flex.justifyStart,
            flex: 1,
            minWidth: 0,
            svg: { ...(view.favorite && { color: theme => theme.palette.colors.complementary[500] }) },
            '.MuiButton-root': { mx: 1 },
          }}>
          <Box sx={{ flex: 'none', ...flex.center }}>
            {ActionButton(
              !view.favorite ? 'Add to Favorite' : 'Remove from favorite',
              updatePreference(ViewPreferences.FAVORITE),
              view.favorite ? starFullIcon : starLineIcon
            )}
          </Box>

          <Box className="name" sx={{ fontWeight: 600, fontSize: 14, minWidth: 0 }}>
            <AvTooltip>{view.name}</AvTooltip>
          </Box>
        </Box>

        <Box className="actions" sx={{ ...flex.row, flex: 'none', overflow: 'hidden', gap: 1, svg: iconSize(20) }}>
          {defaultEnabled && isAllowToEditAccountDefaultView && (
            <AccountDefaultViewIcon isCurrentView={isCurrentView} view={view} updateAccountDefaultView={updateAccountDefaultView} />
          )}

          {defaultEnabled && <DefaultViewIcon isCurrentView={isCurrentView} view={view} updatePreference={updatePreference} />}

          {defaultEnabled && !isAllowToEditAccountDefaultView && view.accountDefaultView && (
            <AvTag
              sx={{
                backgroundColor: theme => theme.palette.colors.neutrals[300],
                height: 22,
                lineHeight: '18px',
              }}
              text="Account Default"
            />
          )}

          {isEditEnabled && (
            <>
              {ActionButton('Edit', () => openViewPopper(), editIcon)}
              {ActionButton('Delete', deleteView, deleteIcon)}
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          ...flex.justifyBetweenCenter,
          color: theme => theme.palette.colors.neutrals[600],
          fontSize: 12,
          'div:first-of-type': { mr: 1 },
          '.MuiButton-root': { fontSize: 'inherit' },
        }}>
        <Box className="createdByInfo" sx={{ ...flex.row, gap: 1, marginLeft: '36px', '> svg': { ...iconSize(14), mx: 1 } }}>
          {`By ${!view.global ? userToDisplayName[view.createdByUserId] : 'System'}`}
        </Box>
        {view?.createdAt && !view.global ? <span>{format(new Date(view.createdAt), 'MMM dd, yy')}</span> : null}
      </Box>
    </Box>
  );
};

interface IAccountDefaultViewIconProps {
  isCurrentView: boolean;
  view: ViewType;
  updateAccountDefaultView: (e) => void;
}

const AccountDefaultViewIcon: React.FC<IAccountDefaultViewIconProps> = ({ isCurrentView, view, updateAccountDefaultView }) => {
  if (isCurrentView) {
    return (
      <Box className="accountDefaultButton" sx={{ ...flex.center, ...(view.accountDefaultView && svgFillPurple) }}>
        {ActionButton(
          view.accountDefaultView ? 'Unset as Account Default View' : 'Set as Account Default View',
          updateAccountDefaultView,
          accountDefault
        )}
      </Box>
    );
  }

  if (view.accountDefaultView) {
    return (
      <AvTooltip title="Account Default View">
        <Box className="default" sx={defaultViewContainerStyle}>
          {accountDefault}
        </Box>
      </AvTooltip>
    );
  }
  return null;
};

interface IDefaultViewIconProps {
  isCurrentView: boolean;
  view: ViewType;
  updatePreference: (preferenceType) => (e) => void;
}

const DefaultViewIcon: React.FC<IDefaultViewIconProps> = ({ isCurrentView, view, updatePreference }) => {
  if (isCurrentView) {
    return (
      <Box className="defaultButton" sx={{ ...flex.center, ...(view.defaultView && svgFillPurple) }}>
        {ActionButton(
          view.defaultView ? 'Unset My Default View' : 'Set As My Default View',
          updatePreference(ViewPreferences.DEFAULT),
          checkIcon
        )}
      </Box>
    );
  }

  if (view.defaultView) {
    return (
      <AvTooltip title="My Default View">
        <Box className="default" sx={defaultViewContainerStyle}>
          {checkIcon}
        </Box>
      </AvTooltip>
    );
  }

  return null;
};
