import React, { useContext } from 'react';
import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { FieldListObject } from '../../context/context.type';
import { WorkflowType } from '../../context/SnackBar.types';
import { PolicyCategory, PolicyCategoryId, PolicyType } from '../../types/executionRules.types';
import { noop } from '../../utils/Utils';
import CmdbSimpleFilter from './components/CmdbSimpleFilter';
import ToolCoverageSimpleFilter from './components/ToolCoverageSimpleFilter';
import { DeleteExecutionRuleType } from './types';
import { clearEmptyPolicyScenarioFilter } from './utils';

export const useGetSimpleComponent = ({
  setNewData,
  newData,
  filterOptions,
  projName,
}: {
  setNewData: (newData: PolicyType) => void;
  newData: PolicyType;
  filterOptions: FieldListObject[];
  projName: string;
}) => {
  const commonProps = { setNewData, newData, filterOptions, projName };
  return {
    [PolicyCategory[PolicyCategoryId.CMDB]]: <CmdbSimpleFilter {...commonProps} />,
    [PolicyCategory[PolicyCategoryId.TOOL_COVERAGE]]: <ToolCoverageSimpleFilter {...commonProps} />,
  };
};

export const usePoliciesList = ({ type }: { type: string }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['policies', type],
    queryFn: () => api(GET_POLICIES_LIST, { options: { type }, onSuccess: ({ data }) => data.findExecutionRulesByType }),
    gcTime: 0,
  });
};

export const useGetPolicyById = ({ id, onSuccess }: { id?: string; onSuccess: (v: any) => void }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['policy_id', id],
    queryFn: () => api(GET_POLICY_BY_ID, { options: { id }, onSuccess: ({ data }) => onSuccess(data.findExecutionRuleById) }),
    enabled: !!id,
  });
};

export const useUpdatePolicy = (isEditMode: boolean) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ data, onSuccess }: { data: PolicyType; onSuccess: (d) => void }) =>
      api(isEditMode ? UPDATE_POLICY : CREATE_POLICY, { options: { executionRuleDto: clearEmptyPolicyScenarioFilter(data) }, onSuccess }),
  });
};

export const useDeletePolicy = (onSuccess: () => void) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { setRunInfo } = useContext(NotificationContext);
  return useMutation({
    mutationFn: ({ ids, options }: { ids: string[]; options: DeleteExecutionRuleType }) =>
      api(DELETE_POLICY, {
        options: { ids, options },
        onSuccess: ({ data }) => {
          const { runId, wfId } = data?.deleteExecutionRules || {};
          enqueueSnackbar(options.deleteModelData ? 'Policies deletion process has successfully begun' : 'Policy deleted successfully.', {
            variant: 'success',
          });
          if (runId && wfId) {
            setRunInfo({ runId, wfId, returnStatusOnly: true, workflowType: WorkflowType.DeletePolicyRules, onSuccess });
          }
          onSuccess();
        },
      }),
  });
};

export const useTestPolicy = ({
  policy,
  onSuccess = noop,
  onError = noop,
  showPreview = false,
  isLoadingData = false,
}: {
  policy: PolicyType;
  onSuccess: (v) => void;
  onError?: (e) => void;
  showPreview?: boolean;
  isLoadingData?: boolean;
}) => {
  const { api } = useAvContext();

  return useQuery({
    queryKey: ['previewEvaluation', JSON.stringify(policy)],
    queryFn: () =>
      api(TEST_EXECUTABLE_RULE, {
        options: {
          evaluationRequest: {
            isPrecheck: false,
            executableUnit: policy.executableUnits[0],
            ruleId: policy.id,
            type: policy.type,
            clientConfig: clearEmptyPolicyScenarioFilter(policy).clientConfig,
            metadata: policy.metadata,
          },
          gcTime: 0,
        },
        muteErrors: true,
        onSuccess: ({ data, errors }) => {
          if (errors) {
            onError(errors[0]);
          }
          onSuccess(data.previewEvaluation);
        },
        onError,
      }).then(({ data, errors }) => {
        if (errors) {
          throw new Error('Failed to preview evaluation');
        }
        return data.previewEvaluation?.result;
      }),
    enabled: showPreview && !isLoadingData,
  });
};

export const useActivatePolicies = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ ids, active }: { ids: string[]; active: boolean }) =>
      api(SET_POLICY_ACTIVATION, {
        options: {
          ids,
          active,
        },
        onError: err => enqueueSnackbar(`Failed to ${!active && 'de'}activate policies: ${err.message}`, { variant: 'error' }),
        onSuccess,
      }),
  });
};

export const useProcessNowExecutionRules = workflowType => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  const { setRunInfo } = useContext(NotificationContext);

  return useMutation({
    mutationFn: ({ ids }: { ids: string[] }) =>
      api(PROCESS_NOW, {
        options: { ids },
        onError: err => enqueueSnackbar(`Failed to process ${err.message}`, { variant: 'error' }),
        onSuccess: ({ data: { triggerExecutionRules } }) => {
          triggerExecutionRules.forEach(run => setRunInfo({ runId: run.runId, wfId: run.wfId, returnStatusOnly: true, workflowType }));
        },
      }),
  });
};

export const PROCESS_NOW = gql`
  mutation triggerExecutionRules($ids: [String!]!) {
    triggerExecutionRules(ids: $ids) {
      runId
      wfId
    }
  }
`;

export const SET_POLICY_ACTIVATION = gql`
  mutation setExecutionRuleActivation($ids: [String!]!, $active: Boolean!) {
    setExecutionRuleActivation(ids: $ids, active: $active)
  }
`;

export const GET_WORKFLOWS_RUNS_BY_TYPE = gql`
  query getWorkflowsByType($id: String!, $searchAttributeType: String!) {
    getWorkflowsByType(id: $id, searchAttributeType: $searchAttributeType)
  }
`;

const TEST_EXECUTABLE_RULE = gql`
  query previewEvaluation($evaluationRequest: EvaluationRequestInput!) {
    previewEvaluation(evaluationRequest: $evaluationRequest)
  }
`;

const DELETE_POLICY = gql`
  mutation deleteExecutionRules($ids: [String!]!, $options: ExecutionRuleDeletionOptionsInput) {
    deleteExecutionRules(ids: $ids, options: $options) {
      runId
      wfId
    }
  }
`;

const UPDATE_POLICY = gql`
  mutation updateExecutionRule($executionRuleDto: ExecutionRuleDtoInput!) {
    updateExecutionRule(executionRuleDto: $executionRuleDto) {
      id
    }
  }
`;

const CREATE_POLICY = gql`
  mutation createExecutionRule($executionRuleDto: ExecutionRuleDtoInput!) {
    createExecutionRule(executionRuleDto: $executionRuleDto) {
      id
    }
  }
`;

const GET_POLICIES_LIST = gql`
  query findExecutionRulesByType($type: ExecutionType!) {
    findExecutionRulesByType(type: $type) {
      id
      metadata
      executionRunConfiguration {
        id
        outcomes {
          outegrationId
        }
        active
        trigger {
          type
          value
        }
      }
    }
  }
`;

const GET_POLICY_BY_ID = gql`
  query findExecutionRuleById($id: String!) {
    findExecutionRuleById(id: $id) {
      id
      metadata
      executionRunConfiguration {
        id
        outcomes {
          outegrationId
        }
        active
        trigger {
          type
          value
        }
      }
      executableUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      preCheckUnits {
        executionConfig {
          evalStrategy
          fields {
            name
            assignment {
              assignmentType
              value
            }
          }
        }
        executionScript {
          evalStrategy
          script
        }
        primaryQuery
        secondaryQueries
      }
      type
      clientConfig
    }
  }
`;
