import React from 'react';
import { useAvContext } from '../../../context/AvContextProvider';
import { useCustomSearchParams } from '../../../hooks/UseCustomSearchParams';
import { iconSize } from '../../../utils/Utils';
import {
  clickthroughDimsPrefix,
  clickthroughMetricsPrefix,
  clickthroughNotOthersDimsPrefix,
  clickthroughTablePage,
  clickthroughWidgetId,
} from '../components/Clickthrough/consts';
import { useGetClickthroughDimsFromFilters } from '../components/Clickthrough/hooks';
import { getFiltersWithoutKeyPrefix } from '../components/Clickthrough/utils';
import { BarVisualizationType, getBarQueryType } from '../components/Widgets/BarWidget/BarWidget';
import { OTHERS } from '../constants';
import { AllTypeWidgets, WidgetCategory } from '../types';
import { ReactComponent as FormatListBulleted } from '../../../assets/Bulletlist.svg';

const listBullet = <FormatListBulleted style={iconSize(16)} />;

export const useGetNotOthers =
  () =>
  ({ dims, widgetData }: { dims: Record<string, string | string[] | number | number[]>; widgetData: string[] }) => {
    const dimsSet = new Set(Object.values(dims));

    const dimNameWithOthersValue = Object.keys(dims).find(
      dim => ((Array.isArray(dims[dim]) ? dims[dim] : [dims[dim]]) as Array<string | number>)?.includes(OTHERS)
    );
    const notOthersDimFilters = dimNameWithOthersValue
      ? { [`${clickthroughNotOthersDimsPrefix}${dimNameWithOthersValue}`]: widgetData.filter(val => !dimsSet.has(val)) }
      : {};

    return { dimNameWithOthersValue, notOthersDimFilters };
  };

// eslint-disable-next-line import/prefer-default-export
export const useGetWidgetSelectionProps = () => {
  const { measurements } = useAvContext();
  const [filters, setFilters] = useCustomSearchParams({ shouldBeArray: () => true });
  const clickthroughDims = useGetClickthroughDimsFromFilters();
  const getNotOthers = useGetNotOthers();

  const onClickthroughUpdate = ({
    widgetId,
    dims = {},
    metric,
    widgetData = [],
  }: {
    widgetId?: string;
    dims?: Record<string, string | string[]>;
    metric?: string;
    widgetData?: string[];
  }) => {
    if (!widgetId) {
      setFilters(getFiltersWithoutKeyPrefix(filters));
      return;
    }
    const metricOldSelectionProjName =
      filters[clickthroughMetricsPrefix]?.[0] &&
      measurements.visible.find(({ systemName }) => systemName === filters[clickthroughMetricsPrefix]?.[0])?.drillDown?.projId.name;
    const metricNewSelectionProjName =
      metric && measurements.visible.find(({ systemName }) => systemName === metric)?.drillDown?.projId.name;
    const dimsFilters = Object.keys(dims).reduce((acc, cur) => ({ ...acc, [`${clickthroughDimsPrefix}${cur}`]: dims[cur] }), {});
    const shouldResetClickthrough = !filters[clickthroughMetricsPrefix]?.[0] || metricNewSelectionProjName !== metricOldSelectionProjName;
    const { dimNameWithOthersValue, notOthersDimFilters } = getNotOthers({ dims, widgetData });
    if (shouldResetClickthrough) {
      setFilters({
        ...getFiltersWithoutKeyPrefix(filters),
        ...dimsFilters,
        ...notOthersDimFilters,
        [clickthroughWidgetId]: widgetId,
        [clickthroughMetricsPrefix]: metric,
        [clickthroughTablePage]: 0,
      });
      return;
    }

    const notOthersKey = Object.keys(filters).find(filter => filter.startsWith(clickthroughNotOthersDimsPrefix));
    const { [notOthersKey || '']: test, ...rest } = filters;
    setFilters({
      ...getFiltersWithoutKeyPrefix(dimNameWithOthersValue ? filters : rest, clickthroughDimsPrefix),
      ...dimsFilters,
      ...notOthersDimFilters,
      [clickthroughWidgetId]: widgetId,
      [clickthroughMetricsPrefix]: metric,
      [clickthroughTablePage]: 0,
    });
  };

  return ({ widget }: { widget: AllTypeWidgets }) => {
    const isHistoricWidget = !!widget.requests[0].timeRange;
    const activeIndex = widget.drillDownHierarchy?.activeIndex;
    return (
      {
        [WidgetCategory.Table]: () => {
          const selected =
            widget.id === filters[clickthroughWidgetId]?.[0]
              ? [
                  {
                    ...Object.keys(clickthroughDims).reduce((acc, cur) => ({ ...acc, [cur]: clickthroughDims[cur][0] }), {}),
                    metric: filters[clickthroughMetricsPrefix]?.[0],
                  },
                ]
              : [];
          const onSelect = ({ row, cell, currentIndex, e, widgetData }) => {
            const currentDrillDownDim = widget.drillDownHierarchy?.fields.slice(0, currentIndex || 0) || [];
            const dims = [...widget.requests[0].select.dims, ...currentDrillDownDim].reduce(
              (acc, cur) => ({ ...acc, [cur.name]: row[cur.name] }),
              {}
            );
            const othersDim = Object.keys(dims).find(dim => dims[dim] === OTHERS);

            onClickthroughUpdate({
              widgetId: widget.id,
              dims,
              metric: cell.id,
              widgetData: othersDim ? widgetData.map(val => val[othersDim]) : [],
            });
            e.stopPropagation();
            e.preventDefault();
          };

          return {
            onSelect,
            selected,
            clickInteractions: [
              {
                title: 'View Items',
                onClick: onSelect,
                isEnabledForCell: ({ dataKey }) => dataKey && !isHistoricWidget,
                icon: listBullet,
              },
            ],
          };
        },
        [WidgetCategory.Pie]: () => {
          const dim = widget.requests[0].select.dims?.[0]?.name;
          const metric = widget.requests[0].select.metrics?.[0]?.name;
          const selected =
            widget.id === filters[clickthroughWidgetId]?.[0]
              ? dim
                ? Object.values(clickthroughDims)[0]?.map(value => ({
                    value,
                  }))
                : [{ index: 0 }]
              : [];
          const onSelect = (selectedDim, widgetData) =>
            onClickthroughUpdate({
              widgetId: selectedDim.length ? widget.id : undefined,
              dims: dim ? { [dim]: selectedDim.map(({ value }) => value) } : undefined,
              metric,
              widgetData: dim ? widgetData.map(val => val[dim]) : [],
            });
          return {
            onSelect,
            selected,
            clickInteractions: selected.length
              ? undefined
              : [
                  {
                    title: 'View Items',
                    onClick: ({ d, widgetData }) => onSelect?.([{ value: d[dim] }], widgetData),
                    isEnabledForCell: () => activeIndex === 0 && !isHistoricWidget,
                    icon: listBullet,
                  },
                ],
          };
        },
        [WidgetCategory.Bar]: () => {
          const { dims, metrics } = widget.requests[0].select;
          const barQueryType = getBarQueryType({ dims, metrics });
          const selected =
            filters[clickthroughWidgetId]?.[0] === widget.id
              ? barQueryType === BarVisualizationType.Normal
                ? [{ name: Object.values(clickthroughDims)?.[0]?.[0] }]
                : barQueryType === BarVisualizationType.MultiDims
                  ? [
                      {
                        name: clickthroughDims[dims[0].name]?.[0],
                        dataKey: clickthroughDims[dims[1].name]?.[0],
                      },
                    ]
                  : [
                      {
                        name: clickthroughDims[dims[0].name]?.[0],
                        dataKey: filters[clickthroughMetricsPrefix]?.[0],
                      },
                    ]
              : [];
          const onSelect = ({ d, dataKey, widgetData }) => {
            if (barQueryType === BarVisualizationType.Normal) {
              onClickthroughUpdate({
                widgetId: widget.id,
                dims: { [dims[0].name]: dataKey },
                metric: metrics[0].name,
                widgetData: widgetData.map(({ name }) => name),
              });
            } else if (barQueryType === BarVisualizationType.MultiDims) {
              onClickthroughUpdate({
                widgetId: widget.id,
                dims: { [dims[0].name]: d.name, [dims[1].name]: dataKey },
                metric: metrics[0].name,
              });
            } else {
              onClickthroughUpdate({
                widgetId: widget.id,
                dims: { [dims[0].name]: d.name },
                metric: dataKey,
                widgetData: widgetData.map(({ name }) => name),
              });
            }
          };
          return {
            selected,
            onSelect,
            clickInteractions: selected.length
              ? undefined
              : [
                  {
                    title: 'View Items',
                    onClick: v => onSelect?.(v),
                    isEnabledForCell: ({ dataKey }: { dataKey?: string } = {}) => dataKey && activeIndex === 0 && !isHistoricWidget,
                    icon: listBullet,
                  },
                ],
          };
        },
        [WidgetCategory.Tile]: () => {
          const { metrics } = widget.requests[0].select;
          const onClickthrough = () =>
            onClickthroughUpdate({
              widgetId: widget.id,
              metric: metrics[0].name,
            });
          return {
            clickInteractions: [
              {
                title: 'View Items',
                onClick: () => onClickthrough?.(),
                isEnabledForCell: ({ dataKey }: { dataKey?: string } = {}) => dataKey && !isHistoricWidget,
                icon: listBullet,
              },
            ],
          };
        },
      }[widget.category]?.() || {}
    );
  };
};
