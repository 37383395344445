import { PeriodBehavior } from '../../components/DatePicker/AvDateRangePicker.constants';
import { RelativeUnit } from '../../components/DatePicker/utils';
import { getDateFromFilter, getEmptyExpression, getEmptyFilterExpression } from '../../components/filters/Utils';
import {
  CommonPolicyFilterConfig,
  ConflictingValuesPolicyFilterConfig,
  ExecutableUnit,
  ExecutionRuleType,
  FilterType,
  PolicyCategory,
  PolicyCategoryId,
  PolicyMetadataType,
  PolicyType,
  TriggerType,
} from '../../types/executionRules.types';
import { ArrayResolution, DateCondition, Filter, OperatorType } from '../../types/filter.types';
import { Strategy } from '../../types/QueryObjectProto.types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { SeverityLabels } from '../../utils/severity.utils';

export const cmdbHygieneDefaultAssetPopulation = {
  and: {
    operands: [
      {
        expression: {
          fieldName: '',
          arrayCondition: {
            underlying: {
              fieldName: 'asset.source_names',
              stringCondition: {
                contains: 'ServiceNow',
              },
            },
            resolution: 'ALL',
          },
        },
      },
    ],
  },
};

export enum EditPageModes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
}

export const toolCoverageDefaultAssetPopulation = {
  and: {
    operands: [
      {
        expression: {
          fieldName: 'asset.last_seen',
          dateCondition: {
            relative: {
              unit: RelativeUnit.DAYS,
              value: 7,
              periodBehaviour: PeriodBehavior.last,
            },
          },
        },
      },
      {
        expression: {
          fieldName: 'asset.type',
          stringCondition: {
            contains: '',
          },
        },
      },
      {
        expression: {
          arrayCondition: {
            underlying: {
              fieldName: 'asset.source_names',
              stringCondition: {
                contains: '',
              },
            },
            resolution: ArrayResolution.ANY,
          },
        },
      },
    ],
  },
};
export const getToolCoverageFilter = ({
  rootOperator,
  sourcesNames = [],
  numOfDays = 7,
  secondFilter = getEmptyExpression(),
}: {
  rootOperator: OperatorType;
  sourcesNames?: string[];
  numOfDays?: number;
  secondFilter?: Filter;
}): Filter => ({
  [rootOperator]: {
    operands: [
      {
        and: {
          operands: [
            {
              expression: {
                fieldName: '',
                arrayCondition: {
                  resolution: ArrayResolution.ANY,
                  underlying: {
                    fieldName: 'asset.sources.source_names',
                    stringCondition: {
                      contains: sourcesNames.join(),
                    },
                  },
                },
              },
            },
            {
              not: {
                expression: {
                  fieldName: 'asset.sources.last_seen',
                  dateCondition: {
                    relative: {
                      unit: RelativeUnit.DAYS,
                      value: numOfDays,
                      periodBehaviour: PeriodBehavior.last,
                    },
                  },
                },
              },
            },
          ],
        },
      },
      secondFilter,
    ],
  },
});

// returns a Cmdb filter structure from the three selected fields
export const getConflictingValuesFilter = ({
  fieldName = '',
  uniqueValues = 0,
  excludedValues = [],
}: {
  fieldName?: string;
  uniqueValues?: number;
  excludedValues?: string[];
}) => ({
  filterType: FilterType.CONFLICTING_VALUES,
  fieldName,
  uniqueValues,
  excludedValues,
});

export const cmdbSimpleFilterDefaults = {
  [FilterType.MISSING_FIELD]: {
    filterType: FilterType.MISSING_FIELD,
    filter: {
      or: {
        operands: [
          {
            expression: {
              fieldName: 'asset.owner_id',
              stringCondition: {
                empty: {},
              },
            },
          },
          {
            expression: {
              fieldName: 'asset.owner_id',
              stringCondition: {
                equals: 'Unknown',
              },
            },
          },
        ],
      },
    },
  },
  [FilterType.CONFLICTING_VALUES]: {
    filterType: FilterType.CONFLICTING_VALUES,
    fieldName: 'asset.sources.owner_id',
    uniqueValues: 1,
    excludedValues: [],
  },
};

export const defaultPolicyMetadataProps: PolicyMetadataType = {
  policy_name: '',
  policy_description: '',
  severity: SeverityLabels.Medium,
  severity_score: 5,
  type: PolicyCategory[PolicyCategoryId.NONE],
};
export const defaultExecutableUnitProps: ExecutableUnit = {
  executionConfig: {
    fields: [],
    evalStrategy: Strategy.CEL,
  },
  primaryQuery: {
    evaluatePerRow: true,
    query: {
      name: '',
      queryObject: {
        sourceProjection: { name: entityViewConfig.Asset.projectionName, builtIn: true },
        dims: [],
        metrics: [],
      },
    },
  },
  secondaryQueries: [],
};

export const defaultPolicyTypeProps: PolicyType = {
  executableUnits: [defaultExecutableUnitProps],
  preCheckUnits: [],
  type: ExecutionRuleType.ASSET_POLICY,
  metadata: defaultPolicyMetadataProps,
  executionRunConfiguration: {
    trigger: {
      type: TriggerType.SCHEDULED,
      value: '0 0 * * *',
    },
    outcomes: [],
    active: true,
  },
  clientConfig: {
    assetPopulationFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'asset.type',
              stringCondition: {
                contains: '',
              },
            },
          },
          {
            expression: {
              arrayCondition: {
                underlying: {
                  fieldName: 'asset.source_names',
                  stringCondition: {
                    contains: '',
                  },
                },
                resolution: ArrayResolution.ANY,
              },
            },
          },
        ],
      },
    },
    policyScenario: {
      filterType: FilterType.ADVANCED,
      filter: getEmptyFilterExpression(),
    },
  },
};

export const categoryToDefaultFilterMap = {
  [PolicyCategoryId.CMDB]: {
    assetPopulationFilter: cmdbHygieneDefaultAssetPopulation,
    policyScenario: cmdbSimpleFilterDefaults[FilterType.MISSING_FIELD],
  },
  [PolicyCategoryId.TOOL_COVERAGE]: {
    assetPopulationFilter: toolCoverageDefaultAssetPopulation,
    policyScenario: {
      filterType: FilterType.TOOL_COVERAGE,
      filter: getToolCoverageFilter({ rootOperator: OperatorType.OR }),
    },
  },
};

export const getDistinctValuesObjects = (newData: PolicyType) => {
  const { fieldName, uniqueValues, excludedValues } = newData.clientConfig.policyScenario as ConflictingValuesPolicyFilterConfig;
  return { fieldName, uniqueValues, excludedValues };
};
const isCommonPolicyFilterConfig = (
  policyScenario: CommonPolicyFilterConfig | ConflictingValuesPolicyFilterConfig
): policyScenario is CommonPolicyFilterConfig => policyScenario.filterType !== 'CONFLICTING_VALUES';

const cleanToolCoverageFilter = (filter: Filter) => {
  const operand = Object.keys(filter)[0];

  return filter[operand].operands?.[1]?.expression.fieldName === null ? { [operand]: { operands: [filter[operand].operands[0]] } } : filter;
};

export const clearEmptyPolicyScenarioFilter = (newData: PolicyType) =>
  isCommonPolicyFilterConfig(newData.clientConfig.policyScenario)
    ? {
        ...newData,
        clientConfig: {
          ...newData.clientConfig,
          policyScenario: {
            ...newData.clientConfig.policyScenario,
            filter:
              newData.clientConfig.policyScenario.filter.and?.operands[0].expression?.fieldName === null
                ? null
                : newData.clientConfig.policyScenario.filterType === FilterType.TOOL_COVERAGE
                  ? cleanToolCoverageFilter(newData.clientConfig.policyScenario.filter)
                  : newData.clientConfig.policyScenario.filter,
          },
        },
      }
    : newData;

export const dateOptions = [
  { title: 'Days', value: RelativeUnit.DAYS },
  { title: 'Weeks', value: RelativeUnit.WEEKS },
  { title: 'Months', value: RelativeUnit.MONTHS },
];

export const getTimeRangeAndDatePickerValue = (dateObject: DateCondition) => {
  const timeRange = JSON.parse(decodeURIComponent(dateObject as any));
  const { value: values } = getDateFromFilter(timeRange as DateCondition);
  const startDate = new Date(values.from);
  const endDate = values.to ? new Date(values.to) : new Date();
  return { timeRange, datePickerValue: { from: startDate, to: endDate } };
};
