import React from 'react';
import { Area, AreaChart } from 'recharts';
import { useFactorSeverityByScore } from '../../../utils/severity.utils';
import { useFactorLineChart } from './hooks';

interface FactorLineChartProps {
  factorKey: string;
  yKey: string;
  scoreLeft: number;
  scoreRight: number;
}

const FactorLineChart: React.FC<FactorLineChartProps> = ({ factorKey, yKey, scoreLeft, scoreRight }) => {
  const { data } = useFactorLineChart({});
  const factorSeverityByScore = useFactorSeverityByScore();
  const { bgColor, color } = factorSeverityByScore((scoreLeft / scoreRight) * 100);
  return (
    <AreaChart width={90} height={28} data={data[factorKey]}>
      <Area type="monotone" dataKey={yKey} stroke={color} fill={bgColor} />
    </AreaChart>
  );
};

export default FactorLineChart;
