import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStaticErrorSnackBar } from '../../components/AvSnackBarMessage';
import { useAvContext } from '../../context/AvContextProvider';
import useHandleError from '../../hooks/useHandleError';
import { FeatureFlags, PermissionEntitiesNames } from '../../types';
import { generateOption, getNetworkErrorCode, isNullOrUndefined } from '../../utils/Utils';
import { AlertDestinationConfig, Integration, IntegrationWithConfig, MappingGroup, SchemaField, TMapping } from './types';

export const GET_SCHEMA_AND_DEFAULT_MAPPING = gql`
  query FetchSchemaAndGetDefaultMapping(
    $id: String
    $authenticationSourceId: String
    $integrationInstanceConfig: AlertDestinationConfig
    $gatewayId: String
    $integrationType: String!
    $projectionId: ProjectionID
  ) {
    fetchIntegrationSchema(
      id: $id
      authenticationSourceId: $authenticationSourceId
      integrationInstanceConfig: $integrationInstanceConfig
      gatewayId: $gatewayId
    )
    getIntegrationDefaultMapping(integrationType: $integrationType, projectionId: $projectionId) {
      createMapping
      syncMapping
      syncMappingEnabled
      attachmentOnCreate {
        fileName
        format
        query {
          filter
          groupBy
          distinct
          source
          metricsFilterThreshold
          select {
            metrics {
              name
              alias
            }
            dims {
              name
              alias
            }
          }
          sorting {
            name
            dir
          }
          top {
            size
            groupOthers
            offset
          }
          projectionId {
            name
            builtIn
          }
          timeRange
        }
        triggerFields {
          field
          from {
            value
            type
          }
          to {
            value
            type
          }
        }
      }
      attachmentOnSync {
        fileName
        format
        query {
          filter
          groupBy
          distinct
          source
          metricsFilterThreshold
          select {
            metrics {
              name
              alias
            }
            dims {
              name
              alias
            }
          }
          sorting {
            name
            dir
          }
          top {
            size
            groupOthers
            offset
          }
          projectionId {
            name
            builtIn
          }
          timeRange
        }
        triggerFields {
          field
          from {
            value
            type
          }
          to {
            value
            type
          }
        }
      }
    }
  }
`;

const GET_LIST = gql`
  query {
    findIntegrationInstanceByAccountId {
      id
      name
      integrationType
      active
      category
    }
  }
`;

export const GET_BY_ID = gql`
  query findIntegrationInstanceById($id: String!) {
    findIntegrationInstanceById(id: $id) {
      id
      name
      active
      integrationType
      redactedConfig
      schema
      category
      mapping {
        id
        createMapping
        syncComments
        syncCommentsTriggers {
          field
          from {
            value
            type
          }
          to {
            value
            type
          }
        }
        createMappingEnabled
        syncMapping
        syncMappingEnabled
        webhookMapping
        webhookMappingEnabled
        attachmentOnCreate {
          fileName
          format
          query {
            filter
            groupBy
            distinct
            source
            metricsFilterThreshold
            select {
              metrics {
                name
                alias
              }
              dims {
                name
                alias
              }
            }
            sorting {
              name
              dir
            }
            top {
              size
              groupOthers
              offset
            }
            projectionId {
              name
              builtIn
            }
            timeRange
          }
          triggerFields {
            field
            from {
              value
              type
            }
            to {
              value
              type
            }
          }
        }
        attachmentOnSync {
          fileName
          format
          query {
            filter
            groupBy
            distinct
            source
            metricsFilterThreshold
            select {
              metrics {
                name
                alias
              }
              dims {
                name
                alias
              }
            }
            sorting {
              name
              dir
            }
            top {
              size
              groupOthers
              offset
            }
            projectionId {
              name
              builtIn
            }
            timeRange
          }

          triggerFields {
            field
            from {
              value
              type
            }
            to {
              value
              type
            }
          }
        }
      }
      matchingCondition
      targetMatchingType
      category
      authenticationSourceId
      projectionName
      gatewayId
    }
  }
`;

const UPDATE_INTEGRATION = gql`
  mutation ($integrationInstanceInput: IntegrationInstanceInput!, $skipValidation: Boolean) {
    updateIntegrationInstance(integrationInstanceInput: $integrationInstanceInput, skipValidation: $skipValidation) {
      name
    }
  }
`;

const CREATE_INTEGRATION = gql`
  mutation ($integrationInstanceInput: IntegrationInstanceInput!, $skipValidation: Boolean) {
    createIntegrationInstance(integrationInstanceInput: $integrationInstanceInput, skipValidation: $skipValidation) {
      name
    }
  }
`;

const TEST_NOTIFICATION = gql`
  query testNotification(
    $integrationInstanceConfig: AlertDestinationConfig
    $authenticationSourceId: String
    $id: String
    $gatewayId: String
  ) {
    testNotification(
      integrationInstanceConfig: $integrationInstanceConfig
      authenticationSourceId: $authenticationSourceId
      id: $id
      gatewayId: $gatewayId
    )
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation ($ids: [String]!) {
    deleteIntegrations(ids: $ids)
  }
`;

const DUPLICATE_INTEGRATION = gql`
  mutation ($integrationInstanceId: String!) {
    duplicateIntegration(integrationInstanceId: $integrationInstanceId)
  }
`;

const TEST_CREATE_MAPPING = gql`
  query ($integrationInstanceId: String, $mapping: MappingMetadata, $entityKey: String!) {
    testCreateIntegrationMapping(integrationInstanceId: $integrationInstanceId, createMapping: $mapping, entityKey: $entityKey)
  }
`;

const FETCH_SCHEMA_BY_ID_OR_CONFIG = gql`
  query ($id: String, $authenticationSourceId: String, $integrationInstanceConfig: AlertDestinationConfig, $gatewayId: String) {
    fetchIntegrationSchema(
      id: $id
      authenticationSourceId: $authenticationSourceId
      integrationInstanceConfig: $integrationInstanceConfig
      gatewayId: $gatewayId
    )
  }
`;

const GET_INTEGRATION_CONTEXT = gql`
  query ($id: String, $authenticationSourceId: String, $integrationInstanceConfig: AlertDestinationConfig, $gatewayId: String) {
    getIntegrationConfigContext(
      id: $id
      authenticationSourceId: $authenticationSourceId
      integrationInstanceConfig: $integrationInstanceConfig
      gatewayId: $gatewayId
    )
  }
`;

export const useGetIntegrationContext = ({
  id,
  authenticationSourceId,
  integrationInstanceConfig,
  gatewayId,
  enabled,
}: {
  id?: string;
  authenticationSourceId?: string;
  integrationInstanceConfig: AlertDestinationConfig;
  gatewayId?: string;
  enabled: boolean;
}) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['contextByIntegration', id],
    queryFn: () =>
      api(GET_INTEGRATION_CONTEXT, {
        options: { id, authenticationSourceId, integrationInstanceConfig, gatewayId },
        onSuccess: ({ data }) => {
          const context = data.getIntegrationConfigContext;
          return Object.keys(context).reduce((acc, key) => ({ ...acc, [key]: context[key].map(generateOption) }), {});
        },
      }),
    enabled,
  });
};

export const useNotificationTargetsList = (options?) => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  return useQuery<Integration[]>({
    queryKey: ['NotificationTargets'],
    queryFn: () => api(GET_LIST, { onSuccess: ({ data }) => data.findIntegrationInstanceByAccountId }),
    ...options,
    enabled: hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.INTEGRATION }),
  });
};

export const useFetchSchemaByConfigMutation = () => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({
      integrationInstanceConfig,
      gatewayId,
      authenticationSourceId,
    }: {
      integrationInstanceConfig: AlertDestinationConfig;
      gatewayId?: string;
      authenticationSourceId?: string;
    }): Promise<SchemaField[]> =>
      api(FETCH_SCHEMA_BY_ID_OR_CONFIG, {
        options: { integrationInstanceConfig, gatewayId, authenticationSourceId },
        onSuccess: ({ data }) => data.fetchIntegrationSchema,
      }),
  });
};

export const useGetIntegrationById = id => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['integration', id],
    queryFn: (): Integration =>
      api(GET_BY_ID, { options: { id } }).then(({ data }) => ({
        ...data?.findIntegrationInstanceById,
        mapping: {
          ...data.findIntegrationInstanceById.mapping,
          createMapping: {
            ...data.findIntegrationInstanceById.mapping.createMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.createMapping.fieldMapping || [],
          },
          syncMapping: {
            ...data.findIntegrationInstanceById.mapping.syncMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.syncMapping.fieldMapping || [],
          },
          webhookMapping: {
            ...data.findIntegrationInstanceById.mapping.webhookMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.webhookMapping.fieldMapping || [],
          },
        },
      })),
    gcTime: 0,
    enabled: !!id,
  });
};

export const useUpdateIntegration = ({ onSuccess, errorMessage }) => {
  const { api, featureFlags } = useAvContext();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const errorSnackBar = useStaticErrorSnackBar();
  const onError = e => {
    if ([400, 406].includes(getNetworkErrorCode(e))) {
      console.warn(e);
      errorSnackBar({ title: 'Outegration Update Failed', message: e.message, subTitle: errorMessage });
    } else {
      handleError(e);
    }
  };
  return useMutation({
    mutationFn: (integration: Integration, skipValidation = false): any => {
      const integrationInstanceInput = {
        id: integration.id,
        name: integration.name,
        active: integration.active,
        integrationType: integration.integrationType,
        projectionName: integration.projectionName,
        config: integration.redactedConfig,
        category: integration.category,
        mapping: integration.mapping,
        targetMatchingType: integration.targetMatchingType,
        matchingCondition: integration.matchingCondition,
        authenticationSourceId: integration.authenticationSourceId,
        gatewayId: integration.gatewayId,
      };
      return api(integration.id ? UPDATE_INTEGRATION : CREATE_INTEGRATION, {
        options: { integrationInstanceInput, skipValidation },
        onSuccess: ({ data, errors }) => (data ? onSuccess() : onError(errors)),
        muteErrors: true,
      }).catch(onError);
    },
  });
};

export const useDuplicationNotificationTarget = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: notificationTargetId =>
      api(DUPLICATE_INTEGRATION, {
        options: { integrationInstanceId: notificationTargetId },
        onSuccess: ({ data }) => {
          enqueueSnackbar('Duplicated Successfully', { variant: 'success' });
          return data.duplicateIntegration;
        },
      }),
  });
};

export const useTestCreateMapping = () => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({
      integrationInstanceId,
      mapping,
      entityKey,
    }: {
      integrationInstanceId: string;
      mapping: MappingGroup;
      entityKey: string;
    }) =>
      api(TEST_CREATE_MAPPING, { options: { integrationInstanceId, mapping, entityKey } }).then(
        ({ data }) => data.testCreateIntegrationMapping
      ),
  });
};

export const useTestNotification = () => {
  const { api, featureFlags } = useAvContext();
  const errorSnackBar = useStaticErrorSnackBar();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const onError = e => {
    if ([400, 406].includes(getNetworkErrorCode(e))) {
      console.warn(e);
      errorSnackBar({
        title: 'Connection Validation Failed',
        message: e.message,
        subTitle: 'The connection details provided were not validated',
      });
    } else {
      handleError(e);
    }
  };
  return useMutation({
    mutationFn: ({
      integrationInstanceConfig,
      authenticationSourceId,
      id,
      gatewayId,
      onSuccess,
    }: {
      integrationInstanceConfig: AlertDestinationConfig;
      authenticationSourceId: string | undefined;
      id: string | undefined;
      gatewayId: string | undefined;
      onSuccess: (data?) => void;
    }) =>
      api(TEST_NOTIFICATION, {
        options: {
          integrationInstanceConfig,
          authenticationSourceId,
          id,
          gatewayId,
        },
        muteErrors: true,
        onSuccess: ({ data, errors }: any) => {
          if (errors) {
            onError(errors[0].extensions);
            return undefined;
          }
          onSuccess?.();
          return data.testNotification;
        },
      }).catch(onError),
  });
};

export const useFetchSchemaAndDefaultMapping = ({
  id,
  authenticationSourceId,
  integrationInstanceConfig,
  gatewayId,
  onSuccess,
  enabled,
  newData,
}: {
  id?: string;
  authenticationSourceId?: string;
  integrationInstanceConfig?: AlertDestinationConfig;
  gatewayId?: string;
  onSuccess: (schema: SchemaField[], defaultMapping: TMapping) => void;
  enabled: boolean;
  newData: Integration | IntegrationWithConfig;
}) => {
  const {
    api,
    accountEntities: { aggProjs },
    featureFlags,
  } = useAvContext();
  const errorSnackBar = useStaticErrorSnackBar();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);
  const onError = e => {
    if (getNetworkErrorCode(e) === 400) {
      console.warn(e);
      errorSnackBar({
        title: 'Schema Fetch Failed',
        message: e[0].message,
        subTitle: `The outegration’s schema could not be fetched. Verify the credentials that were provided for authentication in the Connection step`,
      });
    } else {
      handleError(e);
    }
  };
  const { integrationType, id: integrationId, projectionName } = newData;
  const getDefaultMapping = newData && Object.keys(newData).length > 0 && isNullOrUndefined(integrationId);
  const projectionId = projectionName ? aggProjs[projectionName].projId : null;
  const gqlQuery = getDefaultMapping ? GET_SCHEMA_AND_DEFAULT_MAPPING : FETCH_SCHEMA_BY_ID_OR_CONFIG;
  const queryOptions = getDefaultMapping
    ? { id, authenticationSourceId, integrationInstanceConfig, gatewayId, integrationType, projectionId }
    : { id, authenticationSourceId, integrationInstanceConfig, gatewayId };
  const handleSuccess = data => {
    onSuccess(data.fetchIntegrationSchema, data?.getIntegrationDefaultMapping);
    return data.fetchIntegrationSchema;
  };

  return useQuery({
    queryKey: ['schemaByConfigAndDefaultMapping', getDefaultMapping ? 'withDefaultMapping' : 'withoutDefaultMapping'],
    gcTime: 0,
    enabled: enabled && (!!id || !!integrationInstanceConfig),
    queryFn: (): Promise<SchemaField[]> =>
      api(gqlQuery, {
        options: queryOptions,
        onSuccess: ({ data, errors }) => (errors ? onError(errors) : handleSuccess(data)),
        muteErrors: true,
      }).catch(onError),
  });
};
