import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { flex } from '../../../components/AvThemeProvider';
import { cleanQueryObjectProtoFilters } from '../../../hooks/useQueryObjectProto';
import { rebranding } from '../../../rebranding';
import {
  AnyQuery,
  BaseExecutionRule,
  defaultCelPerType,
  DefaultExecutionConfigFieldsMapType,
  ExecutableQueryObject,
  ExecutableQuerySql,
  ExecutableUnit,
  ExecutableUnitConfig,
  ExecutableUnitScript,
  ExecutionConfigObject,
  ExecutionScriptObject,
  FactorType,
} from '../../../types/executionRules.types';
import { QueryObjectProto, Strategy } from '../../../types/QueryObjectProto.types';
import { AssignmentType } from '../../DataUnification/types';
import FactorRulesConfig from '../FactorRulesConfig';

export const defaultQueryObject: QueryObjectProto = {
  dims: [],
  metrics: [],
  sourceProjection: { name: 'uber_configurations', builtIn: true },
};

export const defaultNamedQueryObject: ExecutableQueryObject = {
  name: 'data',
  queryObject: defaultQueryObject,
};

export const defaultNamedPlainQuery: ExecutableQuerySql = {
  name: 'Query 1',
  querySql: 'SELECT configuration._key\nFROM uber_configurations LIMIT 1',
};

export const getDefaultExecutionConfigFieldsMap: (factorType: FactorType) => DefaultExecutionConfigFieldsMapType = (
  factorType: FactorType
) => ({
  ...(factorType !== FactorType.GROUP_FACTOR && { raw_score: '', raw_zscore: '' }),
  recommendation: '',
  note: '',
  is_licensed: "size(licenses) > 0 && has(metadata.prodSkuIds) ? metadata.prodSkuIds.all(x, x in licenses.map(x, x['sku_id'])) : false",
});

export const RequiredExecutionConfigFields = (factorType: FactorType) =>
  new Set(
    Object.values(FactorRulesConfig.fields)
      .filter(({ includeInGroupFactor }) => (factorType === FactorType.GROUP_FACTOR ? includeInGroupFactor : true))
      .filter(({ required }) => required)
      .map(({ field }) => field)
  );
// new Set(['recommendation', 'note', 'is_licensed', ...(factorType !== FactorType.GROUP_FACTOR ? ['raw_score'] : [])]);

export const defaultExecutionConfig: (factorType: FactorType) => ExecutionConfigObject = factorType => ({
  fields: [
    ...(factorType === FactorType.GROUP_FACTOR
      ? []
      : [
          {
            name: 'raw_score',
            assignment: {
              assignmentType: AssignmentType.CEL_EXPRESSION,
              value: '',
            },
          },
          {
            name: 'raw_zscore',
            assignment: {
              assignmentType: AssignmentType.CEL_EXPRESSION,
              value: '',
            },
          },
        ]),
    {
      name: 'recommendation',
      assignment: {
        assignmentType: AssignmentType.CEL_EXPRESSION,
        value: 'metadata.recommendation',
      },
    },
    {
      name: 'note',
      assignment: {
        assignmentType: AssignmentType.CEL_EXPRESSION,
        value: 'metadata.note',
      },
    },
    {
      name: 'is_licensed',
      assignment: {
        assignmentType: AssignmentType.CEL_EXPRESSION,
        value: "size(licenses) > 0 && has(metadata.prodSkuIds) ? metadata.prodSkuIds.all(x, x in licenses.map(x, x['sku_id'])) : false",
      },
    },
  ],
  evalStrategy: Strategy.CEL,
});

export const defaultExecutionScript: (type: FactorType) => ExecutionScriptObject = type => ({
  script: defaultCelPerType[type],
  evalStrategy: Strategy.CEL,
});

// Type guard function to check for QueryObjects
export const isExecutableQueryObject = (obj: AnyQuery): obj is ExecutableQueryObject => 'queryObject' in obj && !!obj.queryObject;

export const isQueryObject = (obj: any): obj is QueryObjectProto => 'dims' in obj;

export const isExecutableRuleQueryObjects = (obj: ExecutableUnit): obj is ExecutableUnitConfig =>
  'executionConfig' in obj && !!obj.executionConfig;
export const isExecutableRuleQuerySql = (obj: ExecutableUnit): obj is ExecutableUnitScript =>
  'executionScript' in obj && !!obj.executionScript;

export const isInvalidQuery = (query: AnyQuery) =>
  isExecutableQueryObject(query) ? !(query.queryObject.dims.length || query.queryObject.metrics.length) : !query.querySql.length;

export const filterObjectKeys = (obj: Record<string, any>, keysToRemove: string[]) =>
  Object.entries(obj)
    .filter(([key]) => !keysToRemove.includes(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

export const parseJsonOrJsonl = (inputString, returnAsList = false) => {
  // Check if the string contains newline characters
  if (inputString.includes('\n')) {
    // Split the string into lines and parse each line into a JSON object
    return inputString
      .split('\n')
      .map(line => {
        try {
          return JSON.parse(line);
        } catch {
          return null;
        }
      })
      .filter(item => item !== null); // Remove null entries
  }
  // No newline characters, treat the whole string as a single JSON object
  try {
    return returnAsList ? [JSON.parse(inputString)] : JSON.parse(inputString);
  } catch {
    return null;
  }
};

export const getCleanExecutableUnits = (executableUnits: ExecutableUnit[]) =>
  executableUnits.map((preCheckUnit: ExecutableUnit) => {
    const isExecutableRuleQueryObjectsBool = isExecutableRuleQueryObjects(preCheckUnit);
    const relevantKey = isExecutableRuleQueryObjectsBool ? 'executionConfig' : 'executionScript';
    const { primaryQuery, secondaryQueries } = preCheckUnit;
    const cleanPrimaryQuery = isExecutableQueryObject(primaryQuery.query)
      ? cleanQueryObjectProtoFilters(primaryQuery.query.queryObject)
      : primaryQuery.query.querySql;
    const cleanSecondaryQueries = secondaryQueries.map(secondaryQuery => {
      const { query } = secondaryQuery;
      const cleanQuery = isExecutableQueryObject(query) ? cleanQueryObjectProtoFilters(query.queryObject) : query.querySql;
      return {
        ...secondaryQuery,
        query: {
          ...query,
          [isExecutableQueryObject(query) ? 'queryObject' : 'querySql']: cleanQuery,
        },
      };
    });
    return {
      ...preCheckUnit,
      [relevantKey]: preCheckUnit[relevantKey],
      primaryQuery: {
        ...primaryQuery,
        query: {
          ...primaryQuery.query,
          [isExecutableQueryObject(primaryQuery.query) ? 'queryObject' : 'querySql']: cleanPrimaryQuery,
        },
      },
      secondaryQueries: cleanSecondaryQueries,
    };
  });

export const defaultExecutable = ({ isPrecheck = false, factorType }: { isPrecheck: boolean; factorType: FactorType }): ExecutableUnit => ({
  ...(isPrecheck
    ? {
        executionScript: {
          script: 'true',
          evalStrategy: Strategy.CEL,
        },
      }
    : { executionConfig: defaultExecutionConfig(factorType) }),
  primaryQuery: { query: defaultNamedQueryObject, evaluatePerRow: false },
  secondaryQueries: [],
});

export const startAdornment = label =>
  function innerLabel() {
    return <Box sx={innerLabelStyle}>{label}</Box>;
  };

export const innerLabelStyle = {
  ...flex.itemsCenter,
  color: ({ palette }) => palette.colors.neutrals[rebranding ? 600 : 500],
  position: 'relative',
  right: 6,
  mr: '-2px',
  fontSize: 13,
  ...(rebranding && { fontWeight: 400 }),
  '.MuiAutocomplete-inputRoot &': { mr: '-6px' },
  ':before': {
    content: '""',
    backgroundColor: ({ palette }) => palette.colors.neutrals[200],
    width: 'calc(100% + 17px)',
    position: 'absolute',
    top: -6,
    bottom: -6,
    left: -9,
    zIndex: -1,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
};

export const isInvalidExecutableUnit = (v: ExecutableUnit) =>
  v.secondaryQueries.map(({ query }) => query).some(isInvalidQuery) || isInvalidQuery(v.primaryQuery.query);

export const checkIfAnyQueryInvalid = (newData: BaseExecutionRule) =>
  newData.executableUnits?.some(isInvalidExecutableUnit) || checkIfAnyPrecheckInvalid(newData);

export const checkIfAnyPrecheckInvalid = (newData: BaseExecutionRule) => newData.preCheckUnits?.some(isInvalidExecutableUnit);

export const cardStyle = { pb: 3, px: 3 };
export const ExecutableRuleTitle = ({ title }) => <Typography variant="h6">{title}</Typography>;
export const checkIfAnyTestIsInvalid = (executableUnit: ExecutableUnit, factorType: FactorType) => {
  if (isExecutableRuleQueryObjects(executableUnit)) {
    const { executionConfig } = executableUnit;
    const requiredExecutionConfigFields = RequiredExecutionConfigFields(factorType);
    return executionConfig.fields.some(field => !field.assignment.value && requiredExecutionConfigFields.has(field.name));
  }
  const { executionScript } = executableUnit;
  return !executionScript.script;
};
