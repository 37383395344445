import React, { useEffect, useMemo, useState } from 'react';
import { Button, ClickAwayListener, Divider, Grow, IconButton, Paper, Popper, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { arrayEquals, iconSize, rearrange } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import TextInput from './TextInput';
import { ReactComponent as DragSmall } from '../assets/DragSmall.svg';
import { ReactComponent as Key } from '../assets/Key.svg';
import { ReactComponent as Plus } from '../assets/Plus.svg';
import { ReactComponent as Refresh } from '../assets/Refresh.svg';
import { ReactComponent as Search } from '../assets/Search.svg';
import { ReactComponent as Settings } from '../assets/Settings.svg';
import { ReactComponent as Sorting } from '../assets/Sorting.svg';
import { ReactComponent as X } from '../assets/X.svg';

const clearIcon = <X style={iconSize(18)} />;
const plusIcon = <Plus style={iconSize(18)} />;
const sortingIcon = <Sorting style={iconSize(20)} />;
const settingsIcon = size => <Settings style={iconSize(size)} />;
const dragIcon = <DragSmall />;
const refreshIcon = <Refresh style={{ ...iconSize(18), transform: 'scaleX(-1)' }} />;
const closeButton = <X style={iconSize(24)} />;
const keyIcon = color => <Key style={{ ...iconSize(12), color }} />;

type TItems = { id: string; label: string; hidden: boolean }[];
const itemFunc = (items: TItems, icon?: React.JSX.Element) =>
  function dragItem(providedDrag, snapshotDrag, rubric) {
    return (
      <Box
        ref={providedDrag.innerRef}
        {...providedDrag.draggableProps}
        {...providedDrag.dragHandleProps}
        sx={getItemStyle(snapshotDrag.isDragging, providedDrag.draggableProps.style)}>
        <Box sx={{ ...flex.row, gap: 1, overflow: 'hidden' }}>
          {dragIcon}
          <AvTooltip>{items[rubric.source.index].label}</AvTooltip>
        </Box>
        {icon}
      </Box>
    );
  };

interface ColumnReorderProps {
  items: TItems;
  setItems: (items: TItems) => void;
  initialColumns: TItems;
  firstIndexKey?: boolean;
  disabled?: boolean;
  sortOnly?: boolean;
  width?: number;
}
const ColumnReorder: React.FC<ColumnReorderProps> = ({
  items: originalItems,
  setItems: setOriginalItems,
  initialColumns,
  firstIndexKey = false,
  disabled = false,
  sortOnly = false,
  width = 20,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState(originalItems);
  const [search, setSearch] = useState('');
  const filteredItems = items.filter(v => v.label.toLowerCase().includes(search.toLowerCase()));
  useEffect(() => setItems(originalItems), [originalItems]);
  const hasChanges = useMemo(
    () => !arrayEquals(items, initialColumns, ({ id, hidden }, init) => id === init.id && hidden === init.hidden),
    [items, initialColumns]
  );

  const handleClick = ({ currentTarget }: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : currentTarget);

  const onClose = () => {
    setAnchorEl(null);
    setItems(originalItems);
    setSearch('');
  };

  const apply = () => {
    setOriginalItems(items);
    onClose();
  };

  const assortedItems = {
    available: filteredItems.filter(({ hidden }) => hidden),
    used: filteredItems.filter(({ hidden }) => !hidden),
  };

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const sourceIndex = items.indexOf(assortedItems?.[source.droppableId]?.[source.index]);
    const destinationIndex = items.indexOf(assortedItems?.[destination.droppableId]?.[destination.index]);
    if (![destinationIndex, sourceIndex].includes(-1) && sourceIndex !== destinationIndex) {
      setItems(rearrange(items, items[sourceIndex], destinationIndex, 'id', source.droppableId !== destination.droppableId));
    }
  };
  const toggleVisible = (items, id, hidden) => setItems(items.map(item => (item.id === id ? { ...item, hidden } : item)));

  const buttonClass = [anchorEl && 'Mui-focusVisible', 'transparent'].filter(Boolean).join(' ');

  return (
    <>
      <Button
        variant="filter"
        disabled={disabled}
        className={buttonClass}
        onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
        onClick={handleClick}
        sx={{ px: 1 }}>
        {settingsIcon(width)}
      </Button>
      {anchorEl && (
        <Popper
          open={Boolean(anchorEl)}
          placement="bottom-start"
          anchorEl={anchorEl}
          transition
          sx={{ zIndex: ({ zIndex }) => zIndex.modal, borderRadius: '2px', position: 'relative' }}>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom' }}>
              <Paper sx={{ ...flex.col }}>
                <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
                  <Box>
                    <IconButton onClick={onClose} sx={{ position: 'absolute', top: 16, right: 16 }}>
                      {closeButton}
                    </IconButton>
                    <Box sx={{ ...(sortOnly ? {} : { ...flex.itemsCenter, pb: 1 }), pl: 3 }}>
                      <Box
                        sx={{
                          color: ({ palette }) => palette.colors.neutrals[850],
                          fontSize: 16,
                          fontWeight: 600,
                          pt: 3,
                          pr: 1,
                          pb: 2,
                        }}>
                        Manage Table Columns
                      </Box>
                      <TextInput
                        sx={{ width: 220, pb: sortOnly ? 1 : undefined, pl: sortOnly ? 1 : undefined, pt: !sortOnly ? 1 : undefined }}
                        enableAnimation={!sortOnly}
                        helperText="Search"
                        value={search}
                        onChange={setSearch}
                        icon={<Search style={{ ...iconSize(18), color: theme.palette.colors.neutrals[600] }} />}
                        size="small"
                      />
                    </Box>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Box sx={style}>
                        {!sortOnly && (
                          <Droppable droppableId="available" renderClone={itemFunc(assortedItems.available)}>
                            {(provided, snapshot) => (
                              <Box
                                component="section"
                                className="available"
                                data-dragorigin={snapshot.draggingFromThisWith && !snapshot.isDraggingOver}>
                                <Typography variant="h7">Available ({assortedItems.available.length})</Typography>
                                <Box {...provided.droppableProps} ref={provided.innerRef} sx={listStyle}>
                                  {assortedItems.available.map(({ id }, index) => (
                                    <Box key={id} sx={itemWrapperStyle()}>
                                      <Draggable draggableId={id} index={index} key={id}>
                                        {itemFunc(
                                          assortedItems.available,
                                          <IconButton onClick={() => toggleVisible(items, id, false)}>{plusIcon}</IconButton>
                                        )}
                                      </Draggable>
                                    </Box>
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              </Box>
                            )}
                          </Droppable>
                        )}
                        {!sortOnly && (
                          <Box sx={{ ...flex.itemsCenter, color: theme => theme.palette.colors.neutrals[400] }}>{sortingIcon}</Box>
                        )}
                        <Droppable droppableId="used" renderClone={itemFunc(assortedItems.used)}>
                          {(provided, snapshot) => (
                            <Box
                              component="section"
                              className="used"
                              data-dragorigin={snapshot.draggingFromThisWith && !snapshot.isDraggingOver}>
                              <Typography variant="h7" sx={{ color: theme => theme.palette.colors.primary[400] }}>
                                In Use ({assortedItems.used.length})
                              </Typography>
                              <Box {...provided.droppableProps} ref={provided.innerRef} sx={listStyle}>
                                {assortedItems.used.map(({ id }, index) => (
                                  <Box key={id} sx={itemWrapperStyle(true)}>
                                    <Draggable draggableId={id} index={index} key={id} isDragDisabled={assortedItems.used.length === 1}>
                                      {itemFunc(
                                        assortedItems.used,
                                        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                                          {firstIndexKey && items.findIndex(item => item.id === id) === 0
                                            ? keyIcon(theme.palette.colors.primary[300])
                                            : null}
                                          {assortedItems.used.length > 1 && !sortOnly ? (
                                            <IconButton onClick={() => toggleVisible(items, id, true)}>{clearIcon}</IconButton>
                                          ) : null}
                                        </Box>
                                      )}
                                    </Draggable>
                                  </Box>
                                ))}
                                {provided.placeholder}
                              </Box>
                            </Box>
                          )}
                        </Droppable>
                      </Box>
                    </DragDropContext>
                    <Divider />
                    <Box sx={{ ...flex.justifyBetween, gap: 3, padding: '12px 24px' }}>
                      {hasChanges ? (
                        <Button onClick={() => setItems(initialColumns)} variant="outlined" size="small">
                          {refreshIcon} {sortOnly ? '' : 'Reset to '}Default
                        </Button>
                      ) : (
                        <div />
                      )}
                      <Box sx={{ ...flex.row, gap: 3 }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={apply} variant="contained" size="small">
                          Apply
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
export default ColumnReorder;

const style = ({ palette }) => ({
  ...flex.row,
  gap: 1,
  px: 4,
  pb: 4,
  section: {
    ...flex.col,
    gap: 2,
    borderRadius: '2px',
    p: 3,
    minWidth: 300,
    '&.available': {
      backgroundColor: palette.colors.neutrals[150],
      border: `1px solid ${palette.colors.neutrals[300]}`,
    },
    '&.used': {
      backgroundColor: palette.colors.primary[100],
      border: `1px solid ${palette.colors.primary[200]}`,
    },
    '&[data-dragorigin="true"] ~ section, &:has(~ [data-dragorigin="true"])': {
      outline: `2px dashed ${palette.colors.primary[500]}`,
    },
  },
});

const itemWidth = 250;
const emptyOutlineWidth = 2;
const itemWrapperStyle = (hasNumbers?) => ({
  position: 'relative',
  '&:empty': {
    outline: ({ palette }) => `2px solid ${palette.colors.neutrals[400]}`,
    top: -4,
    left: (hasNumbers ? 24 : 0) + emptyOutlineWidth,
    width: itemWidth - emptyOutlineWidth * 2,
  },
  ...(hasNumbers && {
    '+ [data-rbd-placeholder-context-id]': {
      position: 'relative',
    },
    '&:not(:empty), + [data-rbd-placeholder-context-id]': {
      counterIncrement: 'column',
      pl: 3,
      '&:before': {
        content: 'counter(column)',
        position: 'absolute',
        top: 4,
        left: 0,
        fontWeight: 600,
        fontSize: 12,
        color: theme => theme.palette.colors.primary[300],
      },
    },
  }),
});

const getItemStyle =
  (isDragging, draggableStyle) =>
  ({ palette }) => ({
    ...flex.justifyBetween,
    flexGrow: 1,
    width: itemWidth,
    height: 24,
    mb: 1,
    userSelect: 'none',
    padding: '2px 12px',
    borderRadius: '10px',
    border: '1px solid',
    borderColor: isDragging ? palette.colors.primary[500] : palette.colors.neutrals[350],
    backgroundColor: palette.colors.neutrals[100],
    fontSize: 13,
    fontWeight: 600,
    ...draggableStyle,
    svg: {
      color: isDragging ? palette.colors.primary[500] : palette.colors.neutrals[600],
    },
  });

const listStyle = {
  ...flex.col,
  height: '100%',
  maxHeight: 200,
  overflow: 'auto',
};
