import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { SEVERITY_LABELS } from '../../utils/severity.utils';
import { flex } from '../AvThemeProvider';
import { SeverityItem } from '../ItemWithLogo';

interface SeverityStatusBarsProps {
  data?: any[];
}

const SeverityStatusBars: React.FC<SeverityStatusBarsProps> = ({ data = [] }) => {
  const { palette } = useTheme();
  const severities = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
  return (
    <Box sx={{ ...flex.justifyBetweenCenter, height: '100%' }}>
      {severities.map((s, index) => {
        const row = data.find(r => r['ticket.severity'] === s);
        return (
          <Box
            key={s}
            sx={{
              ...flex.itemsStart,
              height: '100%',
              gap: '8px',
              background: palette.white.main,
              flex: 1,
              pt: 3,
              pb: 2,
              px: 2,
              borderRight: index !== severities.length - 1 ? `8px solid ${palette.colors.neutrals[150]}` : '',
            }}>
            <span style={{ alignSelf: 'flex-start' }}>
              <SeverityItem width={25} value={s} showText={false} />
            </span>
            <Box sx={{ ...flex.col, gap: 1 }}>
              <Box sx={{ ...flex.itemsCenter, gap: '12px' }}>
                <Box sx={{ ...flex.col, gap: 1 }}>
                  <Typography variant="h5" sx={{ fontSize: 22 }}>
                    {row?.ticket_count?.toLocaleString() || 0}
                  </Typography>
                  <Box
                    sx={{
                      color: palette.colors.neutrals[500],
                      fontSize: 13,
                    }}>
                    {SEVERITY_LABELS[s]} Open Tickets
                  </Box>
                </Box>
                <Divider flexItem orientation="vertical" />
                <Box sx={{ ...flex.col, gap: 1, color: palette.colors.neutrals[500] }}>
                  <Typography variant="h5" sx={{ fontSize: 22, fontWeight: 300 }}>
                    {row?.finding_count?.toLocaleString() || 0}
                  </Typography>
                  <Box sx={{ fontSize: 13 }}>Active Findings</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SeverityStatusBars;
