import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, InputLabel, LinearProgress, SxProps, Theme, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../context/AvContextProvider';
import { noop } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { ReactComponent as Checkmark } from '../assets/Checkmark.svg';
import { ReactComponent as Upload } from '../assets/UploadCloud.svg';

const height = 270;

const styles = {
  root: {
    flexDirection: 'row',
  },
  outer: {
    ...flex.colCenter,
    label: {
      marginBottom: '4px',
    },
  },
  frame: (theme, isError, disabled) => ({
    ...flex.colCenter,
    minHeight: height,
    border: `2px dashed ${isError ? theme.palette.error.main : theme.palette.colors.neutrals[400]}`,
    background: theme.palette.colors.neutrals[150],
    borderRadius: '2px',
    transition: theme.transitions.create(['border-color', 'background-color']),
    '&.Mui-focused': {
      borderColor: theme.palette.colors.primary[500],
      background: theme.palette.colors.primary[100],
    },
    '&:hover': {
      borderColor: !disabled && theme.palette.colors.neutrals[600],
    },
    svg: {
      height: 135,
      width: 169,
      marginBottom: '8px',
    },
  }),
  label: {
    alignSelf: 'start',
  },
  main: {
    color: theme => theme.palette.colors.neutrals[400],
    fontSize: 24,
    lineHeight: 1.5,
    fontWeight: 600,
  },
  secondary: {
    color: theme => theme.palette.colors.neutrals[500],
  },
  browse: { color: theme => theme.palette.colors.primary[500], fontWeight: 600 },
  uploadingProgress: { marginTop: '-2px', width: '100%' },
  uploadingText: { height: '100%', display: 'flex', alignItems: 'center' },
  filename: { fontWeight: 600 },
  preview: {
    height,
    paddingLeft: '24px',
    borderLeft: theme => `1px solid ${theme.palette.colors.neutrals[400]}`,
    margin: 'auto 0px auto 24px',
  },
  previewImage: {
    width: '140px',
    height: '70px',
    textAlign: 'center',
    border: theme => `1px solid ${theme.palette.colors.neutrals[300]}`,
    borderRadius: '12px',
    '& img': {
      height: '100%',
      width: '100%',
      padding: '8px',
    },
  },
  uploadedLabel: {
    alignSelf: 'flex-start',
    marginLeft: 2,
    display: 'flex',
    position: 'absolute',
    top: 16,
    svg: { color: theme => theme.palette.primary.main },
  },
  ellipsis: { whiteSpace: 'nowrap', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis' },
};

function validateFileExtension(type: string, filename: string): boolean {
  return FileAcceptTypes[type].split(',').includes(`.${filename.split('.').at(-1)}`);
}

type UploadConfigFile = { uploadedFileName: string; uploadedFileContent: string };
export type S3File = { file: File; path: string };

interface UploadFileProps {
  label?: string;
  value?: S3File | UploadConfigFile;
  onChange: (params: S3File | UploadConfigFile) => void;
  url: string;
  isUploadConfig?: boolean;
  lastUploadedFileName?: string;
  filename: string;
  fileType?: string;
  height?: string;
  width?: string;
  error?: string;
  disabled?: boolean;
}
const UploadFile: React.FC<UploadFileProps> = ({
  label = '',
  value,
  onChange = noop,
  url,
  isUploadConfig = false,
  lastUploadedFileName = '',
  filename,
  fileType,
  height,
  width = '450px',
  error: externalError,
  disabled = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dragOver, setDragOver] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(lastUploadedFileName);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { api } = useAvContext();

  const upload = async (file: File) => {
    setError(undefined);
    try {
      if (validateFileExtension(fileType!, file.name)) {
        const formData = new FormData();
        formData.append(filename, file);
        onChange(file as any);
        setUploadedFileName(file.name);
        setIsLoading(true);
        await api(url, { options: { method: 'POST' }, body: formData, isJson: false }).then(({ path, uploadedFileContent }) => {
          setIsLoading(false);
          onChange(isUploadConfig ? { uploadedFileName: file.name, uploadedFileContent } : { file, path });
        });
      } else {
        const validationError = new Error('Invalid file type') as any;
        validationError.status = 400;
        throw validationError;
      }
    } catch (e: any) {
      setUploadedFileName('');
      setIsLoading(false);
      if ([e?.status, e?.graphQLErrors?.[0]?.extensions?.status].includes(400)) {
        setError(e.message);
      } else {
        console.error(e);
      }
      enqueueSnackbar(typeof e?.message === 'string' ? e?.message : 'Something went wrong, Our engineers have been notified', {
        variant: 'error',
      });
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    setDragOver(false);
    event.preventDefault();
    upload(event.dataTransfer.files[0]);
  };

  const onDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    setDragOver(true);
    event.preventDefault();
  };

  const isError = Boolean(error || externalError);

  return (
    <FormControl sx={styles.root} error={isError} disabled={disabled}>
      <Box sx={styles.outer}>
        <InputLabel sx={styles.label}>{label}</InputLabel>
        <FormLabel
          error={isError}
          sx={(theme => ({ ...styles.frame(theme, isError, disabled), height, width })) as SxProps<Theme>}
          className={dragOver ? 'Mui-focused' : ''}
          onDrop={handleDrop}
          onDragOver={onDragOver}
          onDragLeave={() => setDragOver(false)}>
          {isLoading ? (
            <>
              <Box sx={styles.uploadingProgress}>
                <LinearProgress />
                <Typography sx={{ margin: 2, ...styles.ellipsis }}>
                  Uploading file{' '}
                  <Typography component="span" sx={styles.filename}>
                    {uploadedFileName}
                  </Typography>
                </Typography>
              </Box>
              <Box sx={styles.uploadingText}>
                <Typography sx={{ ...styles.main, ...styles.uploadingText }}>UPLOADING...</Typography>
              </Box>
            </>
          ) : (
            <>
              {uploadedFileName && (
                <Box sx={styles.uploadedLabel}>
                  <Checkmark style={{ height: 20, width: 20 }} />
                  <Typography sx={{ marginX: 2, ...styles.ellipsis }}>
                    Uploaded{' '}
                    <Typography component="span" sx={styles.filename}>
                      {uploadedFileName}
                    </Typography>
                  </Typography>
                </Box>
              )}
              <Input
                inputProps={{ accept: FileAcceptTypes[fileType!] }}
                onChange={({ target: { files } }: any) => upload(files[0])}
                type="file"
                sx={visuallyHidden}
              />
              <Upload />
              {!disabled && (
                <>
                  <Typography sx={styles.main}>DRAG & DROP</Typography>
                  <Typography sx={styles.secondary}>
                    files here, or{' '}
                    <Typography component="span" sx={styles.browse}>
                      Browse
                    </Typography>
                  </Typography>
                </>
              )}
            </>
          )}
        </FormLabel>
        <Typography sx={{ ...styles.secondary, alignSelf: 'start' }}>Accepted File Types: {fileType}</Typography>
      </Box>

      {(value as S3File)?.path || (value as S3File)?.file ? (
        <Box sx={styles.preview}>
          <InputLabel focused={false} sx={{ mb: 1 }}>
            Preview
          </InputLabel>
          <Box sx={styles.previewImage}>
            <img src={(value as S3File).file ? URL.createObjectURL((value as S3File).file) : `/${(value as S3File).path}`} alt="preview" />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export default UploadFile;

export const UploadFileType = {
  Image: 'JPG, GIF, PNG',
  Data: 'JSON, JSONL, CSV, ZIP, XML, ZST, ZSTD',
  Config: 'JSON',
};

const FileAcceptTypes = {
  [UploadFileType.Image]: '.jpg,.png,.jpeg,.svg',
  [UploadFileType.Data]: '.zst,.zstd,.zip,.json,.jsonl,.csv,.xml,.nessus',
  [UploadFileType.Config]: '.json',
};
