import React, { useEffect, useRef, useState } from 'react';
import { Box, Fade, Palette, SxProps, Theme, ToggleButton, Tooltip, useTheme } from '@mui/material';
import { ellipsis, emptyObject } from '../utils/Utils';

interface AvTooltipProps {
  children: any;
  title?: React.ReactNode | string;
  variant?: string;
  color?: 'white';
  muiProps?: Omit<React.ComponentProps<typeof Tooltip>, 'children' | 'title'>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  hasWidthLimit?: boolean;
}

const AvTooltip: React.FC<AvTooltipProps> = ({
  children,
  title,
  variant = 'normal',
  color,
  hasWidthLimit = true,
  muiProps = emptyObject,
  disabled = false,
  ...others
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const { palette } = useTheme();
  const textElementRef = useRef<HTMLDivElement>(null);

  const compareSize = () =>
    textElementRef.current && setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);

  useEffect(() => {
    if (!title) {
      compareSize();
      window.addEventListener('resize', compareSize);

      return () => window.removeEventListener('resize', compareSize);
    }
    return undefined;
  }, []);

  return (
    <Tooltip
      componentsProps={getStyles(hasWidthLimit, palette, color)}
      title={title || children}
      disableHoverListener={!title && !isOverflowed}
      arrow={variant === 'message'}
      TransitionComponent={Fade}
      disableInteractive
      TransitionProps={{ timeout: 200 }}
      PopperProps={disabled ? { sx: { opacity: 0 } } : {}}
      {...muiProps}>
      {title ? (
        children
      ) : (
        <Box ref={textElementRef} {...others} sx={{ ...ellipsis, ...others.sx }}>
          {children}
        </Box>
      )}
    </Tooltip>
  );
};

export default AvTooltip;
export const TooltipToggleButton = React.forwardRef(({ tooltipProps, ...props }: any, ref) => (
  <AvTooltip {...tooltipProps}>
    <ToggleButton ref={ref} {...props} />
  </AvTooltip>
));

const whiteStyle = palette => ({
  tooltip: {
    sx: {
      fontSize: 14,
      padding: 1.5,
      background: palette.colors.neutrals[100],
      color: palette.colors.neutrals[800],
      boxShadow: '0px 2px 20px rgba(18, 18, 27, 0.15)',
    },
  },
  arrow: {
    sx: {
      color: palette.colors.neutrals[100],
    },
  },
});

const getStyles = (hasWidthLimit: boolean, palette: Palette, color: 'white' | undefined = undefined) => {
  const { tooltip, arrow } = color === 'white' ? whiteStyle(palette) : { tooltip: { sx: {} }, arrow: { sx: {} } };

  if (!hasWidthLimit) {
    tooltip.sx = { ...tooltip.sx, maxWidth: 'none' };
  }

  return { tooltip, arrow };
};
