import React, { useMemo } from 'react';
import { ChartClickInteraction } from '../../../../components/Widgets/types';
import {
  ScoreTypeWidget,
  TileCategoryWidgetTypes,
  TileSubType,
  TileTypeWidget,
  TileWidgetVisualization,
  TrendTypeWidget,
} from '../../types';
import { isHistoricalQuery } from '../../Utils';
import NoneTileWidget from './TileWidget/NoneTileWidget';
import ProgressTileWidget from './TileWidget/ProgressTileWidget';
import ScoreTileWidget from './TileWidget/ScoreTileWidget';
import TrendTileWidget from './TileWidget/TrendTileWidget';

interface TileWidgetProps {
  widget: TileCategoryWidgetTypes;
  setWidget?: any;
  data?: Record<string, any>[];
  isLoading?: boolean;
  clickInteractions?: ChartClickInteraction[];
}

const TileWidget: React.FC<TileWidgetProps> = ({ widget, data = [], isLoading, clickInteractions }) => {
  const request = widget.requests[0];
  const allMetrics = request.select.metrics;
  const firstMetric = request.select.metrics[0];
  const metric = firstMetric.alias || firstMetric.name;
  const renderTileWidget = useMemo(
    () => ({
      [TileSubType.Tile]: () => {
        const tileWidget = widget as TileTypeWidget;
        const { visualization } = tileWidget.definition.custom;
        return visualization === TileWidgetVisualization.Progress ? (
          <ProgressTileWidget
            widget={widget as TileTypeWidget}
            metricsValues={allMetrics.map(curMetric => data[0]?.[curMetric.name])}
            clickInteractions={clickInteractions}
          />
        ) : (
          <NoneTileWidget widget={widget} data={data} clickInteractions={clickInteractions} />
        );
      },
      [TileSubType.Score]: () => <ScoreTileWidget widget={widget as ScoreTypeWidget} data={data} isLoading={isLoading} />,
      [TileSubType.Trend]: () =>
        isHistoricalQuery(request) && (
          <TrendTileWidget data={data} metric={metric} widget={widget as TrendTypeWidget} clickInteractions={clickInteractions} />
        ),
    }),
    [data, widget]
  );
  return renderTileWidget[widget.type]();
};

export default TileWidget;
