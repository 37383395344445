import React from 'react';
import { Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { iconSize } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import { appsSideBar } from './newNavigation/sidebar.constants';
import { ReactComponent as ArrowDown } from '../assets/Arrow Down.svg';

const backButton = (right = false, size = 16) => <ArrowDown style={{ transform: `rotate(${right ? 270 : 90}deg)`, ...iconSize(size) }} />;
const separator = <ArrowDown style={{ transform: 'rotate(270deg)', ...iconSize(16) }} />;

export enum EVariant {
  default = 'default',
  backButton = 'backButton',
  drillDown = 'drillDown',
}

export type TBackButtonBreadcrumb = {
  id: string | number;
  title: string;
  value: string;
  projectionName: string;
  onClick?: () => void;
  path: string;
  shouldNavigateBack?: boolean;
};

export type TDrillDownBreadcrumb = {
  title: string;
  value: string;
  onClick?: () => void;
};

interface AvBreadcrumbsProps {
  breadcrumbs?: string[] | TBackButtonBreadcrumb[] | TDrillDownBreadcrumb[];
  projectionName?: string;
  variant?: EVariant;
}

type TDefaultBreadcrumbs = {
  breadcrumbs: string[];
};

enum BreadcrumbNameMap {
  targets = 'Integrations',
  global = 'Scripts Library',
}

const convertToStartCase = path => path.replace(/(^|_|-)(\w)/g, ($0, $1, $2) => (Number.isNaN(+$2) ? ($1 && ' ') + $2.toUpperCase() : $0));
const getLabel = path => BreadcrumbNameMap[path] || convertToStartCase(path);

const BackButtonBreadcrumb: React.FC<{ breadcrumbs: TBackButtonBreadcrumb[] }> = ({ breadcrumbs = [] }) => {
  const navigate = useNavigate();
  const breadcrumb = breadcrumbs.at(-2);
  const { title, value, onClick: originalOnClick, shouldNavigateBack } = breadcrumb!;
  const onClick = shouldNavigateBack ? () => navigate(-1) : originalOnClick;

  return (
    <Box sx={{ ...flex.itemsCenter, gap: 0.5 }}>
      <Box
        sx={{
          ...flex.itemsCenter,
          gap: 1,
          textDecoration: 'none',
          width: '100%',
          flexGrow: 1,
        }}>
        <Box
          sx={{
            ...flex.itemsCenter,
            gap: 0.5,
            color: theme => theme.palette.colors.neutrals[600],
            fontSize: '13px',
            width: '100%',
          }}>
          <Box onClick={onClick} sx={{ ...flex.itemsCenter, gap: 0.5, cursor: onClick ? 'pointer' : 'default' }}>
            {backButton()} Back To
          </Box>
          <Box onClick={onClick} className="back-breadcrumb-title" sx={{ cursor: onClick ? 'pointer' : 'default' }}>
            <AvTooltip>
              {title}: {value}
            </AvTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DefaultBreadcrumbs: React.FC<TDefaultBreadcrumbs> = ({ breadcrumbs = [] }) => (
  <Breadcrumbs separator={separator}>
    {breadcrumbs.map((path, index) => {
      const lastPathName = path?.split('/')?.at(-1);
      const flatMenuItems = Object.keys(appsSideBar).flatMap(app => appsSideBar[app].flatMap(({ subMenu }) => subMenu));
      const label = flatMenuItems.find(v => v.path === lastPathName)?.name;
      const last = index === breadcrumbs.length - 1;
      const to = path || `/${breadcrumbs.slice(0, index + 1).join('/')}`;
      return last ? (
        <Box key={to}>{label || getLabel(path)}</Box>
      ) : (
        <Box component={Link} to={to} key={to}>
          {label || getLabel(path)}
        </Box>
      );
    })}
  </Breadcrumbs>
);

const DrillDownBreadcrumb: React.FC<{ breadcrumbs: TDrillDownBreadcrumb[] }> = ({ breadcrumbs = [] }) =>
  breadcrumbs.map(({ title, value, onClick }, index) => {
    const isLast = index === breadcrumbs.length - 1;
    return (
      <Box
        key={title + value}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
          ...flex.row,
          fontWeight: 600,
          gap: 0.5,
          height: '100%',
          color: theme => theme.palette.colors.neutrals[800],
          fontSize: '16px',
        }}
        onClick={onClick}>
        <AvTooltip disabled={index === 0} title={`${title}: ${value}`}>
          <Box
            sx={{
              ...flex.row,
              ...(!isLast
                ? { ':hover': { color: theme => theme.palette.colors.primary[500], textDecorationLine: 'underline' } }
                : undefined),
            }}>
            {value}
          </Box>
        </AvTooltip>
        {!isLast && <Box sx={{ ...flex.row, ...flex.center, pr: 0.5 }}>{backButton(true, 14)}</Box>}
      </Box>
    );
  });

const renderBreadcrumbs = ({ breadcrumbs, variant }) =>
  ({
    [EVariant.backButton]: <BackButtonBreadcrumb breadcrumbs={breadcrumbs as TBackButtonBreadcrumb[]} />,
    [EVariant.default]: <DefaultBreadcrumbs breadcrumbs={breadcrumbs as string[]} />,
    [EVariant.drillDown]: <DrillDownBreadcrumb breadcrumbs={breadcrumbs as TDrillDownBreadcrumb[]} />,
  })[variant];

const AvBreadcrumbs: React.FC<AvBreadcrumbsProps> = ({ breadcrumbs, variant = EVariant.default }) =>
  renderBreadcrumbs({ breadcrumbs, variant });

export default AvBreadcrumbs;
