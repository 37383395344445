import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { isEqual, isValid, parse } from 'date-fns';
import { ReactComponent as Arrow } from '../../assets/Arrow Down.svg';
import { ReactComponent as Calendar } from '../../assets/Calendar.svg';
import 'react-day-picker/dist/style.css';
import { emptyObject, iconSize, noop } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ClearSelection, FilterStyledPopper } from '../Select';
import TextInput from '../TextInput';
import { epochDateString, RelativeDatePeriod, RelativeDateType } from './AvDateRangePicker.constants';
import { CalenderPicker, DynamicRangeSelect, NotSetOption, RelativeDateOptions } from './PickerComponents';
import ShortVal from './ShortVal';
import { DateRangeValueType, DateValue, PredefinedPreset, Preset, ValueWithPreset } from './types';
import {
  customPreset,
  dateFormat,
  formatDate,
  getDatesFromDefinition,
  getEndOfDay,
  getInputValue,
  getStartOfDay,
  RadioButtonValue,
  RelativeUnit,
  validateValue,
} from './utils';

interface DateRangePickerProps {
  value?: DateRangeValueType;
  activePresetValue?: Preset;
  predefinedPresets?: PredefinedPreset[];
  onChange: (data: ValueWithPreset) => void;
  onClose?: () => void;
  isRange?: boolean;
  showRelativeOptions?: boolean;
  applyOnChange?: boolean;
  showAsCalendar?: boolean;
  isEditableInput?: boolean;
  showInputIcon?: boolean;
  inputSx?: any;
  timeOptions?: any[];
  acceptNullDate?: boolean;
  showDropdownIcon?: boolean;
  allowPastDatesOnDayPicker?: boolean;
  allowFutureDates?: boolean;
  isFilterExpression?: boolean;
  startAdornment?: () => React.ReactNode;
  icon?: React.ReactNode;
  showOptions?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  defaultRadioValue?: RadioButtonValue;
  showIntervalShortVal?: boolean;
  customFormat?: string;
  showMinutesAndHours?: boolean;
}

const AvDateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  activePresetValue,
  onChange,
  onClose = noop,
  isRange = false,
  showRelativeOptions = false,
  predefinedPresets,
  applyOnChange = false,
  showAsCalendar = false,
  isEditableInput = true,
  showInputIcon = true,
  inputSx = emptyObject,
  timeOptions,
  acceptNullDate = true,
  showDropdownIcon = false,
  allowPastDatesOnDayPicker = true,
  allowFutureDates = true,
  isFilterExpression = false,
  startAdornment,
  icon,
  showOptions = true,
  inputProps = emptyObject,
  defaultRadioValue = undefined,
  showIntervalShortVal = false,
  customFormat = '',
  showMinutesAndHours = false,
}) => {
  const theme = useTheme();
  const [preset, setPreset] = useState<Preset | undefined>(
    activePresetValue ||
      (isEqual(validateValue(value, isRange)?.from || 0, new Date(epochDateString)) ? { type: RelativeDateType.before } : undefined)
  );
  const [radioValue, setRadioValue] = useState(defaultRadioValue ?? (value !== null ? RadioButtonValue.relative : RadioButtonValue.empty));
  const [isPreset, setIsPreset] = useState(!!activePresetValue);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<DateRangeValueType>(() =>
    activePresetValue ? getDatesFromDefinition(preset) : validateValue(value, isRange)
  );
  const [inputFocus, setInputFocus] = useState(false);
  const [isInputError, setIsInputError] = useState(false);
  const [editableInputValue, setEditableInputValue] = useState(getInputValue({ date, isRange, timeOptions, preset }));
  const [month, setMonth] = useState(isRange && date ? date.from : null);
  const anchorEl = useRef<any>();

  useEffect(() => {
    const shouldSyncValueToPreset =
      isRange && date && value && showRelativeOptions && activePresetValue && !isEqual(new Date(value.from), date.from);
    if (shouldSyncValueToPreset) {
      onChange({ value: date, preset });
    }
  }, []);

  const handleClose = (revertChanges = true) => {
    onClose();
    setOpen(false);
    if (revertChanges) {
      setDate(value);
      setPreset(activePresetValue);
    }
  };

  const onSelect = (value, isFromPicker = false, newPreset = preset) => {
    const isMinutesOrHours = newPreset?.period && [RelativeDatePeriod.hours, RelativeDatePeriod.minutes].includes(newPreset.period);
    if (value === null) {
      setDate(null);
      if (applyOnChange) {
        onChange({ value: null, preset: undefined });
        setOpen(false);
      }
    } else {
      const updateValue: DateValue | undefined =
        value === undefined
          ? undefined
          : isRange
            ? {
                from:
                  newPreset?.type === RelativeDateType.before
                    ? new Date(epochDateString)
                    : isMinutesOrHours
                      ? value.from
                      : getStartOfDay(value.from),
                to: value?.to ? (isMinutesOrHours ? value.to : getEndOfDay(value.to)) : isRange ? getEndOfDay(value.from) : undefined,
              }
            : { from: value.from ? value.from : value };

      setDate(updateValue);
      setIsPreset(newPreset?.type === RelativeDateType.before ? false : !isFromPicker);
      setEditableInputValue(getInputValue({ date: updateValue, isRange, timeOptions, preset: newPreset }));
      setIsInputError(false);
      setInputFocus(false);
      if (isFromPicker) {
        setPreset({});
      }
      if (applyOnChange) {
        const finalPreset = !isFromPicker && (isNull || !newPreset) ? undefined : newPreset;
        const finalValue =
          isFilterExpression && finalPreset && finalPreset
            ? ({
                relative: {
                  unit: finalPreset.period === 'days' ? 'DAYS' : 'MONTHS',
                  value: finalPreset.count,
                  direction: finalPreset.type,
                },
              } as any)
            : updateValue;

        onChange({ value: finalValue, preset: finalPreset });
        setOpen(false);
      }
    }
  };

  const validateInput = () => {
    const inputDates = editableInputValue
      ? editableInputValue.split('-').map(date => {
          const parsedDate = parse(date.trim(), dateFormat, new Date());
          return isValid(parsedDate) ? formatDate(parsedDate, dateFormat) : null;
        })
      : undefined;
    const filteredValues = inputDates ? inputDates.reduce<string[]>((acc, i) => (i ? [...acc, i] : acc), []) : [];
    if (filteredValues.length === inputDates?.length) {
      const sortedValues = filteredValues.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
      });
      onSelect(
        isRange
          ? preset?.type === RelativeDateType.before
            ? undefined
            : {
                from: new Date(sortedValues[0]),
                to: getEndOfDay(new Date(sortedValues[1])),
              }
          : { from: new Date(filteredValues[0]) },
        true
      );
      setEditableInputValue(sortedValues.join(' - '));
      setIsInputError(false);
      setIsPreset(false);
    } else {
      setIsInputError(true);
    }
  };

  const updatePreset = (key, value) => {
    const next = { ...preset, [key]: value };
    setPreset(next);
    const date = getDatesFromDefinition(next);
    onSelect(date, undefined, next);
  };

  const onPresetClick = p => {
    if (p.title === customPreset) {
      onSelect(undefined);
      setPreset({});
      return;
    }
    setPreset(p.definition);
    const date = getDatesFromDefinition(p.definition);
    onSelect(date, undefined, p.definition);
    setMonth(date.from);
  };

  const isNull = radioValue === RadioButtonValue.empty;
  const isRelativeOptions = !isNull && showRelativeOptions;
  const inputError = isInputError ? `format is ${dateFormat}` : '';

  const onSetRadioButton = v => {
    setRadioValue(v);
    if (v === RadioButtonValue.empty) {
      onSelect(null);
    } else {
      onSelect(validateValue(value, isRange));
      setPreset(activePresetValue);
    }
  };

  const onClearSelection = () => {
    onSelect(undefined);

    if (radioValue === RadioButtonValue.empty) {
      setRadioValue(RadioButtonValue.relative);
    }
  };

  const picker = (
    <Box>
      {showRelativeOptions && showOptions && (
        <>
          <NotSetOption value={radioValue} onChange={onSetRadioButton} />
          <Divider />
        </>
      )}
      <Box sx={sx(theme, isRange)}>
        {isRelativeOptions && (
          <>
            <RelativeDateOptions
              allowFutureDates={allowFutureDates}
              onPresetClick={onPresetClick}
              isNull={isNull}
              isPreset={isPreset}
              preset={preset}
              predefinedPresets={predefinedPresets}
            />
            <Divider flexItem orientation="vertical" sx={{ borderColor: theme.palette.colors.neutrals[200] }} />
          </>
        )}
        <Box sx={{ ...flex.col, gap: 3, pt: 2, pb: isEditableInput && !applyOnChange ? 0 : 2 }}>
          {isNull && (
            <Typography
              sx={{
                ...flex.center,
                color: theme.palette.colors.neutrals[500],
                minWidth: 500,
                minHeight: 200,
                height: '100%',
                fontWeight: 600,
              }}>
              No Date Set
            </Typography>
          )}
          {showRelativeOptions && (
            <DynamicRangeSelect
              isNull={isNull}
              disablePortal={showAsCalendar}
              predicate={({ value }) => allowFutureDates || [RelativeDateType.last, RelativeDateType.previous].includes(value)}
              preset={preset}
              inputFocus={inputFocus}
              onBlur={() => setInputFocus(false)}
              updatePreset={updatePreset}
              showMinutesAndHours={showMinutesAndHours}
            />
          )}
          {!isNull && (
            <CalenderPicker
              onSelect={v => {
                if (v || acceptNullDate) {
                  onSelect(v, preset?.type !== RelativeDateType.before);
                }
              }}
              date={date}
              isRange={isRange}
              month={month}
              onMonthChange={setMonth}
              allowFutureDates={allowFutureDates}
              allowPastDatesOnDayPicker={allowPastDatesOnDayPicker}
              timeOptions={timeOptions}
              onChangeTimeOptions={v => {
                if (date) {
                  const time = v.split(':');
                  date.from.setHours(time[0]);
                  date.from.setMinutes(time[1]);
                  onSelect(date);
                }
              }}
            />
          )}
        </Box>
      </Box>
      {!applyOnChange && (
        <Box sx={{ ...flex.col }}>
          <Divider />
          <Box sx={{ ...flex.justifyBetweenCenter, pl: 2, pr: 3, py: 2 }}>
            <Box sx={{ ...flex.row, gap: 1 }}>
              <Box>
                {isEditableInput && !isNull && (
                  <TextInput
                    disabled={isNull}
                    style={{ width: 250 }}
                    size="small"
                    onBlur={validateInput}
                    onChange={setEditableInputValue}
                    value={editableInputValue}
                    error={inputError}
                    disableClearButton
                    autoComplete="off"
                    tooltipContent={inputError}
                    onKeyDown={e => e.code === 'Enter' && validateInput()}
                  />
                )}
              </Box>
              <ClearSelection disabled={!date} onClick={onClearSelection} />
            </Box>
            <Box>
              <Button size="small" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                size="small"
                disabled={isNull || date === undefined ? false : isRange && (!date?.from || !date.to)}
                variant="contained"
                onClick={() => {
                  const finalPreset = !!date && !isNull && isPreset && preset ? preset : undefined;
                  const finalValue: any =
                    isFilterExpression && finalPreset && finalPreset.type !== RelativeDateType.before
                      ? {
                          relative: {
                            unit: RelativeUnit[finalPreset.period?.toUpperCase() ?? ''],
                            value: finalPreset.count,
                            direction: finalPreset.type,
                          },
                        }
                      : date;

                  onChange({ value: finalValue, preset: finalPreset });
                  handleClose(false);
                }}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );

  const TextInputProps: any = {
    size: 'small',
    value:
      value === null ? 'Not Set' : `${getInputValue({ date: validateValue(date, isRange), isRange, timeOptions, preset, customFormat })}`,
  };

  const iconComponent = icon || (
    <Box sx={{ ...flex.center, gap: 1 }}>
      {showInputIcon && <Calendar />}
      {showIntervalShortVal && <ShortVal value={preset?.count} unit={preset?.period} />}
    </Box>
  );

  return showAsCalendar ? (
    picker
  ) : (
    <ClickAwayListener onClickAway={() => (open ? handleClose() : noop())}>
      <Box>
        <TextInput
          {...TextInputProps}
          sx={{
            width: 280,
            ...inputSx,
          }}
          helperText={isRange ? 'Select Dates' : 'Select Date'}
          autoComplete="off"
          onClick={() => setOpen(true)}
          startAdornment={startAdornment}
          icon={iconComponent}
          ref={anchorEl}
          disableClearButton
          actionIcons={
            showDropdownIcon
              ? [
                  <IconButton sx={{ color: theme.palette.colors.neutrals[500] }}>
                    <Arrow style={iconSize(16)} />
                  </IconButton>,
                ]
              : undefined
          }
          {...inputProps}
        />
        <FilterStyledPopper open={open} anchorEl={anchorEl.current} sx={{ p: 0 }} placement="bottom-start">
          {picker}
        </FilterStyledPopper>
      </Box>
    </ClickAwayListener>
  );
};

export default AvDateRangePicker;

const sx = (theme, isRange) => ({
  ...flex.row,
  '.rdp': {
    margin: '0px 24px',
    background: theme.palette.white.main,
    '--rdp-cell-size': '30px',
    '--rdp-accent-color': theme.palette.colors.primary[100],
    '--rdp-background-color': theme.palette.colors.primary[100],

    color: theme.palette.colors.neutrals[800],
    '.rdp-day': {
      fontSize: 13,
    },
    '.rdp-head_cell': {
      color: theme.palette.colors.neutrals[500],
      textTransform: 'capitalize',
    },
    '.rdp-table': {
      borderSpacing: '0px 3px',
      borderCollapse: 'separate',
    },
    '.rdp-day:not(.rdp-day_selected)': {
      borderRadius: '10px',
    },
    ...(!isRange && {
      '.rdp-day_selected': {
        background: theme.palette.colors.primary[400],
        color: theme.palette.white.main,
        borderRadius: '10px',
      },
    }),
    '.rdp-day_outside': {
      color: theme.palette.colors.neutrals[500],
      '&.rdp-day_range_middle': {
        background: theme.palette.colors.primary[100],
        color: theme.palette.colors.neutrals[500],
        opacity: 1,
      },
    },
    '.rdp-day_range_end,.rdp-day_range_start': {
      borderRadius: '10px',
      background: theme.palette.colors.primary[400],
      color: theme.palette.white.main,
    },
    '.rdp-day_range_middle': {
      color: theme.palette.colors.neutrals[800],
    },
    '.rdp-day_today': {
      fontWeight: 'normal',
      border: `1px solid ${theme.palette.colors.neutrals[850]}`,
      borderRadius: '10px',
      '&.rdp-day_range_middle': {
        borderRadius: 0,
      },
    },
  },
});
