import React from 'react';
import { LinearProgress, Typography, Box } from '@mui/material';

interface AvProgressProps {
  value?: number;
  height?: number;
  width?: number;
  showBar?: boolean;
  textStyle?: React.CSSProperties;
}

const AvProgress: React.FC<AvProgressProps> = ({ value = 0, height = 8, width = 72, showBar = true, textStyle = {} }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <Typography
      sx={(theme: any) => ({
        color: value > 0 ? theme.palette.colors.positive[500] : theme.palette.colors.neutrals[500],
        fontSize: 12,
        minWidth: 31,
        textAlign: 'right',
        whiteSpace: 'nowrap',
        ...textStyle,
      })}>
      {Number.isNaN(value) ? '0' : value}%
    </Typography>
    {showBar && (
      <LinearProgress
        variant="determinate"
        sx={(theme: any) => ({
          borderRadius: '3px',
          background: theme.palette.colors.neutrals[350],
          height,
          width,
          [`& .MuiLinearProgress-bar`]: { background: theme.palette.colors.positive[400], borderRadius: 0 },
        })}
        value={value || 0}
      />
    )}
  </Box>
);

export default AvProgress;
