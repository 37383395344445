import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { decodeJWT } from 'aws-amplify/auth';
import { getAccountTokenFromLS, sharedWorkerAuthFlow, shouldSkipUILogin, useUserIsActive } from '../utils/auth.utils';
import { getAccessTokenTimeout } from '../utils/localStorageAuthUtils.utils';
import { convertFromSecondsToMilliseconds, noop } from '../utils/Utils';

export const useAccessToken = (getAccessTokenSilently, isAuthenticated, accountId, isOnline, userIsActive) => {
  const timeout = sharedWorkerAuthFlow() ? undefined : getAccessTokenTimeout();

  const sharedOptions = {
    enabled: isOnline && userIsActive && isAuthenticated,
    gcTime: 0,
  };

  const oldFlowOptions = {
    refetchInterval: timeout,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: isOnline,
    staleTime: timeout,
  };

  const accessTokenQuery = useQuery({
    queryKey: ['access_token'],
    queryFn: async () => (isOnline ? getAccessTokenSilently(accountId) : Promise.resolve()),
    ...(sharedWorkerAuthFlow() ? sharedOptions : { ...sharedOptions, ...oldFlowOptions }),
  });

  return shouldSkipUILogin()
    ? {
        data: getAccountTokenFromLS(accountId),
        isPending: false,
        refetch: noop,
        isRefetching: false,
      }
    : accessTokenQuery;
};

/** Custom hook to track whether the user is active or idle.
 * It monitors user activity and determines if the user has been inactive
 * beyond the token timeout period.
 * @returns {boolean} - Returns `true` if the user is active, `false` if inactive or idle.
 * */
export const useUserActivityStatus = () => {
  const [userIsActive, setUserIsActive] = useState(true);
  const timeout = getAccessTokenTimeout();
  useUserIsActive({ setIsNotIdle: setUserIsActive, accessTokenTimeout: timeout });

  return userIsActive;
};

export const isTokenExpired = accountId => {
  if (!accountId) {
    return true;
  }
  const token = getAccountTokenFromLS(accountId);
  if (!token) {
    return true; // Token is considered expired if it's missing
  }

  const { exp } = decodeJWT(token).payload;
  return !!exp && new Date().getTime() >= convertFromSecondsToMilliseconds(exp);
};
