import React from 'react';
import { gql } from '@apollo/client';
import RunsPage from '../../components/RunsPage';
import { useAvContext } from '../../context/AvContextProvider';
import { ExecutionType } from '../../types/executionRules.types';

export const GET_WORKFLOWS_RUNS_BY_TYPE = gql`
  query getWorkflowsByType($id: String!, $searchAttributeType: String!) {
    getWorkflowsByType(id: $id, searchAttributeType: $searchAttributeType)
  }
`;

const BuiltinExecutionRulesRuns: React.FC<{ executionType: ExecutionType; pagePath: string }> = ({ executionType, pagePath }) => {
  const { getPathName } = useAvContext();

  return (
    <RunsPage
      id={executionType!}
      getWorkflowsQuery={GET_WORKFLOWS_RUNS_BY_TYPE}
      noDataProps={{
        goBackTitle: 'Factor Rules',
        goBackPath: getPathName(pagePath),
      }}
      title="Builtin Execution Rule Sync Runs"
      allowExpandingRows
      shouldShowCategory={false}
      queryOptions={{ searchAttributeType: 'BUILTIN_SYNC' }}
      shouldShowProjectionName={false}
    />
  );
};

export default BuiltinExecutionRulesRuns;
