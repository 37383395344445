import React from 'react';
import { useParams } from 'react-router-dom';
import { FactorKind } from '../../types/executionRules.types';
import AuditLogs from '../AuditLogs/AuditLogs';
import { AuditEntityType } from '../AuditLogs/types';
import { useGetFactorLogicByKind } from './hooks';


export default function AuditLogsFactorRules({kind}) {
  const { id } = useParams();
  const { data , isLoading  } = useGetFactorLogicByKind({kind:kind!, id: id! });
  const entityType = kind === FactorKind.BUILT_IN ? AuditEntityType.BUILTIN_EXECUTION_RULE : AuditEntityType.EXECUTION_RULE;
  return (
      <AuditLogs
          id={id}
          entityType={entityType}
          title={`Audit Logs - ${data?.metadata.name}`}
          isQueryLoading={isLoading}
      />
  );}
