import React from 'react';
import { RelativeDatePeriod, RelativeDateType } from '../../../../components/DatePicker/AvDateRangePicker.constants';
import { RelativeUnit } from '../../../../components/DatePicker/utils';
import { ReactComponent as Automations } from '../../../../assets/Automations.svg';
import { ReactComponent as History } from '../../../../assets/History.svg';

export const TimeframeOptions = {
  current: { value: 'Current', icon: <Automations /> },
  historic: { value: 'Historical', icon: <History /> },
};

export const dateFormat = 'MMM dd, yy';

export const newDefaultTimeRange = {
  value: {
    relative: {
      unit: RelativeUnit.DAYS,
      value: 30,
      periodBehaviour: RelativeDateType.last,
    },
  },
  preset: {
    count: 30,
    period: RelativeDatePeriod.days,
    type: RelativeDateType.last,
  },
};
