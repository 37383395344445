// eslint-disable-next-line import/prefer-default-export
export enum QueryKeysEnums {
  accountUsageSources = 'accountUsageSources',
  mappingPreview = 'mappingPreview',
  sourceTypeList = 'sourceTypeList',
  enhancedAggProjs = 'enhancedAggProjs',
  detectionSources = 'detectionSources',
  notificationTargetsTicketsDropdown = 'notification_targets_tickets_dropdown',
  relations = 'relations',
}
